import { LAST_MODIFIED_TIME, LEGAL_HOLD_TIME_PERIOD } from '../constants'
import { DateRangeParams } from '../services/api/apiTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { IntlShape } from 'react-intl'

dayjs.extend(utc)
dayjs.extend(relativeTime)
interface TimeStamp {
  startTimestamp: number
  endTimestamp: number
}
export const transformDateStringToTimestamp = (time: string | number): number => {
  return dayjs.utc(time).valueOf()
}

/** Timestamp to date format string */
export const formatDate = (time: number | string, isCompact = false): string => {
  return dayjs
    .utc(time)
    .local()
    .format(`MMM DD${isCompact ? '' : ', YYYY'}`)
}

export const formatTime = (time: number | string): string => {
  return dayjs.utc(time).local().format('HH:mm')
}

export const formatDateTimeString = (time: string): string => {
  return dayjs(time).local().format('DD MMM YYYY, hh:mm A')
}

export const formatDateTime = (time: number): string => {
  return dayjs(time).local().format('DD MMM YYYY, hh:mm A')
}

export const formatDateTimeStringToPrettyDateTime = (time: string | number): string => {
  return dayjs(time).local().fromNow()
}

export const formatDateTimeStringToPrettyDateTimeWithoutPrefix = (
  time: string | number
): string => {
  return dayjs(time).local().fromNow(true)
}

export const formatUTCDateTimeStringToPrettyDateTime = (time: string | number): string => {
  return dayjs.utc(time).local().fromNow()
}

export const getDatesInRange = (
  start: Date | number,
  end: Date | number,
  interval: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year',
  asUnixTimestampsMS = false
): (number | Date)[] => {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  const diffInUnits = endDate.diff(startDate, interval)
  return Array.from(Array(diffInUnits + 1).keys()).map((i) => {
    return asUnixTimestampsMS
      ? startDate.add(i, interval).valueOf()
      : startDate.add(i, interval).toDate()
  })
}

export const mapStringToTimeStamp = (lastModifiedString?: string): TimeStamp => {
  const currentDate: Date = new Date()
  const MONTH: number = 30 * 24 * 60 * 60 * 1000

  let endTimestamp: number
  const currentEpochTime = currentDate.getTime()

  switch (lastModifiedString) {
    case LAST_MODIFIED_TIME.threeMonths:
      endTimestamp = currentEpochTime - 3 * MONTH
      break
    case LAST_MODIFIED_TIME.sixMonths:
      endTimestamp = currentEpochTime - 6 * MONTH
      break
    case LAST_MODIFIED_TIME.oneYear:
      endTimestamp = currentEpochTime - 12 * MONTH
      break
    case LAST_MODIFIED_TIME.threeYears:
      endTimestamp = currentEpochTime - 3 * 12 * MONTH
      break
    case LAST_MODIFIED_TIME.fiveYears:
      endTimestamp = currentEpochTime - 5 * 12 * MONTH
      break
    case LAST_MODIFIED_TIME.sevenYears:
      endTimestamp = currentEpochTime - 7 * 12 * MONTH
      break
    case LAST_MODIFIED_TIME.tenYears:
      endTimestamp = currentEpochTime - 10 * 12 * MONTH
      break
    default:
      endTimestamp = currentEpochTime
      break
  }
  const endMonthStart = new Date(endTimestamp)
  endMonthStart.setDate(1)
  endMonthStart.setHours(0, 0, 0, 0)
  endTimestamp = endMonthStart.getTime()

  const startTimestamp = new Date(0).getTime()

  //To convert timestamp to seconds
  const startTimestampInSeconds = Math.floor(startTimestamp / 1000)
  const endTimestampInSeconds = Math.floor(endTimestamp / 1000)

  return { startTimestamp: startTimestampInSeconds, endTimestamp: endTimestampInSeconds }
}

/**
 * Maps timeString of WITHIN range to timeStamp values.
 * Eg. WITHIN_THREE_MONTHS.
 *
 * @param lastModifiedString
 * @returns
 */
export const mapWithinStringToTimeStamp = (lastModifiedString?: string): TimeStamp => {
  const currentDate: Date = new Date()
  const MONTH: number = 30 * 24 * 60 * 60 * 1000

  let startTimestamp: number
  const currentEpochTime = currentDate.getTime()

  switch (lastModifiedString) {
    case LAST_MODIFIED_TIME.withinThreeMonths:
      startTimestamp = currentEpochTime - 3 * MONTH
      break
    case LAST_MODIFIED_TIME.withinSixMonths:
      startTimestamp = currentEpochTime - 6 * MONTH
      break
    case LAST_MODIFIED_TIME.withinOneYear:
      startTimestamp = currentEpochTime - 12 * MONTH
      break
    default:
      startTimestamp = currentEpochTime
      break
  }

  const endTimestamp = currentEpochTime

  //To convert timestamp to seconds
  const startTimestampInSeconds = Math.floor(startTimestamp / 1000)
  const endTimestampInSeconds = Math.floor(endTimestamp / 1000)

  return { startTimestamp: startTimestampInSeconds, endTimestamp: endTimestampInSeconds }
}

export const filterCurrentDayData = (stats) =>
  stats.filter(
    ({ endTimestamp, startTimestamp }) =>
      dayjs(endTimestamp).diff(dayjs(startTimestamp), 'day') !== 0
  )

export const getYesterday = () => {
  const now = new Date().toISOString()
  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)

  return yesterday.toISOString()
}

export const getDate4WeeksBefore = () => {
  const date4WeeksAfter = new Date()
  date4WeeksAfter.setDate(date4WeeksAfter.getDate() - 4 * 7)

  return date4WeeksAfter.toISOString()
}

export const formatEpochToString = (epoch) => {
  const epochMilliseconds = parseInt(epoch) * 1000
  const date = new Date(epochMilliseconds)
  const day = date.getDate().toString().padStart(2, '0')
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()
  let hours = date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, '0')
  let period = 'AM'

  if (hours >= 12) {
    hours -= 12
    period = 'PM'
  }
  if (hours === 0) {
    hours = 12
  }

  const formattedString = `${day} ${month} ${year}, ${hours}:${minutes}`

  return { formattedString, period }
}

export const formatIsoDateString = (isoDateString: string) => {
  const date = new Date(isoDateString)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  let hours = date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, '0')
  let period = 'AM'
  if (hours >= 12) {
    hours -= 12
    period = 'PM'
  }
  if (hours === 0) {
    hours = 12
  }
  return { formattedString: `${month}/${day}/${year} ${hours}:${minutes}`, period }
}

export const mapTimePeriodToDuration = (timePeriod: string): number => {
  const daysInAMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  }

  switch (timePeriod) {
    case LEGAL_HOLD_TIME_PERIOD.thirtyDays:
      return 30
    case LEGAL_HOLD_TIME_PERIOD.ninetyDays:
      return 90
    case LEGAL_HOLD_TIME_PERIOD.sixMonths:
      return 6 * daysInAMonth.reduce((acc, days) => acc + days, 0)
    case LEGAL_HOLD_TIME_PERIOD.oneYear:
      return isLeapYear(new Date().getFullYear()) ? 366 : 365
    case LEGAL_HOLD_TIME_PERIOD.twoYears:
      return isLeapYear(new Date().getFullYear()) ? 2 * 366 : 2 * 365
    default:
      throw new Error('Invalid time period')
  }
}

/**
 * Returns the date/time in
 * the specified format.
 * Uses `dayjs`.
 *
 * @param {Date | string} time The time to format.
 * @param {string} format The expected format.
 * @returns {string} The formatted time.
 */
export const getFormattedDateTime = (time: Date | string, format: string): string => {
  return dayjs(time).format(format)
}

export const formatUtcToDateString = (utcDateString: string, intl: IntlShape) => {
  const isoDateFormatted = utcDateString.replace(' ', 'T')
  const date = new Date(isoDateFormatted)

  if (isNaN(date.getTime())) {
    return 'Invalid Date'
  }

  const day = date.getUTCDate()
  const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' })
  const year = date.getUTCFullYear()
  let hours = date.getUTCHours()
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')
  const period = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12

  const translatedPeriod = intl?.formatMessage({
    id: period === 'AM' ? 'timePeriod.AM' : 'timePeriod.PM'
  })

  return `${day} ${month} ${year}, ${hours}:${minutes}${translatedPeriod}`
}

/**
 * Returns the date range string without year.
 *
 * @param {DateRangeParams} dateRange valid dateRange object.
 * @returns {string} `-` separated start and end dates
 *
 * @example
 * // returns '01 Jan - 31 Jan'
 * getDateRangeWithoutYear({ startDate: '2024-01-01', endDate: '2024-01-31' })
 */

export const getDateRangeWithoutYear = (dateRange: DateRangeParams): string => {
  const { startDate, endDate } = dateRange
  const format = 'DD MMM'
  return `${getFormattedDateTime(startDate, format)} - ${getFormattedDateTime(endDate, format)}`
}
