import { DsrForm, DsrFormAttribute } from './dsrFormsSlice'
import { gql } from 'graphql-request'

export const queryDsrFormAttributes = (): string => {
  return gql`
    {
      attribute {
        count
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}

export const mapQueryDsrFormAttributes = (raw: any): DsrFormAttribute[] => {
  return raw.attribute.edges.map(({ node: attr }) => ({
    id: attr.id,
    name: attr.name,
    internalName: attr.internalName
  }))
}

export const mutationSaveDsrForm = (form: DsrForm) => {
  return gql`
    mutation {
      createDsrForm(clientMutationId: "createDsrForm", dsrFormData: {
        name: ${JSON.stringify(form.name || '')}
        type: ${form.type}
        description: ${JSON.stringify(form.description || '')}
        locations: ${JSON.stringify(form.locations || [])}
        isDraft: false
        isTemplate: ${!!form.isTemplate}
        createdBy: "${form.createdBy || ''}"
        template: {
          name: ${JSON.stringify(form.template?.name || '')}
        }
        logoFileUrl: "${form.logoFileUrl || ''}"
        formRawJSON: ${JSON.stringify(form.formRawJSON || '')}
      }) {
        clientMutationId
        dsrFormId
      }
    }
  `
}

export const mutationUpdateDsrForm = (form: DsrForm) => {
  return gql`
    mutation {
      updateDsrForm(clientMutationId: "createDsrForm", dsrFormId: "${form.id}", dsrFormData: {
        name: ${JSON.stringify(form.name || '')}
        type: ${form.type}
        description: ${JSON.stringify(form.description || '')}
        locations: ${JSON.stringify(form.locations || [])}
        isDraft: false
        isTemplate: ${!!form.isTemplate}
        createdBy: "${form.createdBy || ''}"
        template: {
          name: ${JSON.stringify(form.template?.name || '')}
        }
        logoFileUrl: "${form.logoFileUrl || ''}"
        formRawJSON: ${JSON.stringify(form.formRawJSON || '')}
      }) {
        clientMutationId
        dsrFormId
      }
    }
  `
}

export const mutationDeleteDsrForm = (formId: string) => {
  return gql`
    mutation {
      deleteDsrForm(clientMutationId: "deleteDsrForm", dsrFormId: "${formId}"){ clientMutationId }
    }
  `
}

export const mutationPublishDsrForm = (formId: string) => {
  return gql`
    mutation {
      publishDsrForm(clientMutationId: "publishDsrForm", dsrFormId: "${formId}"){ clientMutationId }
    }
  `
}

export const mutationUnpublishDsrForm = (formId: string) => {
  return gql`
    mutation {
      unpublishDsrForm(clientMutationId: "unpublishDsrForm", dsrFormId: "${formId}"){ clientMutationId }
    }
  `
}

export const mutationResendVerification = (email: string) => {
  return gql`
    mutation {
      resendDsrVerificationEmail(clientMutationId: "resendDsrVerificationEmail", dataSubjectEmailId: "${email}"){ clientMutationId }
 }
  `
}

export const queryTotalUserForms = (): string => {
  return gql`
    {
      dataSubjectRequestForm(booleanFilter: [{ key: IS_TEMPLATE, value: false }]) {
        count
      }
    }
  `
}

export const mapQueryTotalUserForms = (raw: any): number => {
  return raw.dataSubjectRequestForm.count || 0
}
