import { IGetListParams, Notification, NotificationStatus } from './notificationsSlice'
import { LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryNotificationsList = ({ page, filters, startDate }: IGetListParams): string => {
  const cursor = page ? getAfterCursor(page, LIMIT_DEFAULT) : ''
  const dateFilter = startDate ? `dateFilter: {key: UPDATE_TIMESTAMP, start: ${startDate}}` : ''
  let filter = ''
  if (filters?.filter?.length) {
    filter = `,filter:${parameterizeArrayofObjects(filters.filter)}`
  }
  return gql`
    {
      notification(first: ${LIMIT_DEFAULT}, after: "${cursor}" ${filter}, sortField: UPDATE_TIMESTAMP, ${dateFilter}){
        count
        edges {
          node {
            id
            title
            updateTimestamp
            notifiedAt
            icon
            attachments {
              id
              name
              url
            }
            module {
              type
              moduleObject {
                ... on Alert{
                  id
                  severity
                  assignees
                  datasource {
                    edges {
                      node {
                        type
                        id
                      }
                    }
                  }
                }
                ... on DSRRequestNotification{
                  id
                }
                ... on DSRTicketNotification{
                  id
                }
                ... on RopaPiaCollaborationNotification{
                  id
                }
                ... on DatasourceNotification{
                  id
                  type
                  dsType
                }
                ... on Policy{
                  id
                  policyType{
                    edges{
                      node{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const queryNotificationsListCompact = ({ filters, startDate }: IGetListParams): string => {
  const dateFilter = startDate ? `dateFilter: {key: UPDATE_TIMESTAMP, start: ${startDate}}` : ''
  let filter = ''
  if (filters?.filter?.length) {
    filter = `,filter:${parameterizeArrayofObjects(filters.filter)}`
  }
  return gql`
    {
      notification(${filter}, sortField: UPDATE_TIMESTAMP, ${dateFilter}){
        count
        edges {
          node {
            id
            title
            updateTimestamp
            icon
            module {
              type
              moduleObject {
                ... on Alert{
                  id
                  severity
                  assignees
                  datasource {
                    edges {
                      node {
                        type
                        id
                      }
                    }
                  }
                }
                ... on DSRRequestNotification{
                  id
                }
                ... on DSRTicketNotification{
                  id
                }
                ... on RopaPiaCollaborationNotification{
                  id
                }
                ... on DatasourceNotification{
                  id
                  type
                  dsType
                }
                ... on Policy{
                  id
                  policyType{
                    edges{
                      node{
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

/**@todo Ahmad: add type for graphql response */
export const mapQueryNotificationsList = (
  raw: any
): {
  list: Notification[]
  total: number
} => {
  try {
    const list: Notification[] =
      raw.notification?.edges?.map(({ node }) => {
        return {
          id: node.id || '',
          title: node.title || '',
          updateTimestamp: node.updateTimestamp || '',
          notifyEmails: node.notifiedAt || [],
          isPending: node.notifiedAt?.[0] === NotificationStatus.pending,
          icon: node.icon,
          attachments: node.attachments || [],
          module: {
            id: node.module?.moduleObject?.id || '',
            type: node.module?.type || '',
            severity: node.module?.moduleObject?.severity || '',
            assignee: node.module?.moduleObject?.assignees?.[0] || '',
            datasourceType: node.module?.moduleObject?.datasource?.edges?.[0]?.node?.type || '',
            datasourceId: node.module?.moduleObject?.datasource?.edges?.[0]?.node?.id || '',
            policyType: node.module?.moduleObject?.policyType?.edges?.[0]?.node?.name || ''
          }
        }
      }) || []
    return { list, total: raw.notification?.count || 0 }
  } catch (e) {
    console.error(e)
    return { list: [], total: 0 }
  }
}
