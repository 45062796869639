import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './dataTable.less'
import RegularColumn from './components/regularColumn'
import PopupInfo from '../PopupInfo/popupInfo'
import { getMaskedString } from '../../utils/stringUtil'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridOptions } from 'ag-grid-community'
import { Checkbox, TableBodyProps } from 'semantic-ui-react'
import { IntlShape } from 'react-intl'
import React, { useCallback, useMemo, useRef, useState } from 'react'

export interface GetColumnBaseParams {
  intl?: IntlShape
}
export interface IDataExpandable {
  isExpanded: boolean
  cellRenderer: () => React.ReactElement | string | number | boolean
}
export type ExpandedableListItem<T> = T & { expandedMasterId?: string }
export interface TableParams extends GridOptions {
  rowHeight?: number
  hasActionColumn?: boolean
  tableFlex?: number
  className?: string
  rowSelection?: 'multiple' | 'single'
  expandedRowHeight?: number
  tableColumnsResizable?: boolean
  isPrint?: boolean
  onGridUpdate?: (event) => void
  setApi?: (event) => void
  setGridRef?: (ref) => void
}
export interface ITableColumn<T = any> extends ColDef<T> {
  title?: string
  dataKey?: string
  masking?: boolean
  checkboxColumn?: boolean
  maskingToggleDisabled?: boolean
  center?: boolean
}
interface IProps {
  columns: ITableColumn[]
  data: TableBodyProps[]
  tableParams?: TableParams
}

export const AG_CLASS_DISABLED_CHECKBOX_ROW = 'ag-row-cb-disabled'
const DataTable = ({ columns, data, tableParams }: IProps) => {
  const maskingDefaultConfig = columns
    .filter(({ masking }) => masking)
    .reduce((acc, { dataKey = '' }) => {
      acc[dataKey] = true
      return acc
    }, {})
  const [maskingToggle, setMaskingToggle] = useState(maskingDefaultConfig)
  const TooltipEnabledHeaderComponent = ({ headerTooltip = '', displayName = '' }) => (
    <>
      <span title={displayName}>{displayName}</span>
      <PopupInfo
        inverted
        flowing
        on="hover"
        sizeIcon={18}
        text={headerTooltip}
        iconClass="xs-ml-4"
      />
    </>
  )

  const MaskEnabledHeaderComponent = ({ displayName = '', column }) => (
    <>
      {displayName}
      <Checkbox
        toggle
        title={displayName}
        checked={maskingToggle[column.colId]}
        onChange={(e) => {
          e.stopPropagation()
          setMaskingToggle({
            ...maskingToggle,
            [column.colId]: maskingToggle ? !maskingToggle[column.colId] : true
          })
        }}
        label=""
        className="simple fitted xs-ml-8"
      />
    </>
  )
  const maskedValueCellRenderer = ({ column, data }) => (
    <RegularColumn
      content={
        maskingToggle && maskingToggle[column.colId]
          ? getMaskedString(data[column.colId])
          : data[column.colId]
      }
    />
  )
  const gridRef = useRef<AgGridReact<any>>(null)
  const onColumnResized = ({ source, type }) => {
    const isResizeEvent = source == 'uiColumnResized' && type == 'columnResized'
    if (isResizeEvent && gridRef.current) {
      const columns = gridRef.current.columnApi.getColumnState() || []
      columns.length > 0 &&
        gridRef.current.columnApi.applyColumnState({
          state: columns.map((column, i) =>
            i == columns.length - 1 && column.flex !== 1 ? { ...column, flex: 1 } : column
          )
        })
    }
  }

  const columnDefs: ColDef[] = columns.map(
    ({
      dataKey,
      valueGetter,
      title,
      headerTooltip,
      flex,
      center,
      width,
      maxWidth,
      minWidth,
      pinned,
      headerClass,
      masking,
      maskingToggleDisabled = false,
      cellRenderer,
      resizable,
      checkboxSelection,
      cellClass: className = '',
      headerCheckboxSelection,
      headerCheckboxSelectionFilteredOnly = false,
      wrapText,
      autoHeight,
      headerComponent
    }) => {
      let cellClass = className as string
      if (center) {
        cellClass += ' ag-content-centered'
      }

      return {
        headerName: title,
        showDisabledCheckboxes: true,
        headerCheckboxSelectionFilteredOnly,
        wrapText,
        autoHeight,
        ...(headerComponent ? { headerComponent } : {}),
        ...(headerClass ? { headerClass } : {}),
        ...(cellRenderer ? { cellRenderer } : {}),
        ...(masking && !maskingToggleDisabled
          ? { headerComponent: MaskEnabledHeaderComponent, cellRenderer: maskedValueCellRenderer }
          : {}),
        ...(headerTooltip
          ? {
              headerComponent: TooltipEnabledHeaderComponent,
              headerComponentParams: { headerTooltip }
            }
          : { headerTooltip: title }),
        ...(dataKey ? { field: dataKey } : {}),
        ...(valueGetter ? { valueGetter: valueGetter } : {}),
        ...(pinned ? { pinned, lockPinned: true } : {}),
        ...(width == undefined ? {} : { width }),
        ...(minWidth == undefined ? {} : { minWidth }),
        ...(maxWidth == undefined ? {} : { maxWidth }),
        ...(resizable == undefined ? {} : { resizable }),
        ...(expandedRowHeight || hasActionColumn ? {} : flex ? { flex } : {}),
        ...(cellClass ? { cellClass } : {}),
        ...(checkboxSelection ? { checkboxSelection } : {}),
        ...(headerCheckboxSelection ? { headerCheckboxSelection } : {})
      }
    }
  )

  const {
    tableFlex = 1,
    tableColumnsResizable = true,
    fullWidthCellRenderer,
    expandedRowHeight,
    hasActionColumn,
    className,
    rowHeight,
    rowSelection = 'multiple',
    onSelectionChanged,
    onRowSelected,
    getRowClass,
    getBusinessKeyForNode,
    onGridReady,
    isPrint = false,
    setApi,
    setGridRef,
    onGridUpdate,
    isRowSelectable
  } = tableParams || {}
  const defaultColumnProps: ColDef = useMemo(
    () => ({
      suppressMovable: true,
      flex: expandedRowHeight || hasActionColumn ? 0 : tableFlex,
      resizable: tableColumnsResizable,
      showDisabledCheckboxes: true
    }),
    [tableColumnsResizable, expandedRowHeight, tableFlex]
  )
  const isFullWidthRow = useCallback((params) => !!params.rowNode.data.expandedMasterId, [])
  const expandedRowRenderer = useMemo(() => fullWidthCellRenderer, [fullWidthCellRenderer])

  const getRowHeight = useCallback(
    (params) => (params.data.expandedMasterId ? expandedRowHeight : rowHeight),
    []
  )

  const handleGridReady = (event) => {
    const fitColumns = expandedRowHeight || hasActionColumn
    fitColumns && event.api.sizeColumnsToFit()
    setApi && setApi(event.api)
    setGridRef && setGridRef(gridRef)
    if (onGridReady) {
      onGridReady(event)
    }
  }

  return (
    <div
      className="ag-theme-alpine"
      style={{ width: '100%', height: '100%' }}
      data-test-id="data-table"
    >
      <AgGridReact
        domLayout={isPrint ? 'print' : 'autoHeight'}
        onGridReady={handleGridReady}
        ref={gridRef}
        rowData={data}
        columnDefs={columnDefs}
        suppressRowClickSelection={true}
        suppressCellFocus={true}
        rowSelection={rowSelection}
        animateRows={true}
        {...(isRowSelectable ? { isRowSelectable } : {})}
        {...(onGridUpdate ? { onRowDataUpdated: onGridUpdate } : {})}
        {...(getBusinessKeyForNode ? { getBusinessKeyForNode } : {})}
        {...(expandedRowHeight || hasActionColumn ? { onColumnResized } : {})}
        {...(fullWidthCellRenderer
          ? { fullWidthCellRenderer: expandedRowRenderer, isFullWidthRow }
          : {})}
        enableCellTextSelection={true}
        ensureDomOrder={true}
        {...(className ? { className } : {})}
        {...(getRowClass ? { getRowClass } : {})}
        {...(rowHeight || expandedRowHeight ? { getRowHeight } : {})}
        {...(onSelectionChanged ? { onSelectionChanged } : {})}
        {...(onRowSelected ? { onRowSelected } : {})}
        {...(Object.keys(defaultColumnProps).length > 0
          ? { defaultColDef: defaultColumnProps }
          : {})}
      ></AgGridReact>
    </div>
  )
}

export default DataTable
