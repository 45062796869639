import {
  ClusterCard,
  ClusterCardsParams,
  ClusterTable,
  ClusterTableAlertsParams,
  ClusterTablesParams
} from './clustersSlice'
import {
  ALL_CLUSTERS,
  GRAPHQL_API_FILTERS,
  LIMIT_DEFAULT,
  ORPHAN_CLUSTER_ID,
  PAGE
} from '../../constants'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryClusterCards = (params: ClusterCardsParams): string => {
  const { datasourceId, databaseId, clusterId } = params
  const dbQueryParams = databaseId ? `(id: "${databaseId}")` : ''
  const clusterParams = clusterId ? `(id: "${clusterId}")` : ''

  const dbClustersQuery = `
      databases${dbQueryParams}{
        edges {
          node {
            id
            name
            tableClusters${clusterParams} {
              edges {
                node {
                  metadata {
                    timestampColumnID
                  }
                  tables(first: 1) {
                    count
                  }
                  piiTables: tables(first: 1, toggleFilter: {key: ${GRAPHQL_API_FILTERS.isSensitive}}) {
                    count
                  }
                  tablesPiiNeedReview: tables(first: 1, booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}, {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}]) {
                    count
                  }
                  columnsPiiNeedReview: columns(first: 1, booleanFilter: [{key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}, {key: ${GRAPHQL_API_FILTERS.isSensitive}, value: true}])  {
                    count
                  }
                  columns(first: 1) {
                    count
                  }
                  piiColumns: columns(first: 1, toggleFilter: {key: ${GRAPHQL_API_FILTERS.isSensitive}}) {
                    count
                  }
                  ... on AnchorTableCluster {
                    id
                    name
                    isReviewed
                    entityScanDetails {
                      totalEntitiesCount
                      scannedEntitiesCount
                    }
                  }
                  ... on OrphanTableCluster{
                    orphanTableClusterId: id,
                    orphanTableClusterName: name
                  }
                }
              }
            }
          }
        }
      }
    `

  const dsClustersQuery = `
      datasources(first: 1, id: "${datasourceId}") {
        edges {
          node {
            ${dbClustersQuery}
          }
        }
      }
    `

  return gql`{
    ${params.databaseId ? dbClustersQuery : dsClustersQuery}
  }`
}
export const mapQueryClusterCards = (raw: any): ClusterCard[] => {
  try {
    const getCards = (clusterEdges, databaseId: string, databaseName: string) => {
      return clusterEdges
        .filter(({ node: cluster }) => !!cluster.columns?.count)
        .map(({ node: cluster }) => ({
          databaseId,
          databaseName,
          clusterId: cluster.id || cluster.orphanTableClusterId || ORPHAN_CLUSTER_ID,
          clusterName: cluster.name || cluster.orphanTableClusterName || ORPHAN_CLUSTER_ID,
          tablesCount: cluster.tables?.count || 0,
          tablesPiiCount: cluster.piiTables?.count || 0,
          tablesPiiNeedReviewCount: cluster.tablesPiiNeedReview?.count || 0,
          columnsPiiNeedReviewCount: cluster.columnsPiiNeedReview?.count || 0,
          columnsCount: cluster.columns?.count || 0,
          columnsPiiCount: cluster.piiColumns?.count || 0,
          entitiesTotalCount: cluster.entityScanDetails?.totalEntitiesCount || 0,
          entitiesScannedCount: cluster.entityScanDetails?.scannedEntitiesCount || 0,
          isReviewed: cluster.isReviewed,
          isTimestampColumnConfigured: !!cluster?.metadata?.timestampColumnID
        }))
    }

    let cards = []
    if (raw.datasources) {
      cards = raw.datasources?.edges[0].node?.databases?.edges
        .map(({ node: db }) => getCards(db.tableClusters?.edges, db.id, db.name))
        .flat()
    } else {
      cards = getCards(
        raw.databases?.edges[0]?.node?.tableClusters?.edges,
        raw.databases?.edges[0]?.node?.id,
        raw.databases?.edges[0]?.node?.name
      )
    }

    return cards
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryClusterTables = (params: ClusterTablesParams): string => {
  const cursor = getAfterCursor(params[PAGE], LIMIT_DEFAULT)
  const { datasourceId, databaseId, clusterId } = params

  const tablesQuery = `
  tables(first: ${LIMIT_DEFAULT}, after: "${cursor}", toggleFilter: { key:${GRAPHQL_API_FILTERS.isSensitive} }) {
      count
      edges {
        node {
          id
          name
          lastModifiedOn
          createdOn
          rowCount
          clusterRootTableUrn
          columns {
            count
          }
          columnsPii: columns(toggleFilter: { key: ${GRAPHQL_API_FILTERS.isSensitive} }) {
            count
          }
          columnsNoScan: columns(booleanFilter: {key: ${GRAPHQL_API_FILTERS.isNoScan}, value: true}) {
            count
          }
          columnsNeedReview: columns(booleanFilter: {key: ${GRAPHQL_API_FILTERS.isReviewed}, value: false}) {
            count
          }
          schema {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `

  const allClusterTablesQuery = `... on AnchorTableCluster {${tablesQuery}}`
  const orphanTablesQuery = `... on OrphanTableCluster {${tablesQuery}}`

  const clusterQuery = `
  tableClusters${clusterId !== ALL_CLUSTERS ? `(id: "${clusterId}")` : ''}{
    edges {
      node {
        ${clusterId === ORPHAN_CLUSTER_ID ? orphanTablesQuery : allClusterTablesQuery}
        ... on AnchorTableCluster {
          id
          name
        }
        ... on OrphanTableCluster{
          orphanTableClusterId: id,
          orphanTableClusterName: name
        }
      }
    }
  }`

  const dbQueryParams = databaseId ? `(id: "${databaseId}")` : ''

  const dbQuery = `
  databases${dbQueryParams}{
    edges {
      node {
        id
        name
        ${clusterId ? clusterQuery : tablesQuery}
      }
    }
  }`

  const dsQuery = `
  datasources(id: "${datasourceId}") {
    edges {
      node {
        ${dbQuery}
      }
    }
  }`

  return gql`{${databaseId ? dbQuery : dsQuery}}`
}
export const mapQueryClusterTables = (raw: any): { list: ClusterTable[]; total: number } => {
  try {
    const mapTablesList = ({
      tables,
      databaseId,
      databaseName,
      clusterId = '',
      clusterName = ''
    }) => {
      return tables.edges?.map(({ node: table }) => ({
        databaseId,
        databaseName,
        clusterId,
        clusterName,
        tableId: table.id || '',
        tableName: table.name || '',
        rowsCount: table.rowCount || 0,
        isReviewed: (table.columnsNeedReview?.count || 0) === 0,
        parentId: table.clusterRootTableUrn || '',
        lastModified: table.lastModifiedOn || table.createdOn || '',
        columnsCount: table.columns?.count || 0,
        columnsPiiCount: table.columnsPii?.count || 0,
        noScanItemsCount: table.columnsNoScan?.count || 0,
        schemaId: table.schema?.edges[0]?.node?.id || '',
        schemaName: table.schema?.edges[0]?.node?.name || ''
      }))
    }
    const databases = raw.datasources?.edges[0]?.node?.databases || raw.databases
    const tables = databases.edges
      .map(({ node: db }) => {
        const databaseId = db.id
        const databaseName = db.name
        return db.tableClusters
          ? db.tableClusters?.edges
              ?.map(({ node: cl }) => {
                return mapTablesList({
                  tables: cl.tables,
                  databaseId,
                  databaseName,
                  clusterId: cl.id,
                  clusterName: cl.name
                })
              })
              .flat()
          : mapTablesList({ tables: db.tables, databaseId, databaseName })
      })
      .flat()

    return { list: tables, total: tables?.length || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryClusterTableAlerts = (params: ClusterTableAlertsParams): string => {
  const queries = params.tableIds
    .map((id, i) => {
      return `table_${i}: subAlert(first: 1, filter: { key: TABLE_ID, values: ["${id}"]}) { count }`
    })
    .join()

  return gql`{${queries}}`
}
export const mapQueryClusterTableAlerts = (
  raw: any,
  tableIds: string[]
): Array<{ tableId: string; alertsCount: number }> => {
  try {
    return tableIds.map((id, i) => ({
      tableId: id,
      alertsCount: raw[`table_${i}`]?.count || 0
    }))
  } catch (error) {
    console.error(error)
    throw error
  }
}
