import {
  Template,
  CreateTemplateParams,
  UpdateTemplateParams,
  TemplateAttribute
} from './templatesSlice'
import { gql } from 'graphql-request'

export const queryTemplates = (): string => {
  return gql`
    {
      template {
        edges {
          node {
            id
            templateName
            templateClass
            templateSubClass
            enabled
            createdBy
            backingDocumentId
            objects {
              count
            }
            attributeCount
          }
        }
      }
    }
  `
}
export const mapQueryTemplates = (raw: any): Template[] => {
  return raw.template?.edges?.map(({ node: template }) => ({
    id: template.id || '',
    templateName: template.templateName || '',
    templateClass: template.templateClass || '',
    templateSubClass: template.templateSubClass || '',
    enabled: template.enabled || false,
    createdBy: template.createdBy || 'admin@lightbeam.ai', // TODO: demo change, remove whenever BE is ready
    objectsCount: template.objects?.count || 0,
    attributesCount: template.attributeCount || 0,
    backingDocumentId: template.backingDocumentId || ''
  }))
}

export const mutationCreateTemplate = (params: CreateTemplateParams): any => {
  return gql`
    mutation {
      createTemplate(
        clientMutationId: "1"
        templateInput: {
          templateName: "${params.templateName}"
          backingDocumentId:  "${params.backingDocumentId}"
          backingDatasourceId: "${params.backingDatasourceId}"
          enabled: ${params.enabled}
          templateClass: "${params.templateClass}"
          templateSubClass: "${params.templateSubClass}"
        }
      ) {
        clientMutationId
        status
        templateId
        message
      }
    }
  `
}

export const mutationUpdateTemplate = (params: UpdateTemplateParams): any => {
  return gql`
    mutation {
      updateTemplate(
        clientMutationId: "1"
        templateId: "${params.id}"
        templateData: {
          ${params.templateName ? 'templateName: "' + params.templateName + '",' : ''}
          ${
            params.backingDocumentId ? 'backingDocumentId: "' + params.backingDocumentId + '",' : ''
          }
          ${params.backingDatasourceId ? 'templateName: "' + params.backingDatasourceId + '",' : ''}
          ${params.enabled !== undefined ? 'enabled: ' + params.enabled + ',' : ''}
          ${params.templateClass ? 'templateClass: "' + params.templateClass + '",' : ''}
          ${params.templateSubClass ? 'templateSubClass: "' + params.templateSubClass + '",' : ''}
        }
      ) {
        clientMutationId
        status
      }
    }
  `
}

export const mutationDeleteTemplate = (id: string): any => {
  return gql`
    mutation {
      deleteTemplate(clientMutationId: "1", templateIds: ["${id}"]) {
        isDeleted
      }
    }
  `
}

export const queryTemplateAttributes = (templateId: string): string => {
  return gql`
    {
      attribute(templateIds: ["${templateId}"]) {
        edges {
          node {
            id
            name
            internalName
            objects {
              count
            }
          }
        }
      }
    }
  `
}
export const mapQueryTemplateAttributes = (raw: any): TemplateAttribute[] => {
  return raw.attribute?.edges?.map(({ node: attr }) => ({
    id: attr.id || '',
    name: attr.name,
    internalName: attr.internalName,
    objectsCount: attr.objects.count || 0
  }))
}
