import apiService from '../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface FeatureFlags {
  accessControl: boolean
  auditLog: boolean
  dashboardCountersForFileDataSourcesEnabled: boolean
  [key: string]: boolean
}

const initialState: FeatureFlags = {
  accessControl: false,
  auditLog: false,
  dashboardCountersForFileDataSourcesEnabled: false
}

export const ACTION_FETCH_FEATURE_FLAGS = 'featureFlags/fetch'
export const fetchFeatureFlags = createAsyncThunk(ACTION_FETCH_FEATURE_FLAGS, async () => {
  return await apiService.getFeatureFlags()
})

const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    resetFeatureFlag: (state, { payload }) => {
      state[payload] = initialState[payload]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureFlags.fulfilled, (_, { payload }) => {
      window.__featureFlags = payload
      return payload
    })
  }
})

export const { resetFeatureFlag } = featureFlagSlice.actions
export default featureFlagSlice.reducer
