import { PrintDataSourceSummary } from '../printSlice'
import SvgIconFileShares from '../../assets/inlineSvg/icon-shares.svg'
import SvgIconChannels from '../../assets/inlineSvg/icon-channel.svg'
import SvgIconBuckets from '../../assets/inlineSvg/icon-buckets-unselected.svg'
import SvgIconFiles from '../../assets/inlineSvg/icon-files-unselected.svg'
import SvgIconDrives from '../../assets/inlineSvg/icon-drive.svg'
import SvgIconProjects from '../../assets/inlineSvg/icon-projects.svg'
import SvgIconEmail from '../../assets/inlineSvg/icon-mail.svg'
import SvgIconAttribute from '../../assets/inlineSvg/icon-attribute-unselected.svg'
import SvgIconPolicies from '../../assets/inlineSvg/icon-policies.svg'
import SvgIconTable from '../../assets/inlineSvg/icon-tables-unselected.svg'
import SvgIconColumn from '../../assets/inlineSvg/icon-column.svg'
import SvgIconType from '../../assets/inlineSvg/icon-object-type.svg'
import { DATA_SOURCE_TYPES, STRUCTURED_DATASOURCES, MIXED_DATASOURCES } from '../../constants'
import { DataSourceParentObjectTypes } from '../../features/ObjectTypeAndPolicies/objectTypeAndPoliciesSlice'
import { HardDrives as SvgHardDrives } from 'phosphor-react'
import React from 'react'
import { useIntl } from 'react-intl'

interface IProps {
  dataSource: PrintDataSourceSummary
}

const PrintWidgetDsDetails = (props: IProps): React.ReactElement => {
  const intl = useIntl()

  const { dataSource } = props

  const isStructured = STRUCTURED_DATASOURCES.find((dsType) => dsType === dataSource.type)
  const isMixed = MIXED_DATASOURCES.find((dsType) => dsType === dataSource.type)

  let icon = <></>
  let count = 0
  let text = ''

  if (
    dataSource.type === DATA_SOURCE_TYPES.aws ||
    dataSource.type === DATA_SOURCE_TYPES.azureBlob
  ) {
    icon = <SvgIconBuckets />
    count = dataSource.bucketsCount || 0
    text = intl.formatMessage({ id: 'print.ds.buckets' })
  }
  if (dataSource.type === DATA_SOURCE_TYPES.slack || dataSource.type === DATA_SOURCE_TYPES.teams) {
    icon = <SvgIconChannels />
    count = dataSource.channelsCount || 0
    text = intl.formatMessage({ id: 'print.ds.channels' })
  }
  if (
    dataSource.type === DATA_SOURCE_TYPES.gdrive ||
    dataSource.type === DATA_SOURCE_TYPES.gdrivePersonal ||
    dataSource.type === DATA_SOURCE_TYPES.oneDrive ||
    dataSource.type === DATA_SOURCE_TYPES.sharePoint ||
    dataSource.type === DATA_SOURCE_TYPES.box ||
    dataSource.type === DATA_SOURCE_TYPES.dropbox
  ) {
    icon = <SvgIconDrives />
    count = dataSource.drivesCount || 0
    text = intl.formatMessage({ id: 'print.ds.drives' })
  }
  if (dataSource.type === DATA_SOURCE_TYPES.generic) {
    icon = <SvgIconProjects />
    count = dataSource.projectsCount || 0
    text = intl.formatMessage({ id: 'print.ds.projects' })
  }
  if (dataSource.type === DATA_SOURCE_TYPES.jira) {
    icon = <SvgIconProjects />
    count = dataSource.jiraProjectCount || 0
    text = intl.formatMessage({ id: 'print.ds.projects' })
  }
  if (
    dataSource.type === DATA_SOURCE_TYPES.outLook ||
    dataSource.type === DATA_SOURCE_TYPES.gmail
  ) {
    icon = <SvgIconEmail />
    count = dataSource.mailsCount || 0
    text = intl.formatMessage({ id: 'print.ds.mails' })
  }
  if (dataSource.type === DATA_SOURCE_TYPES.smb) {
    icon = <SvgIconFileShares />
    count = dataSource.sharesCount || 0
    text = intl.formatMessage({ id: 'print.ds.shares' })
  }

  const parentObjectType = dataSource.parentObjectType
  let textParentObjectType = ''
  if (parentObjectType === DataSourceParentObjectTypes.customer) {
    textParentObjectType = intl.formatMessage({ id: 'dashboard.dataSource.widget.text.customers' })
  }
  if (parentObjectType === DataSourceParentObjectTypes.organization) {
    textParentObjectType = intl.formatMessage({
      id: 'dashboard.dataSource.widget.text.organizations'
    })
  }
  if (parentObjectType === DataSourceParentObjectTypes.incidents) {
    textParentObjectType = intl.formatMessage({ id: 'dashboard.dataSource.widget.text.incidents' })
  }

  return (
    <div id="data-sources" className="print-widget details" data-test-id="print-widget-ds-details">
      <div className="left">
        <div className="title">{intl.formatMessage({ id: 'print.ds.details' })}</div>
        {dataSource.ownerName && (
          <div className="col">
            <div className="label">{intl.formatMessage({ id: 'print.ds.ownerName' })}</div>
            <div className="value">{dataSource.ownerName}</div>
          </div>
        )}
        <div className="col">
          <div className="label">{intl.formatMessage({ id: 'print.ds.ownerEmail' })}</div>
          <div className="value">{dataSource.ownerEmail}</div>
        </div>
      </div>
      <div className="right">
        {isStructured ? (
          <div className="count-box">
            <div className="icon-box">
              <SvgHardDrives size={20} weight="light" />
            </div>
            <div className="count">{dataSource.databasesCount}</div>
            <div className="text">{intl.formatMessage({ id: 'print.ds.databases' })}</div>
          </div>
        ) : isMixed ? (
          <div className="count-box">
            <div className="icon-box">
              <SvgIconType />
            </div>
            <div className="count">{dataSource.parentObjectCount}</div>
            <div className="text">{textParentObjectType}</div>
          </div>
        ) : text ? (
          <div className="count-box">
            <div className="icon-box">{icon}</div>
            <div className="count">{count}</div>
            <div className="text">{text}</div>
          </div>
        ) : (
          <></>
        )}
        <div className="divider" />
        {isStructured ? (
          <div className="count-box">
            <div className="icon-box">
              <SvgIconTable />
            </div>
            <div className="count">
              {dataSource.piiTablesCount}
              <div className="count-total-wrapper ">
                <div className="count-total">
                  {intl.formatMessage({ id: 'print.ds.piiTables' })}
                </div>
              </div>
            </div>
            <div className="text">
              {intl.formatMessage({ id: 'print.ds.total' })}&#58;&nbsp;{dataSource.tablesCount}
            </div>
          </div>
        ) : (
          <div className="count-box">
            <div className="icon-box">
              <SvgIconFiles />
            </div>
            <div className="count">{dataSource.piiFilesCount}</div>
            <div className="text">{intl.formatMessage({ id: 'print.ds.piiFiles' })}</div>
          </div>
        )}
        <div className="divider" />
        {isStructured ? (
          <div className="count-box">
            <div className="icon-box">
              <SvgIconColumn />
            </div>
            <div className="count">
              {dataSource.piiColumnsCount}
              <div className="count-total-wrapper ">
                <div className="count-total">
                  {intl.formatMessage({ id: 'print.ds.piiColumns' })}
                </div>
              </div>
            </div>
            <div className="text">
              {intl.formatMessage({ id: 'print.ds.total' })}&#58;&nbsp;{dataSource.columnsCount}
            </div>
          </div>
        ) : (
          <div className="count-box">
            <div className="icon-box">
              <SvgIconAttribute />
            </div>
            <div className="count">{dataSource.attributesCount}</div>
            <div className="text">{intl.formatMessage({ id: 'print.ds.attributes' })}</div>
          </div>
        )}
        <div className="divider" />
        <div className="count-box">
          <div className="icon-box">
            <SvgIconPolicies />
          </div>
          <div className="count">{dataSource.policiesViolatedCount}</div>
          <div className="text">{intl.formatMessage({ id: 'print.ds.policiesViolated' })}</div>
        </div>
      </div>
    </div>
  )
}

export default PrintWidgetDsDetails
