import {
  mapQueryClusterCards,
  mapQueryClusterTableAlerts,
  mapQueryClusterTables,
  queryClusterCards,
  queryClusterTableAlerts,
  queryClusterTables
} from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import { CLUSTER_ID, DATABASE_ID, DATA_SOURCE_ID, PAGE } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type ClusterCard = {
  databaseId: string
  databaseName: string
  clusterId: string
  clusterName: string
  tablesCount: number
  tablesPiiCount: number
  tablesPiiNeedReviewCount: number
  columnsPiiNeedReviewCount: number
  columnsCount: number
  columnsPiiCount: number
  entitiesTotalCount: number
  entitiesScannedCount: number
  isReviewed: boolean
  isTimestampColumnConfigured?: boolean
}

export type OrphanTablesCard = {
  tablesCount: number
  tablesPiiCount: number
  columnsCount: number
  columnsPiiCount: number
}

export interface ClusterCardsParams {
  [CLUSTER_ID]?: string
  [DATABASE_ID]?: string
  [DATA_SOURCE_ID]: string
}

export const ACTION_CLUSTER_CARDS = 'clusters/cards'
export const fetchClusterCards = createAsyncThunk(
  ACTION_CLUSTER_CARDS,
  async (params: ClusterCardsParams) => {
    const result = await graphqlService.execute(queryClusterCards(params))
    return mapQueryClusterCards(result)
  }
)

export type ClusterTable = {
  databaseId: string
  databaseName: string
  clusterId?: string
  clusterName?: string
  tableId: string
  tableName: string
  parentId: string
  rowsCount: number
  columnsCount: number
  columnsPiiCount: number
  noScanItemsCount: number
  schemaId: string
  schemaName: string
  lastModified: string
  isReviewed: boolean
  alertsCount?: number
}

export interface ClusterTablesParams {
  [PAGE]: number
  [CLUSTER_ID]: string
  [DATABASE_ID]: string
  [DATA_SOURCE_ID]: string
}

export const ACTION_CLUSTER_TABLES = 'clusters/tables'
export const fetchClusterTables = createAsyncThunk(
  ACTION_CLUSTER_TABLES,
  async (params: ClusterTablesParams) => {
    const result = await graphqlService.execute(queryClusterTables(params))
    return mapQueryClusterTables(result)
  }
)

export interface ClusterTableAlertsParams {
  tableIds: string[]
}
export const ACTION_CLUSTER_TABLE_ALERTS = 'clusters/tableAlerts'
export const fetchClusterTableAlerts = createAsyncThunk(
  ACTION_CLUSTER_TABLE_ALERTS,
  async (params: ClusterTableAlertsParams) => {
    const result = await graphqlService.execute(queryClusterTableAlerts(params))
    return mapQueryClusterTableAlerts(result, params.tableIds)
  }
)

export type ClusterTablesList = {
  list?: ClusterTable[]
  total?: number
  sort: SortParams
}

const initialList: ClusterTablesList = {
  sort: defaultSortParams
}

type ClustersState = {
  list: ClusterTablesList
  cards?: ClusterCard[]
  filters?: any
}

export const initialState: ClustersState = {
  list: initialList
}

const clustersSlice = createSlice({
  name: 'clusters',
  initialState,
  reducers: {
    setListSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClusterCards.fulfilled, (state, action) => {
      state.cards = action.payload
    })
    builder.addCase(fetchClusterTables.fulfilled, (state, { payload }) => {
      state.list.list = payload.list
      state.list.total = payload.total
    })
    builder.addCase(fetchClusterTableAlerts.fulfilled, (state, { payload }) => {
      state.list.list = state.list.list?.map((t) => {
        return {
          ...t,
          alertsCount: payload.find((a) => a.tableId === t.tableId)?.alertsCount || 0
        }
      })
    })
  }
})

export const { setListSort } = clustersSlice.actions

export default clustersSlice.reducer
