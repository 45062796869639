import { LIMIT_DEFAULT } from '../../constants'
import { IGetDrivesParams } from '../../interfaces'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { Drive } from '../../services/api/apiTypes'
import { gql } from 'graphql-request'

export const queryDrivesList = (params: IGetDrivesParams): string => {
  const { datasourceId, filters, page = 1 } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let filterParamsString = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''

  if (filterString) {
    filterParamsString += `, filter: ${filterString}`
  }

  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
      {
        drive(first: ${LIMIT_DEFAULT}, after: "${cursor}", datasourceId: "${datasourceId}"${filterParamsString}){
          count
            edges{
              node{
                owner
                driveId
                driveName
                size
                documentsCount
                riskyDocumentsCount
                site{
                  edges{
                    node{
                      name
                      webUrl
                    }
                  }
                }
                entities{
                  count
                }
              }
            }
          }
      }
    `
}

export const queryDrivesListCompact = (params: IGetDrivesParams): string => {
  const { datasourceId, page = 1, searchQuery } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  const optionalFilter = searchQuery ? `, searchQuery: "${searchQuery}"` : ''

  // adding filter to connection as duplicate datasource can create issues for non mongo ids (objects ids)
  return gql`
      {
        drive(first: ${LIMIT_DEFAULT}, after: "${cursor}", datasourceId: "${datasourceId}"${optionalFilter}){
          count
          edges{
            node{
              driveName
              driveId
              documentsCount
            }
          }
        }
      }
    `
}

export const mapQueryDrivesList = (raw: any): { list: Drive[]; total: number } => {
  try {
    let list = []
    if (raw && raw.drive && raw.drive.edges && Array.isArray(raw.drive.edges)) {
      list = raw.drive.edges.map(
        ({
          node: {
            driveName = '',
            driveId = '',
            owner = '',
            documentsCount = 0,
            riskyDocumentsCount = 0,
            entities = { count: 0 },
            size = 0,
            site = { edges: [] } as any
          }
        }) => ({
          driveName,
          driveId,
          size,
          owner,
          impactedFilesCount: riskyDocumentsCount,
          filesCount: documentsCount,
          entitiesCount: entities.count,
          siteName: site.edges[0]?.node?.name || '',
          siteUrl: site.edges[0]?.node?.webUrl || ''
        })
      )
    }

    return {
      list,
      total: raw.drive.count
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDrivesOwners = (params: IGetDrivesParams): string => {
  const { datasourceId } = params
  return gql`
        {
          drive(datasourceId: "${datasourceId}"){
            edges{
                node{
            owner
                }
            }
          }
        }
      `
}

export const mapQueryDrivesOwners = (raw: any): { list: string[] } => {
  let list = []
  if (raw && raw.drive && raw.drive.edges && Array.isArray(raw.drive.edges)) {
    list = raw.drive.edges.map(({ node: { owner = '' } }) => owner)
  }

  return {
    list
  }
}
