import apiService from '../../services/api/apiService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export enum ReportTypes {
  dataSource = 'DATASOURCE_SUMMARY_PDF_REPORT',
  delta = 'DELTA_SUMMARY_PDF_REPORT'
}

export interface ReportConfiguration {
  id?: string
  configuration: {
    emailIDs: string[]
  }
  notificationConfiguration?: {
    emailIDs: string[]
  }
  notificationType?: string
  cron: string
  emailBody?: string
  reportType?: string
}
export const ACTION_REPORT_CONFIG_SAVE = 'report/save'
export const saveReportConfiguration = createAsyncThunk(
  ACTION_REPORT_CONFIG_SAVE,
  async (params: ReportConfiguration) => {
    params?.id && (await apiService.deleteReportConfiguration(params.id))
    await apiService.saveReportConfiguration(params)
  }
)

export const ACTION_REPORT_CONFIG_DELETE = 'report-settings/delete'
export const deleteReportConfiguration = createAsyncThunk(
  ACTION_REPORT_CONFIG_DELETE,
  async (id: string) => await apiService.deleteReportConfiguration(id)
)

export const ACTION_REPORT_CONFIG_FETCH = 'report/get'
export const getReportConfiguration = createAsyncThunk(
  ACTION_REPORT_CONFIG_FETCH,
  async () => await apiService.getReportConfiguration()
)
interface ReportState {
  config?: ReportConfiguration
}

export const initialState: ReportState = {}

const reportSlice = createSlice({
  name: 'reportConfig',
  initialState,
  reducers: {
    resetConfig: (state) => {
      state.config = initialState.config
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getReportConfiguration.fulfilled, (state, { payload }) => {
      state.config = payload[payload.length - 1]
    })
  }
})

export const { resetConfig } = reportSlice.actions
export default reportSlice.reducer
