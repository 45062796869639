import './tableLegend.less'
import { LIMIT_DEFAULT } from '../../constants'
import cookie from '../../services/cookieService'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { ReactNode } from 'react'
import { Typography, TypographyProps } from '@lightbeamai/design-system'

interface IProps {
  name: ReactNode
  total: number
  showing: number
  page: number
  pageSize?: number
  className?: string
  miscellaneousRecordsCount?: number
  variant?: TypographyProps['variant']
}

const TableLegend = (props: IProps): React.ReactElement => {
  const {
    name,
    total,
    showing,
    page,
    pageSize,
    className = '',
    miscellaneousRecordsCount = 0,
    variant = 'base'
  } = props
  const intl = useIntl()
  const start = (pageSize || LIMIT_DEFAULT) * (page - 1)
  // const first = start + 1
  // const last = start + showing
  const first = +page !== 1 ? start + 1 + miscellaneousRecordsCount : start + 1 //Todo - Temp fix for UX issue on Partners Page, adjusts start due to extra table entry (External Emails)
  const last = first + showing - 1
  const applyCountersCalibration = cookie.get('countersCalibration') == 'true'

  /**
   * Counter calibration on UI is done to handle wrong values of list counters coming from BE (LA-3285)
   */
  const totalItems = applyCountersCalibration ? (total > last ? total : last) : total
  const totalFormatted = intl.formatNumber(totalItems, { notation: 'compact' })

  if (showing) {
    return (
      <div className={`table-legend xs-pt-8 xs-pb-8 ${className}`} data-test-id="table-legend">
        {typeof name !== 'string' ? (
          name
        ) : (
          <>
            {last > 1 ? (
              <Typography color="placeholder" type="medium" variant={variant}>
                <FormattedMessage
                  id="table.legend.string"
                  values={{ name, first, last, total: totalFormatted }}
                />
              </Typography>
            ) : (
              <Typography color="placeholder" type="medium" variant={variant}>
                <FormattedMessage
                  id="table.legend.string2"
                  values={{ name, first, total: totalFormatted }}
                />
              </Typography>
            )}
          </>
        )}
      </div>
    )
  }

  return <></>
}

export default TableLegend
