import {
  PreferenceCenterQueryParams,
  mapQueryCommunicationPreferenceList,
  mapQueryPreferenceCenter,
  mapQueryPreferenceCenterList,
  mapQueryPreferenceCenterOverviewData,
  mapQueryRenewalEmailTemplateList,
  mutationDeletePreferenceCenter,
  mutationSavePreferenceCenter,
  mutationPublishPreferenceCenter,
  queryCommunicationPreferenceList,
  queryPreferenceCenter,
  queryPreferenceCenterList,
  queryPreferenceCenterOverviewData,
  queryRenewalEmailTemplateList,
  mutationUnpublishPreferenceCenter,
  queryCategoryList,
  mapQueryCategoryList
} from './preferenceCenterQueries'
import { objectId } from '../../../utils'
import graphqlService from '../../../services/graphqlService'
import { PageInfo } from '../../../services/graphqlSchemaTypes'
import { RootState } from '../../../rootReducer'
import { URL_CONSENT_MANAGEMENT, URL_PREFERENCE_CENTER } from '../../../constants'
import apiService from '../../../services/api/apiService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// #region Queries
export const ACTION_PREFERENCE_CENTER_LIST = 'preferenceCenter/list'
export const fetchPreferenceCenterList = createAsyncThunk(
  ACTION_PREFERENCE_CENTER_LIST,
  async (params: PreferenceCenterQueryParams) => {
    const resultRaw = await graphqlService.execute(queryPreferenceCenterList(params))
    return mapQueryPreferenceCenterList(resultRaw)
  }
)

export const ACTION_PREFERENCE_CENTER_CONFIG = 'preferenceCenter/config'
export const fetchPreferenceCenterConfig = createAsyncThunk(
  ACTION_PREFERENCE_CENTER_CONFIG,
  async (preferenceCenterId: string) => {
    const resultRaw = await graphqlService.execute(queryPreferenceCenter(preferenceCenterId))
    return mapQueryPreferenceCenter(resultRaw)
  }
)

export const ACTION_PREFERENCE_CENTER_WIDGET_DATA = 'preferenceCenter/widgetData'
export const fetchPreferenceCenterWidgetData = createAsyncThunk(
  ACTION_PREFERENCE_CENTER_WIDGET_DATA,
  async (params: { id: string; groupId: string }) => {
    const { id, groupId } = params

    const resultRaw = await graphqlService.execute(queryPreferenceCenterOverviewData(id, groupId))
    return mapQueryPreferenceCenterOverviewData(resultRaw)
  }
)

export const ACTION_PREFERENCE_CENTER_WEB_APP_URL = 'preferenceCenter/webAppUrl'
export const fetchPreferenceCenterWebAppUrl = createAsyncThunk(
  ACTION_PREFERENCE_CENTER_WEB_APP_URL,
  async () => {
    return await apiService.getWebAppUrl()
  }
)

export const ACTION_COMMUNICATION_PREFERENCES = 'preferenceCenter/communicationPreferences'
export const fetchCommunicationPreferences = createAsyncThunk(
  ACTION_COMMUNICATION_PREFERENCES,
  async (datasourceIds: string[]) => {
    const resultRaw = await graphqlService.execute(queryCommunicationPreferenceList(datasourceIds))
    return mapQueryCommunicationPreferenceList(resultRaw)
  }
)

// #region Category list
export const ACTION_CATEGORY_LIST = 'preferenceCenter/categoryList'
export const fetchAllCategoryList = createAsyncThunk(ACTION_CATEGORY_LIST, async () => {
  const resultRaw = await graphqlService.execute(queryCategoryList())
  return mapQueryCategoryList(resultRaw)
})

export const ACTION_RENEWAL_EMAIL_TEMPLATES = 'preferenceCenter/renewalEmailTemplates'
export const fetchRenewalEmailTemplates = createAsyncThunk(
  ACTION_RENEWAL_EMAIL_TEMPLATES,
  async () => {
    const resultRaw = await graphqlService.execute(queryRenewalEmailTemplateList)
    return mapQueryRenewalEmailTemplateList(resultRaw)
  }
)

export const ACTION_SAVE_PREFERENCE_CENTER = 'preferenceCenter/save'
export const savePreferenceCenter = createAsyncThunk(
  ACTION_SAVE_PREFERENCE_CENTER,
  async (preferenceCenterData: PreferenceCenterData, { rejectWithValue }) => {
    try {
      const mutation = mutationSavePreferenceCenter(preferenceCenterData)
      const resultRaw = await graphqlService.execute(mutation)
      return resultRaw
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error?.errors[0]?.message || '' })
    }
  }
)

export const ACTION_DELETE_PREFERENCE_CENTER = 'preferenceCenter/delete'
export const deletePreferenceCenter = createAsyncThunk(
  ACTION_DELETE_PREFERENCE_CENTER,
  async (preferenceCenterId: string, { dispatch }) => {
    const resultRaw = await graphqlService.execute(
      mutationDeletePreferenceCenter(preferenceCenterId)
    )

    if (resultRaw) {
      dispatch(fetchPreferenceCenterList({}))
    }

    if (resultRaw?.deletePreferenceCenter?.preferenceCenterId) {
      return 'SUCCESS'
    }
    return resultRaw
  }
)

export const ACTION_PUBLISH_PREFERENCE_CENTER = 'preferenceCenter/publish'
export const publishPreferenceCenter = createAsyncThunk(
  ACTION_PUBLISH_PREFERENCE_CENTER,
  async (preferenceCenterId: string) => {
    const resultRaw = await graphqlService.execute(
      mutationPublishPreferenceCenter(preferenceCenterId)
    )

    if (resultRaw?.publishPreferenceCenter?.isPublished == true) {
      return {
        preferenceCenterId,
        mutationStatus: 'SUCCESS'
      }
    }

    return {
      preferenceCenterId,
      mutationStatus: 'ERROR'
    }
  }
)

export const ACTION_UNPUBLISH_PREFERENCE_CENTER = 'preferenceCenter/unpublish'
export const unpublishPreferenceCenter = createAsyncThunk(
  ACTION_UNPUBLISH_PREFERENCE_CENTER,
  async (preferenceCenterId: string) => {
    const resultRaw = await graphqlService.execute(
      mutationUnpublishPreferenceCenter(preferenceCenterId)
    )

    if (resultRaw?.unpublishPreferenceCenter?.isPublished === false) {
      return {
        preferenceCenterId,
        mutationStatus: 'SUCCESS'
      }
    }

    return {
      preferenceCenterId,
      mutationStatus: 'ERROR'
    }
  }
)
// #endregion

// #region Preference Center Data Types

export type AssociatedDataSources = {
  id: string
  name: string
  totalConsents: number
  type: string
}
export type PreferenceCenterWidgetData = {
  preferenceCenterId: string
  totalCategories: number
  associatedDomains: string[]
  consentWidgetData: ConsentWidgetData
  entitiesWidgetData: EntitiesWidgetData
  consentCategories: ConsentCategoryData[]
  associatedDataSources: AssociatedDataSources[]
  consentDataByCommunicationMethod?: ConsentDataByCommunicationMethod
  styleConfig: PreferenceStyleConfig
  consentCategoriesForPreview: ConsentCategorySettings[]
}

export type ConsentDataByCommunicationMethod = {
  [key: string]: ConsentWidgetData
}

export type ConsentWidgetData = {
  total: number
  optIn: number
  optOut: number
}

export type EntitiesWidgetData = {
  total: number
  identified: number
  unidentified: number
}

export type ConsentCategoryData = {
  id: string
  title: string
  consentData: ConsentWidgetData
  communicationPreferences: {
    id: string
    name: string
    type: string
    datasourceId: string
    datasourceName: string
    communicationMethod: string
    consentData: ConsentWidgetData
  }[]
}

export type PreferenceCenterCategoryData = {
  id: string
  categoryName: string
  createdBy: string
  createdAt: string
  displayTitle: string
  displayDescription: string
  allowOptOut?: boolean
}

export type PreferenceCenter = {
  id: string
  name: string
  regulations: string[]
  createdAt: string
  updatedTimestamp: string
  associatedDomains: AssociatedDomain[]
  consentsLogged: number
  consentCategoryCount: number
  isPublished: boolean
  preferenceCenterGroupId: string
}

export type PreferenceCenterList = {
  count: number
  pageInfo?: PageInfo
  list: PreferenceCenter[]
}

export type CommunicationPreferenceConfig = {
  __typename: string
  name: string
  description: string
  purpose: string
  communicationMethod: string
  isActive: boolean
}

export type CommunicationPreference = {
  id: string
  type: string
  configuration: CommunicationPreferenceConfig
  createdAt: string
  updatedTimestamp?: string
  datasourceId: string
  datasourceName: string
  displayName?: string
}

export type RenewalEmailTemplate = {
  id: string
  name: string
}
// #endregion

export enum PreferenceCenterConfigTabs {
  EMAIL = 'email',
  PREFERENCES = 'preferences'
}

export enum PreferenceCenterSaveStatus {
  SUCCESS = 'SUCCESS',
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  ERROR = 'ERROR'
}

export const OPT_OUT_ALL_CATEGORIES = 'ALL_CATEGORY'
export const OPT_OUT_PER_CONSENT_CATEGORY = 'PER_CATEGORY'

// #region Preference Center Data (Form Data) Types
export type PreferenceCenterData = {
  // Query Data
  preferenceCenters: PreferenceCenterList
  selectedLinkedDataSources: string[]
  communicationPreferences: CommunicationPreference[]
  categoryList: PreferenceCenterCategoryData[]
  activeCategoryIdToEdit: string
  savePreferenceCenterStatus?: PreferenceCenterSaveStatus
  renewalEmailTemplates: RenewalEmailTemplate[]
  widgetData?: PreferenceCenterWidgetData
  webAppUrl: string

  // Form Data
  preferenceCenterId?: string
  preferenceCenterGroupId?: string
  createPreferenceCenterMode: 'NEW' | 'EDIT'
  generalSettings: PreferenceCenterGeneralSettings
  consentCategories: ConsentCategorySettings[]
  activeCategoryId?: string
  styleConfig: PreferenceStyleConfig
  activeStyleTab: PreferenceCenterConfigTabs

  preferenceCenterPreviousUrlPath?: string
}

// Preference Style Config Types
export type PreferenceStyleConfig = {
  headerLogo?: string
  headerBackgroundColor: string
  headerBorderColor: string
  bodyBackgroundColor: string
  bodyBorderColor: string
  bodyTextColor: string
  emailInputsBackgroundColor: string
  emailInputsBorderColor: string
  emailInputsTextColor: string
  consentInputsOnColor: string
  consentInputsOffColor: string
  consentInputsAlwaysOnColor: string
  consentInputsBorderColor: string

  buttonsSendLinkBackgroundColor: string
  buttonsSendLinkBorderColor: string
  buttonsSendLinkTextColor: string

  buttonsSubmitBackgroundColor: string
  buttonsSubmitBorderColor: string
  buttonsSubmitTextColor: string

  enableButtonCancel: boolean
  buttonsCancelBackgroundColor: string
  buttonsCancelBorderColor: string
  buttonsCancelTextColor: string

  enableCommunicationMode: boolean
  optOutCommunicationMode: 'ALL_CATEGORY' | 'PER_CATEGORY'

  enableCommentBox: boolean

  emailBodyContent: string
  emailFooterContent: string

  preferenceBodyContent: string
  preferenceFooterContent: string

  showLastUpdate: boolean
}

// Preference Style Config Initial State
export const PreferenceStyleConfigInitialState: PreferenceStyleConfig = {
  headerLogo: '',
  headerBackgroundColor: 'FFFFFF',
  headerBorderColor: 'FFFFFF',
  bodyBackgroundColor: 'FFFFFF',
  bodyBorderColor: 'FFFFFF',
  bodyTextColor: '1B1D21',
  emailInputsBackgroundColor: 'EFF4FA',
  emailInputsBorderColor: 'EFF4FA',
  emailInputsTextColor: '1B2229',
  consentInputsOnColor: '32CD32',
  consentInputsOffColor: '6E7191',
  consentInputsAlwaysOnColor: 'ABEBAD',
  consentInputsBorderColor: 'FFFFFF',

  buttonsSendLinkBackgroundColor: '1B2229',
  buttonsSendLinkBorderColor: '1B2229',
  buttonsSendLinkTextColor: 'FFFFFF',

  buttonsSubmitBackgroundColor: '1B2229',
  buttonsSubmitBorderColor: '1B2229',
  buttonsSubmitTextColor: 'FFFFFF',

  enableButtonCancel: false,
  buttonsCancelBackgroundColor: 'FFFFFF',
  buttonsCancelBorderColor: '1B2229',
  buttonsCancelTextColor: '1B2229',

  enableCommunicationMode: true,
  optOutCommunicationMode: OPT_OUT_PER_CONSENT_CATEGORY,

  enableCommentBox: false,

  emailBodyContent: '',
  emailFooterContent: '',

  preferenceBodyContent: '',
  preferenceFooterContent: '',
  showLastUpdate: false
}

export type PreferenceCenterGeneralSettings = {
  name: string
  description?: string
  regulations: string[]
  associatedDomains: AssociatedDomain[]
  periodicConsentRenewal: boolean
  consentRenewalSettings: ConsentRenewalSettings
}

export type AssociatedDomain = {
  id: string
  value: string
  isValid: boolean
}

export type ConsentRenewalSettings = {
  consentRenewalType: 'OPT_IN' | 'OPT_OUT'
  consentRenewalPeriod: number
  consentRenewalPeriodUnit: 'DAY' | 'MONTH' | 'YEAR'
  emailToUserAfterRenewal: boolean
  emailToUserAfterRenewalTemplateId?: string
}

export type ConsentCategorySettings = {
  id: string
  categoryName?: string
  displayTitle: string
  displayDescription: string
  allowOptOut: boolean
  associatedSubscriptions: ConsentCategorySubscriptionSettings[]
}

// Dummy Data will be removed after development
export const consentCategories: ConsentCategorySettings[] = [
  {
    id: '1903aa024d2',
    displayTitle: 'Marketing',
    displayDescription: 'Receive our latest news, updates, and offers directly in your inbox.',
    allowOptOut: false,
    associatedSubscriptions: [
      {
        subscriptionId: '6640b9e175ad6f2b1e7117d7',
        displayName: 'Email'
      },
      {
        subscriptionId: '664cc589e5c7c54029298f70',
        displayName: 'SMS'
      }
    ]
  },
  {
    id: '1903aa024d21',
    displayTitle: 'Personalized Ads',
    displayDescription:
      'By consenting you allow us to tailor your experience by providing content and recommendations that match your interests and preferences.',
    allowOptOut: false,
    associatedSubscriptions: [
      {
        subscriptionId: '664cc589e5c7c54029298f71',
        displayName: 'SMS'
      },
      {
        subscriptionId: '6640b9e175ad6f2b1e7117d9',
        displayName: 'Email'
      },
      {
        subscriptionId: '664cc589e5c7c54029298f71'
      }
    ]
  }
]

export type ConsentCategorySubscriptionSettings = {
  subscriptionId: string
  displayName?: string
}
// #endregion

// #region Preference Center Data (Form Data) Initial State
export const preferenceCenterInitialState: PreferenceCenterData = {
  // Query Data
  preferenceCenters: {
    count: 0,
    list: []
  },
  selectedLinkedDataSources: [],
  communicationPreferences: [],
  categoryList: [],
  activeCategoryIdToEdit: '',
  renewalEmailTemplates: [],
  webAppUrl: '',

  // Form Data
  createPreferenceCenterMode: 'NEW',
  generalSettings: {
    name: '',
    description: '',
    regulations: [],
    associatedDomains: [
      {
        id: objectId(),
        value: '',
        isValid: true
      }
    ],
    periodicConsentRenewal: false,
    consentRenewalSettings: {
      consentRenewalType: 'OPT_OUT',
      consentRenewalPeriod: 1,
      consentRenewalPeriodUnit: 'YEAR',
      emailToUserAfterRenewal: false
    }
  },
  consentCategories: [],
  styleConfig: PreferenceStyleConfigInitialState,
  activeStyleTab: PreferenceCenterConfigTabs.EMAIL,
  preferenceCenterPreviousUrlPath: URL_CONSENT_MANAGEMENT + URL_PREFERENCE_CENTER
}
// #endregion

// Create the slice
const preferenceCenterSlice = createSlice({
  name: 'preferenceCenter',
  initialState: preferenceCenterInitialState,
  reducers: {
    // #region General Settings
    setGeneralSettingValue: (state, { payload }) => {
      state.generalSettings[payload.key] = payload.value
    },
    setAssociatedDomainValue: (state, { payload }) => {
      const domain = state.generalSettings.associatedDomains.find((d) => d.id === payload.domainId)

      if (domain) {
        domain.value = payload.value
        domain.isValid = payload.isValid
      }
    },
    addAssociatedDomain: (state) => {
      state.generalSettings.associatedDomains.push({
        id: objectId(),
        value: '',
        isValid: true
      })
    },
    removeAssociatedDomain: (state, { payload }) => {
      state.generalSettings.associatedDomains = state.generalSettings.associatedDomains.filter(
        (d) => d.id !== payload.domainId
      )
    },
    // #endregion

    // #region Linked Data Sources
    setSelectedLinkedDataSources: (state, { payload }) => {
      state.selectedLinkedDataSources = payload
    },
    // #endregion

    // #region Consent Categories
    setConsentCategoryValue: (state, { payload }) => {
      const category = state.consentCategories.find((c) => c.id === payload.categoryId)

      if (category) {
        category[payload.key] = payload.value
      }
    },
    // #endregion

    // #region consentRenewalSettings (General Settings)
    setConsentRenewalSettingValue: (state, { payload }) => {
      state.generalSettings.consentRenewalSettings[payload.key] = payload.value
    },
    // #endregion

    // #region Consent Categories
    setConsentCategorySettings: (state, { payload }) => {
      const category = state.consentCategories.find((c) => c.id === payload.categoryId)

      if (category) {
        category[payload.key] = payload.value
      }
    },

    addToConsentCategoryList: (state, { payload }) => {
      state.consentCategories.forEach((category) => {
        if (category.associatedSubscriptions) {
          category.associatedSubscriptions = category.associatedSubscriptions.filter(
            (sub) => sub.subscriptionId !== payload.subscriptionIdToBeRemoved
          )
        }
      })

      state.consentCategories.push(payload.newCategory)
    },

    removeConsentCategory: (state) => {
      state.consentCategories = state.consentCategories.filter(
        (c) => c.id !== state.activeCategoryId
      )

      // Set the active category to the first category
      if (state.consentCategories.length > 0) {
        state.activeCategoryId = state.consentCategories[0].id
      }
    },

    setActiveConsentCategoryId: (state, { payload }) => {
      state.activeCategoryId = payload.categoryId
    },

    addSubscription: (state) => {
      const category = state.consentCategories.find((c) => c.id === state.activeCategoryId)

      if (category) {
        category.associatedSubscriptions?.push({
          subscriptionId: ''
        })
      }
    },

    removeSubscription: (state, { payload }) => {
      const category = state.consentCategories.find((c) => c.id === state.activeCategoryId)
      if (category) {
        const subscriptionIndex = payload

        // Remove the subscription at the given index
        if (category.associatedSubscriptions && category.associatedSubscriptions.length > 1) {
          category.associatedSubscriptions = category.associatedSubscriptions?.filter(
            (_, index) => index !== subscriptionIndex
          )
        }
      }
    },
    // #endregion
    // #region remove subscription from category
    removeSubscriptionFromCategory: (state, { payload }) => {
      // Find the index of the category that needs to be updated
      const categoryIndex = state.consentCategories.findIndex((c) => c.id === payload.categoryId)

      if (categoryIndex !== -1) {
        const category = state.consentCategories[categoryIndex]

        // Filter out the subscription from associatedSubscriptions
        category.associatedSubscriptions = category.associatedSubscriptions.filter(
          (subscription) => subscription.subscriptionId !== payload.subscriptionId
        )

        // Remove the category if no associated subscriptions are left
        if (category.associatedSubscriptions.length === 0) {
          state.consentCategories.splice(categoryIndex, 1)
        }
      }
    },

    updateSubscription: (state, { payload }) => {
      const category = state.consentCategories.find((c) => c.id === state.activeCategoryId)

      if (category && category.associatedSubscriptions) {
        const { subscriptionIndex, subscription } = payload
        category.associatedSubscriptions[subscriptionIndex] = subscription
      }
    },
    // #endregion

    //#region Update Consent Category with Subscription item
    updateConsentCategoryWithSubscription: (state, { payload }) => {
      const updatedCategories = state.consentCategories.map((category) => {
        // Filter out the subscription from this category if it exists
        const updatedSubscriptions = category.associatedSubscriptions?.filter(
          (subscription) => subscription.subscriptionId !== payload.subscription[0].subscriptionId
        )

        // Return the updated category
        return {
          ...category,
          associatedSubscriptions: updatedSubscriptions
        }
      })

      const targetCategory = updatedCategories.find((c) => c.id === payload.categoryId)

      // If the target category is found, update its subscriptions
      if (targetCategory) {
        targetCategory.associatedSubscriptions = [
          ...(targetCategory.associatedSubscriptions || []),
          ...payload.subscription
        ]
      }

      // Set the new updated categories in the state
      state.consentCategories = updatedCategories
    },
    //#endregion

    //#region set active category Id to edit

    setActiveCategoryIdToEdit: (state, { payload }) => {
      state.activeCategoryIdToEdit = payload.categoryId
    },
    //#endregion

    //#region reset active category Id to edit
    resetActiveCategoryIdToEdit: (state) => {
      state.activeCategoryIdToEdit = ''
    },

    // #region Update consent category with update categoryList

    updateConsentCategoryWithCategoryList: (state, { payload }) => {
      state.consentCategories = state.consentCategories.map((category) => {
        const categoryToBeUpdated = payload.categoryList.find((c) => c.id === category.id)
        if (categoryToBeUpdated) {
          return {
            ...category,
            displayTitle: categoryToBeUpdated.displayTitle,
            displayDescription: categoryToBeUpdated.displayDescription,
            categoryName: categoryToBeUpdated.categoryName
          }
        }
        return category
      })
    },

    // #region Update consent category with selected communication preferences
    updateConsentCategoryWithSelectedSubscription: (state) => {
      const selectedCommunicationPreferences = state.communicationPreferences.map(
        (preference) => preference.id
      )

      state.consentCategories.forEach((category) => {
        category.associatedSubscriptions = category.associatedSubscriptions?.filter(
          (subscription) => selectedCommunicationPreferences.includes(subscription.subscriptionId)
        )
      })
    },
    // #endregion

    // #region Communication Preferences
    setCommunicationPreferenceModeOfCommunication: (state, { payload }) => {
      const preferenceIndex = state.communicationPreferences.findIndex((p) => p.id === payload.id)

      if (preferenceIndex !== -1) {
        state.communicationPreferences[preferenceIndex] = {
          ...state.communicationPreferences[preferenceIndex],
          displayName: payload.displayName
        }
      }

      const categoryIndex = state.consentCategories.findIndex((c) => c.id === payload.categoryId)

      if (categoryIndex !== -1) {
        state.consentCategories[categoryIndex] = {
          ...state.consentCategories[categoryIndex],
          associatedSubscriptions: state.consentCategories[
            categoryIndex
          ].associatedSubscriptions.map((subscription) => {
            if (subscription.subscriptionId === payload.id) {
              return {
                ...subscription,
                displayName: payload.displayName
              }
            }
            return subscription
          })
        }
      }
    },

    // #region Preference Center Style Config
    setStyleConfig: (state, { payload }) => {
      const { key, value } = payload
      state.styleConfig[key] = value
    },
    // #endregion

    createNewPreferenceCenterGroupId: (state) => {
      state.preferenceCenterGroupId = objectId()
    },

    setActiveStyleTab: (state, { payload }) => {
      state.activeStyleTab = payload.activeStyleTab
    },
    // #endregion

    resetPreferenceCenterForm: (state) => {
      state.preferenceCenterId = undefined
      state.preferenceCenterGroupId = undefined
      state.createPreferenceCenterMode = 'NEW'
      state.generalSettings = preferenceCenterInitialState.generalSettings
      state.consentCategories = []
      state.selectedLinkedDataSources = []
      state.savePreferenceCenterStatus = preferenceCenterInitialState.savePreferenceCenterStatus
      state.styleConfig = PreferenceStyleConfigInitialState
      state.activeStyleTab = PreferenceCenterConfigTabs.EMAIL
      state.communicationPreferences = []
    },

    // Set previous Url path
    setPreviousUrlPath: (state, { payload }) => {
      state.preferenceCenterPreviousUrlPath = payload
    },
    // #endregion

    // #region Clear Subscription List
    clearCommunicationPreferences: (state) => {
      state.communicationPreferences = []
    },
    clearConsentCategories: (state) => {
      state.consentCategories = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPreferenceCenterList.fulfilled, (state, { payload }) => {
      state.preferenceCenters.list = payload.list
      state.preferenceCenters.count = payload.count
      state.preferenceCenters.pageInfo = payload.pageInfo
    })
    builder.addCase(fetchPreferenceCenterConfig.fulfilled, (state, { payload }) => {
      if (payload) {
        state.preferenceCenterId = payload.id
        state.preferenceCenterGroupId = payload.preferenceCenterGroupId
        state.createPreferenceCenterMode = 'EDIT'
        state.generalSettings = payload.generalSettings
        state.consentCategories = payload.consentCategories
        state.styleConfig = payload.styleConfig
        state.selectedLinkedDataSources = payload.selectedLinkedDataSources
      }
    })
    builder.addCase(fetchPreferenceCenterWidgetData.fulfilled, (state, { payload }) => {
      state.widgetData = payload || undefined
    })
    builder.addCase(fetchPreferenceCenterWebAppUrl.fulfilled, (state, { payload }) => {
      const result = payload || []
      state.webAppUrl = result.at(0)?.lightbeamWebURL || ''
    })
    builder.addCase(fetchCommunicationPreferences.fulfilled, (state, { payload }) => {
      state.communicationPreferences = payload
    })
    builder.addCase(fetchRenewalEmailTemplates.fulfilled, (state, { payload }) => {
      state.renewalEmailTemplates = payload
    })
    builder.addCase(publishPreferenceCenter.fulfilled, (state, { payload }) => {
      if (payload.mutationStatus === 'SUCCESS') {
        const preferenceCenterIndex = state.preferenceCenters.list.findIndex(
          (pc) => pc.id == payload.preferenceCenterId
        )

        if (preferenceCenterIndex > -1) {
          state.preferenceCenters.list[preferenceCenterIndex].isPublished = true
        }
      }
    })
    builder.addCase(unpublishPreferenceCenter.fulfilled, (state, { payload }) => {
      if (payload.mutationStatus === 'SUCCESS') {
        const preferenceCenterIndex = state.preferenceCenters.list.findIndex(
          (pc) => pc.id == payload.preferenceCenterId
        )

        if (preferenceCenterIndex > -1) {
          state.preferenceCenters.list[preferenceCenterIndex].isPublished = false
        }
      }
    })
    builder.addCase(fetchAllCategoryList.fulfilled, (state, { payload }) => {
      state.categoryList = payload
    })
    builder.addCase(savePreferenceCenter.fulfilled, (state, { payload }) => {
      if (payload?.createPreferenceCenter?.preferenceCenterId) {
        state.savePreferenceCenterStatus = PreferenceCenterSaveStatus.SUCCESS
      } else if (payload?.updatePreferenceCenter?.preferenceCenterId) {
        state.savePreferenceCenterStatus = PreferenceCenterSaveStatus.UPDATE_SUCCESS
      } else {
        state.savePreferenceCenterStatus = PreferenceCenterSaveStatus.ERROR
      }
    })
  }
})

// Active Category Information

export const activeCategory = (state: RootState) =>
  state.preferenceCenter.consentCategories.find(
    (c) => c.id === state.preferenceCenter.activeCategoryId
  )

// Action creators are generated for each case reducer function
export const {
  setGeneralSettingValue,
  setConsentCategoryValue,
  setAssociatedDomainValue,
  addAssociatedDomain,
  removeAssociatedDomain,
  setConsentRenewalSettingValue,
  setSelectedLinkedDataSources,
  addToConsentCategoryList,
  removeConsentCategory,
  setActiveConsentCategoryId,
  setConsentCategorySettings,
  addSubscription,
  removeSubscription,
  removeSubscriptionFromCategory,
  updateSubscription,
  updateConsentCategoryWithSubscription,
  setCommunicationPreferenceModeOfCommunication,
  setStyleConfig,
  setActiveStyleTab,
  resetPreferenceCenterForm,
  setPreviousUrlPath,
  clearCommunicationPreferences,
  clearConsentCategories,
  updateConsentCategoryWithCategoryList,
  setActiveCategoryIdToEdit,
  resetActiveCategoryIdToEdit,
  updateConsentCategoryWithSelectedSubscription,
  createNewPreferenceCenterGroupId
} = preferenceCenterSlice.actions

export default preferenceCenterSlice.reducer
