import {
  mapQueryDsrFormAttributes,
  mapQueryTotalUserForms,
  mutationDeleteDsrForm,
  mutationPublishDsrForm,
  mutationResendVerification,
  mutationSaveDsrForm,
  mutationUnpublishDsrForm,
  mutationUpdateDsrForm,
  queryDsrFormAttributes,
  queryTotalUserForms
} from './queries'
import { DsrCountry, dsrListCountries } from '../optionLists/dsrListCountries'
import { DsrState, dsrListUsStates } from '../optionLists/dsrListStates'
import { DsrCity, dsrListUsCities } from '../optionLists/dsrListCities'
import { defaultSortParams, getSortDirection, SortParams } from '../../../utils/sortUtil'
import apiService from '../../../services/api/apiService'
import { randHex } from '../../../utils/mathUtils'
import {
  mapQueryActiveDsrForm,
  mapQueryDsrForms,
  queryActiveDsrForm,
  queryDsrForms
} from '../queries'
import { FilterParams } from '../../../interfaces'
import {
  DEFAULT_LANGUAGE,
  AttributeInternalNames,
  MESSAGES,
  DSR_FORMS_DEFAULT_LANGUAGES,
  dsrFormDefaultAttributesTranslationMapper,
  dsrFormDefaultAttributeNames
} from '../../../constants'
import graphqlService from '../../../services/graphqlService'
import { DsrRequestType } from '../../../services/graphqlSchemaTypes'
import { dsrListWorldRegions, DsrWorldRegion } from '../optionLists/dsrListWorldRegions'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export enum DSRFormActions {
  delete = 'delete',
  publish = 'publish',
  unpublish = 'unpublish'
}

/** Form schema */
export enum DsrFormElementTypes {
  // custom
  dropdown = 'DROPDOWN',
  richText = 'RICH_TEXT',
  radio = 'RADIO',
  checkbox = 'CHECKBOX',
  input = 'INPUT',
  textarea = 'TEXT_AREA',
  file = 'FILE_INPUT',
  // system
  firstName = 'FIRST_NAME',
  lastName = 'LAST_NAME',
  email = 'EMAIL_ADDRESS',
  country = 'COUNTRY',
  state = 'STATE',
  address = 'address',
  city = 'CITY',
  zipCode = 'POSTAL_CODE',
  phone = 'PHONE_NUMBER',
  birthDate = 'BIRTH_DATE',
  attributes = 'ATTRIBUTES',
  link = 'LINK',
  calendar = 'CALENDAR',
  // special
  requestTypes = 'REQUEST_TYPES'
}

export const dsrFormElementsWithOptions = [
  DsrFormElementTypes.radio,
  DsrFormElementTypes.checkbox,
  DsrFormElementTypes.dropdown,
  DsrFormElementTypes.country,
  DsrFormElementTypes.state,
  DsrFormElementTypes.city,
  DsrFormElementTypes.requestTypes
]

export const dsrFormElementWithInputs = [
  DsrFormElementTypes.input,
  DsrFormElementTypes.link,
  DsrFormElementTypes.firstName,
  DsrFormElementTypes.lastName,
  DsrFormElementTypes.email,
  DsrFormElementTypes.address,
  DsrFormElementTypes.zipCode,
  DsrFormElementTypes.phone,
  DsrFormElementTypes.attributes,
  DsrFormElementTypes.birthDate,
  DsrFormElementTypes.calendar
]

export const dsrFormElementAttributes = [
  DsrFormElementTypes.firstName,
  DsrFormElementTypes.lastName,
  DsrFormElementTypes.email,
  DsrFormElementTypes.country,
  DsrFormElementTypes.state,
  DsrFormElementTypes.address,
  DsrFormElementTypes.city,
  DsrFormElementTypes.zipCode,
  DsrFormElementTypes.phone,
  DsrFormElementTypes.birthDate,
  DsrFormElementTypes.attributes
]

export const DSR_REQUEST_ID = 'requestId'
export const DSR_FORM_ID = 'id'
export const DSR_FORM_NAME = 'name'
export const DSR_FORM_EMAIL = 'email'
export const DSR_FORM_DESCRIPTION = 'description'
export const DSR_FORM_LOGO = 'logo'
export const DSR_FORM_LABEL = 'label'
export const DSR_FORM_VALUE = 'value'
export const DSR_FORM_TYPE = 'type'
export const DSR_FORM_REGULATION_TYPE = 'regulation'
export const DSR_FORM_ELEMENT_TYPE = 'elementType'
export const DSR_FORM_OPTIONS = 'options'
export const DSR_FORM_TOOLTIP = 'tooltip'
export const DSR_FORM_REQUIRED = 'required'
export const DSR_FORM_MIN_LENGTH = 'minLength'
export const DSR_FORM_MAX_LENGTH = 'maxLength'
export const DSR_FORM_PATTERN = 'pattern'
export const DSR_FORM_ATTRIBUTE_ID = 'attributeId'
export const DSR_FORM_VALIDATION = 'validation'
export const DSR_FORM_REMARK = 'remark'
export const DSR_FORM_REASON = 'reason'
export const DSR_FORM_ENTITY_DETECTION = 'enableEntityDetection'
export const DSR_FORM_ORDER = 'order'

export interface I18Labels {
  i18Labels?: { [key: string]: string }
  i18DescriptionLabels?: { [key: string]: string | undefined }
  isActive?: boolean
}

export type DsrFormFieldOption = {
  id: string
  value: string
  selected?: boolean
  showTextField?: boolean
  optionId?: string
  text?: string
  i18Labels?: I18Labels
}

export type DsrFormFieldElement = I18Labels & {
  [DSR_FORM_ID]: string
  [DSR_FORM_ELEMENT_TYPE]: DsrFormElementTypes
  [DSR_FORM_LABEL]: string
  [DSR_FORM_VALUE]?: string | number | boolean | Array<DsrFormFieldOption & I18Labels>
  [DSR_FORM_DESCRIPTION]?: string
  [DSR_FORM_VALIDATION]: {
    [DSR_FORM_REQUIRED]: boolean
    [DSR_FORM_MIN_LENGTH]?: number
    [DSR_FORM_MAX_LENGTH]?: number
    [DSR_FORM_PATTERN]?: string
  }
  [DSR_FORM_OPTIONS]?: Array<DsrFormFieldOption & I18Labels>
  [DSR_FORM_TOOLTIP]?: string
  [DSR_FORM_ATTRIBUTE_ID]?: string
  [DSR_FORM_ENTITY_DETECTION]?: boolean
  [DSR_FORM_ORDER]?: number
}

export const getDefaultDsrFormField = (
  values?: Partial<DsrFormFieldElement>
): DsrFormFieldElement => {
  return {
    id: values?.id || randHex(5),
    label: values?.label || '',
    value: values?.value || '',
    description: values?.description || '',
    elementType: values?.elementType || DsrFormElementTypes.input,
    attributeId: values?.attributeId || '',
    options: values?.options || [],
    enableEntityDetection: values?.enableEntityDetection || false,
    validation: values?.validation || { required: false },
    i18Labels: values?.i18Labels || {},
    i18DescriptionLabels: values?.i18DescriptionLabels || {},
    order: values?.order
  }
}

export const ACTION_DSR_FORM_ATTRIBUTES = 'dsrForms/formAttributes'
export const fetchDsrFormAttributes = createAsyncThunk(ACTION_DSR_FORM_ATTRIBUTES, async () => {
  const attributesRaw = await graphqlService.execute(queryDsrFormAttributes())
  return mapQueryDsrFormAttributes(attributesRaw)
})

export const ACTION_DSR_FORM_WEB_APP_URL = 'dsrForms/webAppUrl'
export const fetchDsrFormWebAppUrl = createAsyncThunk(ACTION_DSR_FORM_WEB_APP_URL, async () => {
  return await apiService.getWebAppUrl()
})

// fetch forms
export interface DsrFormParams {
  id?: string
  searchQuery?: string
  type?: DsrFormTypes
  filters?: FilterParams
  page?: number
  isCreateFlow?: boolean
}
export const ACTION_DSR_FORMS = 'dsrForms/list'
export const fetchDsrForms = createAsyncThunk(ACTION_DSR_FORMS, async (params: DsrFormParams) => {
  const raw = await graphqlService.execute(queryDsrForms(params))
  return mapQueryDsrForms(raw)
})
interface FormResponse {
  form: DsrForm
  isNew: boolean
}
export const ACTION_DSR_FORM = 'dsrForms/form'
export const fetchDsrFormById = createAsyncThunk(
  ACTION_DSR_FORM,
  async (params: DsrFormParams): Promise<FormResponse> => {
    const rawForm = await graphqlService.execute(queryDsrForms(params))
    const response = mapQueryDsrForms(rawForm)
    const form = response.list[0]

    if (params.isCreateFlow) {
      if (params.id) {
        form.template = { name: form.name }
      }

      form.isPublished = false
      form.formJsonParsed = {
        ...form.formJsonParsed,
        fieldsDetails:
          form.formJsonParsed?.fieldsDetails?.map((subject) =>
            subject[DSR_FORM_ID] == AttributeInternalNames.emailAddress
              ? { ...subject, enableEntityDetection: true }
              : subject
          ) || []
      }
    }

    return { form, isNew: !!params.isCreateFlow }
  }
)
export const ACTION_DSR_REGULATIONS = 'dsrForms/regulations'
export const fetchDsrFormTemplates = createAsyncThunk(
  ACTION_DSR_REGULATIONS,
  async (params: DsrFormParams) => {
    const raw = await graphqlService.execute(queryDsrForms(params))
    return mapQueryDsrForms(raw)
  }
)

// update form
export type DsrFormUploadedFile = {
  fileId: string
  fileName: string
  path: string
  formElementId?: string
}

export type DsrFormSaveParams = {
  form: DsrForm
  publish?: boolean
  isTemplate?: boolean
}
export const ACTION_DSR_FORM_SAVE = 'dsrForms/saveForm'
export const saveDsrForm = createAsyncThunk(
  ACTION_DSR_FORM_SAVE,
  async (params: DsrFormSaveParams) => {
    let rawResponse
    if (params.form.id) {
      rawResponse = await graphqlService.execute(mutationUpdateDsrForm(params.form))
    } else {
      rawResponse = await graphqlService.execute(mutationSaveDsrForm(params.form))
    }

    const dsrFormId =
      rawResponse?.updateDsrForm?.dsrFormId || rawResponse?.createDsrForm?.dsrFormId || ''

    if (params.publish && dsrFormId) {
      await graphqlService.execute(mutationPublishDsrForm(dsrFormId))
    }

    return { dsrFormId }
  }
)

export const ACTION_DSR_FORM_PUBLISH = 'dsrForms/publish'
export const publishDsrForm = createAsyncThunk(
  ACTION_DSR_FORM_PUBLISH,
  async (formId: string, { rejectWithValue }) => {
    try {
      const result = await graphqlService.execute(mutationPublishDsrForm(formId))
      return { statusMessage: 'dsr.forms.actions.publish.success', ...result }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'dsr.forms.actions.publish.error' })
    }
  }
)

export const ACTION_DSR_FORM_UNPUBLISH = 'dsrForms/unpublish'
export const unpublishDsrForm = createAsyncThunk(
  ACTION_DSR_FORM_UNPUBLISH,
  async (formId: string, { rejectWithValue }) => {
    try {
      const result = await graphqlService.execute(mutationUnpublishDsrForm(formId))
      return { statusMessage: 'dsr.forms.actions.unpublish.success', ...result }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'dsr.forms.actions.unpublish.error' })
    }
  }
)

export const ACTION_DSR_VERIFICATION_RESEND = 'dsrForms/publish'
export const resendDsrConfirmation = createAsyncThunk(
  ACTION_DSR_VERIFICATION_RESEND,
  async (email: string) => {
    await graphqlService.execute(mutationResendVerification(email))
  }
)

export type DeleteDsrFormParams = { [DSR_FORM_ID]: string }
export const ACTION_DSR_FORM_DELETE = 'dsrForms/delete'
export const deleteDsrForm = createAsyncThunk(
  ACTION_DSR_FORM_DELETE,
  async (formId: string, { rejectWithValue }) => {
    try {
      const result = await graphqlService.execute(mutationDeleteDsrForm(formId))
      return { statusMessage: 'dsr.forms.actions.delete.success', ...result }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'dsr.forms.actions.delete.error' })
    }
  }
)

export const ACTION_ACTIVE_DSR_FORM = 'dsrForms/activeForm'
export const fetchActiveDsrForm = createAsyncThunk(ACTION_ACTIVE_DSR_FORM, async () => {
  const raw = await graphqlService.execute(queryActiveDsrForm())
  return mapQueryActiveDsrForm(raw)
})

export const ACTION_DSR_SUBMIT_FORM_VALUES = 'dsrForms/submitValues'
export const submitDsrFormValues = createAsyncThunk(
  ACTION_DSR_SUBMIT_FORM_VALUES,
  async (params: any) => {
    return await apiService.postDsrSubmittedForm(params)
  }
)

// fetch lists
export type DsrFormAttribute = {
  id: string
  name: string
  internalName: string
}

/** Common DSR params */
export type DsrAssociatedRegulation = {
  id: string
  name: string
  isPredefined: boolean
  regulationType: DataSubjectRegulationTypes
}
export enum DsrRequestTypes {
  access = 'DATA_ACCESS',
  rectification = 'DATA_RECTIFICATION',
  erasure = 'DATA_ERASURE',
  doNotSell = 'DO_NOT_SELL',
  other = 'DATA_OTHER'
}
export enum DsrRequestApiKeys {
  access = 'access',
  erasure = 'erasure',
  rectification = 'rectification',
  doNotSell = 'doNotSell',
  other = 'other'
}
export enum DsrRequestTypeKeys {
  access = 'dataAccess',
  erasure = 'dataErasure',
  rectification = 'dataRectification',
  doNotSell = 'dataDoNotSell',
  other = 'dataOther'
}
export type DsrAssociatedRequest = {
  id: string
  name: string
  isPredefined: boolean
  requestType: DsrRequestTypes[]
}

export enum DsrFormSectionOptions {
  logo = 'LOGO',
  description = 'DESCRIPTION',
  fields = 'FIELDS',
  terms = 'TERMS',
  buttons = 'BUTTONS',
  footer = 'FOOTER'
}

export const dsrFormMandatorySections = [DsrFormSectionOptions.fields, DsrFormSectionOptions.terms]

export type DsrFormAttachments = {
  isEnabled?: boolean
  isRequired?: boolean
  list?: Array<DsrFormUploadedFile>
}
export type DsrFormTerms = {
  isEnabled?: boolean
  isRequired?: boolean
  link?: string
}
export type DsrFormTemplate = {
  id?: string
  name: string
}
export enum DsrFormTypes {
  form = 'FORM',
  regulation = 'REGULATION_TEMPLATE',
  request = 'REQUEST_TEMPLATE'
}
export enum DataSubjectRegulationTypes {
  gdpr = 'GDPR',
  ccpa = 'CCPA',
  vcdpa = 'VSDPA',
  lgdp = 'LGDP'
}
export type DsrFormSelectedElement = {
  id?: string
  type?: DsrFormElementTypes
}

export const mapRequestTypeToText = (type) => {
  if (type === DsrRequestType.Access) return 'Access'
  if (type === DsrRequestType.Rectification) return 'Rectification'
  if (type === DsrRequestType.Erasure) return 'Erasure'
  if (type === DsrRequestType.Other) return 'Other'
  if (type === DsrRequestType.DoNotSell) return 'Do Not Sell'
  return ''
}

export type DsrRequesterDetails = {
  name: DsrFormFieldElement
  email: DsrFormFieldElement
  remark: DsrFormFieldElement
  isActive?: boolean
}
export type DsrAccessTypeRequestFields = I18Labels & {
  reason?: DsrFormFieldElement
  isActive?: boolean
  label?: string
  erasureDetails?: DsrFormFieldElement
  attributes?: DsrAttributeUpdate[]
  from?: I18Labels
  to?: I18Labels
  select?: I18Labels
}

export type DsrAttributeUpdate = {
  attributeId: string
  oldValue: string
  newValue: string
  from?: I18Labels
  to?: I18Labels
  select?: I18Labels
}
export type DsrTermsAndConditionsFields = I18Labels & {
  mandatoryAcceptance?: boolean
  tnc?: DsrFormFieldElement
  link?: DsrFormFieldElement
  isActive?: boolean
}
export interface DsrFormLanguage {
  code: string
  isDefault?: boolean
  i18Labels?: I18Labels
}
export const dsrFormLogoSettingsDefaultBgColor = '#cce3ff'
export const dsrFormLogoSettingsDefaultAlignment = 'left'
export interface DsrFormLogoSettings {
  label?: string
  alignment?: 'left' | 'right' | 'center'
  backgroundColor?: string
}
export type DsrFormJsonParsed = {
  id?: string
  name?: string
  type?: DsrFormTypes
  activeSections?: DsrFormSectionOptions[]
  enableLightbeamBranding?: boolean
  requesterDetails?: DsrRequesterDetails
  fieldsDetails?: DsrFormFieldElement[]
  dataSubjectDetails?: DsrFormFieldElement[] // backward compatibility
  additionalDetails?: DsrFormFieldElement[] // backward compatibility
  description?: string
  rawDescription?: any
  createdAt?: string
  createdBy?: string
  updateTimestamp?: string
  updatedBy?: string
  locations?: string[]
  isTemplate?: boolean
  status?: string
  template?: DsrFormTemplate
  requestType?: DsrRequestTypes[]
  dataAccess?: DsrAccessTypeRequestFields
  dataDoNotSell?: DsrAccessTypeRequestFields
  dataOther?: DsrAccessTypeRequestFields
  dataErasure?: DsrAccessTypeRequestFields
  dataRectification?: DsrAccessTypeRequestFields
  termsAndConditions?: DsrTermsAndConditionsFields
  attributes?: DsrFormAttribute[]
  logoSettings?: DsrFormLogoSettings
  languages?: DsrFormLanguage[]
  requestTypes?: I18Labels
  requestTypesSection?: I18Labels
  btnSubmit?: I18Labels
  language?: I18Labels
  poweredBy?: I18Labels
  fieldsDetailsSection?: I18Labels
  additionalDetailsSection?: I18Labels
  formName?: I18Labels
  formDescription?: I18Labels
  formDescriptionRaw?: I18Labels
  formFooter?: I18Labels
  formFooterRaw?: I18Labels
  successTitle?: I18Labels
  successText?: I18Labels
  successLink?: I18Labels
  errorText?: I18Labels
  logoFileUrl?: string
  logoFileUrlType?: string
  version?: string // is needed for correct compatibility working
}

export type DsrForm = {
  id?: string
  name: string
  type: DsrFormTypes
  description?: string
  createdAt?: string
  createdBy?: string
  updateTimestamp?: string
  updatedBy?: string
  locations?: string[]
  isTemplate?: boolean
  templateName?: string
  isPublished?: boolean
  status?: string
  template?: DsrFormTemplate
  logoFileUrl?: string
  requestType?: DsrRequestTypes[]
  formRawJSON?: string
  formJsonParsed?: DsrFormJsonParsed
  associatedRequests?: string[]
  formGroupId?: string // static form ID for web app link
  formWebUrl?: string // form URL for web app link
}

export type DsrFormDraggingElement = {
  type: DsrFormElementTypes
  state: 'init' | 'dragging' | 'dropped'
}

// Option lists
export enum DsrListTypes {
  worldRegions = 'worldRegions',
  countries = 'countries',
  statesUs = 'statesUs',
  citiesUs = 'citiesUs'
}

export const DSR_FORM_VERSION = '2'

/** Initial state */
type DsrFormsState = {
  forms: {
    sort: SortParams
    list?: DsrForm[]
    total?: number
  }

  regulationTemplates: {
    sort: SortParams
    list?: DsrForm[]
    total?: number
  }
  requestTemplates: {
    sort: SortParams
    list?: DsrForm[]
    total?: number
  }
  totalUserForms?: number
  selectedForm?: DsrForm
  draggingElement?: DsrFormDraggingElement
  showSelectedFormErrors: boolean
  webAppUrl?: string
  activeForm?: DsrForm
  formAttributes?: DsrFormAttribute[]
  formLanguage: string
  optionLists: {
    worldRegions?: DsrWorldRegion[]
    countries?: DsrCountry[]
    statesUs?: DsrState[]
    citiesUs?: DsrCity[]
  }
}

const initialDsrFormsList = {
  sort: defaultSortParams,
  list: []
}

export const initialState: DsrFormsState = {
  forms: initialDsrFormsList,
  regulationTemplates: {
    sort: defaultSortParams
  },
  requestTemplates: {
    sort: defaultSortParams,
    list: [],
    total: 0
  },
  showSelectedFormErrors: false,
  formLanguage: DEFAULT_LANGUAGE,
  optionLists: {
    worldRegions: dsrListWorldRegions,
    countries: dsrListCountries,
    statesUs: dsrListUsStates,
    citiesUs: dsrListUsCities
  }
}

export const translationsMapper = (languages: DsrFormLanguage[], key: string) => {
  const defaultLanguage = languages.find(({ isDefault }) => isDefault)?.code || DEFAULT_LANGUAGE

  return languages.reduce((previousValue, { code: currentValue }) => {
    previousValue[currentValue] = MESSAGES[currentValue]
      ? MESSAGES[currentValue][key]
      : MESSAGES[defaultLanguage][key]
    return previousValue
  }, {})
}

export const dataAccessKey = 'dsr.configurator.section.dataAccess'
export const dataDoNotSellKey = 'dsr.configurator.doNotSell'
export const dataOtherKey = 'dsr.configurator.dataOther'
export const dataRectificationKey = 'dsr.configurator.section.dataRectification'
export const dataErasureKey = 'dsr.configurator.section.dataErasure'
export const dataAccessReasonKey = 'dsr.configurator.section.dataAccess.reason'
export const dataErasureReasonKey = 'dsr.configurator.section.dataErasure.details'
export const dataErasureTitleKey = 'dsr.configurator.section.dataErasure'
export const dataErasureDetailKey = 'dsr.configurator.section.dataErasure.reason'
export const dsrLangKeyFieldsDetails = 'dsr.configurator.sections.FIELDS'
export const termsSectionTitleKey = 'dsr.configurator.section.tnc'
export const termsLinkKey = 'dsr.configurator.section.link'
export const termsConditionKey = 'dsr.configurator.section.terms'
export const changeToKey = 'dsr.configurator.section.dataRectification.changeTo'
export const changeFromKey = 'dsr.configurator.section.dataRectification.changeFrom'
export const selectAttrKey = 'dsr.configurator.section.dataRectification.selectAttribute'
export const selectReqKey = 'dsr.forms.preview.form.dataRequestType'
export const requestTypeKey = 'dsr.forms.preview.form.dataRequest'
export const btnSubmitKey = 'btn.submit'
export const languageKey = 'dsr.forms.language'
export const poweredByKey = 'dsr.forms.poweredBy'
export const successTitleKey = 'dsr.forms.submit.success.title'
export const successTextKey = 'dsr.forms.submit.success.text'
export const successLinkKey = 'dsr.forms.submit.success.link'
export const errorTextKey = 'dsr.forms.submit.error.text'
export const enhanceWithDefaultTranslations = (dsrForm: DsrForm) => {
  const { formJsonParsed } = dsrForm
  if (formJsonParsed) {
    const {
      dataAccess,
      dataErasure,
      dataDoNotSell,
      dataOther,
      dataRectification,
      languages = [],
      termsAndConditions = {},
      fieldsDetailsSection,
      formDescriptionRaw,
      formDescription,
      formFooterRaw,
      formFooter,
      formName = '',
      name = ''
    } = formJsonParsed
    let transformmedForm: DsrForm = { ...dsrForm }
    const translationMapper = translationsMapper.bind(null, languages)
    const dataAccessReasonLabels = translationMapper(dataAccessReasonKey)
    const dataAccessLabels = translationMapper(dataAccessKey)
    const dataDoNotSellLabels = translationMapper(dataDoNotSellKey)
    const dataOtherLabels = translationMapper(dataOtherKey)
    const dataRectLabels = translationMapper(dataRectificationKey)
    const dataErasureLabels = translationMapper(dataErasureKey)
    const dataRectToLabels = translationMapper(changeToKey)
    const dataRectFromLabels = translationMapper(changeFromKey)
    const dataRectSelectLabels = translationMapper(selectAttrKey)
    const dataErasureReasonLabels = translationMapper(dataErasureReasonKey)
    const dataErasureDetailLabels = translationMapper(dataErasureDetailKey)
    const dataAccessFields = {
      dataAccess: {
        ...dataAccess,
        i18Labels:
          Object.keys(dataAccess?.i18Labels || {}).length > 0
            ? { ...dataAccessLabels, ...(dataAccess?.i18Labels || {}) }
            : dataAccessLabels,
        reason: {
          ...dataAccess?.reason,
          i18Labels:
            Object.keys(dataAccess?.reason?.i18Labels || {}).length > 0
              ? { ...dataAccessReasonLabels, ...(dataAccess?.reason?.i18Labels || {}) }
              : dataAccessReasonLabels,
          i18DescriptionLabels: dataAccess?.reason?.i18DescriptionLabels || {}
        }
      } as DsrAccessTypeRequestFields
    }
    const dataDoNotSellFields = {
      dataDoNotSell: {
        ...dataDoNotSell,
        i18Labels:
          Object.keys(dataDoNotSell?.i18Labels || {}).length > 0
            ? { ...dataDoNotSellLabels, ...(dataDoNotSell?.i18Labels || {}) }
            : dataDoNotSellLabels,
        reason: {
          ...dataDoNotSell?.reason,
          i18Labels:
            Object.keys(dataDoNotSell?.reason?.i18Labels || {}).length > 0
              ? { ...dataAccessReasonLabels, ...(dataOther?.reason?.i18Labels || {}) }
              : dataAccessReasonLabels,
          i18DescriptionLabels: dataDoNotSell?.reason?.i18DescriptionLabels || {}
        }
      } as DsrAccessTypeRequestFields
    }
    const dataOtherFields = {
      dataOther: {
        ...dataOther,
        i18Labels:
          Object.keys(dataOther?.i18Labels || {}).length > 0
            ? { ...dataOtherLabels, ...(dataOther?.i18Labels || {}) }
            : dataOtherLabels,
        reason: {
          ...dataOther?.reason,
          i18Labels:
            Object.keys(dataOther?.reason?.i18Labels || {}).length > 0
              ? { ...dataAccessReasonLabels, ...(dataOther?.reason?.i18Labels || {}) }
              : dataAccessReasonLabels,
          i18DescriptionLabels: dataOther?.reason?.i18DescriptionLabels || {}
        }
      } as DsrAccessTypeRequestFields
    }
    const dataErasureFields = {
      dataErasure: {
        ...dataErasure,
        i18Labels:
          Object.keys(dataErasure?.i18Labels || {}).length > 0
            ? { ...dataErasureLabels, ...(dataErasure?.i18Labels || {}) }
            : dataErasureLabels,
        erasureDetails: {
          ...dataErasure?.erasureDetails,
          i18Labels:
            Object.keys(dataErasure?.erasureDetails?.i18Labels || {}).length > 0
              ? { ...dataErasureDetailLabels, ...(dataErasure?.erasureDetails?.i18Labels || {}) }
              : dataErasureDetailLabels,
          i18DescriptionLabels: dataErasure?.erasureDetails?.i18DescriptionLabels || {},
          elementType: DsrFormElementTypes.input
        },
        reason: {
          ...dataErasure?.reason,
          i18Labels:
            Object.keys(dataErasure?.reason?.i18Labels || {}).length > 0
              ? { ...dataErasureReasonLabels, ...(dataErasure?.reason?.i18Labels || {}) }
              : dataErasureReasonLabels,
          i18DescriptionLabels: dataErasure?.reason?.i18DescriptionLabels || {},
          elementType: DsrFormElementTypes.textarea
        }
      } as DsrAccessTypeRequestFields
    }
    const detailsRectificationFields = {
      dataRectification: {
        ...dataRectification,
        i18Labels:
          Object.keys(dataRectification?.i18Labels || {}).length > 0
            ? { ...dataRectLabels, ...(dataRectification?.i18Labels || {}) }
            : dataRectLabels,
        to: {
          i18Labels:
            Object.keys(dataRectification?.to?.i18Labels || {}).length > 0
              ? { ...dataRectToLabels, ...(dataRectification?.to?.i18Labels || {}) }
              : dataRectToLabels,
          i18DescriptionLabels: dataRectification?.to?.i18DescriptionLabels || {}
        },
        from: {
          i18Labels:
            Object.keys(dataRectification?.from?.i18Labels || {}).length > 0
              ? { ...dataRectFromLabels, ...(dataRectification?.from?.i18Labels || {}) }
              : dataRectFromLabels,
          i18DescriptionLabels: dataRectification?.from?.i18DescriptionLabels || {}
        },
        select: {
          i18Labels:
            Object.keys(dataRectification?.select?.i18Labels || {}).length > 0
              ? { ...dataRectSelectLabels, ...(dataRectification?.select?.i18Labels || {}) }
              : dataRectSelectLabels,
          i18DescriptionLabels: dataRectification?.select?.i18DescriptionLabels || {}
        }
      } as DsrAccessTypeRequestFields
    }
    const termsConditionsi18Labels = translationMapper(termsConditionKey)
    const tnci18Labels = translationMapper(termsSectionTitleKey)
    const fieldsDetailsSectionLabels = translationMapper(dsrLangKeyFieldsDetails)
    const termsi18Labels: DsrTermsAndConditionsFields = {
      ...termsAndConditions,
      i18Labels:
        Object.keys(termsAndConditions?.i18Labels || {}).length > 0
          ? { ...termsConditionsi18Labels, ...(termsAndConditions?.i18Labels || {}) }
          : termsConditionsi18Labels,

      tnc: {
        ...(termsAndConditions.tnc as DsrFormFieldElement),
        options:
          termsAndConditions.tnc?.[DSR_FORM_OPTIONS]?.map((opt) =>
            opt.i18Labels
              ? {
                  ...opt,
                  i18Labels:
                    Object.keys(opt.i18Labels || {}).length > 0
                      ? {
                          ...tnci18Labels,
                          ...opt.i18Labels
                        }
                      : tnci18Labels
                }
              : {
                  ...opt,
                  i18Labels: tnci18Labels
                }
          ) || []
      },
      link: {
        ...(termsAndConditions.link as DsrFormFieldElement),
        i18Labels:
          Object.keys(termsAndConditions?.link?.i18Labels || {}).length > 0
            ? { ...translationMapper(termsLinkKey), ...(termsAndConditions?.link?.i18Labels || {}) }
            : translationMapper(termsLinkKey)
      }
    }

    transformmedForm = {
      ...transformmedForm,
      formJsonParsed: {
        ...formJsonParsed,
        fieldsDetails:
          formJsonParsed.fieldsDetails?.map((detail) =>
            dsrFormDefaultAttributeNames.find((name) => name === detail.id)
              ? {
                  ...detail,
                  i18Labels: {
                    ...translationMapper(dsrFormDefaultAttributesTranslationMapper[detail.id]),
                    ...(detail.i18Labels || {})
                  }
                }
              : detail
          ) || [],
        ...dataAccessFields,
        ...dataDoNotSellFields,
        ...dataOtherFields,
        ...dataErasureFields,
        ...detailsRectificationFields,
        termsAndConditions: termsi18Labels,
        requestTypes: { i18Labels: translationMapper(selectReqKey) },
        requestTypesSection: { i18Labels: translationMapper(requestTypeKey) },
        btnSubmit: { i18Labels: translationMapper(btnSubmitKey) },
        language: { i18Labels: translationMapper(languageKey) },
        poweredBy: { i18Labels: translationMapper(poweredByKey) },
        successTitle: { i18Labels: translationMapper(successTitleKey) },
        successText: { i18Labels: translationMapper(successTextKey) },
        successLink: { i18Labels: translationMapper(successLinkKey) },
        errorText: { i18Labels: translationMapper(errorTextKey) },
        fieldsDetailsSection: {
          i18Labels:
            Object.keys(fieldsDetailsSection?.i18Labels || {}).length > 0
              ? {
                  ...fieldsDetailsSectionLabels,
                  ...(fieldsDetailsSection?.i18Labels || {})
                }
              : fieldsDetailsSectionLabels
        },
        formDescriptionRaw: {
          i18Labels: languages.reduce((previousValue, { code = '' }) => {
            previousValue[code] =
              formDescriptionRaw && formDescriptionRaw.i18Labels
                ? formDescriptionRaw.i18Labels[code] || ''
                : ''
            return previousValue
          }, {})
        },
        formDescription: {
          i18Labels: languages.reduce((previousValue, { code = '' }) => {
            previousValue[code] =
              formDescription && formDescription.i18Labels
                ? formDescription.i18Labels[code] || ''
                : ''
            return previousValue
          }, {})
        },
        formFooterRaw: {
          i18Labels: languages.reduce((previousValue, { code = '' }) => {
            previousValue[code] =
              formFooterRaw && formFooterRaw.i18Labels ? formFooterRaw.i18Labels[code] || '' : ''
            return previousValue
          }, {})
        },
        formFooter: {
          i18Labels: languages.reduce((previousValue, { code = '' }) => {
            previousValue[code] =
              formFooter && formFooter.i18Labels ? formFooter.i18Labels[code] || '' : ''
            return previousValue
          }, {})
        },
        formName: {
          i18Labels: languages.reduce((previousValue, { code = '' }) => {
            previousValue[code] =
              formName && formName.i18Labels ? formName.i18Labels[code] || name || '' : name || ''
            return previousValue
          }, {})
        }
      }
    }

    return transformmedForm
  } else {
    return dsrForm
  }
}

// merge sections for backward compatibility
const getMergedFieldDetails = (formJsonParsed?: DsrFormJsonParsed) => {
  if (!formJsonParsed) return []

  const mergedFields = [
    ...(formJsonParsed?.dataSubjectDetails || []),
    ...(formJsonParsed?.additionalDetails || []),
    ...(formJsonParsed?.fieldsDetails || [])
  ]

  const fieldsDetails: DsrFormFieldElement[] = []

  mergedFields.forEach((item) => {
    const alreadyAddedElement = fieldsDetails.find(({ id = '' }) => item.id === id)
    if (!alreadyAddedElement) {
      fieldsDetails.push(item)
    }
  })

  // add order index
  const fieldSorted = fieldsDetails
    .map((field, i) => (Number.isInteger(field.order) ? field : { ...field, order: i }))
    .sort((a, b) => (a.order || 0) - (b.order || 0))

  return fieldSorted
}

const hydrateDsrForm = (form: DsrForm): DsrForm => {
  const requesterDetails: DsrRequesterDetails = {
    isActive: !!form.formJsonParsed?.requesterDetails?.isActive,
    name: form.formJsonParsed?.requesterDetails?.name
      ? form.formJsonParsed?.requesterDetails?.name
      : getDefaultDsrFormField({ label: 'Name', validation: { required: true } }),
    email: form.formJsonParsed?.requesterDetails?.email
      ? form.formJsonParsed?.requesterDetails?.email
      : getDefaultDsrFormField({ label: 'Email', validation: { required: true } }),
    remark: form.formJsonParsed?.requesterDetails?.remark
      ? form.formJsonParsed?.requesterDetails?.remark
      : getDefaultDsrFormField()
  }

  const isDefaultActive = !!(
    !form.formJsonParsed?.dataAccess?.isActive &&
    !form.formJsonParsed?.dataErasure?.isActive &&
    !form.formJsonParsed?.dataRectification?.isActive &&
    !form.formJsonParsed?.dataDoNotSell?.isActive &&
    !form.formJsonParsed?.dataOther?.isActive
  )

  const dataAccess: DsrAccessTypeRequestFields = form.formJsonParsed?.dataAccess
    ? {
        ...form.formJsonParsed.dataAccess,
        label:
          form.formJsonParsed.dataAccess?.label ||
          MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.access],
        isActive: form.formJsonParsed.dataAccess?.isActive || false
      }
    : {
        isActive: isDefaultActive,
        label: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.access],
        reason: getDefaultDsrFormField({
          elementType: DsrFormElementTypes.textarea
        })
      }
  const dataDoNotSell: DsrAccessTypeRequestFields = form.formJsonParsed?.dataDoNotSell
    ? {
        ...form.formJsonParsed.dataDoNotSell,
        label:
          form.formJsonParsed.dataDoNotSell?.label ||
          MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.doNotSell],
        isActive: form.formJsonParsed.dataDoNotSell?.isActive || false
      }
    : {
        isActive: false,
        label: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.doNotSell],
        reason: getDefaultDsrFormField({
          elementType: DsrFormElementTypes.textarea
        })
      }
  const dataOther: DsrAccessTypeRequestFields = form.formJsonParsed?.dataOther
    ? {
        ...form.formJsonParsed.dataOther,
        isActive: form.formJsonParsed.dataOther?.isActive || false,
        label:
          form.formJsonParsed.dataOther?.label ||
          MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.other]
      }
    : {
        isActive: false,
        label: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.other],
        reason: getDefaultDsrFormField({
          elementType: DsrFormElementTypes.textarea
        })
      }

  const dataErasure: DsrAccessTypeRequestFields = form.formJsonParsed?.dataErasure
    ? {
        ...form.formJsonParsed.dataErasure,
        isActive: form.formJsonParsed.dataErasure?.isActive || false,
        label:
          form.formJsonParsed.dataErasure?.label ||
          MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.erasure]
      }
    : {
        isActive: false,
        label: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.erasure],
        erasureDetails: {
          ...getDefaultDsrFormField({ validation: { required: true } }),
          elementType: DsrFormElementTypes.input
        },
        reason: getDefaultDsrFormField({
          elementType: DsrFormElementTypes.textarea
        })
      }

  const dataRectification: DsrAccessTypeRequestFields = form.formJsonParsed?.dataRectification
    ? {
        ...form.formJsonParsed.dataRectification,
        isActive: form.formJsonParsed?.dataRectification?.isActive || false,
        label:
          form.formJsonParsed.dataRectification?.label ||
          MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.rectification]
      }
    : {
        isActive: false,
        label: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.rectification]
      }

  const fieldsDetails = getMergedFieldDetails(form.formJsonParsed)

  if (!fieldsDetails.length) {
    fieldsDetails.push(
      getDefaultDsrFormField({
        id: DsrFormElementTypes.firstName,
        label: DsrFormElementTypes.firstName,
        elementType: DsrFormElementTypes.firstName,
        enableEntityDetection: true,
        order: 0,
        attributeId:
          form.formJsonParsed?.attributes?.find(
            (attr) => attr.internalName === DsrFormElementTypes.firstName
          )?.id || ''
      })
    )
    fieldsDetails.push(
      getDefaultDsrFormField({
        id: DsrFormElementTypes.lastName,
        label: DsrFormElementTypes.lastName,
        elementType: DsrFormElementTypes.lastName,
        enableEntityDetection: true,
        order: 1,
        attributeId:
          form.formJsonParsed?.attributes?.find(
            (attr) => attr.internalName === DsrFormElementTypes.firstName
          )?.id || ''
      })
    )
    fieldsDetails.push(
      getDefaultDsrFormField({
        id: DsrFormElementTypes.email,
        label: DsrFormElementTypes.email,
        elementType: DsrFormElementTypes.email,
        enableEntityDetection: true,
        order: 2,
        attributeId:
          form.formJsonParsed?.attributes?.find(
            (attr) => attr.internalName === DsrFormElementTypes.firstName
          )?.id || ''
      })
    )
  }

  if (!fieldsDetails.find((field) => field.elementType === DsrFormElementTypes.requestTypes)) {
    fieldsDetails.push(
      getDefaultDsrFormField({
        id: DsrFormElementTypes.requestTypes,
        label: DsrFormElementTypes.requestTypes,
        elementType: DsrFormElementTypes.requestTypes,
        validation: { required: true },
        order: fieldsDetails.length - 1,
        options: [
          {
            id: DsrRequestTypes.access,
            value: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.access],
            selected: dataAccess.isActive
          },
          {
            id: DsrRequestTypes.rectification,
            value: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.rectification],
            selected: dataRectification.isActive
          },
          {
            id: DsrRequestTypes.erasure,
            value: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.erasure],
            selected: dataErasure.isActive
          },
          {
            id: DsrRequestTypes.doNotSell,
            value: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.doNotSell],
            selected: dataDoNotSell.isActive
          },
          {
            id: DsrRequestTypes.other,
            value: MESSAGES[DEFAULT_LANGUAGE]['dsr.displayText.' + DsrRequestTypes.other],
            selected: dataOther.isActive
          }
        ]
      })
    )
  }

  const termsAndConditions: DsrTermsAndConditionsFields = form.formJsonParsed?.termsAndConditions
    ? { ...form.formJsonParsed.termsAndConditions }
    : {
        mandatoryAcceptance: false,
        tnc: getDefaultDsrFormField({
          elementType: DsrFormElementTypes.checkbox,
          options: [
            {
              id: randHex(5),
              selected: false,
              value: ''
            }
          ]
        }),
        link: getDefaultDsrFormField({
          elementType: DsrFormElementTypes.link
        }),
        isActive: true
      }

  const activeSections = form.formJsonParsed?.activeSections?.length
    ? form.formJsonParsed?.activeSections
    : [DsrFormSectionOptions.logo, DsrFormSectionOptions.description, ...dsrFormMandatorySections]

  const logoSettings = form.formJsonParsed?.logoSettings || {
    label: '',
    alignment: dsrFormLogoSettingsDefaultAlignment,
    color: dsrFormLogoSettingsDefaultBgColor
  }

  const formWithLanguages = {
    ...form,
    formJsonParsed: {
      ...form.formJsonParsed,
      name: form.name || '',
      description: form.description || '',
      enableLightbeamBranding:
        form.formJsonParsed?.enableLightbeamBranding == undefined
          ? true
          : form.formJsonParsed?.enableLightbeamBranding || false,
      requesterDetails,
      fieldsDetails,
      dataAccess,
      dataDoNotSell,
      dataOther,
      dataErasure,
      dataRectification,
      termsAndConditions,
      languages: form.formJsonParsed?.languages || DSR_FORMS_DEFAULT_LANGUAGES,
      activeSections,
      logoSettings
    }
  }

  return enhanceWithDefaultTranslations(formWithLanguages)
}

export const mapDsrFormSubmittedValuesToParsedJson = (
  submittedValuesJson: string,
  formJsonParsedImmutable?: DsrFormJsonParsed
): DsrFormJsonParsed | undefined => {
  if (!submittedValuesJson || !formJsonParsedImmutable) return

  const formJsonParsed = { ...formJsonParsedImmutable }

  // backward compatibility
  formJsonParsed.fieldsDetails = getMergedFieldDetails(formJsonParsed)

  const submittedValues = JSON.parse(submittedValuesJson)
  submittedValues.fieldsDetails = [
    ...(submittedValues?.fieldsDetails || []),
    ...(submittedValues?.dataSubjectDetails || []),
    ...(submittedValues?.additionalDetails || [])
  ]

  const getValue = (
    elementName: string,
    section:
      | 'requesterDetails'
      | 'fieldsDetails'
      | 'dataAccess'
      | 'dataErasure'
      | 'dataDoNotSell'
      | 'dataOther'
      | 'dataRectification'
      | 'termsAndConditions'
  ) => {
    const foundItem = submittedValues[section]?.find((parsedVal) => {
      const targetSection = formJsonParsed[section]

      let targetField
      if (Array.isArray(targetSection)) {
        targetField = targetSection.find((ts) => ts.id === elementName)
      } else {
        targetField = targetSection && targetSection[elementName]
      }

      return parsedVal.fieldId === targetField?.id
    })

    return foundItem?.value || ''
  }

  const requesterDetails: DsrRequesterDetails = {
    isActive: !!submittedValues.requesterDetails,
    name: getDefaultDsrFormField({
      ...formJsonParsed.requesterDetails?.name,
      value: getValue('name', 'requesterDetails')
    }),
    email: getDefaultDsrFormField({
      ...submittedValues.requesterDetails?.email,
      value: getValue('email', 'requesterDetails')
    }),
    remark: getDefaultDsrFormField({
      ...submittedValues.requesterDetails?.remark,
      value: getValue('remark', 'requesterDetails')
    })
  }

  const dataAccess: DsrAccessTypeRequestFields = {
    isActive: !!submittedValues.dataAccess,
    reason: getDefaultDsrFormField({
      ...formJsonParsed.dataAccess?.reason,
      value: getValue('reason', 'dataAccess')
    })
  }

  const dataDoNotSell: DsrAccessTypeRequestFields = {
    isActive: !!submittedValues.dataDoNotSell,
    reason: getDefaultDsrFormField({
      ...formJsonParsed.dataDoNotSell?.reason,
      value: getValue('reason', 'dataDoNotSell')
    })
  }

  const dataOther: DsrAccessTypeRequestFields = {
    isActive: !!submittedValues.dataOther,
    reason: getDefaultDsrFormField({
      ...formJsonParsed.dataOther?.reason,
      value: getValue('reason', 'dataOther')
    })
  }

  const dataErasure: DsrAccessTypeRequestFields = {
    isActive: !!submittedValues.dataErasure,
    reason: getDefaultDsrFormField({
      ...submittedValues.dataErasure?.reason,
      value: getValue('reason', 'dataErasure'),
      elementType: DsrFormElementTypes.textarea
    }),
    erasureDetails: getDefaultDsrFormField({
      ...submittedValues.dataErasure?.erasureDetails,
      value: getValue('erasureDetails', 'dataErasure'),
      elementType: DsrFormElementTypes.input
    })
  }

  const dataRectification: DsrAccessTypeRequestFields = {
    isActive: !!submittedValues.dataRectification,
    attributes: (submittedValues.dataRectification || []).map((attr) => ({
      ...attr,
      from: formJsonParsed.dataRectification?.from,
      to: formJsonParsed.dataRectification?.to,
      select: formJsonParsed.dataRectification?.select
    }))
  }

  const fieldsDetails: DsrFormFieldElement[] =
    submittedValues.fieldsDetails?.map((el) => {
      const value = getValue(el.fieldId, 'fieldsDetails')
      const sourceElem = formJsonParsed?.fieldsDetails?.find((ts) => ts.id === el.fieldId) || {}

      let options: Array<DsrFormFieldOption & I18Labels> = []
      const isOptions = Array.isArray(value) && sourceElem[DSR_FORM_OPTIONS]?.length
      if (isOptions) {
        options = (sourceElem[DSR_FORM_OPTIONS] || []).map((sourceOpt) => {
          const submittedOpt = el.options?.find(({ optionId }) => optionId === sourceOpt?.id)

          return {
            ...sourceOpt,
            selected: !!value.find((val) => val?.optionId === submittedOpt?.id),
            value: submittedOpt?.value || ''
          }
        })
      }

      return { ...sourceElem, value, options }
    }) || []

  // request types selections
  const requestTypesElem = formJsonParsed.fieldsDetails?.find(
    (field) => field.elementType === DsrFormElementTypes.requestTypes
  )

  const requestValue = dataAccess.isActive
    ? requestTypesElem?.options?.find((opt) => opt.id === DsrRequestTypes.access)?.value
    : dataDoNotSell.isActive
    ? requestTypesElem?.options?.find((opt) => opt.id === DsrRequestTypes.doNotSell)?.value
    : dataOther.isActive
    ? requestTypesElem?.options?.find((opt) => opt.id === DsrRequestTypes.other)?.value
    : dataErasure.isActive
    ? requestTypesElem?.options?.find((opt) => opt.id === DsrRequestTypes.erasure)?.value
    : dataRectification.isActive
    ? requestTypesElem?.options?.find((opt) => opt.id === DsrRequestTypes.rectification)?.value
    : ''

  if (requestTypesElem) {
    fieldsDetails.push(
      getDefaultDsrFormField({
        ...requestTypesElem,
        order: fieldsDetails.length - 1,
        value: requestValue
      })
    )
  }

  const submittedTerms = submittedValues.termsAndConditions || []
  const termsAndConditions: DsrTermsAndConditionsFields = {
    isActive: !!submittedValues.termsAndConditions,
    mandatoryAcceptance: formJsonParsed.termsAndConditions?.mandatoryAcceptance,
    tnc: getDefaultDsrFormField({
      ...formJsonParsed.termsAndConditions?.tnc,
      options: [
        {
          id: submittedTerms[0]?.id || randHex(5),
          value: formJsonParsed.termsAndConditions?.tnc?.options?.length
            ? formJsonParsed.termsAndConditions?.tnc.options[0].value
            : 'I have read the terms & conditions',
          selected: !!submittedTerms[0]?.value
        }
      ]
    }),
    link: formJsonParsed.termsAndConditions?.link
  }

  const result = {
    name: formJsonParsed?.name || '',
    description: formJsonParsed?.description || '',
    requesterDetails,
    fieldsDetails,
    dataAccess,
    dataDoNotSell,
    dataOther,
    dataErasure,
    dataRectification,
    termsAndConditions,
    attributes: formJsonParsed?.attributes
  }

  return result
}

export const getDsrFormElementSelectedOptionValues = (
  element: DsrFormFieldElement,
  lang
): string => {
  if (!dsrFormElementsWithOptions.includes(element.elementType)) return ''

  const getOptionValue = (option?: DsrFormFieldOption & I18Labels) => {
    if (!option) return ''

    const translations = option?.i18Labels

    let translatedValue = ''
    if (translations) {
      // fallback
      translatedValue = translations?.[lang || ''] || Object.values(translations)[0] || ''
    }

    return translatedValue || option?.value || ''
  }

  if (Array.isArray(element.value)) {
    const values =
      element?.value?.map((val) => {
        const opt = element?.options?.find((opt) => val?.optionId === opt?.id)
        let optValue = getOptionValue(opt)
        if (opt?.showTextField && val.text) {
          optValue += ` - ${val.text}`
        }
        return (optValue || val || '') + ''
      }) || []

    return values.join('; ')
  }

  return element.value + ''
}

const mapDsrRequestTypeKeysToNames = (key: DsrRequestTypeKeys) => {
  if (key === DsrRequestTypeKeys.access) return DsrRequestTypes.access
  if (key === DsrRequestTypeKeys.doNotSell) return DsrRequestTypes.doNotSell
  if (key === DsrRequestTypeKeys.other) return DsrRequestTypes.other
  if (key === DsrRequestTypeKeys.erasure) return DsrRequestTypes.erasure
  if (key === DsrRequestTypeKeys.rectification) return DsrRequestTypes.rectification
  return undefined
}
export const mapDsrRequestTypeNamesToKeys = (name?: DsrRequestTypes | DsrRequestType) => {
  if (name === DsrRequestTypes.access) return DsrRequestTypeKeys.access
  if (name === DsrRequestTypes.doNotSell) return DsrRequestTypeKeys.doNotSell
  if (name === DsrRequestTypes.other) return DsrRequestTypeKeys.other
  if (name === DsrRequestTypes.erasure) return DsrRequestTypeKeys.erasure
  if (name === DsrRequestTypes.rectification) return DsrRequestTypeKeys.rectification
  if (name === DsrRequestType.Access) return DsrRequestTypeKeys.access
  if (name === DsrRequestType.DoNotSell) return DsrRequestTypeKeys.doNotSell
  if (name === DsrRequestType.Other) return DsrRequestTypeKeys.other
  if (name === DsrRequestType.Erasure) return DsrRequestTypeKeys.erasure
  if (name === DsrRequestType.Rectification) return DsrRequestTypeKeys.rectification
  return undefined
}

export const getDsrRequestDisplayText = (params: {
  type?: DsrRequestTypes | DsrRequestType
  formJsonParsed?: DsrFormJsonParsed
  language?: string
}): string => {
  const { type, formJsonParsed, language } = params

  if (!type || !formJsonParsed) return ''
  const getDefaultRequestText = (type?: DsrRequestType | DsrRequestTypes) => {
    if (type === DsrRequestType.Access || type === DsrRequestTypes.access) {
      return 'dsr.configurator.dataAccess'
    }
    if (type === DsrRequestType.Erasure || type === DsrRequestTypes.erasure) {
      return 'dsr.configurator.dataErasure'
    }
    if (type === DsrRequestType.Rectification || type === DsrRequestTypes.rectification) {
      return 'dsr.configurator.dataRectification'
    }
    if (type === DsrRequestType.DoNotSell || type === DsrRequestTypes.doNotSell) {
      return 'dsr.configurator.doNotSell'
    }

    if (type === DsrRequestType.Other || type === DsrRequestTypes.other) {
      return 'dsr.configurator.dataOther'
    }

    return ''
  }

  const requestName = mapDsrRequestTypeNamesToKeys(type)
  const defaultLabel = MESSAGES[DEFAULT_LANGUAGE][getDefaultRequestText(type)] || ''
  let label = defaultLabel
  if (formJsonParsed && requestName && formJsonParsed[requestName]) {
    label = formJsonParsed[requestName]?.label || defaultLabel
    if (language) {
      label =
        formJsonParsed[requestName]?.i18Labels?.[language] ||
        formJsonParsed[requestName]?.label ||
        defaultLabel
    }
  }

  return label
}

export const updateDsrFormRequests = (params: {
  form: DsrForm
  requestType: DsrRequestTypeKeys
  language: string
  label?: string
  isActive?: boolean
}) => {
  const { form, requestType, language = DEFAULT_LANGUAGE, label = '', isActive } = params
  const { formJsonParsed = {} } = form

  const requestData = formJsonParsed[requestType]

  const updatedLabel = label ? label : requestData?.label || ''
  const updatedActive = isActive !== undefined ? isActive : requestData?.isActive || false

  const getUpdatedRequest = () => {
    return {
      ...requestData,
      label: language === DEFAULT_LANGUAGE ? updatedLabel : '',
      isActive: updatedActive,
      i18Labels: {
        ...(requestData?.i18Labels || {}),
        [language]: updatedLabel
      }
    }
  }

  const getUpdatedFormFields = () => {
    return formJsonParsed.fieldsDetails?.map((elem) => {
      const isRequestElem = elem.elementType === DsrFormElementTypes.requestTypes

      if (isRequestElem) {
        const options = elem.options?.map((opt) => {
          if (opt.id === mapDsrRequestTypeKeysToNames(requestType)) {
            return { ...opt, selected: updatedActive, value: updatedLabel }
          }
          return opt
        })

        return { ...elem, options }
      }
      return elem
    })
  }

  const updatedForm: DsrForm = {
    ...form,
    formJsonParsed: {
      ...formJsonParsed,
      fieldsDetails: getUpdatedFormFields(),
      [requestType]: getUpdatedRequest()
    }
  }

  return updatedForm
}

export const FETCH_USER_FORMS_TOTAL = 'fetch/user-forms/total'
export const fetchTotalUserForms = createAsyncThunk(FETCH_USER_FORMS_TOTAL, async () => {
  const resultRaw = await graphqlService.execute(queryTotalUserForms())
  return mapQueryTotalUserForms(resultRaw)
})

const dsrFormsSlice = createSlice({
  name: 'dsrForms',
  initialState,
  reducers: {
    setupNewDsrForm: (state, { payload }) => {
      state.selectedForm = hydrateDsrForm({
        type: payload.type,
        name: '',
        id: '',
        isTemplate: !!payload.isTemplate
      })
      state.formLanguage = DEFAULT_LANGUAGE
    },
    setFormLanguage: (state, { payload }) => {
      state.formLanguage = payload
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetForms: (state) => {
      state.forms = initialState.forms
    },
    resetTotalUserForms: (state) => {
      state.totalUserForms = initialState.totalUserForms
    },
    resetFormTemplates: (state) => {
      state.regulationTemplates = initialState.regulationTemplates
    },
    resetLists: (state) => {
      state.forms = initialState.forms
      state.regulationTemplates = initialState.regulationTemplates
      state.requestTemplates = initialState.requestTemplates
    },
    setDraggingElement: (state, { payload }) => {
      state.draggingElement = payload
    },
    resetDraggingElement: (state) => {
      state.draggingElement = initialState.draggingElement
    },
    resetSelectedForm: (state) => {
      state.selectedForm = initialState.selectedForm
    },
    setSelectedForm: (state, { payload }) => {
      state.selectedForm = { ...payload, isPredefined: false }
    },
    setShowSelectedFormErrors: (state, { payload }) => {
      state.showSelectedFormErrors = payload
    },
    setSelectedFormSections: (state, { payload }) => {
      if (state.selectedForm?.formJsonParsed?.activeSections) {
        state.selectedForm.formJsonParsed.activeSections = payload
      }
    },
    resetFormBuilder: (state) => {
      state.selectedForm = initialState.selectedForm
      state.draggingElement = initialState.draggingElement
      state.showSelectedFormErrors = initialState.showSelectedFormErrors
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDsrFormAttributes.fulfilled, (state, { payload }) => {
      state.formAttributes = payload
      if (state.selectedForm?.formJsonParsed) {
        state.selectedForm.formJsonParsed.attributes = payload
      }
    })
    builder.addCase(fetchDsrFormWebAppUrl.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload) && payload[0]?.lightbeamWebURL)
        state.webAppUrl = payload[0].lightbeamWebURL
    })
    builder.addCase(fetchDsrForms.fulfilled, (state, { payload }) => {
      state.forms.list = payload.list
      state.forms.total = payload.total
    })
    builder.addCase(fetchDsrFormById.fulfilled, (state, { payload }) => {
      const hydratedForm = hydrateDsrForm(payload.form)
      state.selectedForm = hydratedForm
      if (payload.isNew) {
        state.formLanguage = DEFAULT_LANGUAGE
      } else {
        state.formLanguage =
          hydratedForm.formJsonParsed?.languages?.find(({ isDefault }) => isDefault)?.code ||
          DEFAULT_LANGUAGE
      }
    })
    builder.addCase(fetchDsrFormTemplates.fulfilled, (state, { payload }) => {
      state.regulationTemplates.list = payload.list
      state.regulationTemplates.total = payload.total
    })
    builder.addCase(fetchTotalUserForms.fulfilled, (state, { payload }) => {
      state.totalUserForms = payload
    })
    builder.addCase(deleteDsrForm.fulfilled, (state, { payload }) => {
      state.forms.list = (state.forms.list || []).filter((form) => form.id !== payload.id)
      state.regulationTemplates.list = (state.regulationTemplates.list || []).filter(
        (form) => form.id !== payload.id
      )
      state.requestTemplates.list = (state.requestTemplates.list || []).filter(
        (form) => form.id !== payload.id
      )
    })
    builder.addCase(fetchActiveDsrForm.fulfilled, (state, { payload }) => {
      state.activeForm = payload
    })
  }
})

export const {
  setupNewDsrForm,
  setSort,
  resetForms,
  resetFormTemplates,
  resetLists,
  setDraggingElement,
  resetDraggingElement,
  setSelectedForm,
  setSelectedFormSections,
  setFormLanguage,
  setShowSelectedFormErrors,
  resetSelectedForm,
  resetTotalUserForms,
  resetFormBuilder
} = dsrFormsSlice.actions

export default dsrFormsSlice.reducer
