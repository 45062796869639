import { FetchReportersListParams, Reporter } from './reportersSlice'
import { LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryReportersList = ({ datasourceId, page }: FetchReportersListParams): string => {
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const datasourceFilter = datasourceId ? `datasourceId: "${datasourceId}",` : ''

  return gql`
  {
    ticketsGroupByReporter(booleanFilter: [{key: CONTAINS_PII_DATA, value: true}], ${datasourceFilter} first: ${LIMIT_DEFAULT}, after: "${cursor}") {
      count
      edges {
        node {
          reporter {
            name
            email
          }
          ticketsCount
        }
      }
    }
  }
`
}

export const mapQueryReportersList = (raw: any): { list: Reporter[]; totalReporters: number } => {
  try {
    const list = raw.ticketsGroupByReporter.edges.map(({ node: { reporter, ticketsCount } }) => ({
      name: reporter?.name || '',
      email: reporter?.email || '',
      totalTicketsWithPII: ticketsCount || 0
    }))
    const totalReporters = raw.ticketsGroupByReporter.count
    return { list, totalReporters }
  } catch (error) {
    console.error(error)
    throw error
  }
}
