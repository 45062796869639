//source  https://github.com/microsoft/TypeScript/issues/20707
// Typescript throws undefined error for arr.map(someFn).filter(Boolean)
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export const groupByProperty = <T>(items: T[], property: string): { [key: string]: T[] } => {
  return items
    .filter((item) => item)
    .reduce((categorizedItems, item) => {
      const key = item[property]
      if (!categorizedItems[key]) {
        categorizedItems[key] = []
      }
      categorizedItems[key].push(item)
      return categorizedItems
    }, {})
}
