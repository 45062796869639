import '../../components/Loader/loader.less'
import { fetchToken, setAuth, Token_Params } from '../login/loginSlice'
import { RootState } from '../../rootReducer'
import { accessTokenKey, FROM, URL_DASHBOARD } from '../../constants'
import { goToLoginPage } from '../../utils/urlUtil'
import { STATES } from '../../interfaces'
import historyService from '../../services/historyService'
import { connect } from 'react-redux'
import { Dimmer } from 'semantic-ui-react'
import React, { useEffect } from 'react'

interface IProps {
  fetchingAuthStatusState?: STATES
  isAuthenticated: boolean
  fetchToken: (params: Token_Params) => void
  setAuth: (param: boolean) => void
}

const Loading = ({
  fetchingAuthStatusState,
  isAuthenticated,
  fetchToken,
  setAuth
}: IProps): React.ReactElement => {
  const fromPage = localStorage.getItem(FROM) || ''
  const urlSearchParams = new URLSearchParams(window.location.search)
  const sessionState = urlSearchParams.get('session_state')
  const code = urlSearchParams.get('code')
  const resetUrlQueryStr = true

  const redirectTo = fromPage && fromPage !== '/' ? fromPage : URL_DASHBOARD

  useEffect(() => {
    const session = window.localStorage.getItem(accessTokenKey)
    if ((sessionState && code) || isAuthenticated || session) {
      if (isAuthenticated || (session && session !== 'undefined')) {
        setAuth(true)
        historyService.push(redirectTo)
      } else {
        if (fetchingAuthStatusState == STATES.failed) {
          setTimeout(() => goToLoginPage(), 500)
        } else {
          if (fetchingAuthStatusState !== STATES.pending && sessionState && code)
            fetchToken({
              session_state: sessionState,
              code,
              redirectURI: `${window.location.origin}`
            })
        }
      }
    } else {
      goToLoginPage()
    }
  }, [
    isAuthenticated,
    fromPage,
    fetchingAuthStatusState,
    fetchToken,
    resetUrlQueryStr,
    code,
    sessionState,
    setAuth
  ])

  if (fetchingAuthStatusState == STATES.pending) {
    return (
      <Dimmer active={true} page={true} inverted data-test-id="loading-loading">
        <img src="/assets/images/pageLoader.gif" className="lb-loader" alt="Loading..." />
      </Dimmer>
    )
  } else {
    return <></>
  }
}

const mapDispatchToProps = {
  fetchToken,
  setAuth
}

const mapStateToProps = (state: RootState) => ({
  fetchingAuthStatusState: state.user.fetchingAuthStatusState,
  isAuthenticated: state.user.isAuthenticated
})

export default connect(mapStateToProps, mapDispatchToProps)(Loading)
