import {
  mapQueryDocumentLabelAttributes,
  mapQueryLabelSets,
  mapQueryLabels,
  mutationAttachLabel,
  mutationDeleteLabel,
  mutationSaveCustomAttributeSet,
  mutationSaveDocumentLabelProvider,
  mutationSaveLabelDefinition,
  mutationSaveLightbeamLabel,
  mutationSaveLightbeamLabelSet,
  mutationUpdateLabel,
  mutationUpdateLabelSet,
  queryDocumentLabelAttributes,
  queryLabelSets,
  queryLabels
} from './queries'
import graphqlService from '../../services/graphqlService'
import apiService from '../../services/api/apiService'
import { AttributeSet, AttributeSetAttribute } from '../attributes/attributesSlice'
import { Classification } from '../../services/api/apiTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// CRUD providers
export const DocumentLabelProviderValues = {
  id: 'id',
  name: 'name',
  lbName: 'lbName',
  type: 'type',
  clientId: 'clientId',
  clientSecret: 'clientSecret',
  tenantId: 'tenantId',
  delegatedCredential: 'delegatedCredential',
  accountJson: 'accountJson'
}
export type MipCredentials = {
  clientId?: string
  clientSecret?: string
  tenantId?: string
}

export type GoogleCredentials = {
  delegatedCredential?: string
  accountJson?: string
  accountType?: string
}
export enum DocumentLabelProviderConfigurations {
  mip = 'microsoftInformationProtectionConfiguration',
  google = 'googleLabelsConfiguration'
}

export enum DocumentLabelProviderPrefix {
  mip = 'MIP-'
}

export enum DOCUMENT_LABEL_PROVIDERS {
  lightBeam = 'LIGHTBEAM',
  mip = 'MICROSOFT_INFORMATION_PROTECTION',
  google = 'GOOGLE_LABELS'
}

export type DocumentLabelProvider = {
  id?: string
  name?: string
  lbName?: string
  type?: DOCUMENT_LABEL_PROVIDERS
  mappedLabelSetId?: string
  [DocumentLabelProviderConfigurations.mip]?: MipCredentials
  [DocumentLabelProviderConfigurations.google]?: GoogleCredentials
}

export type Label = {
  id?: string
  name?: string
  labelSetId: string
  labelId: string
  labelName?: string
  labelSetName?: string
}

export type DocumentLabelSaveParams = {
  mappedLabelId?: string
  lightBeamLabelName: string
  setId?: string
  priority: number
  lightBeamLabelOperator: DocumentLabelOperatorTypes
  lightBeamLabelGroups: DocumentLabelConditionalGroup[]
  color?: string
  description?: string
}

export const ACTION_DOCUMENT_LABEL_PROVIDER_CONNECT = 'documentLabels/connectProvider'
export const saveDocumentLabelProvider = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_PROVIDER_CONNECT,
  async (params: DocumentLabelProvider) => {
    await graphqlService.execute(mutationSaveDocumentLabelProvider(params))
  }
)

// TODO: MIP. This endpoint is not presented
export const ACTION_DOCUMENT_LABEL_PROVIDER_TEST_CONNECTION = 'documentLabels/test-connection'
export const documentLabelProviderTestConnection = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_PROVIDER_TEST_CONNECTION,
  async (params: DocumentLabelProvider) => {
    return await apiService.testDocumentLabelProviderConnection(params)
  }
)

export const ACTION_UPDATE_DOCUMENT_PROVIDER = 'documentLabels/updateProvider'

// CRUD label sets
export interface DocumentLabelSet {
  id: string
  name: string
  type?: DOCUMENT_LABEL_PROVIDERS
  labelsCount?: number
  mappedLabelSetId?: string
  mappedLabelSetName?: string
  labels?: { id: string; name: string; mappedId?: string }[]
  createdAt: string
  updatedAt: string
}
export const ACTION_DOCUMENT_LABEL_SET_LIST = 'documentLabels/setList'
export const fetchDocumentLabelSets = createAsyncThunk(ACTION_DOCUMENT_LABEL_SET_LIST, async () => {
  const resultRaw = await graphqlService.execute(queryLabelSets())
  return mapQueryLabelSets(resultRaw)
})

// CRUD labels
export enum DocumentLabelDetectionTypes {
  attributeSet = 'ATTRIBUTE_SET',
  fileClassificationSet = 'FILE_CLASSIFICATION_SET'
}
export enum DocumentLabelOperatorTypes {
  or = 'OR',
  and = 'AND'
}
export enum DocumentLabelGroupOperatorTypes {
  or = 'ANY_OF',
  and = 'ALL_OF',
  nor = 'NOT_ANY_OF',
  nand = 'NOT_ALL_OF'
}

export type ClassificationDetectionType = {
  fileClass: string
  fileSubClass: string
}

export type DocumentLabelConditionalGroup = {
  type: DocumentLabelDetectionTypes
  operator: DocumentLabelGroupOperatorTypes
  attributeSetId?: string
  attributeIds?: string[]
  fileClassificationSet?: ClassificationDetectionType[]
}
export interface DocumentLabel {
  id?: string
  name?: string
  type?: string
  priority?: number
  suffix?: string
  description?: string
  active?: boolean
  uuid?: string
  labelSetId?: string
  labelSetName?: string
  mappedLabelId?: string
  mappedLabelName?: string
  mappedLabelSetId?: string
  mappedLabelSetName?: string
  objectCount?: number
  color?: string
  datasourceCount?: number
  datasources?: { id: string; name: string; type: string; objectCount: number }[]
  labelDefinition?: {
    operator: DocumentLabelOperatorTypes
    groups: DocumentLabelConditionalGroup[]
  }
  createdAt?: string
  updatedAt?: string
}
export type DocumentLabelsParams = {
  labelSetId?: string
}
export const ACTION_DOCUMENT_LABELS = 'documentLabels/labelsList'
export const fetchDocumentLabels = createAsyncThunk(
  ACTION_DOCUMENT_LABELS,
  async (): Promise<DocumentLabel[]> => {
    const resultRaw = await graphqlService.execute(queryLabels())
    return mapQueryLabels(resultRaw)
  }
)
export const ACTION_DOCUMENT_LABELS_BY_SET_ID = 'documentLabels/labelsListBySetId'
export const fetchDocumentLabelsBySetId = createAsyncThunk(
  ACTION_DOCUMENT_LABELS_BY_SET_ID,
  async (params: DocumentLabelsParams): Promise<DocumentLabel[]> => {
    const resultRaw = await graphqlService.execute(queryLabels(params))
    return mapQueryLabels(resultRaw)
  }
)

export const ACTION_DOCUMENT_LABEL_LINK = 'documentLabels/link'
export const linkDocumentLabels = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_LINK,
  async (params: DocumentLabelSaveParams) => {
    const {
      mappedLabelId = '',
      lightBeamLabelName,
      priority,
      setId = '',
      lightBeamLabelGroups,
      lightBeamLabelOperator
    } = params

    // save custom attributes and get attributeSetIds
    const groupsWithAttributeSetIds = await Promise.all(
      lightBeamLabelGroups.map(async (group) => {
        const response = await graphqlService.execute(
          mutationSaveCustomAttributeSet(group.attributeIds || [])
        )
        return {
          ...group,
          attributeSetId: response?.createAttributeSet?.attributeSet?.edges[0]?.node?.id || ''
        }
      })
    )

    // link label
    await graphqlService.execute(
      mutationSaveLightbeamLabel({
        name: lightBeamLabelName,
        priority,
        setId,
        mappedLabelId,
        lightBeamLabelGroups: groupsWithAttributeSetIds,
        lightBeamLabelOperator
      })
    )
  }
)

export const ACTION_DOCUMENT_LABEL_SET_CREATE = 'documentLabels/createSet'
export const createDocumentLabelSet = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_SET_CREATE,
  async (params: { name: string }) => {
    const saveLbSetResponse = await graphqlService.execute(
      mutationSaveLightbeamLabelSet({
        ...params
      })
    )
    return saveLbSetResponse
  }
)

export type UpdateLabelSetParams = {
  name: string
  setId: string
}
export const ACTION_DOCUMENT_LABEL_SET_UPDATE = 'documentLabels/updateSet'
export const updateDocumentLabelSet = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_SET_UPDATE,
  async (params: UpdateLabelSetParams) => {
    const saveLbSetResponse = await graphqlService.execute(
      mutationUpdateLabelSet({
        ...params
      })
    )
    return saveLbSetResponse
  }
)

export type CreateLabelParams = {
  name: string
  setId: string
  mappedLabelId?: string
  priority: number
  description?: string
  color?: string
  lightBeamLabelOperator?: DocumentLabelOperatorTypes
  lightBeamLabelGroups?: DocumentLabelConditionalGroup[]
}

export const ACTION_DOCUMENT_LABEL_CREATE = 'documentLabels/createLabel'
export const createDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_CREATE,
  async (params: CreateLabelParams) => {
    const saveLbSetResponse = await graphqlService.execute(
      mutationSaveLightbeamLabel({
        ...params
      })
    )
    return saveLbSetResponse
  }
)

export type UpdateLabelParams = {
  name?: string
  id: string
  labelId: string
  lightBeamLabelOperator?: DocumentLabelOperatorTypes
  lightBeamLabelGroups?: DocumentLabelConditionalGroup[]
  color?: string
  priority?: number
  description?: string
}
export const ACTION_DOCUMENT_LABEL_UPDATE = 'documentLabels/updateLabel'
export const updateDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_UPDATE,
  async (params: UpdateLabelParams) => {
    const { lightBeamLabelGroups, lightBeamLabelOperator } = params
    const attrGroups = lightBeamLabelGroups?.filter((g) => !!g.attributeIds?.length) || []
    const fileGroups = lightBeamLabelGroups?.filter((g) => !!g.fileClassificationSet?.length) || []
    if (!!attrGroups?.length && lightBeamLabelOperator) {
      const groupsWithAttributeSetIds = await Promise.all(
        attrGroups.map(async (group) => {
          const response = await graphqlService.execute(
            mutationSaveCustomAttributeSet(group.attributeIds || [])
          )
          return {
            ...group,
            attributeSetId: response?.createAttributeSet?.attributeSet?.edges[0]?.node?.id || ''
          }
        })
      )
      const saveLbSetResponse = await graphqlService.execute(
        mutationUpdateLabel({
          ...params,
          lightBeamLabelGroups: [...groupsWithAttributeSetIds, ...fileGroups]
        })
      )
      return saveLbSetResponse
    } else {
      const saveLbSetResponse = await graphqlService.execute(
        mutationUpdateLabel({
          ...params
        })
      )
      return saveLbSetResponse
    }
  }
)

export const ACTION_DOCUMENT_LABEL_SAVE_DEFINITION = 'documentLabels/saveDocumentLabelDefinition'
export const saveDocumentLabelDefinition = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_SAVE_DEFINITION,
  async (params: UpdateLabelParams) => {
    const { lightBeamLabelGroups, lightBeamLabelOperator } = params
    const attrGroups = lightBeamLabelGroups?.filter((g) => !!g.attributeIds?.length) || []
    const fileGroups = lightBeamLabelGroups?.filter((g) => !!g.fileClassificationSet?.length) || []
    if (!!attrGroups?.length && lightBeamLabelOperator) {
      const groupsWithAttributeSetIds = await Promise.all(
        attrGroups.map(async (group) => {
          const response = await graphqlService.execute(
            mutationSaveCustomAttributeSet(group.attributeIds || [])
          )
          return {
            ...group,
            attributeSetId: response?.createAttributeSet?.attributeSet?.edges[0]?.node?.id || ''
          }
        })
      )
      const saveLbSetResponse = await graphqlService.execute(
        mutationSaveLabelDefinition({
          ...params,
          lightBeamLabelGroups: [...groupsWithAttributeSetIds, ...fileGroups]
        })
      )
      return saveLbSetResponse
    } else {
      const saveLbSetResponse = await graphqlService.execute(
        mutationSaveLabelDefinition({
          ...params
        })
      )
      return saveLbSetResponse
    }
  }
)

export type AttachDocumentParams = { labels: Label[]; docId: string }
export const ACTION_DOCUMENT_LABEL_ATTACH = 'documentLabels/attachLabel'
export const attachDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_ATTACH,
  async (params: AttachDocumentParams) => {
    const saveLbSetResponse = await graphqlService.execute(
      mutationAttachLabel({
        ...params
      })
    )
    return saveLbSetResponse
  }
)

export type DeleteLabelParams = { labelId: string; labelSetId: string }
export const ACTION_DOCUMENT_LABEL_DELETE = 'documentLabels/deleteLabel'
export const deleteDocumentLabel = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_DELETE,
  async (params: DeleteLabelParams) => {
    const deleteResponse = await graphqlService.execute(mutationDeleteLabel(params))
    return deleteResponse
  }
)

// CRUD attributes
export const ACTION_DOCUMENT_LABEL_ATTRIBUTES = 'documentLabels/attributes'
export const fetchDocumentLabelAttributes = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_ATTRIBUTES,
  async () => {
    const resultRaw = await graphqlService.execute(queryDocumentLabelAttributes())
    return mapQueryDocumentLabelAttributes(resultRaw)
  }
)

export const ACTION_DOCUMENT_LABEL_CLASSIFICATIONS = 'documentLabels/classifications'
export const fetchDocumentLabelClassifications = createAsyncThunk(
  ACTION_DOCUMENT_LABEL_CLASSIFICATIONS,
  async () => await apiService.getClassificationsList()
)

interface DocumentLabelsState {
  sets?: DocumentLabelSet[]
  selectedSet?: DocumentLabelSet
  labels?: DocumentLabel[]
  attributeSets?: AttributeSet[]
  classifications?: Classification[]
  attributes?: AttributeSetAttribute[]
  labelDefinition?: {
    labelId: string
    operator: DocumentLabelOperatorTypes
    groups: DocumentLabelConditionalGroup[]
  }
  isLabelAttached: boolean
}

export const initialState: DocumentLabelsState = {
  isLabelAttached: false
}

const labelManagementSlice = createSlice({
  name: 'documentLabels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentLabelSets.fulfilled, (state, { payload }) => {
      state.sets = payload
    })
    builder.addCase(fetchDocumentLabels.fulfilled, (state, { payload }) => {
      state.labels = payload
    })
    builder.addCase(fetchDocumentLabelAttributes.fulfilled, (state, { payload }) => {
      state.attributeSets = payload.attributeSets
      state.attributes = payload.attributes
    })
    builder.addCase(fetchDocumentLabelClassifications.fulfilled, (state, { payload }) => {
      state.classifications = payload
    })
    builder.addCase(attachDocumentLabel.fulfilled, (state) => {
      state.isLabelAttached = true
    })
    builder.addCase(attachDocumentLabel.rejected, (state) => {
      state.isLabelAttached = false
    })
  }
})

export default labelManagementSlice.reducer
