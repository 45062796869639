export type DsrCity = {
  name: string
  state?: string
  country?: string
}

export const dsrListUsCities: DsrCity[] = [
  { name: 'Abbeville', state: 'Louisiana', country: 'US' },
  { name: 'Aberdeen', state: 'Maryland', country: 'US' },
  { name: 'Aberdeen', state: 'Mississippi', country: 'US' },
  { name: 'Aberdeen', state: 'South Dakota', country: 'US' },
  { name: 'Aberdeen', state: 'Washington', country: 'US' },
  { name: 'Abilene', state: 'Texas', country: 'US' },
  { name: 'Abilene', state: 'Kansas', country: 'US' },
  { name: 'Abingdon', state: 'Virginia', country: 'US' },
  { name: 'Abington', state: 'Massachusetts', country: 'US' },
  { name: 'Abington', state: 'Massachusetts', country: 'US' },
  { name: 'Absecon', state: 'New Jersey', country: 'US' },
  { name: 'Accokeek', state: 'Maryland', country: 'US' },
  { name: 'Acton', state: 'Massachusetts', country: 'US' },
  { name: 'Acushnet', state: 'Massachusetts', country: 'US' },
  { name: 'Acworth', state: 'Georgia', country: 'US' },
  { name: 'Ada', state: 'Oklahoma', country: 'US' },
  { name: 'Adams', state: 'Massachusetts', country: 'US' },
  { name: 'Addison', state: 'Illinois', country: 'US' },
  { name: 'Addison', state: 'Texas', country: 'US' },
  { name: 'Adelanto', state: 'California', country: 'US' },
  { name: 'Adelphi', state: 'Maryland', country: 'US' },
  { name: 'Adrian', state: 'Michigan', country: 'US' },
  { name: 'Affton', state: 'Missouri', country: 'US' },
  { name: 'Agawam', state: 'Massachusetts', country: 'US' },
  { name: 'Agoura Hills', state: 'California', country: 'US' },
  { name: 'Ahuimanu', state: 'Hawaii', country: 'US' },
  { name: 'Aiea', state: 'Hawaii', country: 'US' },
  { name: 'Aiken', state: 'South Carolina', country: 'US' },
  { name: 'Air Force Academy', state: 'Colorado', country: 'US' },
  { name: 'Airmont', state: 'New York', country: 'US' },
  { name: 'Akron', state: 'Ohio', country: 'US' },
  { name: 'Alabaster', state: 'Alabama', country: 'US' },
  { name: 'Alachua', state: 'Florida', country: 'US' },
  { name: 'Alameda', state: 'California', country: 'US' },
  { name: 'Alamo', state: 'California', country: 'US' },
  { name: 'Alamo', state: 'Texas', country: 'US' },
  { name: 'Alamo Heights', state: 'Texas', country: 'US' },
  { name: 'Alamogordo', state: 'New Mexico', country: 'US' },
  { name: 'Alamosa', state: 'Colorado', country: 'US' },
  { name: 'Albany', state: 'Georgia', country: 'US' },
  { name: 'Albany', state: 'California', country: 'US' },
  { name: 'Albany', state: 'New York', country: 'US' },
  { name: 'Albany', state: 'Oregon', country: 'US' },
  { name: 'Albemarle', state: 'North Carolina', country: 'US' },
  { name: 'Albert Lea', state: 'Minnesota', country: 'US' },
  { name: 'Albertville', state: 'Alabama', country: 'US' },
  { name: 'Albion', state: 'Michigan', country: 'US' },
  { name: 'Albion', state: 'New York', country: 'US' },
  { name: 'Albion', state: 'New York', country: 'US' },
  { name: 'Albuquerque', state: 'New Mexico', country: 'US' },
  { name: 'Alcoa', state: 'Tennessee', country: 'US' },
  { name: 'Alden', state: 'New York', country: 'US' },
  { name: 'Alderwood Manor', state: 'Washington', country: 'US' },
  { name: 'Aldine', state: 'Texas', country: 'US' },
  { name: 'Alexander City', state: 'Alabama', country: 'US' },
  { name: 'Alexandria', state: 'Indiana', country: 'US' },
  { name: 'Alexandria', state: 'Minnesota', country: 'US' },
  { name: 'Alexandria', state: 'Kentucky', country: 'US' },
  { name: 'Alexandria', state: 'Louisiana', country: 'US' },
  { name: 'Alexandria', state: 'Virginia', country: 'US' },
  { name: 'Algonquin', state: 'Illinois', country: 'US' },
  { name: 'Alhambra', state: 'California', country: 'US' },
  { name: 'Alice', state: 'Texas', country: 'US' },
  { name: 'Aliquippa', state: 'Pennsylvania', country: 'US' },
  { name: 'Aliso Viejo', state: 'California', country: 'US' },
  { name: 'Allegany', state: 'New York', country: 'US' },
  { name: 'Allen', state: 'Texas', country: 'US' },
  { name: 'Allen Park', state: 'Michigan', country: 'US' },
  { name: 'Allendale', state: 'Michigan', country: 'US' },
  { name: 'Allendale', state: 'New Jersey', country: 'US' },
  { name: 'Allentown', state: 'Pennsylvania', country: 'US' },
  { name: 'Alliance', state: 'Ohio', country: 'US' },
  { name: 'Alliance', state: 'Nebraska', country: 'US' },
  { name: 'Allouez', state: 'Wisconsin', country: 'US' },
  { name: 'Alma', state: 'Michigan', country: 'US' },
  { name: 'Aloha', state: 'Oregon', country: 'US' },
  { name: 'Alondra Park', state: 'California', country: 'US' },
  { name: 'Alpena', state: 'Michigan', country: 'US' },
  { name: 'Alpharetta', state: 'Georgia', country: 'US' },
  { name: 'Alpine', state: 'California', country: 'US' },
  { name: 'Alpine', state: 'Utah', country: 'US' },
  { name: 'Alsip', state: 'Illinois', country: 'US' },
  { name: 'Alta Sierra', state: 'California', country: 'US' },
  { name: 'Altadena', state: 'California', country: 'US' },
  { name: 'Altamont', state: 'Oregon', country: 'US' },
  { name: 'Altamont', state: 'New York', country: 'US' },
  { name: 'Altamonte Springs', state: 'Florida', country: 'US' },
  { name: 'Alton', state: 'Illinois', country: 'US' },
  { name: 'Altoona', state: 'Iowa', country: 'US' },
  { name: 'Altoona', state: 'Pennsylvania', country: 'US' },
  { name: 'Altoona', state: 'Wisconsin', country: 'US' },
  { name: 'Altus', state: 'Oklahoma', country: 'US' },
  { name: 'Alum Rock', state: 'California', country: 'US' },
  { name: 'Alvin', state: 'Texas', country: 'US' },
  { name: 'Amarillo', state: 'Texas', country: 'US' },
  { name: 'Ambler', state: 'Pennsylvania', country: 'US' },
  { name: 'Ambridge', state: 'Pennsylvania', country: 'US' },
  { name: 'American Canyon', state: 'California', country: 'US' },
  { name: 'American Fork', state: 'Utah', country: 'US' },
  { name: 'Americus', state: 'Georgia', country: 'US' },
  { name: 'Ames', state: 'Iowa', country: 'US' },
  { name: 'Amesbury', state: 'Massachusetts', country: 'US' },
  { name: 'Amesbury', state: 'Massachusetts', country: 'US' },
  { name: 'Amherst', state: 'New Hampshire', country: 'US' },
  { name: 'Amherst', state: 'Massachusetts', country: 'US' },
  { name: 'Amherst', state: 'Ohio', country: 'US' },
  { name: 'Amherst', state: 'New York', country: 'US' },
  { name: 'Amherst Center', state: 'Massachusetts', country: 'US' },
  { name: 'Amityville', state: 'New York', country: 'US' },
  { name: 'Ammon', state: 'Idaho', country: 'US' },
  { name: 'Amory', state: 'Mississippi', country: 'US' },
  { name: 'Amsterdam', state: 'New York', country: 'US' },
  { name: 'Anaconda-Deer Lodge County', state: 'Montana', country: 'US' },
  { name: 'Anacortes', state: 'Washington', country: 'US' },
  { name: 'Anadarko', state: 'Oklahoma', country: 'US' },
  { name: 'Anaheim', state: 'California', country: 'US' },
  { name: 'Anchorage', state: 'Alaska', country: 'US' },
  { name: 'Andalusia', state: 'Alabama', country: 'US' },
  { name: 'Anderson', state: 'California', country: 'US' },
  { name: 'Anderson', state: 'Indiana', country: 'US' },
  { name: 'Anderson', state: 'South Carolina', country: 'US' },
  { name: 'Anderson Mill', state: 'Texas', country: 'US' },
  { name: 'Andover', state: 'Florida', country: 'US' },
  { name: 'Andover', state: 'Minnesota', country: 'US' },
  { name: 'Andover', state: 'Massachusetts', country: 'US' },
  { name: 'Andover', state: 'Kansas', country: 'US' },
  { name: 'Andover', state: 'Massachusetts', country: 'US' },
  { name: 'Andrews', state: 'Texas', country: 'US' },
  { name: 'Andrews AFB', state: 'Maryland', country: 'US' },
  { name: 'Angleton', state: 'Texas', country: 'US' },
  { name: 'Angola', state: 'Indiana', country: 'US' },
  { name: 'Ankeny', state: 'Iowa', country: 'US' },
  { name: 'Ann Arbor', state: 'Michigan', country: 'US' },
  { name: 'Annandale', state: 'Virginia', country: 'US' },
  { name: 'Annapolis', state: 'Maryland', country: 'US' },
  { name: 'Anniston', state: 'Alabama', country: 'US' },
  { name: 'Anoka', state: 'Minnesota', country: 'US' },
  { name: 'Ansonia', state: 'Connecticut', country: 'US' },
  { name: 'Ansonia', state: 'Connecticut', country: 'US' },
  { name: 'Anthony', state: 'New Mexico', country: 'US' },
  { name: 'Antigo', state: 'Wisconsin', country: 'US' },
  { name: 'Antioch', state: 'Illinois', country: 'US' },
  { name: 'Antioch', state: 'California', country: 'US' },
  { name: 'Apache Junction', state: 'Arizona', country: 'US' },
  { name: 'Apex', state: 'North Carolina', country: 'US' },
  { name: 'Apollo Beach', state: 'Florida', country: 'US' },
  { name: 'Apopka', state: 'Florida', country: 'US' },
  { name: 'Apple Valley', state: 'California', country: 'US' },
  { name: 'Apple Valley', state: 'Minnesota', country: 'US' },
  { name: 'Appleton', state: 'Wisconsin', country: 'US' },
  { name: 'Applewood', state: 'Colorado', country: 'US' },
  { name: 'Aptos', state: 'California', country: 'US' },
  { name: 'Aquia Harbour', state: 'Virginia', country: 'US' },
  { name: 'Arab', state: 'Alabama', country: 'US' },
  { name: 'Arabi', state: 'Louisiana', country: 'US' },
  { name: 'Aransas Pass', state: 'Texas', country: 'US' },
  { name: 'Arbutus', state: 'Maryland', country: 'US' },
  { name: 'Arcadia', state: 'California', country: 'US' },
  { name: 'Arcadia', state: 'Florida', country: 'US' },
  { name: 'Arcadia', state: 'New York', country: 'US' },
  { name: 'Arcata', state: 'California', country: 'US' },
  { name: 'Archbald', state: 'Pennsylvania', country: 'US' },
  { name: 'Archdale', state: 'North Carolina', country: 'US' },
  { name: 'Arden Hills', state: 'Minnesota', country: 'US' },
  { name: 'Arden-Arcade', state: 'California', country: 'US' },
  { name: 'Ardmore', state: 'Oklahoma', country: 'US' },
  { name: 'Ardmore', state: 'Pennsylvania', country: 'US' },
  { name: 'Arkadelphia', state: 'Arkansas', country: 'US' },
  { name: 'Arkansas City', state: 'Kansas', country: 'US' },
  { name: 'Arlington', state: 'Massachusetts', country: 'US' },
  { name: 'Arlington', state: 'Massachusetts', country: 'US' },
  { name: 'Arlington', state: 'Texas', country: 'US' },
  { name: 'Arlington', state: 'New York', country: 'US' },
  { name: 'Arlington', state: 'Virginia', country: 'US' },
  { name: 'Arlington', state: 'Washington', country: 'US' },
  { name: 'Arlington Heights', state: 'Illinois', country: 'US' },
  { name: 'Arnold', state: 'Missouri', country: 'US' },
  { name: 'Arnold', state: 'Maryland', country: 'US' },
  { name: 'Arroyo Grande', state: 'California', country: 'US' },
  { name: 'Artesia', state: 'California', country: 'US' },
  { name: 'Artesia', state: 'New Mexico', country: 'US' },
  { name: 'Artondale', state: 'Washington', country: 'US' },
  { name: 'Arvada', state: 'Colorado', country: 'US' },
  { name: 'Arvin', state: 'California', country: 'US' },
  { name: 'Asbury Park', state: 'New Jersey', country: 'US' },
  { name: 'Asheboro', state: 'North Carolina', country: 'US' },
  { name: 'Asheville', state: 'North Carolina', country: 'US' },
  { name: 'Ashland', state: 'Oregon', country: 'US' },
  { name: 'Ashland', state: 'Ohio', country: 'US' },
  { name: 'Ashland', state: 'Virginia', country: 'US' },
  { name: 'Ashland', state: 'Wisconsin', country: 'US' },
  { name: 'Ashland', state: 'New Jersey', country: 'US' },
  { name: 'Ashland', state: 'Massachusetts', country: 'US' },
  { name: 'Ashland', state: 'Kentucky', country: 'US' },
  { name: 'Ashland', state: 'California', country: 'US' },
  { name: 'Ashtabula', state: 'Ohio', country: 'US' },
  { name: 'Ashwaubenon', state: 'Wisconsin', country: 'US' },
  { name: 'Aspen Hill', state: 'Maryland', country: 'US' },
  { name: 'Astoria', state: 'Oregon', country: 'US' },
  { name: 'Atascadero', state: 'California', country: 'US' },
  { name: 'Atascocita', state: 'Texas', country: 'US' },
  { name: 'Atchison', state: 'Kansas', country: 'US' },
  { name: 'Athens', state: 'Alabama', country: 'US' },
  { name: 'Athens', state: 'Texas', country: 'US' },
  { name: 'Athens', state: 'Tennessee', country: 'US' },
  { name: 'Athens', state: 'Ohio', country: 'US' },
  { name: 'Athens-Clarke County', state: 'Georgia', country: 'US' },
  { name: 'Atherton', state: 'California', country: 'US' },
  { name: 'Athol', state: 'Massachusetts', country: 'US' },
  { name: 'Athol', state: 'Massachusetts', country: 'US' },
  { name: 'Atkinson', state: 'New Hampshire', country: 'US' },
  { name: 'Atlanta', state: 'Georgia', country: 'US' },
  { name: 'Atlantic', state: 'Iowa', country: 'US' },
  { name: 'Atlantic Beach', state: 'Florida', country: 'US' },
  { name: 'Atlantic City', state: 'New Jersey', country: 'US' },
  { name: 'Atmore', state: 'Alabama', country: 'US' },
  { name: 'Attalla', state: 'Alabama', country: 'US' },
  { name: 'Attica', state: 'New York', country: 'US' },
  { name: 'Attleboro', state: 'Massachusetts', country: 'US' },
  { name: 'Atwater', state: 'California', country: 'US' },
  { name: 'Auburn', state: 'California', country: 'US' },
  { name: 'Auburn', state: 'Alabama', country: 'US' },
  { name: 'Auburn', state: 'Georgia', country: 'US' },
  { name: 'Auburn', state: 'Indiana', country: 'US' },
  { name: 'Auburn', state: 'Massachusetts', country: 'US' },
  { name: 'Auburn', state: 'Maine', country: 'US' },
  { name: 'Auburn', state: 'New York', country: 'US' },
  { name: 'Auburn', state: 'Washington', country: 'US' },
  { name: 'Auburn Hills', state: 'Michigan', country: 'US' },
  { name: 'Auburndale', state: 'Florida', country: 'US' },
  { name: 'Audubon', state: 'New Jersey', country: 'US' },
  { name: 'Audubon', state: 'Pennsylvania', country: 'US' },
  { name: 'August', state: 'California', country: 'US' },
  { name: 'Augusta', state: 'Maine', country: 'US' },
  { name: 'Augusta', state: 'Kansas', country: 'US' },
  { name: 'Augusta-Richmond County', state: 'Georgia', country: 'US' },
  { name: 'Aurora', state: 'Illinois', country: 'US' },
  { name: 'Aurora', state: 'Colorado', country: 'US' },
  { name: 'Aurora', state: 'Missouri', country: 'US' },
  { name: 'Aurora', state: 'New York', country: 'US' },
  { name: 'Aurora', state: 'Ohio', country: 'US' },
  { name: 'Austin', state: 'Texas', country: 'US' },
  { name: 'Austin', state: 'Minnesota', country: 'US' },
  { name: 'Austintown', state: 'Ohio', country: 'US' },
  { name: 'Avenal', state: 'California', country: 'US' },
  { name: 'Avenel', state: 'New Jersey', country: 'US' },
  { name: 'Aventura', state: 'Florida', country: 'US' },
  { name: 'Avocado Heights', state: 'California', country: 'US' },
  { name: 'Avon', state: 'Connecticut', country: 'US' },
  { name: 'Avon', state: 'Indiana', country: 'US' },
  { name: 'Avon', state: 'Ohio', country: 'US' },
  { name: 'Avon', state: 'New York', country: 'US' },
  { name: 'Avon Lake', state: 'Ohio', country: 'US' },
  { name: 'Avon Park', state: 'Florida', country: 'US' },
  { name: 'Avondale', state: 'Arizona', country: 'US' },
  { name: 'Ayer', state: 'Massachusetts', country: 'US' },
  { name: 'Azalea Park', state: 'Florida', country: 'US' },
  { name: 'Azle', state: 'Texas', country: 'US' },
  { name: 'Aztec', state: 'New Mexico', country: 'US' },
  { name: 'Azusa', state: 'California', country: 'US' },
  { name: 'Babylon', state: 'New York', country: 'US' },
  { name: 'Babylon', state: 'New York', country: 'US' },
  { name: 'Back Mountain', state: 'Pennsylvania', country: 'US' },
  { name: 'Bacliff', state: 'Texas', country: 'US' },
  { name: 'Bailey’s Crossroads', state: 'Virginia', country: 'US' },
  { name: 'Bainbridge', state: 'Georgia', country: 'US' },
  { name: 'Bainbridge Island', state: 'Washington', country: 'US' },
  { name: 'Baker', state: 'Louisiana', country: 'US' },
  { name: 'Baker City', state: 'Oregon', country: 'US' },
  { name: 'Bakersfield', state: 'California', country: 'US' },
  { name: 'Balch Springs', state: 'Texas', country: 'US' },
  { name: 'Baldwin', state: 'Pennsylvania', country: 'US' },
  { name: 'Baldwin', state: 'New York', country: 'US' },
  { name: 'Baldwin Harbor', state: 'New York', country: 'US' },
  { name: 'Baldwin Park', state: 'California', country: 'US' },
  { name: 'Baldwinsville', state: 'New York', country: 'US' },
  { name: 'Ballenger Creek', state: 'Maryland', country: 'US' },
  { name: 'Ballston', state: 'New York', country: 'US' },
  { name: 'Ballwin', state: 'Missouri', country: 'US' },
  { name: 'Baltimore', state: 'Maryland', country: 'US' },
  { name: 'Bangor', state: 'Maine', country: 'US' },
  { name: 'Bangor Trident Base', state: 'Washington', country: 'US' },
  { name: 'Banning', state: 'California', country: 'US' },
  { name: 'Baraboo', state: 'Wisconsin', country: 'US' },
  { name: 'Barberton', state: 'Ohio', country: 'US' },
  { name: 'Barclay-Kingston', state: 'New Jersey', country: 'US' },
  { name: 'Bardstown', state: 'Kentucky', country: 'US' },
  { name: 'Barnhart', state: 'Missouri', country: 'US' },
  { name: 'Barnstable Town', state: 'Massachusetts', country: 'US' },
  { name: 'Barre', state: 'Vermont', country: 'US' },
  { name: 'Barre', state: 'Vermont', country: 'US' },
  { name: 'Barrington', state: 'Rhode Island', country: 'US' },
  { name: 'Barrington', state: 'Rhode Island', country: 'US' },
  { name: 'Barrington', state: 'New Jersey', country: 'US' },
  { name: 'Barrington', state: 'New Hampshire', country: 'US' },
  { name: 'Barrington', state: 'Illinois', country: 'US' },
  { name: 'Barstow', state: 'California', country: 'US' },
  { name: 'Bartlesville', state: 'Oklahoma', country: 'US' },
  { name: 'Bartlett', state: 'Illinois', country: 'US' },
  { name: 'Bartlett', state: 'Tennessee', country: 'US' },
  { name: 'Barton', state: 'New York', country: 'US' },
  { name: 'Bartonville', state: 'Illinois', country: 'US' },
  { name: 'Bartow', state: 'Florida', country: 'US' },
  { name: 'Bastrop', state: 'Louisiana', country: 'US' },
  { name: 'Batavia', state: 'Illinois', country: 'US' },
  { name: 'Batavia', state: 'New York', country: 'US' },
  { name: 'Batesville', state: 'Mississippi', country: 'US' },
  { name: 'Batesville', state: 'Indiana', country: 'US' },
  { name: 'Batesville', state: 'Arkansas', country: 'US' },
  { name: 'Bath', state: 'Maine', country: 'US' },
  { name: 'Bath', state: 'New York', country: 'US' },
  { name: 'Baton Rouge', state: 'Louisiana', country: 'US' },
  { name: 'Battle Creek', state: 'Michigan', country: 'US' },
  { name: 'Battle Ground', state: 'Washington', country: 'US' },
  { name: 'Bay City', state: 'Texas', country: 'US' },
  { name: 'Bay City', state: 'Michigan', country: 'US' },
  { name: 'Bay Minette', state: 'Alabama', country: 'US' },
  { name: 'Bay Point', state: 'California', country: 'US' },
  { name: 'Bay Shore', state: 'New York', country: 'US' },
  { name: 'Bay St. Louis', state: 'Mississippi', country: 'US' },
  { name: 'Bay Village', state: 'Ohio', country: 'US' },
  { name: 'Bayonet Point', state: 'Florida', country: 'US' },
  { name: 'Bayonne', state: 'New Jersey', country: 'US' },
  { name: 'Bayou Cane', state: 'Louisiana', country: 'US' },
  { name: 'Bayport', state: 'New York', country: 'US' },
  { name: 'Bayshore Gardens', state: 'Florida', country: 'US' },
  { name: 'Baytown', state: 'Texas', country: 'US' },
  { name: 'Bayville', state: 'New York', country: 'US' },
  { name: 'Baywood', state: 'New York', country: 'US' },
  { name: 'Baywood-Los Osos', state: 'California', country: 'US' },
  { name: 'Beach Park', state: 'Illinois', country: 'US' },
  { name: 'Beachwood', state: 'Ohio', country: 'US' },
  { name: 'Beachwood', state: 'New Jersey', country: 'US' },
  { name: 'Beacon', state: 'New York', country: 'US' },
  { name: 'Beacon Square', state: 'Florida', country: 'US' },
  { name: 'Bear', state: 'Delaware', country: 'US' },
  { name: 'Beatrice', state: 'Nebraska', country: 'US' },
  { name: 'Beaufort', state: 'South Carolina', country: 'US' },
  { name: 'Beaumont', state: 'Texas', country: 'US' },
  { name: 'Beaumont', state: 'California', country: 'US' },
  { name: 'Beaver Dam', state: 'Wisconsin', country: 'US' },
  { name: 'Beaver Falls', state: 'Pennsylvania', country: 'US' },
  { name: 'Beavercreek', state: 'Ohio', country: 'US' },
  { name: 'Beaverton', state: 'Oregon', country: 'US' },
  { name: 'Beckett Ridge', state: 'Ohio', country: 'US' },
  { name: 'Beckley', state: 'West Virginia', country: 'US' },
  { name: 'Bedford', state: 'Virginia', country: 'US' },
  { name: 'Bedford', state: 'Texas', country: 'US' },
  { name: 'Bedford', state: 'Ohio', country: 'US' },
  { name: 'Bedford', state: 'New York', country: 'US' },
  { name: 'Bedford', state: 'New Hampshire', country: 'US' },
  { name: 'Bedford', state: 'Massachusetts', country: 'US' },
  { name: 'Bedford', state: 'Indiana', country: 'US' },
  { name: 'Bedford Heights', state: 'Ohio', country: 'US' },
  { name: 'Bee Ridge', state: 'Florida', country: 'US' },
  { name: 'Beech Grove', state: 'Indiana', country: 'US' },
  { name: 'Beecher', state: 'Michigan', country: 'US' },
  { name: 'Beekman', state: 'New York', country: 'US' },
  { name: 'Beeville', state: 'Texas', country: 'US' },
  { name: 'Bel Air', state: 'Maryland', country: 'US' },
  { name: 'Bel Air North', state: 'Maryland', country: 'US' },
  { name: 'Bel Air South', state: 'Maryland', country: 'US' },
  { name: 'Belchertown', state: 'Massachusetts', country: 'US' },
  { name: 'Belen', state: 'New Mexico', country: 'US' },
  { name: 'Belfast', state: 'Maine', country: 'US' },
  { name: 'Bell', state: 'California', country: 'US' },
  { name: 'Bell Gardens', state: 'California', country: 'US' },
  { name: 'Bella Vista', state: 'Arkansas', country: 'US' },
  { name: 'Bellair-Meadowbrook Terrace', state: 'Florida', country: 'US' },
  { name: 'Bellaire', state: 'Texas', country: 'US' },
  { name: 'Bellbrook', state: 'Ohio', country: 'US' },
  { name: 'Belle Chasse', state: 'Louisiana', country: 'US' },
  { name: 'Belle Glade', state: 'Florida', country: 'US' },
  { name: 'Belle Haven', state: 'Virginia', country: 'US' },
  { name: 'Bellefontaine', state: 'Ohio', country: 'US' },
  { name: 'Bellefontaine Neighbors', state: 'Missouri', country: 'US' },
  { name: 'Bellefonte', state: 'Pennsylvania', country: 'US' },
  { name: 'Belleville', state: 'New Jersey', country: 'US' },
  { name: 'Belleville', state: 'Illinois', country: 'US' },
  { name: 'Bellevue', state: 'Kentucky', country: 'US' },
  { name: 'Bellevue', state: 'Nebraska', country: 'US' },
  { name: 'Bellevue', state: 'Ohio', country: 'US' },
  { name: 'Bellevue', state: 'Pennsylvania', country: 'US' },
  { name: 'Bellevue', state: 'Wisconsin', country: 'US' },
  { name: 'Bellevue', state: 'Washington', country: 'US' },
  { name: 'Bellevue Town', state: 'Wisconsin', country: 'US' },
  { name: 'Bellflower', state: 'California', country: 'US' },
  { name: 'Bellingham', state: 'Massachusetts', country: 'US' },
  { name: 'Bellingham', state: 'Washington', country: 'US' },
  { name: 'Bellmawr', state: 'New Jersey', country: 'US' },
  { name: 'Bellmead', state: 'Texas', country: 'US' },
  { name: 'Bellmore', state: 'New York', country: 'US' },
  { name: 'Bellview', state: 'Florida', country: 'US' },
  { name: 'Bellwood', state: 'Illinois', country: 'US' },
  { name: 'Belmar', state: 'New Jersey', country: 'US' },
  { name: 'Belmont', state: 'Massachusetts', country: 'US' },
  { name: 'Belmont', state: 'Massachusetts', country: 'US' },
  { name: 'Belmont', state: 'New Hampshire', country: 'US' },
  { name: 'Belmont', state: 'North Carolina', country: 'US' },
  { name: 'Belmont', state: 'California', country: 'US' },
  { name: 'Beloit', state: 'Wisconsin', country: 'US' },
  { name: 'Beloit', state: 'Wisconsin', country: 'US' },
  { name: 'Belpre', state: 'Ohio', country: 'US' },
  { name: 'Belton', state: 'Missouri', country: 'US' },
  { name: 'Belton', state: 'Texas', country: 'US' },
  { name: 'Beltsville', state: 'Maryland', country: 'US' },
  { name: 'Belvedere Park', state: 'Georgia', country: 'US' },
  { name: 'Belvidere', state: 'Illinois', country: 'US' },
  { name: 'Bemidji', state: 'Minnesota', country: 'US' },
  { name: 'Benbrook', state: 'Texas', country: 'US' },
  { name: 'Bend', state: 'Oregon', country: 'US' },
  { name: 'Benicia', state: 'California', country: 'US' },
  { name: 'Bennettsville', state: 'South Carolina', country: 'US' },
  { name: 'Bennington', state: 'Vermont', country: 'US' },
  { name: 'Bennington', state: 'Vermont', country: 'US' },
  { name: 'Bennsville', state: 'Maryland', country: 'US' },
  { name: 'Bensenville', state: 'Illinois', country: 'US' },
  { name: 'Benton', state: 'Illinois', country: 'US' },
  { name: 'Benton', state: 'Arkansas', country: 'US' },
  { name: 'Benton Harbor', state: 'Michigan', country: 'US' },
  { name: 'Bentonville', state: 'Arkansas', country: 'US' },
  { name: 'Berea', state: 'Kentucky', country: 'US' },
  { name: 'Berea', state: 'Ohio', country: 'US' },
  { name: 'Berea', state: 'South Carolina', country: 'US' },
  { name: 'Bergenfield', state: 'New Jersey', country: 'US' },
  { name: 'Berkeley', state: 'Missouri', country: 'US' },
  { name: 'Berkeley', state: 'California', country: 'US' },
  { name: 'Berkeley Heights', state: 'New Jersey', country: 'US' },
  { name: 'Berkley', state: 'Michigan', country: 'US' },
  { name: 'Berkley', state: 'Colorado', country: 'US' },
  { name: 'Berlin', state: 'Connecticut', country: 'US' },
  { name: 'Berlin', state: 'New Jersey', country: 'US' },
  { name: 'Berlin', state: 'New Hampshire', country: 'US' },
  { name: 'Bermuda Dunes', state: 'California', country: 'US' },
  { name: 'Bernalillo', state: 'New Mexico', country: 'US' },
  { name: 'Bernardsville', state: 'New Jersey', country: 'US' },
  { name: 'Berwick', state: 'Maine', country: 'US' },
  { name: 'Berwick', state: 'Pennsylvania', country: 'US' },
  { name: 'Berwyn', state: 'Illinois', country: 'US' },
  { name: 'Bessemer', state: 'Alabama', country: 'US' },
  { name: 'Bethalto', state: 'Illinois', country: 'US' },
  { name: 'Bethany', state: 'Oklahoma', country: 'US' },
  { name: 'Bethel', state: 'Connecticut', country: 'US' },
  { name: 'Bethel', state: 'Connecticut', country: 'US' },
  { name: 'Bethel Park', state: 'Pennsylvania', country: 'US' },
  { name: 'Bethesda', state: 'Maryland', country: 'US' },
  { name: 'Bethlehem', state: 'New York', country: 'US' },
  { name: 'Bethlehem', state: 'Pennsylvania', country: 'US' },
  { name: 'Bethpage', state: 'New York', country: 'US' },
  { name: 'Bettendorf', state: 'Iowa', country: 'US' },
  { name: 'Beverly', state: 'Massachusetts', country: 'US' },
  { name: 'Beverly Hills', state: 'Michigan', country: 'US' },
  { name: 'Beverly Hills', state: 'Florida', country: 'US' },
  { name: 'Beverly Hills', state: 'California', country: 'US' },
  { name: 'Bexley', state: 'Ohio', country: 'US' },
  { name: 'Biddeford', state: 'Maine', country: 'US' },
  { name: 'Big Flats', state: 'New York', country: 'US' },
  { name: 'Big Lake', state: 'Minnesota', country: 'US' },
  { name: 'Big Rapids', state: 'Michigan', country: 'US' },
  { name: 'Big Spring', state: 'Texas', country: 'US' },
  { name: 'Billerica', state: 'Massachusetts', country: 'US' },
  { name: 'Billings', state: 'Montana', country: 'US' },
  { name: 'Biloxi', state: 'Mississippi', country: 'US' },
  { name: 'Binghamton', state: 'New York', country: 'US' },
  { name: 'Birmingham', state: 'Michigan', country: 'US' },
  { name: 'Birmingham', state: 'Alabama', country: 'US' },
  { name: 'Bisbee', state: 'Arizona', country: 'US' },
  { name: 'Bismarck', state: 'North Dakota', country: 'US' },
  { name: 'Bixby', state: 'Oklahoma', country: 'US' },
  { name: 'Black Forest', state: 'Colorado', country: 'US' },
  { name: 'Black Jack', state: 'Missouri', country: 'US' },
  { name: 'Black Mountain', state: 'North Carolina', country: 'US' },
  { name: 'Blackfoot', state: 'Idaho', country: 'US' },
  { name: 'Blackhawk-Camino Tassajara', state: 'California', country: 'US' },
  { name: 'Blacklick Estates', state: 'Ohio', country: 'US' },
  { name: 'Blacksburg', state: 'Virginia', country: 'US' },
  { name: 'Blackstone', state: 'Massachusetts', country: 'US' },
  { name: 'Blackwell', state: 'Oklahoma', country: 'US' },
  { name: 'Bladensburg', state: 'Maryland', country: 'US' },
  { name: 'Blaine', state: 'Minnesota', country: 'US' },
  { name: 'Blair', state: 'Nebraska', country: 'US' },
  { name: 'Blakely', state: 'Pennsylvania', country: 'US' },
  { name: 'Bloomfield', state: 'New Jersey', country: 'US' },
  { name: 'Bloomfield', state: 'New Mexico', country: 'US' },
  { name: 'Bloomfield', state: 'Connecticut', country: 'US' },
  { name: 'Bloomfield Township', state: 'Michigan', country: 'US' },
  { name: 'Blooming Grove', state: 'New York', country: 'US' },
  { name: 'Bloomingdale', state: 'New Jersey', country: 'US' },
  { name: 'Bloomingdale', state: 'Florida', country: 'US' },
  { name: 'Bloomingdale', state: 'Illinois', country: 'US' },
  { name: 'Bloomingdale', state: 'Tennessee', country: 'US' },
  { name: 'Bloomington', state: 'Illinois', country: 'US' },
  { name: 'Bloomington', state: 'Indiana', country: 'US' },
  { name: 'Bloomington', state: 'California', country: 'US' },
  { name: 'Bloomington', state: 'Minnesota', country: 'US' },
  { name: 'Bloomsburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Blue Ash', state: 'Ohio', country: 'US' },
  { name: 'Blue Bell', state: 'Pennsylvania', country: 'US' },
  { name: 'Blue Island', state: 'Illinois', country: 'US' },
  { name: 'Blue Springs', state: 'Missouri', country: 'US' },
  { name: 'Bluefield', state: 'West Virginia', country: 'US' },
  { name: 'Bluffton', state: 'Indiana', country: 'US' },
  { name: 'Blythe', state: 'California', country: 'US' },
  { name: 'Blytheville', state: 'Arkansas', country: 'US' },
  { name: 'Boardman', state: 'Ohio', country: 'US' },
  { name: 'Boaz', state: 'Alabama', country: 'US' },
  { name: 'Boca Del Mar', state: 'Florida', country: 'US' },
  { name: 'Boca Raton', state: 'Florida', country: 'US' },
  { name: 'Boerne', state: 'Texas', country: 'US' },
  { name: 'Bogalusa', state: 'Louisiana', country: 'US' },
  { name: 'Bogota', state: 'New Jersey', country: 'US' },
  { name: 'Bohemia', state: 'New York', country: 'US' },
  { name: 'Boise City', state: 'Idaho', country: 'US' },
  { name: 'Bolingbrook', state: 'Illinois', country: 'US' },
  { name: 'Bolivar', state: 'Missouri', country: 'US' },
  { name: 'Bon Air', state: 'Virginia', country: 'US' },
  { name: 'Bonadelle Ranchos-Madera Ranchos', state: 'California', country: 'US' },
  { name: 'Bonham', state: 'Texas', country: 'US' },
  { name: 'Bonita', state: 'California', country: 'US' },
  { name: 'Bonita Springs', state: 'Florida', country: 'US' },
  { name: 'Bonner Springs', state: 'Kansas', country: 'US' },
  { name: 'Bonney Lake', state: 'Washington', country: 'US' },
  { name: 'Boone', state: 'Iowa', country: 'US' },
  { name: 'Boone', state: 'North Carolina', country: 'US' },
  { name: 'Booneville', state: 'Mississippi', country: 'US' },
  { name: 'Boonton', state: 'New Jersey', country: 'US' },
  { name: 'Boonville', state: 'Missouri', country: 'US' },
  { name: 'Boonville', state: 'Indiana', country: 'US' },
  { name: 'Borger', state: 'Texas', country: 'US' },
  { name: 'Bossier City', state: 'Louisiana', country: 'US' },
  { name: 'Boston', state: 'Massachusetts', country: 'US' },
  { name: 'Boston', state: 'New York', country: 'US' },
  { name: 'Bostonia', state: 'California', country: 'US' },
  { name: 'Bothell', state: 'Washington', country: 'US' },
  { name: 'Boulder', state: 'Colorado', country: 'US' },
  { name: 'Boulder City', state: 'Nevada', country: 'US' },
  { name: 'Boulder Hill', state: 'Illinois', country: 'US' },
  { name: 'Bound Brook', state: 'New Jersey', country: 'US' },
  { name: 'Bountiful', state: 'Utah', country: 'US' },
  { name: 'Bourbonnais', state: 'Illinois', country: 'US' },
  { name: 'Bourne', state: 'Massachusetts', country: 'US' },
  { name: 'Bow', state: 'New Hampshire', country: 'US' },
  { name: 'Bowie', state: 'Maryland', country: 'US' },
  { name: 'Bowleys Quarters', state: 'Maryland', country: 'US' },
  { name: 'Bowling Green', state: 'Kentucky', country: 'US' },
  { name: 'Bowling Green', state: 'Ohio', country: 'US' },
  { name: 'Boxford', state: 'Massachusetts', country: 'US' },
  { name: 'Boyes Hot Springs', state: 'California', country: 'US' },
  { name: 'Boynton Beach', state: 'Florida', country: 'US' },
  { name: 'Bozeman', state: 'Montana', country: 'US' },
  { name: 'Bradenton', state: 'Florida', country: 'US' },
  { name: 'Bradford', state: 'Pennsylvania', country: 'US' },
  { name: 'Bradley', state: 'Illinois', country: 'US' },
  { name: 'Brainerd', state: 'Minnesota', country: 'US' },
  { name: 'Braintree', state: 'Massachusetts', country: 'US' },
  { name: 'Braintree', state: 'Massachusetts', country: 'US' },
  { name: 'Brandon', state: 'Mississippi', country: 'US' },
  { name: 'Brandon', state: 'Florida', country: 'US' },
  { name: 'Branford', state: 'Connecticut', country: 'US' },
  { name: 'Branson', state: 'Missouri', country: 'US' },
  { name: 'Brattleboro', state: 'Vermont', country: 'US' },
  { name: 'Brattleboro', state: 'Vermont', country: 'US' },
  { name: 'Brawley', state: 'California', country: 'US' },
  { name: 'Brazil', state: 'Indiana', country: 'US' },
  { name: 'Brea', state: 'California', country: 'US' },
  { name: 'Breaux Bridge', state: 'Louisiana', country: 'US' },
  { name: 'Brecksville', state: 'Ohio', country: 'US' },
  { name: 'Bremerton', state: 'Washington', country: 'US' },
  { name: 'Brenham', state: 'Texas', country: 'US' },
  { name: 'Brent', state: 'Florida', country: 'US' },
  { name: 'Brentwood', state: 'California', country: 'US' },
  { name: 'Brentwood', state: 'New York', country: 'US' },
  { name: 'Brentwood', state: 'Missouri', country: 'US' },
  { name: 'Brentwood', state: 'Pennsylvania', country: 'US' },
  { name: 'Brentwood', state: 'Tennessee', country: 'US' },
  { name: 'Brevard', state: 'North Carolina', country: 'US' },
  { name: 'Brewer', state: 'Maine', country: 'US' },
  { name: 'Brewster', state: 'Massachusetts', country: 'US' },
  { name: 'Briarcliff Manor', state: 'New York', country: 'US' },
  { name: 'Bridge City', state: 'Louisiana', country: 'US' },
  { name: 'Bridge City', state: 'Texas', country: 'US' },
  { name: 'Bridgeport', state: 'West Virginia', country: 'US' },
  { name: 'Bridgeport', state: 'Connecticut', country: 'US' },
  { name: 'Bridgeport', state: 'Connecticut', country: 'US' },
  { name: 'Bridgeport', state: 'Michigan', country: 'US' },
  { name: 'Bridgeton', state: 'Missouri', country: 'US' },
  { name: 'Bridgeton', state: 'New Jersey', country: 'US' },
  { name: 'Bridgetown North', state: 'Ohio', country: 'US' },
  { name: 'Bridgeview', state: 'Illinois', country: 'US' },
  { name: 'Bridgewater', state: 'Massachusetts', country: 'US' },
  { name: 'Bridgewater', state: 'Massachusetts', country: 'US' },
  { name: 'Brier', state: 'Washington', country: 'US' },
  { name: 'Brigantine', state: 'New Jersey', country: 'US' },
  { name: 'Brigham City', state: 'Utah', country: 'US' },
  { name: 'Brighton', state: 'Michigan', country: 'US' },
  { name: 'Brighton', state: 'New York', country: 'US' },
  { name: 'Brighton', state: 'New York', country: 'US' },
  { name: 'Brighton', state: 'Colorado', country: 'US' },
  { name: 'Bristol', state: 'Connecticut', country: 'US' },
  { name: 'Bristol', state: 'Connecticut', country: 'US' },
  { name: 'Bristol', state: 'Rhode Island', country: 'US' },
  { name: 'Bristol', state: 'Tennessee', country: 'US' },
  { name: 'Bristol', state: 'Rhode Island', country: 'US' },
  { name: 'Bristol', state: 'Virginia', country: 'US' },
  { name: 'Bristol', state: 'Pennsylvania', country: 'US' },
  { name: 'Broadview', state: 'Illinois', country: 'US' },
  { name: 'Broadview Heights', state: 'Ohio', country: 'US' },
  { name: 'Broadview Park', state: 'Florida', country: 'US' },
  { name: 'Brockport', state: 'New York', country: 'US' },
  { name: 'Brockton', state: 'Massachusetts', country: 'US' },
  { name: 'Broken Arrow', state: 'Oklahoma', country: 'US' },
  { name: 'Bronxville', state: 'New York', country: 'US' },
  { name: 'Brook Park', state: 'Ohio', country: 'US' },
  { name: 'Brookfield', state: 'Connecticut', country: 'US' },
  { name: 'Brookfield', state: 'Illinois', country: 'US' },
  { name: 'Brookfield', state: 'Wisconsin', country: 'US' },
  { name: 'Brookfield', state: 'Wisconsin', country: 'US' },
  { name: 'Brookhaven', state: 'Pennsylvania', country: 'US' },
  { name: 'Brookhaven', state: 'New York', country: 'US' },
  { name: 'Brookhaven', state: 'Mississippi', country: 'US' },
  { name: 'Brookings', state: 'South Dakota', country: 'US' },
  { name: 'Brookline', state: 'Massachusetts', country: 'US' },
  { name: 'Brookline', state: 'Massachusetts', country: 'US' },
  { name: 'Brooklyn', state: 'Ohio', country: 'US' },
  { name: 'Brooklyn', state: 'Connecticut', country: 'US' },
  { name: 'Brooklyn Center', state: 'Minnesota', country: 'US' },
  { name: 'Brooklyn Park', state: 'Minnesota', country: 'US' },
  { name: 'Brooklyn Park', state: 'Maryland', country: 'US' },
  { name: 'Brookside', state: 'Delaware', country: 'US' },
  { name: 'Brooksville', state: 'Florida', country: 'US' },
  { name: 'Broomall', state: 'Pennsylvania', country: 'US' },
  { name: 'Broomfield', state: 'Colorado', country: 'US' },
  { name: 'Brown Deer', state: 'Wisconsin', country: 'US' },
  { name: 'Brownfield', state: 'Texas', country: 'US' },
  { name: 'Browns Mills', state: 'New Jersey', country: 'US' },
  { name: 'Brownsburg', state: 'Indiana', country: 'US' },
  { name: 'Brownsville', state: 'Florida', country: 'US' },
  { name: 'Brownsville', state: 'Texas', country: 'US' },
  { name: 'Brownsville', state: 'Tennessee', country: 'US' },
  { name: 'Brownsville-Bawcomville', state: 'Louisiana', country: 'US' },
  { name: 'Brownwood', state: 'Texas', country: 'US' },
  { name: 'Brunswick', state: 'Maine', country: 'US' },
  { name: 'Brunswick', state: 'Maine', country: 'US' },
  { name: 'Brunswick', state: 'Georgia', country: 'US' },
  { name: 'Brunswick', state: 'Ohio', country: 'US' },
  { name: 'Brunswick', state: 'New York', country: 'US' },
  { name: 'Brushy Creek', state: 'Texas', country: 'US' },
  { name: 'Bryan', state: 'Texas', country: 'US' },
  { name: 'Bryan', state: 'Ohio', country: 'US' },
  { name: 'Bryant', state: 'Arkansas', country: 'US' },
  { name: 'Bryn Mawr-Skyway', state: 'Washington', country: 'US' },
  { name: 'Buckeye', state: 'Arizona', country: 'US' },
  { name: 'Bucyrus', state: 'Ohio', country: 'US' },
  { name: 'Budd Lake', state: 'New Jersey', country: 'US' },
  { name: 'Buechel', state: 'Kentucky', country: 'US' },
  { name: 'Buena Park', state: 'California', country: 'US' },
  { name: 'Buena Vista', state: 'Michigan', country: 'US' },
  { name: 'Buena Vista', state: 'Virginia', country: 'US' },
  { name: 'Buffalo', state: 'Minnesota', country: 'US' },
  { name: 'Buffalo', state: 'New York', country: 'US' },
  { name: 'Buffalo Grove', state: 'Illinois', country: 'US' },
  { name: 'Buford', state: 'Georgia', country: 'US' },
  { name: 'Bull Run', state: 'Virginia', country: 'US' },
  { name: 'Bullhead City', state: 'Arizona', country: 'US' },
  { name: 'Burbank', state: 'California', country: 'US' },
  { name: 'Burbank', state: 'Illinois', country: 'US' },
  { name: 'Burien', state: 'Washington', country: 'US' },
  { name: 'Burkburnett', state: 'Texas', country: 'US' },
  { name: 'Burke', state: 'Virginia', country: 'US' },
  { name: 'Burleson', state: 'Texas', country: 'US' },
  { name: 'Burley', state: 'Idaho', country: 'US' },
  { name: 'Burlingame', state: 'California', country: 'US' },
  { name: 'Burlington', state: 'Connecticut', country: 'US' },
  { name: 'Burlington', state: 'Kentucky', country: 'US' },
  { name: 'Burlington', state: 'Iowa', country: 'US' },
  { name: 'Burlington', state: 'North Carolina', country: 'US' },
  { name: 'Burlington', state: 'Massachusetts', country: 'US' },
  { name: 'Burlington', state: 'Massachusetts', country: 'US' },
  { name: 'Burlington', state: 'New Jersey', country: 'US' },
  { name: 'Burlington', state: 'Vermont', country: 'US' },
  { name: 'Burlington', state: 'Washington', country: 'US' },
  { name: 'Burlington', state: 'Wisconsin', country: 'US' },
  { name: 'Burlington', state: 'Wisconsin', country: 'US' },
  { name: 'Burnsville', state: 'Minnesota', country: 'US' },
  { name: 'Burr Ridge', state: 'Illinois', country: 'US' },
  { name: 'Burrillville', state: 'Rhode Island', country: 'US' },
  { name: 'Burton', state: 'South Carolina', country: 'US' },
  { name: 'Burton', state: 'Michigan', country: 'US' },
  { name: 'Burtonsville', state: 'Maryland', country: 'US' },
  { name: 'Busti', state: 'New York', country: 'US' },
  { name: 'Butler', state: 'New Jersey', country: 'US' },
  { name: 'Butler', state: 'Pennsylvania', country: 'US' },
  { name: 'Butte-Silver Bow', state: 'Montana', country: 'US' },
  { name: 'Buxton', state: 'Maine', country: 'US' },
  { name: 'Byram', state: 'Mississippi', country: 'US' },
  { name: 'Cabot', state: 'Arkansas', country: 'US' },
  { name: 'Cadillac', state: 'Michigan', country: 'US' },
  { name: 'Cahokia', state: 'Illinois', country: 'US' },
  { name: 'Cairo', state: 'Georgia', country: 'US' },
  { name: 'Cairo', state: 'New York', country: 'US' },
  { name: 'Calabasas', state: 'California', country: 'US' },
  { name: 'Caldwell', state: 'New Jersey', country: 'US' },
  { name: 'Caldwell', state: 'Idaho', country: 'US' },
  { name: 'Caledonia', state: 'Wisconsin', country: 'US' },
  { name: 'Calexico', state: 'California', country: 'US' },
  { name: 'Calhoun', state: 'Georgia', country: 'US' },
  { name: 'California', state: 'Maryland', country: 'US' },
  { name: 'California City', state: 'California', country: 'US' },
  { name: 'Calimesa', state: 'California', country: 'US' },
  { name: 'Calipatria', state: 'California', country: 'US' },
  { name: 'Callaway', state: 'Florida', country: 'US' },
  { name: 'Calumet City', state: 'Illinois', country: 'US' },
  { name: 'Calumet Park', state: 'Illinois', country: 'US' },
  { name: 'Calverton', state: 'Maryland', country: 'US' },
  { name: 'Camano', state: 'Washington', country: 'US' },
  { name: 'Camarillo', state: 'California', country: 'US' },
  { name: 'Camas', state: 'Washington', country: 'US' },
  { name: 'Cambria', state: 'California', country: 'US' },
  { name: 'Cambridge', state: 'Maryland', country: 'US' },
  { name: 'Cambridge', state: 'Massachusetts', country: 'US' },
  { name: 'Cambridge', state: 'Ohio', country: 'US' },
  { name: 'Camden', state: 'South Carolina', country: 'US' },
  { name: 'Camden', state: 'New Jersey', country: 'US' },
  { name: 'Camden', state: 'Arkansas', country: 'US' },
  { name: 'Cameron', state: 'Missouri', country: 'US' },
  { name: 'Cameron Park', state: 'California', country: 'US' },
  { name: 'Camillus', state: 'New York', country: 'US' },
  { name: 'Camp Hill', state: 'Pennsylvania', country: 'US' },
  { name: 'Camp Pendleton North', state: 'California', country: 'US' },
  { name: 'Camp Pendleton South', state: 'California', country: 'US' },
  { name: 'Camp Springs', state: 'Maryland', country: 'US' },
  { name: 'Camp Verde', state: 'Arizona', country: 'US' },
  { name: 'Campbell', state: 'California', country: 'US' },
  { name: 'Campbell', state: 'Ohio', country: 'US' },
  { name: 'Campbellsville', state: 'Kentucky', country: 'US' },
  { name: 'Canandaigua', state: 'New York', country: 'US' },
  { name: 'Canandaigua', state: 'New York', country: 'US' },
  { name: 'Canby', state: 'Oregon', country: 'US' },
  { name: 'Candler-McAfee', state: 'Georgia', country: 'US' },
  { name: 'Canfield', state: 'Ohio', country: 'US' },
  { name: 'Canon City', state: 'Colorado', country: 'US' },
  { name: 'Canonsburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Canton', state: 'Ohio', country: 'US' },
  { name: 'Canton', state: 'Georgia', country: 'US' },
  { name: 'Canton', state: 'Illinois', country: 'US' },
  { name: 'Canton', state: 'Connecticut', country: 'US' },
  { name: 'Canton', state: 'New York', country: 'US' },
  { name: 'Canton', state: 'Mississippi', country: 'US' },
  { name: 'Canton', state: 'Massachusetts', country: 'US' },
  { name: 'Canton', state: 'Michigan', country: 'US' },
  { name: 'Canyon', state: 'Texas', country: 'US' },
  { name: 'Canyon Lake', state: 'Texas', country: 'US' },
  { name: 'Canyon Lake', state: 'California', country: 'US' },
  { name: 'Canyon Rim', state: 'Utah', country: 'US' },
  { name: 'Cape Canaveral', state: 'Florida', country: 'US' },
  { name: 'Cape Coral', state: 'Florida', country: 'US' },
  { name: 'Cape Elizabeth', state: 'Maine', country: 'US' },
  { name: 'Cape Girardeau', state: 'Missouri', country: 'US' },
  { name: 'Cape St. Claire', state: 'Maryland', country: 'US' },
  { name: 'Capitola', state: 'California', country: 'US' },
  { name: 'Carbondale', state: 'Illinois', country: 'US' },
  { name: 'Carbondale', state: 'Pennsylvania', country: 'US' },
  { name: 'Carencro', state: 'Louisiana', country: 'US' },
  { name: 'Caribou', state: 'Maine', country: 'US' },
  { name: 'Carlisle', state: 'Pennsylvania', country: 'US' },
  { name: 'Carlsbad', state: 'New Mexico', country: 'US' },
  { name: 'Carlsbad', state: 'California', country: 'US' },
  { name: 'Carmel', state: 'Indiana', country: 'US' },
  { name: 'Carmel', state: 'New York', country: 'US' },
  { name: 'Carmichael', state: 'California', country: 'US' },
  { name: 'Carnegie', state: 'Pennsylvania', country: 'US' },
  { name: 'Carney', state: 'Maryland', country: 'US' },
  { name: 'Carneys Point', state: 'New Jersey', country: 'US' },
  { name: 'Carnot-Moon', state: 'Pennsylvania', country: 'US' },
  { name: 'Carol City', state: 'Florida', country: 'US' },
  { name: 'Carol Stream', state: 'Illinois', country: 'US' },
  { name: 'Carpentersville', state: 'Illinois', country: 'US' },
  { name: 'Carpinteria', state: 'California', country: 'US' },
  { name: 'Carrboro', state: 'North Carolina', country: 'US' },
  { name: 'Carroll', state: 'Iowa', country: 'US' },
  { name: 'Carrollton', state: 'Michigan', country: 'US' },
  { name: 'Carrollton', state: 'Georgia', country: 'US' },
  { name: 'Carrollton', state: 'Texas', country: 'US' },
  { name: 'Carson', state: 'California', country: 'US' },
  { name: 'Carson City', state: 'Nevada', country: 'US' },
  { name: 'Carteret', state: 'New Jersey', country: 'US' },
  { name: 'Cartersville', state: 'Georgia', country: 'US' },
  { name: 'Carthage', state: 'Missouri', country: 'US' },
  { name: 'Carthage', state: 'Texas', country: 'US' },
  { name: 'Caruthersville', state: 'Missouri', country: 'US' },
  { name: 'Carver', state: 'Massachusetts', country: 'US' },
  { name: 'Cary', state: 'Illinois', country: 'US' },
  { name: 'Cary', state: 'North Carolina', country: 'US' },
  { name: 'Casa de Oro-Mount Helix', state: 'California', country: 'US' },
  { name: 'Casa Grande', state: 'Arizona', country: 'US' },
  { name: 'Casas Adobes', state: 'Arizona', country: 'US' },
  { name: 'Cascade-Fairwood', state: 'Washington', country: 'US' },
  { name: 'Casper', state: 'Wyoming', country: 'US' },
  { name: 'Casselberry', state: 'Florida', country: 'US' },
  { name: 'Castle Rock', state: 'Colorado', country: 'US' },
  { name: 'Castle Shannon', state: 'Pennsylvania', country: 'US' },
  { name: 'Castlewood', state: 'Colorado', country: 'US' },
  { name: 'Castro Valley', state: 'California', country: 'US' },
  { name: 'Castroville', state: 'California', country: 'US' },
  { name: 'Catalina', state: 'Arizona', country: 'US' },
  { name: 'Catalina Foothills', state: 'Arizona', country: 'US' },
  { name: 'Catasauqua', state: 'Pennsylvania', country: 'US' },
  { name: 'Cathedral City', state: 'California', country: 'US' },
  { name: 'Catonsville', state: 'Maryland', country: 'US' },
  { name: 'Catskill', state: 'New York', country: 'US' },
  { name: 'Cave Spring', state: 'Virginia', country: 'US' },
  { name: 'Cayce', state: 'South Carolina', country: 'US' },
  { name: 'Cazenovia', state: 'New York', country: 'US' },
  { name: 'Cedar City', state: 'Utah', country: 'US' },
  { name: 'Cedar Falls', state: 'Iowa', country: 'US' },
  { name: 'Cedar Grove', state: 'New Jersey', country: 'US' },
  { name: 'Cedar Hill', state: 'Texas', country: 'US' },
  { name: 'Cedar Hills', state: 'Oregon', country: 'US' },
  { name: 'Cedar Lake', state: 'Indiana', country: 'US' },
  { name: 'Cedar Mill', state: 'Oregon', country: 'US' },
  { name: 'Cedar Park', state: 'Texas', country: 'US' },
  { name: 'Cedar Rapids', state: 'Iowa', country: 'US' },
  { name: 'Cedarburg', state: 'Wisconsin', country: 'US' },
  { name: 'Cedarhurst', state: 'New York', country: 'US' },
  { name: 'Cedartown', state: 'Georgia', country: 'US' },
  { name: 'Celina', state: 'Ohio', country: 'US' },
  { name: 'Center Line', state: 'Michigan', country: 'US' },
  { name: 'Center Moriches', state: 'New York', country: 'US' },
  { name: 'Center Point', state: 'Alabama', country: 'US' },
  { name: 'Centereach', state: 'New York', country: 'US' },
  { name: 'Centerville', state: 'Ohio', country: 'US' },
  { name: 'Centerville', state: 'Utah', country: 'US' },
  { name: 'Central Falls', state: 'Rhode Island', country: 'US' },
  { name: 'Central Islip', state: 'New York', country: 'US' },
  { name: 'Central Manchester', state: 'Connecticut', country: 'US' },
  { name: 'Central Point', state: 'Oregon', country: 'US' },
  { name: 'Centralia', state: 'Washington', country: 'US' },
  { name: 'Centralia', state: 'Illinois', country: 'US' },
  { name: 'Centreville', state: 'Virginia', country: 'US' },
  { name: 'Century Village', state: 'Florida', country: 'US' },
  { name: 'Ceres', state: 'California', country: 'US' },
  { name: 'Cerritos', state: 'California', country: 'US' },
  { name: 'Chalco', state: 'Nebraska', country: 'US' },
  { name: 'Chalmette', state: 'Louisiana', country: 'US' },
  { name: 'Chambersburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Chamblee', state: 'Georgia', country: 'US' },
  { name: 'Champaign', state: 'Illinois', country: 'US' },
  { name: 'Champlin', state: 'Minnesota', country: 'US' },
  { name: 'Chandler', state: 'Arizona', country: 'US' },
  { name: 'Chanhassen', state: 'Minnesota', country: 'US' },
  { name: 'Channahon', state: 'Illinois', country: 'US' },
  { name: 'Channelview', state: 'Texas', country: 'US' },
  { name: 'Chantilly', state: 'Virginia', country: 'US' },
  { name: 'Chanute', state: 'Kansas', country: 'US' },
  { name: 'Chaparral', state: 'New Mexico', country: 'US' },
  { name: 'Chapel Hill', state: 'North Carolina', country: 'US' },
  { name: 'Chappaqua', state: 'New York', country: 'US' },
  { name: 'Charles City', state: 'Iowa', country: 'US' },
  { name: 'Charleston', state: 'Illinois', country: 'US' },
  { name: 'Charleston', state: 'West Virginia', country: 'US' },
  { name: 'Charleston', state: 'South Carolina', country: 'US' },
  { name: 'Charlestown', state: 'Rhode Island', country: 'US' },
  { name: 'Charlotte', state: 'North Carolina', country: 'US' },
  { name: 'Charlotte', state: 'Michigan', country: 'US' },
  { name: 'Charlottesville', state: 'Virginia', country: 'US' },
  { name: 'Charlton', state: 'Massachusetts', country: 'US' },
  { name: 'Charter Oak', state: 'California', country: 'US' },
  { name: 'Chaska', state: 'Minnesota', country: 'US' },
  { name: 'Chatham', state: 'Massachusetts', country: 'US' },
  { name: 'Chatham', state: 'New Jersey', country: 'US' },
  { name: 'Chatham', state: 'Illinois', country: 'US' },
  { name: 'Chattanooga', state: 'Tennessee', country: 'US' },
  { name: 'Cheat Lake', state: 'West Virginia', country: 'US' },
  { name: 'Cheektowaga', state: 'New York', country: 'US' },
  { name: 'Cheektowaga', state: 'New York', country: 'US' },
  { name: 'Chehalis', state: 'Washington', country: 'US' },
  { name: 'Chelmsford', state: 'Massachusetts', country: 'US' },
  { name: 'Chelsea', state: 'Massachusetts', country: 'US' },
  { name: 'Chenango', state: 'New York', country: 'US' },
  { name: 'Cheney', state: 'Washington', country: 'US' },
  { name: 'Cherry Hill Mall', state: 'New Jersey', country: 'US' },
  { name: 'Cherryland', state: 'California', country: 'US' },
  { name: 'Chesapeake', state: 'Virginia', country: 'US' },
  { name: 'Chesapeake Ranch Estates-Drum Point', state: 'Maryland', country: 'US' },
  { name: 'Cheshire', state: 'Connecticut', country: 'US' },
  { name: 'Chester', state: 'New York', country: 'US' },
  { name: 'Chester', state: 'Virginia', country: 'US' },
  { name: 'Chester', state: 'South Carolina', country: 'US' },
  { name: 'Chester', state: 'Pennsylvania', country: 'US' },
  { name: 'Chesterfield', state: 'Missouri', country: 'US' },
  { name: 'Chesterton', state: 'Indiana', country: 'US' },
  { name: 'Chestnut Ridge', state: 'New York', country: 'US' },
  { name: 'Cheval', state: 'Florida', country: 'US' },
  { name: 'Cheverly', state: 'Maryland', country: 'US' },
  { name: 'Cheviot', state: 'Ohio', country: 'US' },
  { name: 'Chevy Chase', state: 'Maryland', country: 'US' },
  { name: 'Cheyenne', state: 'Wyoming', country: 'US' },
  { name: 'Chicago', state: 'Illinois', country: 'US' },
  { name: 'Chicago Heights', state: 'Illinois', country: 'US' },
  { name: 'Chicago Ridge', state: 'Illinois', country: 'US' },
  { name: 'Chickasaw', state: 'Alabama', country: 'US' },
  { name: 'Chickasha', state: 'Oklahoma', country: 'US' },
  { name: 'Chico', state: 'California', country: 'US' },
  { name: 'Chicopee', state: 'Massachusetts', country: 'US' },
  { name: 'Childress', state: 'Texas', country: 'US' },
  { name: 'Chili', state: 'New York', country: 'US' },
  { name: 'Chillicothe', state: 'Missouri', country: 'US' },
  { name: 'Chillicothe', state: 'Ohio', country: 'US' },
  { name: 'Chillum', state: 'Maryland', country: 'US' },
  { name: 'Chino', state: 'California', country: 'US' },
  { name: 'Chino Hills', state: 'California', country: 'US' },
  { name: 'Chino Valley', state: 'Arizona', country: 'US' },
  { name: 'Chippewa Falls', state: 'Wisconsin', country: 'US' },
  { name: 'Choctaw', state: 'Oklahoma', country: 'US' },
  { name: 'Chowchilla', state: 'California', country: 'US' },
  { name: 'Christiansburg', state: 'Virginia', country: 'US' },
  { name: 'Chubbuck', state: 'Idaho', country: 'US' },
  { name: 'Chula Vista', state: 'California', country: 'US' },
  { name: 'Cicero', state: 'Illinois', country: 'US' },
  { name: 'Cicero', state: 'New York', country: 'US' },
  { name: 'Cimarron Hills', state: 'Colorado', country: 'US' },
  { name: 'Cincinnati', state: 'Ohio', country: 'US' },
  { name: 'Cinco Ranch', state: 'Texas', country: 'US' },
  { name: 'Circleville', state: 'Ohio', country: 'US' },
  { name: 'Citrus', state: 'California', country: 'US' },
  { name: 'Citrus Heights', state: 'California', country: 'US' },
  { name: 'Citrus Park', state: 'Florida', country: 'US' },
  { name: 'Citrus Ridge', state: 'Florida', country: 'US' },
  { name: 'City of The Dalles', state: 'Oregon', country: 'US' },
  { name: 'Claiborne', state: 'Louisiana', country: 'US' },
  { name: 'Clairton', state: 'Pennsylvania', country: 'US' },
  { name: 'Clanton', state: 'Alabama', country: 'US' },
  { name: 'Claremont', state: 'California', country: 'US' },
  { name: 'Claremont', state: 'New Hampshire', country: 'US' },
  { name: 'Claremore', state: 'Oklahoma', country: 'US' },
  { name: 'Clarence', state: 'New York', country: 'US' },
  { name: 'Clarendon Hills', state: 'Illinois', country: 'US' },
  { name: 'Clarion', state: 'Pennsylvania', country: 'US' },
  { name: 'Clark', state: 'New Jersey', country: 'US' },
  { name: 'Clarksburg', state: 'West Virginia', country: 'US' },
  { name: 'Clarksdale', state: 'Mississippi', country: 'US' },
  { name: 'Clarkson', state: 'New York', country: 'US' },
  { name: 'Clarkston', state: 'Georgia', country: 'US' },
  { name: 'Clarkston', state: 'Washington', country: 'US' },
  { name: 'Clarkston Heights-Vineland', state: 'Washington', country: 'US' },
  { name: 'Clarkstown', state: 'New York', country: 'US' },
  { name: 'Clarksville', state: 'Indiana', country: 'US' },
  { name: 'Clarksville', state: 'Arkansas', country: 'US' },
  { name: 'Clarksville', state: 'Tennessee', country: 'US' },
  { name: 'Claverack', state: 'New York', country: 'US' },
  { name: 'Clawson', state: 'Michigan', country: 'US' },
  { name: 'Clay', state: 'New York', country: 'US' },
  { name: 'Claymont', state: 'Delaware', country: 'US' },
  { name: 'Clayton', state: 'California', country: 'US' },
  { name: 'Clayton', state: 'Missouri', country: 'US' },
  { name: 'Clayton', state: 'New Jersey', country: 'US' },
  { name: 'Clayton', state: 'Ohio', country: 'US' },
  { name: 'Clayton', state: 'North Carolina', country: 'US' },
  { name: 'Clear Lake', state: 'Iowa', country: 'US' },
  { name: 'Clearfield', state: 'Pennsylvania', country: 'US' },
  { name: 'Clearfield', state: 'Utah', country: 'US' },
  { name: 'Clearlake', state: 'California', country: 'US' },
  { name: 'Clearwater', state: 'Florida', country: 'US' },
  { name: 'Cleburne', state: 'Texas', country: 'US' },
  { name: 'Clemmons', state: 'North Carolina', country: 'US' },
  { name: 'Clemson', state: 'South Carolina', country: 'US' },
  { name: 'Clermont', state: 'Florida', country: 'US' },
  { name: 'Cleveland', state: 'Mississippi', country: 'US' },
  { name: 'Cleveland', state: 'Tennessee', country: 'US' },
  { name: 'Cleveland', state: 'Texas', country: 'US' },
  { name: 'Cleveland', state: 'Ohio', country: 'US' },
  { name: 'Cleveland Heights', state: 'Ohio', country: 'US' },
  { name: 'Clewiston', state: 'Florida', country: 'US' },
  { name: 'Cliffside Park', state: 'New Jersey', country: 'US' },
  { name: 'Clifton', state: 'New Jersey', country: 'US' },
  { name: 'Clifton', state: 'Colorado', country: 'US' },
  { name: 'Clifton Heights', state: 'Pennsylvania', country: 'US' },
  { name: 'Clifton Park', state: 'New York', country: 'US' },
  { name: 'Clinton', state: 'Mississippi', country: 'US' },
  { name: 'Clinton', state: 'Missouri', country: 'US' },
  { name: 'Clinton', state: 'Iowa', country: 'US' },
  { name: 'Clinton', state: 'Maryland', country: 'US' },
  { name: 'Clinton', state: 'Michigan', country: 'US' },
  { name: 'Clinton', state: 'Massachusetts', country: 'US' },
  { name: 'Clinton', state: 'Massachusetts', country: 'US' },
  { name: 'Clinton', state: 'Connecticut', country: 'US' },
  { name: 'Clinton', state: 'Illinois', country: 'US' },
  { name: 'Clinton', state: 'Oklahoma', country: 'US' },
  { name: 'Clinton', state: 'North Carolina', country: 'US' },
  { name: 'Clinton', state: 'Tennessee', country: 'US' },
  { name: 'Clinton', state: 'South Carolina', country: 'US' },
  { name: 'Clinton', state: 'Utah', country: 'US' },
  { name: 'Clive', state: 'Iowa', country: 'US' },
  { name: 'Cloquet', state: 'Minnesota', country: 'US' },
  { name: 'Closter', state: 'New Jersey', country: 'US' },
  { name: 'Cloverdale', state: 'California', country: 'US' },
  { name: 'Cloverleaf', state: 'Texas', country: 'US' },
  { name: 'Cloverly', state: 'Maryland', country: 'US' },
  { name: 'Clovis', state: 'New Mexico', country: 'US' },
  { name: 'Clovis', state: 'California', country: 'US' },
  { name: 'Clute', state: 'Texas', country: 'US' },
  { name: 'Clyde', state: 'Ohio', country: 'US' },
  { name: 'Coachella', state: 'California', country: 'US' },
  { name: 'Coalinga', state: 'California', country: 'US' },
  { name: 'Coatesville', state: 'Pennsylvania', country: 'US' },
  { name: 'Cobleskill', state: 'New York', country: 'US' },
  { name: 'Cochituate', state: 'Massachusetts', country: 'US' },
  { name: 'Cockeysville', state: 'Maryland', country: 'US' },
  { name: 'Cocoa', state: 'Florida', country: 'US' },
  { name: 'Cocoa Beach', state: 'Florida', country: 'US' },
  { name: 'Coconut Creek', state: 'Florida', country: 'US' },
  { name: 'Cody', state: 'Wyoming', country: 'US' },
  { name: 'Coeur d’Alene', state: 'Idaho', country: 'US' },
  { name: 'Coeymans', state: 'New York', country: 'US' },
  { name: 'Coffeyville', state: 'Kansas', country: 'US' },
  { name: 'Cohasset', state: 'Massachusetts', country: 'US' },
  { name: 'Cohoes', state: 'New York', country: 'US' },
  { name: 'Colchester', state: 'Vermont', country: 'US' },
  { name: 'Colchester', state: 'Connecticut', country: 'US' },
  { name: 'Coldwater', state: 'Michigan', country: 'US' },
  { name: 'Colesville', state: 'Maryland', country: 'US' },
  { name: 'College', state: 'Alaska', country: 'US' },
  { name: 'College Park', state: 'Georgia', country: 'US' },
  { name: 'College Park', state: 'Maryland', country: 'US' },
  { name: 'College Place', state: 'Washington', country: 'US' },
  { name: 'College Station', state: 'Texas', country: 'US' },
  { name: 'Collegedale', state: 'Tennessee', country: 'US' },
  { name: 'Collegeville', state: 'Pennsylvania', country: 'US' },
  { name: 'Colleyville', state: 'Texas', country: 'US' },
  { name: 'Collier Manor-Cresthaven', state: 'Florida', country: 'US' },
  { name: 'Collierville', state: 'Tennessee', country: 'US' },
  { name: 'Collingdale', state: 'Pennsylvania', country: 'US' },
  { name: 'Collingswood', state: 'New Jersey', country: 'US' },
  { name: 'Collins', state: 'New York', country: 'US' },
  { name: 'Collinsville', state: 'Illinois', country: 'US' },
  { name: 'Collinsville', state: 'Virginia', country: 'US' },
  { name: 'Colonia', state: 'New Jersey', country: 'US' },
  { name: 'Colonial Heights', state: 'Virginia', country: 'US' },
  { name: 'Colonial Heights', state: 'Tennessee', country: 'US' },
  { name: 'Colonial Park', state: 'Pennsylvania', country: 'US' },
  { name: 'Colonie', state: 'New York', country: 'US' },
  { name: 'Colonie', state: 'New York', country: 'US' },
  { name: 'Colorado Springs', state: 'Colorado', country: 'US' },
  { name: 'Colton', state: 'California', country: 'US' },
  { name: 'Columbia', state: 'Illinois', country: 'US' },
  { name: 'Columbia', state: 'Missouri', country: 'US' },
  { name: 'Columbia', state: 'Mississippi', country: 'US' },
  { name: 'Columbia', state: 'Maryland', country: 'US' },
  { name: 'Columbia', state: 'Pennsylvania', country: 'US' },
  { name: 'Columbia', state: 'Tennessee', country: 'US' },
  { name: 'Columbia', state: 'South Carolina', country: 'US' },
  { name: 'Columbia City', state: 'Indiana', country: 'US' },
  { name: 'Columbia Heights', state: 'Minnesota', country: 'US' },
  { name: 'Columbine', state: 'Colorado', country: 'US' },
  { name: 'Columbus', state: 'Indiana', country: 'US' },
  { name: 'Columbus', state: 'Georgia', country: 'US' },
  { name: 'Columbus', state: 'Mississippi', country: 'US' },
  { name: 'Columbus', state: 'Nebraska', country: 'US' },
  { name: 'Columbus', state: 'Ohio', country: 'US' },
  { name: 'Commack', state: 'New York', country: 'US' },
  { name: 'Commerce', state: 'Texas', country: 'US' },
  { name: 'Commerce', state: 'California', country: 'US' },
  { name: 'Commerce City', state: 'Colorado', country: 'US' },
  { name: 'Compton', state: 'California', country: 'US' },
  { name: 'Comstock Park', state: 'Michigan', country: 'US' },
  { name: 'Concord', state: 'Massachusetts', country: 'US' },
  { name: 'Concord', state: 'Missouri', country: 'US' },
  { name: 'Concord', state: 'New Hampshire', country: 'US' },
  { name: 'Concord', state: 'New York', country: 'US' },
  { name: 'Concord', state: 'California', country: 'US' },
  { name: 'Concord', state: 'North Carolina', country: 'US' },
  { name: 'Congers', state: 'New York', country: 'US' },
  { name: 'Conley', state: 'Georgia', country: 'US' },
  { name: 'Conneaut', state: 'Ohio', country: 'US' },
  { name: 'Connellsville', state: 'Pennsylvania', country: 'US' },
  { name: 'Connersville', state: 'Indiana', country: 'US' },
  { name: 'Conning Towers-Nautilus Park', state: 'Connecticut', country: 'US' },
  { name: 'Conover', state: 'North Carolina', country: 'US' },
  { name: 'Conroe', state: 'Texas', country: 'US' },
  { name: 'Conshohocken', state: 'Pennsylvania', country: 'US' },
  { name: 'Converse', state: 'Texas', country: 'US' },
  { name: 'Conway', state: 'South Carolina', country: 'US' },
  { name: 'Conway', state: 'Florida', country: 'US' },
  { name: 'Conway', state: 'Arkansas', country: 'US' },
  { name: 'Conway', state: 'New Hampshire', country: 'US' },
  { name: 'Conyers', state: 'Georgia', country: 'US' },
  { name: 'Cookeville', state: 'Tennessee', country: 'US' },
  { name: 'Coolidge', state: 'Arizona', country: 'US' },
  { name: 'Coon Rapids', state: 'Minnesota', country: 'US' },
  { name: 'Cooper City', state: 'Florida', country: 'US' },
  { name: 'Coos Bay', state: 'Oregon', country: 'US' },
  { name: 'Copiague', state: 'New York', country: 'US' },
  { name: 'Coppell', state: 'Texas', country: 'US' },
  { name: 'Copperas Cove', state: 'Texas', country: 'US' },
  { name: 'Coral Gables', state: 'Florida', country: 'US' },
  { name: 'Coral Hills', state: 'Maryland', country: 'US' },
  { name: 'Coral Springs', state: 'Florida', country: 'US' },
  { name: 'Coral Terrace', state: 'Florida', country: 'US' },
  { name: 'Coralville', state: 'Iowa', country: 'US' },
  { name: 'Coram', state: 'New York', country: 'US' },
  { name: 'Coraopolis', state: 'Pennsylvania', country: 'US' },
  { name: 'Corbin', state: 'Kentucky', country: 'US' },
  { name: 'Corcoran', state: 'California', country: 'US' },
  { name: 'Cordele', state: 'Georgia', country: 'US' },
  { name: 'Corinth', state: 'Mississippi', country: 'US' },
  { name: 'Corinth', state: 'Texas', country: 'US' },
  { name: 'Cornelius', state: 'Oregon', country: 'US' },
  { name: 'Cornelius', state: 'North Carolina', country: 'US' },
  { name: 'Corning', state: 'New York', country: 'US' },
  { name: 'Corning', state: 'New York', country: 'US' },
  { name: 'Corning', state: 'California', country: 'US' },
  { name: 'Cornwall', state: 'New York', country: 'US' },
  { name: 'Corona', state: 'California', country: 'US' },
  { name: 'Coronado', state: 'California', country: 'US' },
  { name: 'Corpus Christi', state: 'Texas', country: 'US' },
  { name: 'Corrales', state: 'New Mexico', country: 'US' },
  { name: 'Corry', state: 'Pennsylvania', country: 'US' },
  { name: 'Corsicana', state: 'Texas', country: 'US' },
  { name: 'Corte Madera', state: 'California', country: 'US' },
  { name: 'Cortez', state: 'Colorado', country: 'US' },
  { name: 'Cortland', state: 'New York', country: 'US' },
  { name: 'Cortland', state: 'Ohio', country: 'US' },
  { name: 'Cortlandt', state: 'New York', country: 'US' },
  { name: 'Cortlandville', state: 'New York', country: 'US' },
  { name: 'Corvallis', state: 'Oregon', country: 'US' },
  { name: 'Coshocton', state: 'Ohio', country: 'US' },
  { name: 'Costa Mesa', state: 'California', country: 'US' },
  { name: 'Cotati', state: 'California', country: 'US' },
  { name: 'Coto de Caza', state: 'California', country: 'US' },
  { name: 'Cottage Grove', state: 'Minnesota', country: 'US' },
  { name: 'Cottage Grove', state: 'Oregon', country: 'US' },
  { name: 'Cottage Lake', state: 'Washington', country: 'US' },
  { name: 'Cottonwood', state: 'Arizona', country: 'US' },
  { name: 'Cottonwood Heights', state: 'Utah', country: 'US' },
  { name: 'Cottonwood West', state: 'Utah', country: 'US' },
  { name: 'Cottonwood-Verde Village', state: 'Arizona', country: 'US' },
  { name: 'Council Bluffs', state: 'Iowa', country: 'US' },
  { name: 'Country Club', state: 'California', country: 'US' },
  { name: 'Country Club', state: 'Florida', country: 'US' },
  { name: 'Country Club Estates', state: 'Georgia', country: 'US' },
  { name: 'Country Club Hills', state: 'Illinois', country: 'US' },
  { name: 'Country Walk', state: 'Florida', country: 'US' },
  { name: 'Covedale', state: 'Ohio', country: 'US' },
  { name: 'Coventry', state: 'Rhode Island', country: 'US' },
  { name: 'Coventry', state: 'Connecticut', country: 'US' },
  { name: 'Covina', state: 'California', country: 'US' },
  { name: 'Covington', state: 'Georgia', country: 'US' },
  { name: 'Covington', state: 'Kentucky', country: 'US' },
  { name: 'Covington', state: 'Louisiana', country: 'US' },
  { name: 'Covington', state: 'Washington', country: 'US' },
  { name: 'Covington', state: 'Virginia', country: 'US' },
  { name: 'Covington', state: 'Tennessee', country: 'US' },
  { name: 'Coweta', state: 'Oklahoma', country: 'US' },
  { name: 'Coxsackie', state: 'New York', country: 'US' },
  { name: 'Crafton', state: 'Pennsylvania', country: 'US' },
  { name: 'Craig', state: 'Colorado', country: 'US' },
  { name: 'Cranford', state: 'New Jersey', country: 'US' },
  { name: 'Cranston', state: 'Rhode Island', country: 'US' },
  { name: 'Crawford', state: 'New York', country: 'US' },
  { name: 'Crawfordsville', state: 'Indiana', country: 'US' },
  { name: 'Cresskill', state: 'New Jersey', country: 'US' },
  { name: 'Crest Hill', state: 'Illinois', country: 'US' },
  { name: 'Crestline', state: 'California', country: 'US' },
  { name: 'Creston', state: 'Iowa', country: 'US' },
  { name: 'Crestview', state: 'Florida', country: 'US' },
  { name: 'Crestwood', state: 'Illinois', country: 'US' },
  { name: 'Crestwood', state: 'Missouri', country: 'US' },
  { name: 'Crestwood Village', state: 'New Jersey', country: 'US' },
  { name: 'Crete', state: 'Nebraska', country: 'US' },
  { name: 'Crete', state: 'Illinois', country: 'US' },
  { name: 'Creve Coeur', state: 'Missouri', country: 'US' },
  { name: 'Crockett', state: 'Texas', country: 'US' },
  { name: 'Crofton', state: 'Maryland', country: 'US' },
  { name: 'Cromwell', state: 'Connecticut', country: 'US' },
  { name: 'Crookston', state: 'Minnesota', country: 'US' },
  { name: 'Cross Lanes', state: 'West Virginia', country: 'US' },
  { name: 'Crossett', state: 'Arkansas', country: 'US' },
  { name: 'Crossville', state: 'Tennessee', country: 'US' },
  { name: 'Croton-on-Hudson', state: 'New York', country: 'US' },
  { name: 'Crowley', state: 'Texas', country: 'US' },
  { name: 'Crowley', state: 'Louisiana', country: 'US' },
  { name: 'Crown Point', state: 'Indiana', country: 'US' },
  { name: 'Croydon', state: 'Pennsylvania', country: 'US' },
  { name: 'Crystal', state: 'Minnesota', country: 'US' },
  { name: 'Crystal City', state: 'Texas', country: 'US' },
  { name: 'Crystal Lake', state: 'Illinois', country: 'US' },
  { name: 'Cudahy', state: 'California', country: 'US' },
  { name: 'Cudahy', state: 'Wisconsin', country: 'US' },
  { name: 'Cuero', state: 'Texas', country: 'US' },
  { name: 'Cullman', state: 'Alabama', country: 'US' },
  { name: 'Culpeper', state: 'Virginia', country: 'US' },
  { name: 'Culver City', state: 'California', country: 'US' },
  { name: 'Cumberland', state: 'Maine', country: 'US' },
  { name: 'Cumberland', state: 'Maryland', country: 'US' },
  { name: 'Cumberland', state: 'Rhode Island', country: 'US' },
  { name: 'Cumberland Hill', state: 'Rhode Island', country: 'US' },
  { name: 'Cupertino', state: 'California', country: 'US' },
  { name: 'Cushing', state: 'Oklahoma', country: 'US' },
  { name: 'Cutler', state: 'Florida', country: 'US' },
  { name: 'Cutler Ridge', state: 'Florida', country: 'US' },
  { name: 'Cutlerville', state: 'Michigan', country: 'US' },
  { name: 'Cuyahoga Falls', state: 'Ohio', country: 'US' },
  { name: 'Cynthiana', state: 'Kentucky', country: 'US' },
  { name: 'Cypress', state: 'California', country: 'US' },
  { name: 'Cypress Gardens', state: 'Florida', country: 'US' },
  { name: 'Cypress Lake', state: 'Florida', country: 'US' },
  { name: 'D’Iberville', state: 'Mississippi', country: 'US' },
  { name: 'Dade City', state: 'Florida', country: 'US' },
  { name: 'Dale City', state: 'Virginia', country: 'US' },
  { name: 'Dalhart', state: 'Texas', country: 'US' },
  { name: 'Dallas', state: 'Texas', country: 'US' },
  { name: 'Dallas', state: 'Oregon', country: 'US' },
  { name: 'Dalton', state: 'Massachusetts', country: 'US' },
  { name: 'Dalton', state: 'Georgia', country: 'US' },
  { name: 'Daly City', state: 'California', country: 'US' },
  { name: 'Damascus', state: 'Maryland', country: 'US' },
  { name: 'Dana Point', state: 'California', country: 'US' },
  { name: 'Danbury', state: 'Connecticut', country: 'US' },
  { name: 'Danbury', state: 'Connecticut', country: 'US' },
  { name: 'Dania Beach', state: 'Florida', country: 'US' },
  { name: 'Danvers', state: 'Massachusetts', country: 'US' },
  { name: 'Danvers', state: 'Massachusetts', country: 'US' },
  { name: 'Danville', state: 'California', country: 'US' },
  { name: 'Danville', state: 'Kentucky', country: 'US' },
  { name: 'Danville', state: 'Indiana', country: 'US' },
  { name: 'Danville', state: 'Illinois', country: 'US' },
  { name: 'Danville', state: 'Virginia', country: 'US' },
  { name: 'Daphne', state: 'Alabama', country: 'US' },
  { name: 'Darby', state: 'Pennsylvania', country: 'US' },
  { name: 'Darby Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Darien', state: 'Connecticut', country: 'US' },
  { name: 'Darien', state: 'Connecticut', country: 'US' },
  { name: 'Darien', state: 'Illinois', country: 'US' },
  { name: 'Darlington', state: 'South Carolina', country: 'US' },
  { name: 'Darnestown', state: 'Maryland', country: 'US' },
  { name: 'Dartmouth', state: 'Massachusetts', country: 'US' },
  { name: 'Davenport', state: 'Iowa', country: 'US' },
  { name: 'Davidson', state: 'North Carolina', country: 'US' },
  { name: 'Davie', state: 'Florida', country: 'US' },
  { name: 'Davis', state: 'California', country: 'US' },
  { name: 'Dayton', state: 'Ohio', country: 'US' },
  { name: 'Dayton', state: 'New Jersey', country: 'US' },
  { name: 'Dayton', state: 'Tennessee', country: 'US' },
  { name: 'Daytona Beach', state: 'Florida', country: 'US' },
  { name: 'De Bary', state: 'Florida', country: 'US' },
  { name: 'De Land', state: 'Florida', country: 'US' },
  { name: 'De Pere', state: 'Wisconsin', country: 'US' },
  { name: 'De Ridder', state: 'Louisiana', country: 'US' },
  { name: 'De Soto', state: 'Missouri', country: 'US' },
  { name: 'De Witt', state: 'New York', country: 'US' },
  { name: 'Dearborn', state: 'Michigan', country: 'US' },
  { name: 'Dearborn Heights', state: 'Michigan', country: 'US' },
  { name: 'Decatur', state: 'Indiana', country: 'US' },
  { name: 'Decatur', state: 'Illinois', country: 'US' },
  { name: 'Decatur', state: 'Georgia', country: 'US' },
  { name: 'Decatur', state: 'Alabama', country: 'US' },
  { name: 'Decorah', state: 'Iowa', country: 'US' },
  { name: 'Dedham', state: 'Massachusetts', country: 'US' },
  { name: 'Dedham', state: 'Massachusetts', country: 'US' },
  { name: 'Deer Park', state: 'New York', country: 'US' },
  { name: 'Deer Park', state: 'Texas', country: 'US' },
  { name: 'Deerfield', state: 'Illinois', country: 'US' },
  { name: 'Deerfield Beach', state: 'Florida', country: 'US' },
  { name: 'Deerpark', state: 'New York', country: 'US' },
  { name: 'Defiance', state: 'Ohio', country: 'US' },
  { name: 'DeForest', state: 'Wisconsin', country: 'US' },
  { name: 'DeKalb', state: 'Illinois', country: 'US' },
  { name: 'Del Aire', state: 'California', country: 'US' },
  { name: 'Del City', state: 'Oklahoma', country: 'US' },
  { name: 'Del Rio', state: 'Texas', country: 'US' },
  { name: 'Delafield', state: 'Wisconsin', country: 'US' },
  { name: 'Delafield', state: 'Wisconsin', country: 'US' },
  { name: 'Delano', state: 'California', country: 'US' },
  { name: 'Delavan', state: 'Wisconsin', country: 'US' },
  { name: 'Delaware', state: 'Ohio', country: 'US' },
  { name: 'Delhi', state: 'California', country: 'US' },
  { name: 'Delmar', state: 'New York', country: 'US' },
  { name: 'Delphos', state: 'Ohio', country: 'US' },
  { name: 'Delray Beach', state: 'Florida', country: 'US' },
  { name: 'Delta', state: 'Colorado', country: 'US' },
  { name: 'Deltona', state: 'Florida', country: 'US' },
  { name: 'Deming', state: 'New Mexico', country: 'US' },
  { name: 'Demopolis', state: 'Alabama', country: 'US' },
  { name: 'Denham Springs', state: 'Louisiana', country: 'US' },
  { name: 'Denison', state: 'Iowa', country: 'US' },
  { name: 'Denison', state: 'Texas', country: 'US' },
  { name: 'Dennis', state: 'Massachusetts', country: 'US' },
  { name: 'Dent', state: 'Ohio', country: 'US' },
  { name: 'Denton', state: 'Texas', country: 'US' },
  { name: 'Dentsville', state: 'South Carolina', country: 'US' },
  { name: 'Denver', state: 'Colorado', country: 'US' },
  { name: 'Depew', state: 'New York', country: 'US' },
  { name: 'Derby', state: 'Colorado', country: 'US' },
  { name: 'Derby', state: 'Connecticut', country: 'US' },
  { name: 'Derby', state: 'Connecticut', country: 'US' },
  { name: 'Derby', state: 'Kansas', country: 'US' },
  { name: 'Derry', state: 'New Hampshire', country: 'US' },
  { name: 'Derry', state: 'New Hampshire', country: 'US' },
  { name: 'Des Moines', state: 'Iowa', country: 'US' },
  { name: 'Des Moines', state: 'Washington', country: 'US' },
  { name: 'Des Peres', state: 'Missouri', country: 'US' },
  { name: 'Des Plaines', state: 'Illinois', country: 'US' },
  { name: 'Desert Hot Springs', state: 'California', country: 'US' },
  { name: 'DeSoto', state: 'Texas', country: 'US' },
  { name: 'Destin', state: 'Florida', country: 'US' },
  { name: 'Destrehan', state: 'Louisiana', country: 'US' },
  { name: 'Detroit', state: 'Michigan', country: 'US' },
  { name: 'Detroit Lakes', state: 'Minnesota', country: 'US' },
  { name: 'Devils Lake', state: 'North Dakota', country: 'US' },
  { name: 'Dewey-Humboldt', state: 'Arizona', country: 'US' },
  { name: 'Dexter', state: 'Missouri', country: 'US' },
  { name: 'Diamond Bar', state: 'California', country: 'US' },
  { name: 'Dickinson', state: 'North Dakota', country: 'US' },
  { name: 'Dickinson', state: 'Texas', country: 'US' },
  { name: 'Dickson', state: 'Tennessee', country: 'US' },
  { name: 'Dickson City', state: 'Pennsylvania', country: 'US' },
  { name: 'Dighton', state: 'Massachusetts', country: 'US' },
  { name: 'Dillon', state: 'South Carolina', country: 'US' },
  { name: 'Dinuba', state: 'California', country: 'US' },
  { name: 'Discovery Bay', state: 'California', country: 'US' },
  { name: 'Dishman', state: 'Washington', country: 'US' },
  { name: 'Dix Hills', state: 'New York', country: 'US' },
  { name: 'Dixon', state: 'California', country: 'US' },
  { name: 'Dixon', state: 'Illinois', country: 'US' },
  { name: 'Dobbs Ferry', state: 'New York', country: 'US' },
  { name: 'Dock Junction', state: 'Georgia', country: 'US' },
  { name: 'Doctor Phillips', state: 'Florida', country: 'US' },
  { name: 'Dodge City', state: 'Kansas', country: 'US' },
  { name: 'Dolton', state: 'Illinois', country: 'US' },
  { name: 'Donaldsonville', state: 'Louisiana', country: 'US' },
  { name: 'Donna', state: 'Texas', country: 'US' },
  { name: 'Doral', state: 'Florida', country: 'US' },
  { name: 'Doraville', state: 'Georgia', country: 'US' },
  { name: 'Dormont', state: 'Pennsylvania', country: 'US' },
  { name: 'Dothan', state: 'Alabama', country: 'US' },
  { name: 'Douglas', state: 'Arizona', country: 'US' },
  { name: 'Douglas', state: 'Georgia', country: 'US' },
  { name: 'Douglas', state: 'Massachusetts', country: 'US' },
  { name: 'Douglasville', state: 'Georgia', country: 'US' },
  { name: 'Dover', state: 'Delaware', country: 'US' },
  { name: 'Dover', state: 'New Hampshire', country: 'US' },
  { name: 'Dover', state: 'New York', country: 'US' },
  { name: 'Dover', state: 'New Jersey', country: 'US' },
  { name: 'Dover', state: 'Ohio', country: 'US' },
  { name: 'Dowagiac', state: 'Michigan', country: 'US' },
  { name: 'Downers Grove', state: 'Illinois', country: 'US' },
  { name: 'Downey', state: 'California', country: 'US' },
  { name: 'Downingtown', state: 'Pennsylvania', country: 'US' },
  { name: 'Doylestown', state: 'Pennsylvania', country: 'US' },
  { name: 'Dracut', state: 'Massachusetts', country: 'US' },
  { name: 'Draper', state: 'Utah', country: 'US' },
  { name: 'Drexel Heights', state: 'Arizona', country: 'US' },
  { name: 'Drexel Hill', state: 'Pennsylvania', country: 'US' },
  { name: 'Druid Hills', state: 'Georgia', country: 'US' },
  { name: 'Dry Run', state: 'Ohio', country: 'US' },
  { name: 'Dryden', state: 'New York', country: 'US' },
  { name: 'Du Quoin', state: 'Illinois', country: 'US' },
  { name: 'Duarte', state: 'California', country: 'US' },
  { name: 'Dublin', state: 'California', country: 'US' },
  { name: 'Dublin', state: 'Georgia', country: 'US' },
  { name: 'Dublin', state: 'Ohio', country: 'US' },
  { name: 'DuBois', state: 'Pennsylvania', country: 'US' },
  { name: 'Dubuque', state: 'Iowa', country: 'US' },
  { name: 'Dudley', state: 'Massachusetts', country: 'US' },
  { name: 'Duluth', state: 'Minnesota', country: 'US' },
  { name: 'Duluth', state: 'Georgia', country: 'US' },
  { name: 'Dumas', state: 'Texas', country: 'US' },
  { name: 'Dumbarton', state: 'Virginia', country: 'US' },
  { name: 'Dumont', state: 'New Jersey', country: 'US' },
  { name: 'Dunbar', state: 'West Virginia', country: 'US' },
  { name: 'Duncan', state: 'Oklahoma', country: 'US' },
  { name: 'Duncanville', state: 'Texas', country: 'US' },
  { name: 'Dundalk', state: 'Maryland', country: 'US' },
  { name: 'Dunedin', state: 'Florida', country: 'US' },
  { name: 'Dunellen', state: 'New Jersey', country: 'US' },
  { name: 'Dunkirk', state: 'New York', country: 'US' },
  { name: 'Dunmore', state: 'Pennsylvania', country: 'US' },
  { name: 'Dunn', state: 'North Carolina', country: 'US' },
  { name: 'Dunn Loring', state: 'Virginia', country: 'US' },
  { name: 'Dunwoody', state: 'Georgia', country: 'US' },
  { name: 'Duquesne', state: 'Pennsylvania', country: 'US' },
  { name: 'Durango', state: 'Colorado', country: 'US' },
  { name: 'Durant', state: 'Oklahoma', country: 'US' },
  { name: 'Durham', state: 'Connecticut', country: 'US' },
  { name: 'Durham', state: 'North Carolina', country: 'US' },
  { name: 'Durham', state: 'New Hampshire', country: 'US' },
  { name: 'Durham', state: 'New Hampshire', country: 'US' },
  { name: 'Duxbury', state: 'Massachusetts', country: 'US' },
  { name: 'Dyer', state: 'Indiana', country: 'US' },
  { name: 'Dyersburg', state: 'Tennessee', country: 'US' },
  { name: 'Eagan', state: 'Minnesota', country: 'US' },
  { name: 'Eagle', state: 'Idaho', country: 'US' },
  { name: 'Eagle Mountain', state: 'Texas', country: 'US' },
  { name: 'Eagle Pass', state: 'Texas', country: 'US' },
  { name: 'Earlimart', state: 'California', country: 'US' },
  { name: 'Easley', state: 'South Carolina', country: 'US' },
  { name: 'East Alton', state: 'Illinois', country: 'US' },
  { name: 'East Aurora', state: 'New York', country: 'US' },
  { name: 'East Bethel', state: 'Minnesota', country: 'US' },
  { name: 'East Brainerd', state: 'Tennessee', country: 'US' },
  { name: 'East Bridgewater', state: 'Massachusetts', country: 'US' },
  { name: 'East Brunswick', state: 'New Jersey', country: 'US' },
  { name: 'East Chicago', state: 'Indiana', country: 'US' },
  { name: 'East Cleveland', state: 'Ohio', country: 'US' },
  { name: 'East Compton', state: 'California', country: 'US' },
  { name: 'East Falmouth', state: 'Massachusetts', country: 'US' },
  { name: 'East Fishkill', state: 'New York', country: 'US' },
  { name: 'East Foothills', state: 'California', country: 'US' },
  { name: 'East Glenville', state: 'New York', country: 'US' },
  { name: 'East Grand Forks', state: 'Minnesota', country: 'US' },
  { name: 'East Grand Rapids', state: 'Michigan', country: 'US' },
  { name: 'East Greenbush', state: 'New York', country: 'US' },
  { name: 'East Greenwich', state: 'Rhode Island', country: 'US' },
  { name: 'East Haddam', state: 'Connecticut', country: 'US' },
  { name: 'East Hampton', state: 'Connecticut', country: 'US' },
  { name: 'East Hampton', state: 'New York', country: 'US' },
  { name: 'East Hartford', state: 'Connecticut', country: 'US' },
  { name: 'East Hartford', state: 'Connecticut', country: 'US' },
  { name: 'East Haven', state: 'Connecticut', country: 'US' },
  { name: 'East Haven', state: 'Connecticut', country: 'US' },
  { name: 'East Hemet', state: 'California', country: 'US' },
  { name: 'East Highland Park', state: 'Virginia', country: 'US' },
  { name: 'East Hill-Meridian', state: 'Washington', country: 'US' },
  { name: 'East Hills', state: 'New York', country: 'US' },
  { name: 'East Islip', state: 'New York', country: 'US' },
  { name: 'East La Mirada', state: 'California', country: 'US' },
  { name: 'East Lake', state: 'Florida', country: 'US' },
  { name: 'East Lansing', state: 'Michigan', country: 'US' },
  { name: 'East Liverpool', state: 'Ohio', country: 'US' },
  { name: 'East Longmeadow', state: 'Massachusetts', country: 'US' },
  { name: 'East Los Angeles', state: 'California', country: 'US' },
  { name: 'East Lyme', state: 'Connecticut', country: 'US' },
  { name: 'East Massapequa', state: 'New York', country: 'US' },
  { name: 'East Meadow', state: 'New York', country: 'US' },
  { name: 'East Millcreek', state: 'Utah', country: 'US' },
  { name: 'East Moline', state: 'Illinois', country: 'US' },
  { name: 'East Norriton', state: 'Pennsylvania', country: 'US' },
  { name: 'East Northport', state: 'New York', country: 'US' },
  { name: 'East Orange', state: 'New Jersey', country: 'US' },
  { name: 'East Palo Alto', state: 'California', country: 'US' },
  { name: 'East Pasadena', state: 'California', country: 'US' },
  { name: 'East Patchogue', state: 'New York', country: 'US' },
  { name: 'East Peoria', state: 'Illinois', country: 'US' },
  { name: 'East Perrine', state: 'Florida', country: 'US' },
  { name: 'East Point', state: 'Georgia', country: 'US' },
  { name: 'East Porterville', state: 'California', country: 'US' },
  { name: 'East Providence', state: 'Rhode Island', country: 'US' },
  { name: 'East Renton Highlands', state: 'Washington', country: 'US' },
  { name: 'East Ridge', state: 'Tennessee', country: 'US' },
  { name: 'East Riverdale', state: 'Maryland', country: 'US' },
  { name: 'East Rochester', state: 'New York', country: 'US' },
  { name: 'East Rochester', state: 'New York', country: 'US' },
  { name: 'East Rockaway', state: 'New York', country: 'US' },
  { name: 'East Rutherford', state: 'New Jersey', country: 'US' },
  { name: 'East San Gabriel', state: 'California', country: 'US' },
  { name: 'East St. Louis', state: 'Illinois', country: 'US' },
  { name: 'East Stroudsburg', state: 'Pennsylvania', country: 'US' },
  { name: 'East Wenatchee Bench', state: 'Washington', country: 'US' },
  { name: 'East Windsor', state: 'Connecticut', country: 'US' },
  { name: 'East York', state: 'Pennsylvania', country: 'US' },
  { name: 'Eastchester', state: 'New York', country: 'US' },
  { name: 'Eastchester', state: 'New York', country: 'US' },
  { name: 'Easthampton', state: 'Massachusetts', country: 'US' },
  { name: 'Eastlake', state: 'Ohio', country: 'US' },
  { name: 'Easton', state: 'Pennsylvania', country: 'US' },
  { name: 'Easton', state: 'Maryland', country: 'US' },
  { name: 'Easton', state: 'Massachusetts', country: 'US' },
  { name: 'Easton', state: 'Connecticut', country: 'US' },
  { name: 'Eastpointe', state: 'Michigan', country: 'US' },
  { name: 'Eastwood', state: 'Michigan', country: 'US' },
  { name: 'Eaton', state: 'Ohio', country: 'US' },
  { name: 'Eatonton', state: 'Georgia', country: 'US' },
  { name: 'Eatontown', state: 'New Jersey', country: 'US' },
  { name: 'Eau Claire', state: 'Wisconsin', country: 'US' },
  { name: 'Echelon', state: 'New Jersey', country: 'US' },
  { name: 'Economy', state: 'Pennsylvania', country: 'US' },
  { name: 'Ecorse', state: 'Michigan', country: 'US' },
  { name: 'Eden', state: 'New York', country: 'US' },
  { name: 'Eden', state: 'North Carolina', country: 'US' },
  { name: 'Eden Isle', state: 'Louisiana', country: 'US' },
  { name: 'Eden Prairie', state: 'Minnesota', country: 'US' },
  { name: 'Edgemere', state: 'Maryland', country: 'US' },
  { name: 'Edgewater', state: 'New Jersey', country: 'US' },
  { name: 'Edgewater', state: 'Florida', country: 'US' },
  { name: 'Edgewood', state: 'Kentucky', country: 'US' },
  { name: 'Edgewood', state: 'Maryland', country: 'US' },
  { name: 'Edgewood', state: 'Washington', country: 'US' },
  { name: 'Edina', state: 'Minnesota', country: 'US' },
  { name: 'Edinboro', state: 'Pennsylvania', country: 'US' },
  { name: 'Edinburg', state: 'Texas', country: 'US' },
  { name: 'Edison', state: 'New Jersey', country: 'US' },
  { name: 'Edmond', state: 'Oklahoma', country: 'US' },
  { name: 'Edmonds', state: 'Washington', country: 'US' },
  { name: 'Edwards', state: 'Colorado', country: 'US' },
  { name: 'Edwardsville', state: 'Illinois', country: 'US' },
  { name: 'Effingham', state: 'Illinois', country: 'US' },
  { name: 'Eglin AFB', state: 'Florida', country: 'US' },
  { name: 'Egypt Lake-Leto', state: 'Florida', country: 'US' },
  { name: 'Eidson Road', state: 'Texas', country: 'US' },
  { name: 'El Cajon', state: 'California', country: 'US' },
  { name: 'El Campo', state: 'Texas', country: 'US' },
  { name: 'El Centro', state: 'California', country: 'US' },
  { name: 'El Cerrito', state: 'California', country: 'US' },
  { name: 'El Dorado', state: 'Arkansas', country: 'US' },
  { name: 'El Dorado', state: 'Kansas', country: 'US' },
  { name: 'El Dorado Hills', state: 'California', country: 'US' },
  { name: 'El Mirage', state: 'Arizona', country: 'US' },
  { name: 'El Monte', state: 'California', country: 'US' },
  { name: 'El Paso', state: 'Texas', country: 'US' },
  { name: 'El Paso de Robles', state: 'California', country: 'US' },
  { name: 'El Reno', state: 'Oklahoma', country: 'US' },
  { name: 'El Rio', state: 'California', country: 'US' },
  { name: 'El Segundo', state: 'California', country: 'US' },
  { name: 'El Sobrante', state: 'California', country: 'US' },
  { name: 'Elbridge', state: 'New York', country: 'US' },
  { name: 'Eldersburg', state: 'Maryland', country: 'US' },
  { name: 'Elfers', state: 'Florida', country: 'US' },
  { name: 'Elgin', state: 'Illinois', country: 'US' },
  { name: 'Elizabeth', state: 'New Jersey', country: 'US' },
  { name: 'Elizabeth City', state: 'North Carolina', country: 'US' },
  { name: 'Elizabethton', state: 'Tennessee', country: 'US' },
  { name: 'Elizabethtown', state: 'Pennsylvania', country: 'US' },
  { name: 'Elizabethtown', state: 'Kentucky', country: 'US' },
  { name: 'Elk City', state: 'Oklahoma', country: 'US' },
  { name: 'Elk Grove', state: 'California', country: 'US' },
  { name: 'Elk Grove Village', state: 'Illinois', country: 'US' },
  { name: 'Elk Plain', state: 'Washington', country: 'US' },
  { name: 'Elk River', state: 'Minnesota', country: 'US' },
  { name: 'Elkhart', state: 'Indiana', country: 'US' },
  { name: 'Elkhorn', state: 'Nebraska', country: 'US' },
  { name: 'Elkhorn', state: 'Wisconsin', country: 'US' },
  { name: 'Elkins', state: 'West Virginia', country: 'US' },
  { name: 'Elko', state: 'Nevada', country: 'US' },
  { name: 'Elkridge', state: 'Maryland', country: 'US' },
  { name: 'Elkton', state: 'Maryland', country: 'US' },
  { name: 'Ellensburg', state: 'Washington', country: 'US' },
  { name: 'Ellicott', state: 'New York', country: 'US' },
  { name: 'Ellicott City', state: 'Maryland', country: 'US' },
  { name: 'Ellington', state: 'Connecticut', country: 'US' },
  { name: 'Ellisville', state: 'Missouri', country: 'US' },
  { name: 'Ellsworth', state: 'Maine', country: 'US' },
  { name: 'Ellwood City', state: 'Pennsylvania', country: 'US' },
  { name: 'Elm Grove', state: 'Wisconsin', country: 'US' },
  { name: 'Elma', state: 'New York', country: 'US' },
  { name: 'Elmhurst', state: 'Illinois', country: 'US' },
  { name: 'Elmira', state: 'New York', country: 'US' },
  { name: 'Elmira', state: 'New York', country: 'US' },
  { name: 'Elmont', state: 'New York', country: 'US' },
  { name: 'Elmwood Park', state: 'New Jersey', country: 'US' },
  { name: 'Elmwood Park', state: 'Illinois', country: 'US' },
  { name: 'Elon College', state: 'North Carolina', country: 'US' },
  { name: 'Eloy', state: 'Arizona', country: 'US' },
  { name: 'Elsmere', state: 'Kentucky', country: 'US' },
  { name: 'Elwood', state: 'Indiana', country: 'US' },
  { name: 'Elwood', state: 'New York', country: 'US' },
  { name: 'Elyria', state: 'Ohio', country: 'US' },
  { name: 'Emerson', state: 'New Jersey', country: 'US' },
  { name: 'Emeryville', state: 'California', country: 'US' },
  { name: 'Emmaus', state: 'Pennsylvania', country: 'US' },
  { name: 'Emporia', state: 'Kansas', country: 'US' },
  { name: 'Encinitas', state: 'California', country: 'US' },
  { name: 'Endicott', state: 'New York', country: 'US' },
  { name: 'Endwell', state: 'New York', country: 'US' },
  { name: 'Enfield', state: 'Connecticut', country: 'US' },
  { name: 'Englewood', state: 'Colorado', country: 'US' },
  { name: 'Englewood', state: 'Florida', country: 'US' },
  { name: 'Englewood', state: 'New Jersey', country: 'US' },
  { name: 'Englewood', state: 'Ohio', country: 'US' },
  { name: 'Enid', state: 'Oklahoma', country: 'US' },
  { name: 'Ennis', state: 'Texas', country: 'US' },
  { name: 'Ensley', state: 'Florida', country: 'US' },
  { name: 'Enterprise', state: 'Alabama', country: 'US' },
  { name: 'Enterprise', state: 'Nevada', country: 'US' },
  { name: 'Enumclaw', state: 'Washington', country: 'US' },
  { name: 'Ephrata', state: 'Washington', country: 'US' },
  { name: 'Ephrata', state: 'Pennsylvania', country: 'US' },
  { name: 'Erie', state: 'Pennsylvania', country: 'US' },
  { name: 'Erie', state: 'Colorado', country: 'US' },
  { name: 'Erlanger', state: 'Kentucky', country: 'US' },
  { name: 'Erlton-Ellisburg', state: 'New Jersey', country: 'US' },
  { name: 'Erwin', state: 'New York', country: 'US' },
  { name: 'Escanaba', state: 'Michigan', country: 'US' },
  { name: 'Escondido', state: 'California', country: 'US' },
  { name: 'Esopus', state: 'New York', country: 'US' },
  { name: 'Espanola', state: 'New Mexico', country: 'US' },
  { name: 'Essex', state: 'Maryland', country: 'US' },
  { name: 'Essex', state: 'Connecticut', country: 'US' },
  { name: 'Essex', state: 'Vermont', country: 'US' },
  { name: 'Essex Junction', state: 'Vermont', country: 'US' },
  { name: 'Estelle', state: 'Louisiana', country: 'US' },
  { name: 'Estero', state: 'Florida', country: 'US' },
  { name: 'Estherville', state: 'Iowa', country: 'US' },
  { name: 'Euclid', state: 'Ohio', country: 'US' },
  { name: 'Eufaula', state: 'Alabama', country: 'US' },
  { name: 'Eugene', state: 'Oregon', country: 'US' },
  { name: 'Euless', state: 'Texas', country: 'US' },
  { name: 'Eunice', state: 'Louisiana', country: 'US' },
  { name: 'Eureka', state: 'California', country: 'US' },
  { name: 'Eureka', state: 'Missouri', country: 'US' },
  { name: 'Eustis', state: 'Florida', country: 'US' },
  { name: 'Evans', state: 'Georgia', country: 'US' },
  { name: 'Evans', state: 'Colorado', country: 'US' },
  { name: 'Evans', state: 'New York', country: 'US' },
  { name: 'Evanston', state: 'Illinois', country: 'US' },
  { name: 'Evanston', state: 'Wyoming', country: 'US' },
  { name: 'Evansville', state: 'Indiana', country: 'US' },
  { name: 'Everett', state: 'Massachusetts', country: 'US' },
  { name: 'Everett', state: 'Washington', country: 'US' },
  { name: 'Evergreen', state: 'Montana', country: 'US' },
  { name: 'Evergreen', state: 'Colorado', country: 'US' },
  { name: 'Evergreen Park', state: 'Illinois', country: 'US' },
  { name: 'Ewa Beach', state: 'Hawaii', country: 'US' },
  { name: 'Ewing', state: 'New Jersey', country: 'US' },
  { name: 'Excelsior Springs', state: 'Missouri', country: 'US' },
  { name: 'Exeter', state: 'New Hampshire', country: 'US' },
  { name: 'Exeter', state: 'New Hampshire', country: 'US' },
  { name: 'Exeter', state: 'California', country: 'US' },
  { name: 'Exeter', state: 'Rhode Island', country: 'US' },
  { name: 'Fabens', state: 'Texas', country: 'US' },
  { name: 'Fair Lawn', state: 'New Jersey', country: 'US' },
  { name: 'Fair Oaks', state: 'California', country: 'US' },
  { name: 'Fair Oaks', state: 'Georgia', country: 'US' },
  { name: 'Fair Plain', state: 'Michigan', country: 'US' },
  { name: 'Fairbanks', state: 'Alaska', country: 'US' },
  { name: 'Fairborn', state: 'Ohio', country: 'US' },
  { name: 'Fairdale', state: 'Kentucky', country: 'US' },
  { name: 'Fairfax', state: 'California', country: 'US' },
  { name: 'Fairfax', state: 'Virginia', country: 'US' },
  { name: 'Fairfield', state: 'Ohio', country: 'US' },
  { name: 'Fairfield', state: 'New Jersey', country: 'US' },
  { name: 'Fairfield', state: 'California', country: 'US' },
  { name: 'Fairfield', state: 'Alabama', country: 'US' },
  { name: 'Fairfield', state: 'Connecticut', country: 'US' },
  { name: 'Fairfield', state: 'Iowa', country: 'US' },
  { name: 'Fairfield', state: 'Maine', country: 'US' },
  { name: 'Fairhaven', state: 'Massachusetts', country: 'US' },
  { name: 'Fairhope', state: 'Alabama', country: 'US' },
  { name: 'Fairland', state: 'Maryland', country: 'US' },
  { name: 'Fairlawn', state: 'Ohio', country: 'US' },
  { name: 'Fairless Hills', state: 'Pennsylvania', country: 'US' },
  { name: 'Fairmont', state: 'West Virginia', country: 'US' },
  { name: 'Fairmont', state: 'Minnesota', country: 'US' },
  { name: 'Fairmount', state: 'New York', country: 'US' },
  { name: 'Fairview', state: 'Oregon', country: 'US' },
  { name: 'Fairview', state: 'New Jersey', country: 'US' },
  { name: 'Fairview', state: 'California', country: 'US' },
  { name: 'Fairview', state: 'Georgia', country: 'US' },
  { name: 'Fairview Heights', state: 'Illinois', country: 'US' },
  { name: 'Fairview Park', state: 'Ohio', country: 'US' },
  { name: 'Fairview Shores', state: 'Florida', country: 'US' },
  { name: 'Fairwood', state: 'Washington', country: 'US' },
  { name: 'Fall River', state: 'Massachusetts', country: 'US' },
  { name: 'Fallbrook', state: 'California', country: 'US' },
  { name: 'Fallon', state: 'Nevada', country: 'US' },
  { name: 'Falls Church', state: 'Virginia', country: 'US' },
  { name: 'Fallsburg', state: 'New York', country: 'US' },
  { name: 'Fallston', state: 'Maryland', country: 'US' },
  { name: 'Falmouth', state: 'Maine', country: 'US' },
  { name: 'Falmouth', state: 'Massachusetts', country: 'US' },
  { name: 'Fanwood', state: 'New Jersey', country: 'US' },
  { name: 'Fargo', state: 'North Dakota', country: 'US' },
  { name: 'Faribault', state: 'Minnesota', country: 'US' },
  { name: 'Farmers Branch', state: 'Texas', country: 'US' },
  { name: 'Farmersville', state: 'California', country: 'US' },
  { name: 'Farmingdale', state: 'New York', country: 'US' },
  { name: 'Farmington', state: 'New Mexico', country: 'US' },
  { name: 'Farmington', state: 'New York', country: 'US' },
  { name: 'Farmington', state: 'Utah', country: 'US' },
  { name: 'Farmington', state: 'Minnesota', country: 'US' },
  { name: 'Farmington', state: 'Missouri', country: 'US' },
  { name: 'Farmington', state: 'Maine', country: 'US' },
  { name: 'Farmington', state: 'Michigan', country: 'US' },
  { name: 'Farmington', state: 'Connecticut', country: 'US' },
  { name: 'Farmington Hills', state: 'Michigan', country: 'US' },
  { name: 'Farmingville', state: 'New York', country: 'US' },
  { name: 'Farmville', state: 'Virginia', country: 'US' },
  { name: 'Farragut', state: 'Tennessee', country: 'US' },
  { name: 'Farrell', state: 'Pennsylvania', country: 'US' },
  { name: 'Fayetteville', state: 'North Carolina', country: 'US' },
  { name: 'Fayetteville', state: 'Tennessee', country: 'US' },
  { name: 'Fayetteville', state: 'Arkansas', country: 'US' },
  { name: 'Fayetteville', state: 'Georgia', country: 'US' },
  { name: 'Feasterville-Trevose', state: 'Pennsylvania', country: 'US' },
  { name: 'Federal Heights', state: 'Colorado', country: 'US' },
  { name: 'Federal Way', state: 'Washington', country: 'US' },
  { name: 'Fenton', state: 'New York', country: 'US' },
  { name: 'Fenton', state: 'Michigan', country: 'US' },
  { name: 'Fergus Falls', state: 'Minnesota', country: 'US' },
  { name: 'Ferguson', state: 'Missouri', country: 'US' },
  { name: 'Fern Creek', state: 'Kentucky', country: 'US' },
  { name: 'Fern Park', state: 'Florida', country: 'US' },
  { name: 'Fernandina Beach', state: 'Florida', country: 'US' },
  { name: 'Ferndale', state: 'Maryland', country: 'US' },
  { name: 'Ferndale', state: 'Michigan', country: 'US' },
  { name: 'Ferndale', state: 'Washington', country: 'US' },
  { name: 'Fernley', state: 'Nevada', country: 'US' },
  { name: 'Fernway', state: 'Pennsylvania', country: 'US' },
  { name: 'Ferry Pass', state: 'Florida', country: 'US' },
  { name: 'Festus', state: 'Missouri', country: 'US' },
  { name: 'Fillmore', state: 'California', country: 'US' },
  { name: 'Findlay', state: 'Ohio', country: 'US' },
  { name: 'Finneytown', state: 'Ohio', country: 'US' },
  { name: 'Fishers', state: 'Indiana', country: 'US' },
  { name: 'Fishkill', state: 'New York', country: 'US' },
  { name: 'Fitchburg', state: 'Wisconsin', country: 'US' },
  { name: 'Fitchburg', state: 'Massachusetts', country: 'US' },
  { name: 'Fitzgerald', state: 'Georgia', country: 'US' },
  { name: 'Five Corners', state: 'Washington', country: 'US' },
  { name: 'Five Forks', state: 'South Carolina', country: 'US' },
  { name: 'Flagstaff', state: 'Arizona', country: 'US' },
  { name: 'Flat Rock', state: 'Michigan', country: 'US' },
  { name: 'Flatwoods', state: 'Kentucky', country: 'US' },
  { name: 'Flint', state: 'Michigan', country: 'US' },
  { name: 'Floral Park', state: 'New York', country: 'US' },
  { name: 'Florence', state: 'South Carolina', country: 'US' },
  { name: 'Florence', state: 'Oregon', country: 'US' },
  { name: 'Florence', state: 'Kentucky', country: 'US' },
  { name: 'Florence', state: 'Arizona', country: 'US' },
  { name: 'Florence', state: 'Alabama', country: 'US' },
  { name: 'Florence-Graham', state: 'California', country: 'US' },
  { name: 'Florence-Roebling', state: 'New Jersey', country: 'US' },
  { name: 'Florham Park', state: 'New Jersey', country: 'US' },
  { name: 'Florida City', state: 'Florida', country: 'US' },
  { name: 'Florida Ridge', state: 'Florida', country: 'US' },
  { name: 'Florin', state: 'California', country: 'US' },
  { name: 'Florissant', state: 'Missouri', country: 'US' },
  { name: 'Flossmoor', state: 'Illinois', country: 'US' },
  { name: 'Flower Mound', state: 'Texas', country: 'US' },
  { name: 'Flowing Wells', state: 'Arizona', country: 'US' },
  { name: 'Flushing', state: 'Michigan', country: 'US' },
  { name: 'Folcroft', state: 'Pennsylvania', country: 'US' },
  { name: 'Foley', state: 'Alabama', country: 'US' },
  { name: 'Folsom', state: 'California', country: 'US' },
  { name: 'Folsom', state: 'Pennsylvania', country: 'US' },
  { name: 'Fond du Lac', state: 'Wisconsin', country: 'US' },
  { name: 'Fontana', state: 'California', country: 'US' },
  { name: 'Foothill Farms', state: 'California', country: 'US' },
  { name: 'Foothill Ranch', state: 'California', country: 'US' },
  { name: 'Fords', state: 'New Jersey', country: 'US' },
  { name: 'Forest', state: 'Virginia', country: 'US' },
  { name: 'Forest Acres', state: 'South Carolina', country: 'US' },
  { name: 'Forest City', state: 'North Carolina', country: 'US' },
  { name: 'Forest City', state: 'Florida', country: 'US' },
  { name: 'Forest Glen', state: 'Maryland', country: 'US' },
  { name: 'Forest Grove', state: 'Oregon', country: 'US' },
  { name: 'Forest Hill', state: 'Texas', country: 'US' },
  { name: 'Forest Hills', state: 'Pennsylvania', country: 'US' },
  { name: 'Forest Hills', state: 'Michigan', country: 'US' },
  { name: 'Forest Lake', state: 'Minnesota', country: 'US' },
  { name: 'Forest Park', state: 'Illinois', country: 'US' },
  { name: 'Forest Park', state: 'Georgia', country: 'US' },
  { name: 'Forest Park', state: 'Ohio', country: 'US' },
  { name: 'Forestdale', state: 'Alabama', country: 'US' },
  { name: 'Forestville', state: 'Maryland', country: 'US' },
  { name: 'Forestville', state: 'Ohio', country: 'US' },
  { name: 'Forrest City', state: 'Arkansas', country: 'US' },
  { name: 'Fort Ann', state: 'New York', country: 'US' },
  { name: 'Fort Atkinson', state: 'Wisconsin', country: 'US' },
  { name: 'Fort Belvoir', state: 'Virginia', country: 'US' },
  { name: 'Fort Benning South', state: 'Georgia', country: 'US' },
  { name: 'Fort Bliss', state: 'Texas', country: 'US' },
  { name: 'Fort Bragg', state: 'North Carolina', country: 'US' },
  { name: 'Fort Bragg', state: 'California', country: 'US' },
  { name: 'Fort Campbell North', state: 'Kentucky', country: 'US' },
  { name: 'Fort Carson', state: 'Colorado', country: 'US' },
  { name: 'Fort Collins', state: 'Colorado', country: 'US' },
  { name: 'Fort Dix', state: 'New Jersey', country: 'US' },
  { name: 'Fort Dodge', state: 'Iowa', country: 'US' },
  { name: 'Fort Drum', state: 'New York', country: 'US' },
  { name: 'Fort Hood', state: 'Texas', country: 'US' },
  { name: 'Fort Hunt', state: 'Virginia', country: 'US' },
  { name: 'Fort Knox', state: 'Kentucky', country: 'US' },
  { name: 'Fort Lauderdale', state: 'Florida', country: 'US' },
  { name: 'Fort Lee', state: 'New Jersey', country: 'US' },
  { name: 'Fort Lee', state: 'Virginia', country: 'US' },
  { name: 'Fort Leonard Wood', state: 'Missouri', country: 'US' },
  { name: 'Fort Lewis', state: 'Washington', country: 'US' },
  { name: 'Fort Lupton', state: 'Colorado', country: 'US' },
  { name: 'Fort Madison', state: 'Iowa', country: 'US' },
  { name: 'Fort Meade', state: 'Maryland', country: 'US' },
  { name: 'Fort Mill', state: 'South Carolina', country: 'US' },
  { name: 'Fort Mitchell', state: 'Kentucky', country: 'US' },
  { name: 'Fort Morgan', state: 'Colorado', country: 'US' },
  { name: 'Fort Myers', state: 'Florida', country: 'US' },
  { name: 'Fort Myers Beach', state: 'Florida', country: 'US' },
  { name: 'Fort Oglethorpe', state: 'Georgia', country: 'US' },
  { name: 'Fort Payne', state: 'Alabama', country: 'US' },
  { name: 'Fort Pierce', state: 'Florida', country: 'US' },
  { name: 'Fort Pierce North', state: 'Florida', country: 'US' },
  { name: 'Fort Polk South', state: 'Louisiana', country: 'US' },
  { name: 'Fort Riley North', state: 'Kansas', country: 'US' },
  { name: 'Fort Rucker', state: 'Alabama', country: 'US' },
  { name: 'Fort Salonga', state: 'New York', country: 'US' },
  { name: 'Fort Scott', state: 'Kansas', country: 'US' },
  { name: 'Fort Smith', state: 'Arkansas', country: 'US' },
  { name: 'Fort Stewart', state: 'Georgia', country: 'US' },
  { name: 'Fort Stockton', state: 'Texas', country: 'US' },
  { name: 'Fort Thomas', state: 'Kentucky', country: 'US' },
  { name: 'Fort Valley', state: 'Georgia', country: 'US' },
  { name: 'Fort Walton Beach', state: 'Florida', country: 'US' },
  { name: 'Fort Washington', state: 'Maryland', country: 'US' },
  { name: 'Fort Wayne', state: 'Indiana', country: 'US' },
  { name: 'Fort Worth', state: 'Texas', country: 'US' },
  { name: 'Fortuna', state: 'California', country: 'US' },
  { name: 'Fortuna Foothills', state: 'Arizona', country: 'US' },
  { name: 'Foster City', state: 'California', country: 'US' },
  { name: 'Fostoria', state: 'Ohio', country: 'US' },
  { name: 'Fountain', state: 'Colorado', country: 'US' },
  { name: 'Fountain Hills', state: 'Arizona', country: 'US' },
  { name: 'Fountain Inn', state: 'South Carolina', country: 'US' },
  { name: 'Fountain Valley', state: 'California', country: 'US' },
  { name: 'Fountainbleau', state: 'Florida', country: 'US' },
  { name: 'Four Corners', state: 'Oregon', country: 'US' },
  { name: 'Fox Lake', state: 'Illinois', country: 'US' },
  { name: 'Fox Point', state: 'Wisconsin', country: 'US' },
  { name: 'Foxborough', state: 'Massachusetts', country: 'US' },
  { name: 'Framingham', state: 'Massachusetts', country: 'US' },
  { name: 'Framingham', state: 'Massachusetts', country: 'US' },
  { name: 'Franconia', state: 'Virginia', country: 'US' },
  { name: 'Frankfort', state: 'New York', country: 'US' },
  { name: 'Frankfort', state: 'Indiana', country: 'US' },
  { name: 'Frankfort', state: 'Kentucky', country: 'US' },
  { name: 'Frankfort', state: 'Illinois', country: 'US' },
  { name: 'Frankfort Square', state: 'Illinois', country: 'US' },
  { name: 'Franklin', state: 'Kentucky', country: 'US' },
  { name: 'Franklin', state: 'Indiana', country: 'US' },
  { name: 'Franklin', state: 'Louisiana', country: 'US' },
  { name: 'Franklin', state: 'Massachusetts', country: 'US' },
  { name: 'Franklin', state: 'New Hampshire', country: 'US' },
  { name: 'Franklin', state: 'Ohio', country: 'US' },
  { name: 'Franklin', state: 'Virginia', country: 'US' },
  { name: 'Franklin', state: 'Pennsylvania', country: 'US' },
  { name: 'Franklin', state: 'Wisconsin', country: 'US' },
  { name: 'Franklin', state: 'Tennessee', country: 'US' },
  { name: 'Franklin Lakes', state: 'New Jersey', country: 'US' },
  { name: 'Franklin Park', state: 'Illinois', country: 'US' },
  { name: 'Franklin Park', state: 'Pennsylvania', country: 'US' },
  { name: 'Franklin Square', state: 'New York', country: 'US' },
  { name: 'Fraser', state: 'Michigan', country: 'US' },
  { name: 'Frederick', state: 'Maryland', country: 'US' },
  { name: 'Fredericksburg', state: 'Virginia', country: 'US' },
  { name: 'Fredericksburg', state: 'Texas', country: 'US' },
  { name: 'Fredonia', state: 'New York', country: 'US' },
  { name: 'Freehold', state: 'New Jersey', country: 'US' },
  { name: 'Freeport', state: 'Maine', country: 'US' },
  { name: 'Freeport', state: 'Illinois', country: 'US' },
  { name: 'Freeport', state: 'New York', country: 'US' },
  { name: 'Freeport', state: 'Texas', country: 'US' },
  { name: 'Freetown', state: 'Massachusetts', country: 'US' },
  { name: 'Fremont', state: 'California', country: 'US' },
  { name: 'Fremont', state: 'Ohio', country: 'US' },
  { name: 'Fremont', state: 'Nebraska', country: 'US' },
  { name: 'Fresno', state: 'Texas', country: 'US' },
  { name: 'Fresno', state: 'California', country: 'US' },
  { name: 'Fridley', state: 'Minnesota', country: 'US' },
  { name: 'Friendly', state: 'Maryland', country: 'US' },
  { name: 'Friendswood', state: 'Texas', country: 'US' },
  { name: 'Frisco', state: 'Texas', country: 'US' },
  { name: 'Front Royal', state: 'Virginia', country: 'US' },
  { name: 'Frostburg', state: 'Maryland', country: 'US' },
  { name: 'Fruit Cove', state: 'Florida', country: 'US' },
  { name: 'Fruita', state: 'Colorado', country: 'US' },
  { name: 'Fruitvale', state: 'Colorado', country: 'US' },
  { name: 'Fruitville', state: 'Florida', country: 'US' },
  { name: 'Fullerton', state: 'California', country: 'US' },
  { name: 'Fullerton', state: 'Pennsylvania', country: 'US' },
  { name: 'Fulton', state: 'New York', country: 'US' },
  { name: 'Fulton', state: 'Missouri', country: 'US' },
  { name: 'Fultondale', state: 'Alabama', country: 'US' },
  { name: 'Fuquay-Varina', state: 'North Carolina', country: 'US' },
  { name: 'Gadsden', state: 'Alabama', country: 'US' },
  { name: 'Gaffney', state: 'South Carolina', country: 'US' },
  { name: 'Gages Lake', state: 'Illinois', country: 'US' },
  { name: 'Gahanna', state: 'Ohio', country: 'US' },
  { name: 'Gainesville', state: 'Texas', country: 'US' },
  { name: 'Gainesville', state: 'Georgia', country: 'US' },
  { name: 'Gainesville', state: 'Florida', country: 'US' },
  { name: 'Gaithersburg', state: 'Maryland', country: 'US' },
  { name: 'Galax', state: 'Virginia', country: 'US' },
  { name: 'Galena Park', state: 'Texas', country: 'US' },
  { name: 'Galesburg', state: 'Illinois', country: 'US' },
  { name: 'Galion', state: 'Ohio', country: 'US' },
  { name: 'Gallatin', state: 'Tennessee', country: 'US' },
  { name: 'Galliano', state: 'Louisiana', country: 'US' },
  { name: 'Gallup', state: 'New Mexico', country: 'US' },
  { name: 'Galt', state: 'California', country: 'US' },
  { name: 'Galveston', state: 'Texas', country: 'US' },
  { name: 'Gantt', state: 'South Carolina', country: 'US' },
  { name: 'Garden Acres', state: 'California', country: 'US' },
  { name: 'Garden City', state: 'Georgia', country: 'US' },
  { name: 'Garden City', state: 'Kansas', country: 'US' },
  { name: 'Garden City', state: 'Idaho', country: 'US' },
  { name: 'Garden City', state: 'Michigan', country: 'US' },
  { name: 'Garden City', state: 'South Carolina', country: 'US' },
  { name: 'Garden City', state: 'New York', country: 'US' },
  { name: 'Garden City Park', state: 'New York', country: 'US' },
  { name: 'Garden Grove', state: 'California', country: 'US' },
  { name: 'Garden Home-Whitford', state: 'Oregon', country: 'US' },
  { name: 'Gardena', state: 'California', country: 'US' },
  { name: 'Gardendale', state: 'Alabama', country: 'US' },
  { name: 'Gardere', state: 'Louisiana', country: 'US' },
  { name: 'Gardiner', state: 'Maine', country: 'US' },
  { name: 'Gardner', state: 'Kansas', country: 'US' },
  { name: 'Gardner', state: 'Massachusetts', country: 'US' },
  { name: 'Gardnerville Ranchos', state: 'Nevada', country: 'US' },
  { name: 'Garfield', state: 'New Jersey', country: 'US' },
  { name: 'Garfield Heights', state: 'Ohio', country: 'US' },
  { name: 'Garland', state: 'Texas', country: 'US' },
  { name: 'Garner', state: 'North Carolina', country: 'US' },
  { name: 'Garrison', state: 'Maryland', country: 'US' },
  { name: 'Gary', state: 'Indiana', country: 'US' },
  { name: 'Gastonia', state: 'North Carolina', country: 'US' },
  { name: 'Gates', state: 'New York', country: 'US' },
  { name: 'Gates-North Gates', state: 'New York', country: 'US' },
  { name: 'Gatesville', state: 'Texas', country: 'US' },
  { name: 'Gautier', state: 'Mississippi', country: 'US' },
  { name: 'Geddes', state: 'New York', country: 'US' },
  { name: 'Genesee', state: 'Wisconsin', country: 'US' },
  { name: 'Geneseo', state: 'New York', country: 'US' },
  { name: 'Geneseo', state: 'New York', country: 'US' },
  { name: 'Geneseo', state: 'Illinois', country: 'US' },
  { name: 'Geneva', state: 'Illinois', country: 'US' },
  { name: 'Geneva', state: 'New York', country: 'US' },
  { name: 'Geneva', state: 'Ohio', country: 'US' },
  { name: 'Georgetown', state: 'Texas', country: 'US' },
  { name: 'Georgetown', state: 'South Carolina', country: 'US' },
  { name: 'Georgetown', state: 'Georgia', country: 'US' },
  { name: 'Georgetown', state: 'Massachusetts', country: 'US' },
  { name: 'Georgetown', state: 'Kentucky', country: 'US' },
  { name: 'Georgetown County', state: 'South Carolina', country: 'US' },
  { name: 'Gering', state: 'Nebraska', country: 'US' },
  { name: 'German Flatts', state: 'New York', country: 'US' },
  { name: 'Germantown', state: 'Tennessee', country: 'US' },
  { name: 'Germantown', state: 'Wisconsin', country: 'US' },
  { name: 'Germantown', state: 'Maryland', country: 'US' },
  { name: 'Gettysburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Gibsonton', state: 'Florida', country: 'US' },
  { name: 'Gifford', state: 'Florida', country: 'US' },
  { name: 'Gig Harbor', state: 'Washington', country: 'US' },
  { name: 'Gilbert', state: 'Arizona', country: 'US' },
  { name: 'Gilford', state: 'New Hampshire', country: 'US' },
  { name: 'Gillette', state: 'Wyoming', country: 'US' },
  { name: 'Gilroy', state: 'California', country: 'US' },
  { name: 'Girard', state: 'Ohio', country: 'US' },
  { name: 'Gladeview', state: 'Florida', country: 'US' },
  { name: 'Gladewater', state: 'Texas', country: 'US' },
  { name: 'Gladstone', state: 'Oregon', country: 'US' },
  { name: 'Gladstone', state: 'Missouri', country: 'US' },
  { name: 'Glasgow', state: 'Kentucky', country: 'US' },
  { name: 'Glasgow', state: 'Delaware', country: 'US' },
  { name: 'Glassboro', state: 'New Jersey', country: 'US' },
  { name: 'Glastonbury', state: 'Connecticut', country: 'US' },
  { name: 'Glastonbury Center', state: 'Connecticut', country: 'US' },
  { name: 'Glen Allen', state: 'Virginia', country: 'US' },
  { name: 'Glen Avon', state: 'California', country: 'US' },
  { name: 'Glen Burnie', state: 'Maryland', country: 'US' },
  { name: 'Glen Carbon', state: 'Illinois', country: 'US' },
  { name: 'Glen Cove', state: 'New York', country: 'US' },
  { name: 'Glen Ellyn', state: 'Illinois', country: 'US' },
  { name: 'Glen Ridge', state: 'New Jersey', country: 'US' },
  { name: 'Glen Rock', state: 'New Jersey', country: 'US' },
  { name: 'Glenarden', state: 'Maryland', country: 'US' },
  { name: 'Glencoe', state: 'Illinois', country: 'US' },
  { name: 'Glendale', state: 'California', country: 'US' },
  { name: 'Glendale', state: 'Arizona', country: 'US' },
  { name: 'Glendale', state: 'Wisconsin', country: 'US' },
  { name: 'Glendale Heights', state: 'Illinois', country: 'US' },
  { name: 'Glendora', state: 'California', country: 'US' },
  { name: 'Glenn Dale', state: 'Maryland', country: 'US' },
  { name: 'Glenn Heights', state: 'Texas', country: 'US' },
  { name: 'Glenolden', state: 'Pennsylvania', country: 'US' },
  { name: 'Glenpool', state: 'Oklahoma', country: 'US' },
  { name: 'Glens Falls', state: 'New York', country: 'US' },
  { name: 'Glens Falls North', state: 'New York', country: 'US' },
  { name: 'Glenside', state: 'Pennsylvania', country: 'US' },
  { name: 'Glenvar Heights', state: 'Florida', country: 'US' },
  { name: 'Glenview', state: 'Illinois', country: 'US' },
  { name: 'Glenville', state: 'New York', country: 'US' },
  { name: 'Glenwood', state: 'Illinois', country: 'US' },
  { name: 'Glenwood Springs', state: 'Colorado', country: 'US' },
  { name: 'Globe', state: 'Arizona', country: 'US' },
  { name: 'Glocester', state: 'Rhode Island', country: 'US' },
  { name: 'Gloucester', state: 'Massachusetts', country: 'US' },
  { name: 'Gloucester City', state: 'New Jersey', country: 'US' },
  { name: 'Gloucester Point', state: 'Virginia', country: 'US' },
  { name: 'Gloversville', state: 'New York', country: 'US' },
  { name: 'Godfrey', state: 'Illinois', country: 'US' },
  { name: 'Goffstown', state: 'New Hampshire', country: 'US' },
  { name: 'Gold Camp', state: 'Arizona', country: 'US' },
  { name: 'Gold River', state: 'California', country: 'US' },
  { name: 'Golden', state: 'Colorado', country: 'US' },
  { name: 'Golden Gate', state: 'Florida', country: 'US' },
  { name: 'Golden Glades', state: 'Florida', country: 'US' },
  { name: 'Golden Hills', state: 'California', country: 'US' },
  { name: 'Golden Lakes', state: 'Florida', country: 'US' },
  { name: 'Golden Valley', state: 'Minnesota', country: 'US' },
  { name: 'Goldenrod', state: 'Florida', country: 'US' },
  { name: 'Goldsboro', state: 'North Carolina', country: 'US' },
  { name: 'Goleta', state: 'California', country: 'US' },
  { name: 'Gonzales', state: 'California', country: 'US' },
  { name: 'Gonzales', state: 'Louisiana', country: 'US' },
  { name: 'Gonzales', state: 'Texas', country: 'US' },
  { name: 'Gonzalez', state: 'Florida', country: 'US' },
  { name: 'Goodings Grove', state: 'Illinois', country: 'US' },
  { name: 'Goodlettsville', state: 'Tennessee', country: 'US' },
  { name: 'Goodyear', state: 'Arizona', country: 'US' },
  { name: 'Goose Creek', state: 'South Carolina', country: 'US' },
  { name: 'Gorham', state: 'Maine', country: 'US' },
  { name: 'Goshen', state: 'Indiana', country: 'US' },
  { name: 'Goshen', state: 'New York', country: 'US' },
  { name: 'Goulds', state: 'Florida', country: 'US' },
  { name: 'Gouverneur', state: 'New York', country: 'US' },
  { name: 'Grafton', state: 'Massachusetts', country: 'US' },
  { name: 'Grafton', state: 'Wisconsin', country: 'US' },
  { name: 'Graham', state: 'Washington', country: 'US' },
  { name: 'Graham', state: 'North Carolina', country: 'US' },
  { name: 'Graham', state: 'Texas', country: 'US' },
  { name: 'Granby', state: 'New York', country: 'US' },
  { name: 'Granby', state: 'Connecticut', country: 'US' },
  { name: 'Granby', state: 'Massachusetts', country: 'US' },
  { name: 'Grand Blanc', state: 'Michigan', country: 'US' },
  { name: 'Grand Chute', state: 'Wisconsin', country: 'US' },
  { name: 'Grand Forks', state: 'North Dakota', country: 'US' },
  { name: 'Grand Haven', state: 'Michigan', country: 'US' },
  { name: 'Grand Island', state: 'Nebraska', country: 'US' },
  { name: 'Grand Island', state: 'New York', country: 'US' },
  { name: 'Grand Junction', state: 'Colorado', country: 'US' },
  { name: 'Grand Ledge', state: 'Michigan', country: 'US' },
  { name: 'Grand Prairie', state: 'Texas', country: 'US' },
  { name: 'Grand Rapids', state: 'Wisconsin', country: 'US' },
  { name: 'Grand Rapids', state: 'Michigan', country: 'US' },
  { name: 'Grand Rapids', state: 'Minnesota', country: 'US' },
  { name: 'Grand Terrace', state: 'California', country: 'US' },
  { name: 'Grandview', state: 'Missouri', country: 'US' },
  { name: 'Grandview', state: 'Washington', country: 'US' },
  { name: 'Grandview Heights', state: 'Ohio', country: 'US' },
  { name: 'Grandville', state: 'Michigan', country: 'US' },
  { name: 'Granger', state: 'Indiana', country: 'US' },
  { name: 'Granite Bay', state: 'California', country: 'US' },
  { name: 'Granite City', state: 'Illinois', country: 'US' },
  { name: 'Grants', state: 'New Mexico', country: 'US' },
  { name: 'Grants Pass', state: 'Oregon', country: 'US' },
  { name: 'Grantsville', state: 'Utah', country: 'US' },
  { name: 'Granville', state: 'New York', country: 'US' },
  { name: 'Grapevine', state: 'Texas', country: 'US' },
  { name: 'Grass Valley', state: 'California', country: 'US' },
  { name: 'Gray', state: 'Maine', country: 'US' },
  { name: 'Grayslake', state: 'Illinois', country: 'US' },
  { name: 'Great Barrington', state: 'Massachusetts', country: 'US' },
  { name: 'Great Bend', state: 'Kansas', country: 'US' },
  { name: 'Great Falls', state: 'Montana', country: 'US' },
  { name: 'Great Falls', state: 'Virginia', country: 'US' },
  { name: 'Great Neck', state: 'New York', country: 'US' },
  { name: 'Great Neck Plaza', state: 'New York', country: 'US' },
  { name: 'Greater Carrollwood', state: 'Florida', country: 'US' },
  { name: 'Greater Landover', state: 'Maryland', country: 'US' },
  { name: 'Greater Northdale', state: 'Florida', country: 'US' },
  { name: 'Greater Sun Center', state: 'Florida', country: 'US' },
  { name: 'Greater Upper Marlboro', state: 'Maryland', country: 'US' },
  { name: 'Greatwood', state: 'Texas', country: 'US' },
  { name: 'Greece', state: 'New York', country: 'US' },
  { name: 'Greece', state: 'New York', country: 'US' },
  { name: 'Greeley', state: 'Colorado', country: 'US' },
  { name: 'Green', state: 'Ohio', country: 'US' },
  { name: 'Green', state: 'Oregon', country: 'US' },
  { name: 'Green Bay', state: 'Wisconsin', country: 'US' },
  { name: 'Green Haven', state: 'Maryland', country: 'US' },
  { name: 'Green Hill', state: 'Tennessee', country: 'US' },
  { name: 'Green River', state: 'Wyoming', country: 'US' },
  { name: 'Green Valley', state: 'Maryland', country: 'US' },
  { name: 'Green Valley', state: 'Arizona', country: 'US' },
  { name: 'Greenacres', state: 'Florida', country: 'US' },
  { name: 'Greenbelt', state: 'Maryland', country: 'US' },
  { name: 'Greenburgh', state: 'New York', country: 'US' },
  { name: 'Greencastle', state: 'Indiana', country: 'US' },
  { name: 'Greendale', state: 'Wisconsin', country: 'US' },
  { name: 'Greeneville', state: 'Tennessee', country: 'US' },
  { name: 'Greenfield', state: 'Wisconsin', country: 'US' },
  { name: 'Greenfield', state: 'New York', country: 'US' },
  { name: 'Greenfield', state: 'Indiana', country: 'US' },
  { name: 'Greenfield', state: 'Massachusetts', country: 'US' },
  { name: 'Greenfield', state: 'Massachusetts', country: 'US' },
  { name: 'Greenfield', state: 'California', country: 'US' },
  { name: 'Greenlawn', state: 'New York', country: 'US' },
  { name: 'Greensboro', state: 'North Carolina', country: 'US' },
  { name: 'Greensburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Greensburg', state: 'Indiana', country: 'US' },
  { name: 'Greentree', state: 'New Jersey', country: 'US' },
  { name: 'Greenville', state: 'Mississippi', country: 'US' },
  { name: 'Greenville', state: 'Michigan', country: 'US' },
  { name: 'Greenville', state: 'Alabama', country: 'US' },
  { name: 'Greenville', state: 'Illinois', country: 'US' },
  { name: 'Greenville', state: 'Pennsylvania', country: 'US' },
  { name: 'Greenville', state: 'Rhode Island', country: 'US' },
  { name: 'Greenville', state: 'Texas', country: 'US' },
  { name: 'Greenville', state: 'South Carolina', country: 'US' },
  { name: 'Greenville', state: 'North Carolina', country: 'US' },
  { name: 'Greenville', state: 'Ohio', country: 'US' },
  { name: 'Greenville', state: 'New York', country: 'US' },
  { name: 'Greenville', state: 'Wisconsin', country: 'US' },
  { name: 'Greenwich', state: 'Connecticut', country: 'US' },
  { name: 'Greenwood', state: 'Indiana', country: 'US' },
  { name: 'Greenwood', state: 'Mississippi', country: 'US' },
  { name: 'Greenwood', state: 'Arkansas', country: 'US' },
  { name: 'Greenwood', state: 'South Carolina', country: 'US' },
  { name: 'Greenwood Village', state: 'Colorado', country: 'US' },
  { name: 'Greer', state: 'South Carolina', country: 'US' },
  { name: 'Grenada', state: 'Mississippi', country: 'US' },
  { name: 'Gresham', state: 'Oregon', country: 'US' },
  { name: 'Gresham Park', state: 'Georgia', country: 'US' },
  { name: 'Gretna', state: 'Louisiana', country: 'US' },
  { name: 'Griffin', state: 'Georgia', country: 'US' },
  { name: 'Griffith', state: 'Indiana', country: 'US' },
  { name: 'Grinnell', state: 'Iowa', country: 'US' },
  { name: 'Griswold', state: 'Connecticut', country: 'US' },
  { name: 'Groesbeck', state: 'Ohio', country: 'US' },
  { name: 'Grosse Ile', state: 'Michigan', country: 'US' },
  { name: 'Grosse Pointe Farms', state: 'Michigan', country: 'US' },
  { name: 'Grosse Pointe Park', state: 'Michigan', country: 'US' },
  { name: 'Grosse Pointe Woods', state: 'Michigan', country: 'US' },
  { name: 'Groton', state: 'Massachusetts', country: 'US' },
  { name: 'Groton', state: 'Connecticut', country: 'US' },
  { name: 'Groton', state: 'Connecticut', country: 'US' },
  { name: 'Grove City', state: 'Ohio', country: 'US' },
  { name: 'Grove City', state: 'Pennsylvania', country: 'US' },
  { name: 'Groveland', state: 'Massachusetts', country: 'US' },
  { name: 'Grover Beach', state: 'California', country: 'US' },
  { name: 'Groves', state: 'Texas', country: 'US' },
  { name: 'Groveton', state: 'Virginia', country: 'US' },
  { name: 'Grovetown', state: 'Georgia', country: 'US' },
  { name: 'Guilderland', state: 'New York', country: 'US' },
  { name: 'Guilford', state: 'Connecticut', country: 'US' },
  { name: 'Gulf Gate Estates', state: 'Florida', country: 'US' },
  { name: 'Gulfport', state: 'Florida', country: 'US' },
  { name: 'Gulfport', state: 'Mississippi', country: 'US' },
  { name: 'Gunbarrel', state: 'Colorado', country: 'US' },
  { name: 'Guntersville', state: 'Alabama', country: 'US' },
  { name: 'Gurnee', state: 'Illinois', country: 'US' },
  { name: 'Guthrie', state: 'Oklahoma', country: 'US' },
  { name: 'Guttenberg', state: 'New Jersey', country: 'US' },
  { name: 'Guymon', state: 'Oklahoma', country: 'US' },
  { name: 'Hacienda Heights', state: 'California', country: 'US' },
  { name: 'Hackensack', state: 'New Jersey', country: 'US' },
  { name: 'Hackettstown', state: 'New Jersey', country: 'US' },
  { name: 'Haddam', state: 'Connecticut', country: 'US' },
  { name: 'Haddon Heights', state: 'New Jersey', country: 'US' },
  { name: 'Haddonfield', state: 'New Jersey', country: 'US' },
  { name: 'Hagerstown', state: 'Maryland', country: 'US' },
  { name: 'Haiku-Pauwela', state: 'Hawaii', country: 'US' },
  { name: 'Hailey', state: 'Idaho', country: 'US' },
  { name: 'Haines City', state: 'Florida', country: 'US' },
  { name: 'Halawa', state: 'Hawaii', country: 'US' },
  { name: 'Haledon', state: 'New Jersey', country: 'US' },
  { name: 'Hales Corners', state: 'Wisconsin', country: 'US' },
  { name: 'Half Moon', state: 'North Carolina', country: 'US' },
  { name: 'Half Moon Bay', state: 'California', country: 'US' },
  { name: 'Halfmoon', state: 'New York', country: 'US' },
  { name: 'Halfway', state: 'Maryland', country: 'US' },
  { name: 'Halifax', state: 'Massachusetts', country: 'US' },
  { name: 'Hallandale', state: 'Florida', country: 'US' },
  { name: 'Haltom City', state: 'Texas', country: 'US' },
  { name: 'Ham Lake', state: 'Minnesota', country: 'US' },
  { name: 'Hamburg', state: 'New York', country: 'US' },
  { name: 'Hamburg', state: 'New York', country: 'US' },
  { name: 'Hamden', state: 'Connecticut', country: 'US' },
  { name: 'Hamilton', state: 'Alabama', country: 'US' },
  { name: 'Hamilton', state: 'Massachusetts', country: 'US' },
  { name: 'Hamilton', state: 'Ohio', country: 'US' },
  { name: 'Hamlet', state: 'North Carolina', country: 'US' },
  { name: 'Hamlin', state: 'New York', country: 'US' },
  { name: 'Hammond', state: 'Louisiana', country: 'US' },
  { name: 'Hammond', state: 'Indiana', country: 'US' },
  { name: 'Hammonton', state: 'New Jersey', country: 'US' },
  { name: 'Hampden', state: 'Maine', country: 'US' },
  { name: 'Hampstead', state: 'New Hampshire', country: 'US' },
  { name: 'Hampton', state: 'New Hampshire', country: 'US' },
  { name: 'Hampton', state: 'New Hampshire', country: 'US' },
  { name: 'Hampton', state: 'Virginia', country: 'US' },
  { name: 'Hampton Bays', state: 'New York', country: 'US' },
  { name: 'Hampton Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Hamptons at Boca Raton', state: 'Florida', country: 'US' },
  { name: 'Hamtramck', state: 'Michigan', country: 'US' },
  { name: 'Hanahan', state: 'South Carolina', country: 'US' },
  { name: 'Hanford', state: 'California', country: 'US' },
  { name: 'Hannibal', state: 'Missouri', country: 'US' },
  { name: 'Hanover', state: 'New Hampshire', country: 'US' },
  { name: 'Hanover', state: 'New Hampshire', country: 'US' },
  { name: 'Hanover', state: 'Massachusetts', country: 'US' },
  { name: 'Hanover', state: 'Pennsylvania', country: 'US' },
  { name: 'Hanover', state: 'New York', country: 'US' },
  { name: 'Hanover Park', state: 'Illinois', country: 'US' },
  { name: 'Hanson', state: 'Massachusetts', country: 'US' },
  { name: 'Hapeville', state: 'Georgia', country: 'US' },
  { name: 'Harahan', state: 'Louisiana', country: 'US' },
  { name: 'Harker Heights', state: 'Texas', country: 'US' },
  { name: 'Harleysville', state: 'Pennsylvania', country: 'US' },
  { name: 'Harlingen', state: 'Texas', country: 'US' },
  { name: 'Harper Woods', state: 'Michigan', country: 'US' },
  { name: 'Harriman', state: 'Tennessee', country: 'US' },
  { name: 'Harrisburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Harrisburg', state: 'Illinois', country: 'US' },
  { name: 'Harrison', state: 'Arkansas', country: 'US' },
  { name: 'Harrison', state: 'Michigan', country: 'US' },
  { name: 'Harrison', state: 'New Jersey', country: 'US' },
  { name: 'Harrison', state: 'New York', country: 'US' },
  { name: 'Harrison', state: 'Ohio', country: 'US' },
  { name: 'Harrison', state: 'New York', country: 'US' },
  { name: 'Harrison', state: 'Tennessee', country: 'US' },
  { name: 'Harrison Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Harrisonburg', state: 'Virginia', country: 'US' },
  { name: 'Harrisonville', state: 'Missouri', country: 'US' },
  { name: 'Harrodsburg', state: 'Kentucky', country: 'US' },
  { name: 'Hartford', state: 'Connecticut', country: 'US' },
  { name: 'Hartford', state: 'Connecticut', country: 'US' },
  { name: 'Hartford', state: 'Wisconsin', country: 'US' },
  { name: 'Hartford', state: 'Vermont', country: 'US' },
  { name: 'Hartford City', state: 'Indiana', country: 'US' },
  { name: 'Hartland', state: 'Wisconsin', country: 'US' },
  { name: 'Hartsdale', state: 'New York', country: 'US' },
  { name: 'Hartselle', state: 'Alabama', country: 'US' },
  { name: 'Hartsville', state: 'South Carolina', country: 'US' },
  { name: 'Harvard', state: 'Illinois', country: 'US' },
  { name: 'Harvey', state: 'Illinois', country: 'US' },
  { name: 'Harvey', state: 'Louisiana', country: 'US' },
  { name: 'Harwich', state: 'Massachusetts', country: 'US' },
  { name: 'Harwood Heights', state: 'Illinois', country: 'US' },
  { name: 'Hasbrouck Heights', state: 'New Jersey', country: 'US' },
  { name: 'Haslett', state: 'Michigan', country: 'US' },
  { name: 'Hastings', state: 'Michigan', country: 'US' },
  { name: 'Hastings', state: 'Nebraska', country: 'US' },
  { name: 'Hastings', state: 'Minnesota', country: 'US' },
  { name: 'Hastings', state: 'New York', country: 'US' },
  { name: 'Hastings-on-Hudson', state: 'New York', country: 'US' },
  { name: 'Hatboro', state: 'Pennsylvania', country: 'US' },
  { name: 'Hattiesburg', state: 'Mississippi', country: 'US' },
  { name: 'Hauppauge', state: 'New York', country: 'US' },
  { name: 'Havelock', state: 'North Carolina', country: 'US' },
  { name: 'Haverhill', state: 'Massachusetts', country: 'US' },
  { name: 'Haverstraw', state: 'New York', country: 'US' },
  { name: 'Haverstraw', state: 'New York', country: 'US' },
  { name: 'Havre', state: 'Montana', country: 'US' },
  { name: 'Havre de Grace', state: 'Maryland', country: 'US' },
  { name: 'Hawaiian Gardens', state: 'California', country: 'US' },
  { name: 'Hawaiian Paradise Park', state: 'Hawaii', country: 'US' },
  { name: 'Hawthorn Woods', state: 'Illinois', country: 'US' },
  { name: 'Hawthorne', state: 'California', country: 'US' },
  { name: 'Hawthorne', state: 'New Jersey', country: 'US' },
  { name: 'Hayden', state: 'Idaho', country: 'US' },
  { name: 'Hayesville', state: 'Oregon', country: 'US' },
  { name: 'Hays', state: 'Kansas', country: 'US' },
  { name: 'Haysville', state: 'Kansas', country: 'US' },
  { name: 'Hayward', state: 'California', country: 'US' },
  { name: 'Hazel Crest', state: 'Illinois', country: 'US' },
  { name: 'Hazel Dell North', state: 'Washington', country: 'US' },
  { name: 'Hazel Dell South', state: 'Washington', country: 'US' },
  { name: 'Hazel Park', state: 'Michigan', country: 'US' },
  { name: 'Hazelwood', state: 'Missouri', country: 'US' },
  { name: 'Hazleton', state: 'Pennsylvania', country: 'US' },
  { name: 'Healdsburg', state: 'California', country: 'US' },
  { name: 'Heath', state: 'Ohio', country: 'US' },
  { name: 'Heber', state: 'Utah', country: 'US' },
  { name: 'Heber Springs', state: 'Arkansas', country: 'US' },
  { name: 'Hebron', state: 'Connecticut', country: 'US' },
  { name: 'Helena', state: 'Arkansas', country: 'US' },
  { name: 'Helena', state: 'Alabama', country: 'US' },
  { name: 'Helena', state: 'Montana', country: 'US' },
  { name: 'Helena Valley Southeast', state: 'Montana', country: 'US' },
  { name: 'Helena Valley West Central', state: 'Montana', country: 'US' },
  { name: 'Hemet', state: 'California', country: 'US' },
  { name: 'Hempstead', state: 'New York', country: 'US' },
  { name: 'Hempstead', state: 'New York', country: 'US' },
  { name: 'Henderson', state: 'North Carolina', country: 'US' },
  { name: 'Henderson', state: 'Texas', country: 'US' },
  { name: 'Henderson', state: 'Kentucky', country: 'US' },
  { name: 'Henderson', state: 'Nevada', country: 'US' },
  { name: 'Hendersonville', state: 'Tennessee', country: 'US' },
  { name: 'Hendersonville', state: 'North Carolina', country: 'US' },
  { name: 'Henrietta', state: 'New York', country: 'US' },
  { name: 'Henryetta', state: 'Oklahoma', country: 'US' },
  { name: 'Hercules', state: 'California', country: 'US' },
  { name: 'Hereford', state: 'Texas', country: 'US' },
  { name: 'Herkimer', state: 'New York', country: 'US' },
  { name: 'Herkimer', state: 'New York', country: 'US' },
  { name: 'Hermantown', state: 'Minnesota', country: 'US' },
  { name: 'Hermiston', state: 'Oregon', country: 'US' },
  { name: 'Hermitage', state: 'Pennsylvania', country: 'US' },
  { name: 'Hermosa Beach', state: 'California', country: 'US' },
  { name: 'Hernando', state: 'Florida', country: 'US' },
  { name: 'Hernando', state: 'Mississippi', country: 'US' },
  { name: 'Herndon', state: 'Virginia', country: 'US' },
  { name: 'Herrin', state: 'Illinois', country: 'US' },
  { name: 'Hershey', state: 'Pennsylvania', country: 'US' },
  { name: 'Hesperia', state: 'California', country: 'US' },
  { name: 'Hewitt', state: 'Texas', country: 'US' },
  { name: 'Hewlett', state: 'New York', country: 'US' },
  { name: 'Hialeah', state: 'Florida', country: 'US' },
  { name: 'Hialeah Gardens', state: 'Florida', country: 'US' },
  { name: 'Hiawatha', state: 'Iowa', country: 'US' },
  { name: 'Hibbing', state: 'Minnesota', country: 'US' },
  { name: 'Hickory', state: 'North Carolina', country: 'US' },
  { name: 'Hickory Hills', state: 'Illinois', country: 'US' },
  { name: 'Hicksville', state: 'New York', country: 'US' },
  { name: 'Hidalgo', state: 'Texas', country: 'US' },
  { name: 'High Point', state: 'North Carolina', country: 'US' },
  { name: 'Highland', state: 'Utah', country: 'US' },
  { name: 'Highland', state: 'Illinois', country: 'US' },
  { name: 'Highland', state: 'Indiana', country: 'US' },
  { name: 'Highland', state: 'California', country: 'US' },
  { name: 'Highland Heights', state: 'Kentucky', country: 'US' },
  { name: 'Highland Heights', state: 'Ohio', country: 'US' },
  { name: 'Highland Park', state: 'Texas', country: 'US' },
  { name: 'Highland Park', state: 'Illinois', country: 'US' },
  { name: 'Highland Park', state: 'New Jersey', country: 'US' },
  { name: 'Highland Park', state: 'Michigan', country: 'US' },
  { name: 'Highland Springs', state: 'Virginia', country: 'US' },
  { name: 'Highland Village', state: 'Texas', country: 'US' },
  { name: 'Highlands', state: 'Texas', country: 'US' },
  { name: 'Highlands', state: 'New York', country: 'US' },
  { name: 'Highlands Ranch', state: 'Colorado', country: 'US' },
  { name: 'Highview', state: 'Kentucky', country: 'US' },
  { name: 'Hillcrest', state: 'New York', country: 'US' },
  { name: 'Hillcrest Heights', state: 'Maryland', country: 'US' },
  { name: 'Hilliard', state: 'Ohio', country: 'US' },
  { name: 'Hillsboro', state: 'Ohio', country: 'US' },
  { name: 'Hillsboro', state: 'Oregon', country: 'US' },
  { name: 'Hillsboro', state: 'Texas', country: 'US' },
  { name: 'Hillsborough', state: 'California', country: 'US' },
  { name: 'Hillsdale', state: 'Michigan', country: 'US' },
  { name: 'Hillsdale', state: 'New Jersey', country: 'US' },
  { name: 'Hillside', state: 'New Jersey', country: 'US' },
  { name: 'Hillside', state: 'Illinois', country: 'US' },
  { name: 'Hillview', state: 'Kentucky', country: 'US' },
  { name: 'Hilo', state: 'Hawaii', country: 'US' },
  { name: 'Hilton Head Island', state: 'South Carolina', country: 'US' },
  { name: 'Hinesville', state: 'Georgia', country: 'US' },
  { name: 'Hingham', state: 'Massachusetts', country: 'US' },
  { name: 'Hinsdale', state: 'Illinois', country: 'US' },
  { name: 'Hitchcock', state: 'Texas', country: 'US' },
  { name: 'Hobart', state: 'Washington', country: 'US' },
  { name: 'Hobart', state: 'Indiana', country: 'US' },
  { name: 'Hobbs', state: 'New Mexico', country: 'US' },
  { name: 'Hobe Sound', state: 'Florida', country: 'US' },
  { name: 'Hoboken', state: 'New Jersey', country: 'US' },
  { name: 'Hockessin', state: 'Delaware', country: 'US' },
  { name: 'Hoffman Estates', state: 'Illinois', country: 'US' },
  { name: 'Holbrook', state: 'Massachusetts', country: 'US' },
  { name: 'Holbrook', state: 'Massachusetts', country: 'US' },
  { name: 'Holbrook', state: 'New York', country: 'US' },
  { name: 'Holden', state: 'Massachusetts', country: 'US' },
  { name: 'Holiday', state: 'Florida', country: 'US' },
  { name: 'Holiday City-Berkeley', state: 'New Jersey', country: 'US' },
  { name: 'Holladay', state: 'Utah', country: 'US' },
  { name: 'Holland', state: 'Michigan', country: 'US' },
  { name: 'Hollins', state: 'Virginia', country: 'US' },
  { name: 'Hollis', state: 'New Hampshire', country: 'US' },
  { name: 'Hollister', state: 'California', country: 'US' },
  { name: 'Holliston', state: 'Massachusetts', country: 'US' },
  { name: 'Holly', state: 'Michigan', country: 'US' },
  { name: 'Holly Hill', state: 'Florida', country: 'US' },
  { name: 'Holly Springs', state: 'Mississippi', country: 'US' },
  { name: 'Holly Springs', state: 'North Carolina', country: 'US' },
  { name: 'Hollywood', state: 'Florida', country: 'US' },
  { name: 'Holmen', state: 'Wisconsin', country: 'US' },
  { name: 'Holt', state: 'Michigan', country: 'US' },
  { name: 'Holtsville', state: 'New York', country: 'US' },
  { name: 'Holualoa', state: 'Hawaii', country: 'US' },
  { name: 'Holyoke', state: 'Massachusetts', country: 'US' },
  { name: 'Home Gardens', state: 'California', country: 'US' },
  { name: 'Homeacre-Lyndora', state: 'Pennsylvania', country: 'US' },
  { name: 'Homeland Park', state: 'South Carolina', country: 'US' },
  { name: 'Homer', state: 'New York', country: 'US' },
  { name: 'Homestead', state: 'Florida', country: 'US' },
  { name: 'Homestead Meadows South', state: 'Texas', country: 'US' },
  { name: 'Homewood', state: 'Alabama', country: 'US' },
  { name: 'Homewood', state: 'Illinois', country: 'US' },
  { name: 'Homosassa Springs', state: 'Florida', country: 'US' },
  { name: 'Hondo', state: 'Texas', country: 'US' },
  { name: 'Honolulu', state: 'Hawaii', country: 'US' },
  { name: 'Hooksett', state: 'New Hampshire', country: 'US' },
  { name: 'Hoosick', state: 'New York', country: 'US' },
  { name: 'Hoover', state: 'Alabama', country: 'US' },
  { name: 'Hopatcong', state: 'New Jersey', country: 'US' },
  { name: 'Hope', state: 'Arkansas', country: 'US' },
  { name: 'Hope Mills', state: 'North Carolina', country: 'US' },
  { name: 'Hopewell', state: 'Virginia', country: 'US' },
  { name: 'Hopkins', state: 'Minnesota', country: 'US' },
  { name: 'Hopkinsville', state: 'Kentucky', country: 'US' },
  { name: 'Hopkinton', state: 'Massachusetts', country: 'US' },
  { name: 'Hopkinton', state: 'Rhode Island', country: 'US' },
  { name: 'Hoquiam', state: 'Washington', country: 'US' },
  { name: 'Horn Lake', state: 'Mississippi', country: 'US' },
  { name: 'Hornell', state: 'New York', country: 'US' },
  { name: 'Horseheads', state: 'New York', country: 'US' },
  { name: 'Horseheads', state: 'New York', country: 'US' },
  { name: 'Horsham', state: 'Pennsylvania', country: 'US' },
  { name: 'Hot Springs', state: 'Arkansas', country: 'US' },
  { name: 'Hot Springs Village', state: 'Arkansas', country: 'US' },
  { name: 'Houghton', state: 'Michigan', country: 'US' },
  { name: 'Houlton', state: 'Maine', country: 'US' },
  { name: 'Houma', state: 'Louisiana', country: 'US' },
  { name: 'Houston', state: 'Texas', country: 'US' },
  { name: 'Howard', state: 'Wisconsin', country: 'US' },
  { name: 'Howell', state: 'Michigan', country: 'US' },
  { name: 'Howland Center', state: 'Ohio', country: 'US' },
  { name: 'Hubbard', state: 'Ohio', country: 'US' },
  { name: 'Huber Heights', state: 'Ohio', country: 'US' },
  { name: 'Hudson', state: 'Ohio', country: 'US' },
  { name: 'Hudson', state: 'New York', country: 'US' },
  { name: 'Hudson', state: 'Wisconsin', country: 'US' },
  { name: 'Hudson', state: 'Wisconsin', country: 'US' },
  { name: 'Hudson', state: 'Massachusetts', country: 'US' },
  { name: 'Hudson', state: 'Massachusetts', country: 'US' },
  { name: 'Hudson', state: 'New Hampshire', country: 'US' },
  { name: 'Hudson', state: 'New Hampshire', country: 'US' },
  { name: 'Hudson', state: 'Florida', country: 'US' },
  { name: 'Hudson Falls', state: 'New York', country: 'US' },
  { name: 'Hudsonville', state: 'Michigan', country: 'US' },
  { name: 'Hueytown', state: 'Alabama', country: 'US' },
  { name: 'Hugo', state: 'Minnesota', country: 'US' },
  { name: 'Hull', state: 'Massachusetts', country: 'US' },
  { name: 'Hull', state: 'Massachusetts', country: 'US' },
  { name: 'Humble', state: 'Texas', country: 'US' },
  { name: 'Humboldt', state: 'Tennessee', country: 'US' },
  { name: 'Hunters Creek', state: 'Florida', country: 'US' },
  { name: 'Huntersville', state: 'North Carolina', country: 'US' },
  { name: 'Huntingdon', state: 'Pennsylvania', country: 'US' },
  { name: 'Huntington', state: 'New York', country: 'US' },
  { name: 'Huntington', state: 'New York', country: 'US' },
  { name: 'Huntington', state: 'Virginia', country: 'US' },
  { name: 'Huntington', state: 'West Virginia', country: 'US' },
  { name: 'Huntington', state: 'Indiana', country: 'US' },
  { name: 'Huntington Beach', state: 'California', country: 'US' },
  { name: 'Huntington Park', state: 'California', country: 'US' },
  { name: 'Huntington Station', state: 'New York', country: 'US' },
  { name: 'Huntington Woods', state: 'Michigan', country: 'US' },
  { name: 'Huntsville', state: 'Alabama', country: 'US' },
  { name: 'Huntsville', state: 'Texas', country: 'US' },
  { name: 'Hurley', state: 'New York', country: 'US' },
  { name: 'Huron', state: 'Ohio', country: 'US' },
  { name: 'Huron', state: 'South Dakota', country: 'US' },
  { name: 'Huron', state: 'California', country: 'US' },
  { name: 'Hurricane', state: 'Utah', country: 'US' },
  { name: 'Hurst', state: 'Texas', country: 'US' },
  { name: 'Hutchinson', state: 'Kansas', country: 'US' },
  { name: 'Hutchinson', state: 'Minnesota', country: 'US' },
  { name: 'Hyattsville', state: 'Maryland', country: 'US' },
  { name: 'Hybla Valley', state: 'Virginia', country: 'US' },
  { name: 'Hyde Park', state: 'New York', country: 'US' },
  { name: 'Hyrum', state: 'Utah', country: 'US' },
  { name: 'Idabel', state: 'Oklahoma', country: 'US' },
  { name: 'Idaho Falls', state: 'Idaho', country: 'US' },
  { name: 'Idylwood', state: 'Virginia', country: 'US' },
  { name: 'Ilion', state: 'New York', country: 'US' },
  { name: 'Immokalee', state: 'Florida', country: 'US' },
  { name: 'Imperial', state: 'California', country: 'US' },
  { name: 'Imperial Beach', state: 'California', country: 'US' },
  { name: 'Incline Village-Crystal Bay', state: 'Nevada', country: 'US' },
  { name: 'Independence', state: 'Oregon', country: 'US' },
  { name: 'Independence', state: 'Missouri', country: 'US' },
  { name: 'Independence', state: 'Ohio', country: 'US' },
  { name: 'Independence', state: 'Iowa', country: 'US' },
  { name: 'Independence', state: 'Kansas', country: 'US' },
  { name: 'Independence', state: 'Kentucky', country: 'US' },
  { name: 'Indian Harbour Beach', state: 'Florida', country: 'US' },
  { name: 'Indian Trail', state: 'North Carolina', country: 'US' },
  { name: 'Indiana', state: 'Pennsylvania', country: 'US' },
  { name: 'Indianapolis', state: 'Indiana', country: 'US' },
  { name: 'Indianola', state: 'Mississippi', country: 'US' },
  { name: 'Indianola', state: 'Iowa', country: 'US' },
  { name: 'Indio', state: 'California', country: 'US' },
  { name: 'Ingleside', state: 'Texas', country: 'US' },
  { name: 'Inglewood', state: 'California', country: 'US' },
  { name: 'Inglewood-Finn Hill', state: 'Washington', country: 'US' },
  { name: 'Inkster', state: 'Michigan', country: 'US' },
  { name: 'Interlaken', state: 'California', country: 'US' },
  { name: 'International Falls', state: 'Minnesota', country: 'US' },
  { name: 'Inver Grove Heights', state: 'Minnesota', country: 'US' },
  { name: 'Inverness', state: 'Florida', country: 'US' },
  { name: 'Inverness', state: 'Illinois', country: 'US' },
  { name: 'Inwood', state: 'Florida', country: 'US' },
  { name: 'Inwood', state: 'New York', country: 'US' },
  { name: 'Iola', state: 'Kansas', country: 'US' },
  { name: 'Iona', state: 'Florida', country: 'US' },
  { name: 'Ione', state: 'California', country: 'US' },
  { name: 'Ionia', state: 'Michigan', country: 'US' },
  { name: 'Iowa City', state: 'Iowa', country: 'US' },
  { name: 'Iowa Park', state: 'Texas', country: 'US' },
  { name: 'Ipswich', state: 'Massachusetts', country: 'US' },
  { name: 'Irmo', state: 'South Carolina', country: 'US' },
  { name: 'Iron Mountain', state: 'Michigan', country: 'US' },
  { name: 'Irondale', state: 'Alabama', country: 'US' },
  { name: 'Irondale', state: 'Georgia', country: 'US' },
  { name: 'Irondequoit', state: 'New York', country: 'US' },
  { name: 'Irondequoit', state: 'New York', country: 'US' },
  { name: 'Ironton', state: 'Ohio', country: 'US' },
  { name: 'Ironwood', state: 'Michigan', country: 'US' },
  { name: 'Irvine', state: 'California', country: 'US' },
  { name: 'Irving', state: 'Texas', country: 'US' },
  { name: 'Irvington', state: 'New Jersey', country: 'US' },
  { name: 'Irvington', state: 'New York', country: 'US' },
  { name: 'Iselin', state: 'New Jersey', country: 'US' },
  { name: 'Ishpeming', state: 'Michigan', country: 'US' },
  { name: 'Isla Vista', state: 'California', country: 'US' },
  { name: 'Islamorada', state: 'Florida', country: 'US' },
  { name: 'Island Lake', state: 'Illinois', country: 'US' },
  { name: 'Islip', state: 'New York', country: 'US' },
  { name: 'Islip', state: 'New York', country: 'US' },
  { name: 'Issaquah', state: 'Washington', country: 'US' },
  { name: 'Itasca', state: 'Illinois', country: 'US' },
  { name: 'Ithaca', state: 'New York', country: 'US' },
  { name: 'Ithaca', state: 'New York', country: 'US' },
  { name: 'Ives Estates', state: 'Florida', country: 'US' },
  { name: 'Jacinto City', state: 'Texas', country: 'US' },
  { name: 'Jackson', state: 'Tennessee', country: 'US' },
  { name: 'Jackson', state: 'Ohio', country: 'US' },
  { name: 'Jackson', state: 'Wyoming', country: 'US' },
  { name: 'Jackson', state: 'Michigan', country: 'US' },
  { name: 'Jackson', state: 'Mississippi', country: 'US' },
  { name: 'Jackson', state: 'Missouri', country: 'US' },
  { name: 'Jacksonville', state: 'Alabama', country: 'US' },
  { name: 'Jacksonville', state: 'Arkansas', country: 'US' },
  { name: 'Jacksonville', state: 'Florida', country: 'US' },
  { name: 'Jacksonville', state: 'Illinois', country: 'US' },
  { name: 'Jacksonville', state: 'North Carolina', country: 'US' },
  { name: 'Jacksonville', state: 'Texas', country: 'US' },
  { name: 'Jacksonville Beach', state: 'Florida', country: 'US' },
  { name: 'Jamesburg', state: 'New Jersey', country: 'US' },
  { name: 'Jamestown', state: 'North Dakota', country: 'US' },
  { name: 'Jamestown', state: 'New York', country: 'US' },
  { name: 'Janesville', state: 'Wisconsin', country: 'US' },
  { name: 'Jasmine Estates', state: 'Florida', country: 'US' },
  { name: 'Jasper', state: 'Alabama', country: 'US' },
  { name: 'Jasper', state: 'Indiana', country: 'US' },
  { name: 'Jasper', state: 'Texas', country: 'US' },
  { name: 'Jeannette', state: 'Pennsylvania', country: 'US' },
  { name: 'Jefferson', state: 'Wisconsin', country: 'US' },
  { name: 'Jefferson', state: 'Virginia', country: 'US' },
  { name: 'Jefferson', state: 'Louisiana', country: 'US' },
  { name: 'Jefferson City', state: 'Missouri', country: 'US' },
  { name: 'Jefferson City', state: 'Tennessee', country: 'US' },
  { name: 'Jefferson Hills', state: 'Pennsylvania', country: 'US' },
  { name: 'Jefferson Valley-Yorktown', state: 'New York', country: 'US' },
  { name: 'Jeffersontown', state: 'Kentucky', country: 'US' },
  { name: 'Jeffersonville', state: 'Indiana', country: 'US' },
  { name: 'Jenison', state: 'Michigan', country: 'US' },
  { name: 'Jenks', state: 'Oklahoma', country: 'US' },
  { name: 'Jennings', state: 'Louisiana', country: 'US' },
  { name: 'Jennings', state: 'Missouri', country: 'US' },
  { name: 'Jennings Lodge', state: 'Oregon', country: 'US' },
  { name: 'Jensen Beach', state: 'Florida', country: 'US' },
  { name: 'Jericho', state: 'New York', country: 'US' },
  { name: 'Jerome', state: 'Idaho', country: 'US' },
  { name: 'Jersey City', state: 'New Jersey', country: 'US' },
  { name: 'Jersey Village', state: 'Texas', country: 'US' },
  { name: 'Jerseyville', state: 'Illinois', country: 'US' },
  { name: 'Jessup', state: 'Maryland', country: 'US' },
  { name: 'Jesup', state: 'Georgia', country: 'US' },
  { name: 'Johnson City', state: 'New York', country: 'US' },
  { name: 'Johnson City', state: 'Tennessee', country: 'US' },
  { name: 'Johnston', state: 'Rhode Island', country: 'US' },
  { name: 'Johnston', state: 'Iowa', country: 'US' },
  { name: 'Johnstown', state: 'New York', country: 'US' },
  { name: 'Johnstown', state: 'New York', country: 'US' },
  { name: 'Johnstown', state: 'Pennsylvania', country: 'US' },
  { name: 'Joliet', state: 'Illinois', country: 'US' },
  { name: 'Jollyville', state: 'Texas', country: 'US' },
  { name: 'Jonesboro', state: 'Arkansas', country: 'US' },
  { name: 'Joplin', state: 'Missouri', country: 'US' },
  { name: 'Joppatowne', state: 'Maryland', country: 'US' },
  { name: 'Junction City', state: 'Kansas', country: 'US' },
  { name: 'Juneau and', state: 'Alaska', country: 'US' },
  { name: 'Jupiter', state: 'Florida', country: 'US' },
  { name: 'Justice', state: 'Illinois', country: 'US' },
  { name: 'Kahului', state: 'Hawaii', country: 'US' },
  { name: 'Kailua', state: 'Hawaii', country: 'US' },
  { name: 'Kailua', state: 'Hawaii', country: 'US' },
  { name: 'Kalamazoo', state: 'Michigan', country: 'US' },
  { name: 'Kalaoa', state: 'Hawaii', country: 'US' },
  { name: 'Kalispell', state: 'Montana', country: 'US' },
  { name: 'Kaneohe', state: 'Hawaii', country: 'US' },
  { name: 'Kaneohe Station', state: 'Hawaii', country: 'US' },
  { name: 'Kankakee', state: 'Illinois', country: 'US' },
  { name: 'Kannapolis', state: 'North Carolina', country: 'US' },
  { name: 'Kansas City', state: 'Missouri', country: 'US' },
  { name: 'Kansas City', state: 'Kansas', country: 'US' },
  { name: 'Kapaa', state: 'Hawaii', country: 'US' },
  { name: 'Katy', state: 'Texas', country: 'US' },
  { name: 'Kaufman', state: 'Texas', country: 'US' },
  { name: 'Kaukauna', state: 'Wisconsin', country: 'US' },
  { name: 'Kaysville', state: 'Utah', country: 'US' },
  { name: 'Keansburg', state: 'New Jersey', country: 'US' },
  { name: 'Kearney', state: 'Nebraska', country: 'US' },
  { name: 'Kearns', state: 'Utah', country: 'US' },
  { name: 'Kearny', state: 'New Jersey', country: 'US' },
  { name: 'Keene', state: 'New Hampshire', country: 'US' },
  { name: 'Keizer', state: 'Oregon', country: 'US' },
  { name: 'Keller', state: 'Texas', country: 'US' },
  { name: 'Kelso', state: 'Washington', country: 'US' },
  { name: 'Kemp Mill', state: 'Maryland', country: 'US' },
  { name: 'Ken Caryl', state: 'Colorado', country: 'US' },
  { name: 'Kenai', state: 'Alaska', country: 'US' },
  { name: 'Kendale Lakes', state: 'Florida', country: 'US' },
  { name: 'Kendall', state: 'Florida', country: 'US' },
  { name: 'Kendall Park', state: 'New Jersey', country: 'US' },
  { name: 'Kendall West', state: 'Florida', country: 'US' },
  { name: 'Kendallville', state: 'Indiana', country: 'US' },
  { name: 'Kenilworth', state: 'New Jersey', country: 'US' },
  { name: 'Kenmore', state: 'New York', country: 'US' },
  { name: 'Kenmore', state: 'Washington', country: 'US' },
  { name: 'Kennebunk', state: 'Maine', country: 'US' },
  { name: 'Kennedy Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Kenner', state: 'Louisiana', country: 'US' },
  { name: 'Kennesaw', state: 'Georgia', country: 'US' },
  { name: 'Kennett', state: 'Missouri', country: 'US' },
  { name: 'Kennewick', state: 'Washington', country: 'US' },
  { name: 'Kenosha', state: 'Wisconsin', country: 'US' },
  { name: 'Kensington', state: 'Connecticut', country: 'US' },
  { name: 'Kent', state: 'Washington', country: 'US' },
  { name: 'Kent', state: 'Ohio', country: 'US' },
  { name: 'Kent', state: 'New York', country: 'US' },
  { name: 'Kentfield', state: 'California', country: 'US' },
  { name: 'Kenton', state: 'Ohio', country: 'US' },
  { name: 'Kentwood', state: 'Michigan', country: 'US' },
  { name: 'Kenwood', state: 'Ohio', country: 'US' },
  { name: 'Keokuk', state: 'Iowa', country: 'US' },
  { name: 'Kerman', state: 'California', country: 'US' },
  { name: 'Kernersville', state: 'North Carolina', country: 'US' },
  { name: 'Kerrville', state: 'Texas', country: 'US' },
  { name: 'Ketchikan', state: 'Alaska', country: 'US' },
  { name: 'Kettering', state: 'Maryland', country: 'US' },
  { name: 'Kettering', state: 'Ohio', country: 'US' },
  { name: 'Kewanee', state: 'Illinois', country: 'US' },
  { name: 'Key Biscayne', state: 'Florida', country: 'US' },
  { name: 'Key Largo', state: 'Florida', country: 'US' },
  { name: 'Key West', state: 'Florida', country: 'US' },
  { name: 'Keyport', state: 'New Jersey', country: 'US' },
  { name: 'Keystone', state: 'Florida', country: 'US' },
  { name: 'Kihei', state: 'Hawaii', country: 'US' },
  { name: 'Kilgore', state: 'Texas', country: 'US' },
  { name: 'Killeen', state: 'Texas', country: 'US' },
  { name: 'Killingly', state: 'Connecticut', country: 'US' },
  { name: 'Killingworth', state: 'Connecticut', country: 'US' },
  { name: 'Kimberly', state: 'Wisconsin', country: 'US' },
  { name: 'Kinderhook', state: 'New York', country: 'US' },
  { name: 'King City', state: 'California', country: 'US' },
  { name: 'King of Prussia', state: 'Pennsylvania', country: 'US' },
  { name: 'Kingman', state: 'Arizona', country: 'US' },
  { name: 'Kings Grant', state: 'North Carolina', country: 'US' },
  { name: 'Kings Mountain', state: 'North Carolina', country: 'US' },
  { name: 'Kings Park', state: 'New York', country: 'US' },
  { name: 'Kings Point', state: 'Florida', country: 'US' },
  { name: 'Kingsburg', state: 'California', country: 'US' },
  { name: 'Kingsbury', state: 'New York', country: 'US' },
  { name: 'Kingsgate', state: 'Washington', country: 'US' },
  { name: 'Kingsland', state: 'Georgia', country: 'US' },
  { name: 'Kingsport', state: 'Tennessee', country: 'US' },
  { name: 'Kingston', state: 'Pennsylvania', country: 'US' },
  { name: 'Kingston', state: 'New York', country: 'US' },
  { name: 'Kingston', state: 'Massachusetts', country: 'US' },
  { name: 'Kingsville', state: 'Texas', country: 'US' },
  { name: 'Kinnelon', state: 'New Jersey', country: 'US' },
  { name: 'Kinston', state: 'North Carolina', country: 'US' },
  { name: 'Kirby', state: 'Texas', country: 'US' },
  { name: 'Kirkland', state: 'Washington', country: 'US' },
  { name: 'Kirkland', state: 'New York', country: 'US' },
  { name: 'Kirksville', state: 'Missouri', country: 'US' },
  { name: 'Kirkwood', state: 'Missouri', country: 'US' },
  { name: 'Kirtland', state: 'New Mexico', country: 'US' },
  { name: 'Kirtland', state: 'Ohio', country: 'US' },
  { name: 'Kiryas Joel', state: 'New York', country: 'US' },
  { name: 'Kissimmee', state: 'Florida', country: 'US' },
  { name: 'Kittery', state: 'Maine', country: 'US' },
  { name: 'Klamath Falls', state: 'Oregon', country: 'US' },
  { name: 'Knik-Fairview', state: 'Alaska', country: 'US' },
  { name: 'Knoxville', state: 'Iowa', country: 'US' },
  { name: 'Knoxville', state: 'Tennessee', country: 'US' },
  { name: 'Kodiak', state: 'Alaska', country: 'US' },
  { name: 'Kokomo', state: 'Indiana', country: 'US' },
  { name: 'Kosciusko', state: 'Mississippi', country: 'US' },
  { name: 'Kulpsville', state: 'Pennsylvania', country: 'US' },
  { name: 'La Canada Flintridge', state: 'California', country: 'US' },
  { name: 'La Crescenta-Montrose', state: 'California', country: 'US' },
  { name: 'La Crosse', state: 'Wisconsin', country: 'US' },
  { name: 'La Fayette', state: 'Georgia', country: 'US' },
  { name: 'La Feria', state: 'Texas', country: 'US' },
  { name: 'La Follette', state: 'Tennessee', country: 'US' },
  { name: 'La Grande', state: 'Oregon', country: 'US' },
  { name: 'La Grange', state: 'New York', country: 'US' },
  { name: 'La Grange', state: 'Illinois', country: 'US' },
  { name: 'La Grange Park', state: 'Illinois', country: 'US' },
  { name: 'La Habra', state: 'California', country: 'US' },
  { name: 'La Homa', state: 'Texas', country: 'US' },
  { name: 'La Junta', state: 'Colorado', country: 'US' },
  { name: 'La Marque', state: 'Texas', country: 'US' },
  { name: 'La Mesa', state: 'California', country: 'US' },
  { name: 'La Mirada', state: 'California', country: 'US' },
  { name: 'La Palma', state: 'California', country: 'US' },
  { name: 'La Plata', state: 'Maryland', country: 'US' },
  { name: 'La Porte', state: 'Indiana', country: 'US' },
  { name: 'La Porte', state: 'Texas', country: 'US' },
  { name: 'La Presa', state: 'California', country: 'US' },
  { name: 'La Puente', state: 'California', country: 'US' },
  { name: 'La Quinta', state: 'California', country: 'US' },
  { name: 'La Riviera', state: 'California', country: 'US' },
  { name: 'La Salle', state: 'Illinois', country: 'US' },
  { name: 'La Vergne', state: 'Tennessee', country: 'US' },
  { name: 'La Verne', state: 'California', country: 'US' },
  { name: 'La Vista', state: 'Nebraska', country: 'US' },
  { name: 'Lacey', state: 'Washington', country: 'US' },
  { name: 'Lackawanna', state: 'New York', country: 'US' },
  { name: 'Lackland AFB', state: 'Texas', country: 'US' },
  { name: 'Lacombe', state: 'Louisiana', country: 'US' },
  { name: 'Laconia', state: 'New Hampshire', country: 'US' },
  { name: 'Ladera Heights', state: 'California', country: 'US' },
  { name: 'Ladson', state: 'South Carolina', country: 'US' },
  { name: 'Ladue', state: 'Missouri', country: 'US' },
  { name: 'Lady Lake', state: 'Florida', country: 'US' },
  { name: 'Lafayette', state: 'Colorado', country: 'US' },
  { name: 'Lafayette', state: 'Louisiana', country: 'US' },
  { name: 'Lafayette', state: 'Indiana', country: 'US' },
  { name: 'Lafayette', state: 'California', country: 'US' },
  { name: 'LaGrange', state: 'Georgia', country: 'US' },
  { name: 'Laguna', state: 'California', country: 'US' },
  { name: 'Laguna Beach', state: 'California', country: 'US' },
  { name: 'Laguna Hills', state: 'California', country: 'US' },
  { name: 'Laguna Niguel', state: 'California', country: 'US' },
  { name: 'Laguna West-Lakeside', state: 'California', country: 'US' },
  { name: 'Laguna Woods', state: 'California', country: 'US' },
  { name: 'Lahaina', state: 'Hawaii', country: 'US' },
  { name: 'Lake Arbor', state: 'Maryland', country: 'US' },
  { name: 'Lake Arrowhead', state: 'California', country: 'US' },
  { name: 'Lake Barcroft', state: 'Virginia', country: 'US' },
  { name: 'Lake Bluff', state: 'Illinois', country: 'US' },
  { name: 'Lake Butter', state: 'Florida', country: 'US' },
  { name: 'Lake Carmel', state: 'New York', country: 'US' },
  { name: 'Lake Charles', state: 'Louisiana', country: 'US' },
  { name: 'Lake City', state: 'Florida', country: 'US' },
  { name: 'Lake City', state: 'South Carolina', country: 'US' },
  { name: 'Lake Dallas', state: 'Texas', country: 'US' },
  { name: 'Lake Elmo', state: 'Minnesota', country: 'US' },
  { name: 'Lake Elsinore', state: 'California', country: 'US' },
  { name: 'Lake Forest', state: 'California', country: 'US' },
  { name: 'Lake Forest', state: 'Illinois', country: 'US' },
  { name: 'Lake Forest Park', state: 'Washington', country: 'US' },
  { name: 'Lake Geneva', state: 'Wisconsin', country: 'US' },
  { name: 'Lake Grove', state: 'New York', country: 'US' },
  { name: 'Lake Havasu City', state: 'Arizona', country: 'US' },
  { name: 'Lake in the Hills', state: 'Illinois', country: 'US' },
  { name: 'Lake Jackson', state: 'Texas', country: 'US' },
  { name: 'Lake Lorraine', state: 'Florida', country: 'US' },
  { name: 'Lake Los Angeles', state: 'California', country: 'US' },
  { name: 'Lake Lucerne', state: 'Florida', country: 'US' },
  { name: 'Lake Magdalene', state: 'Florida', country: 'US' },
  { name: 'Lake Mary', state: 'Florida', country: 'US' },
  { name: 'Lake Mohawk', state: 'New Jersey', country: 'US' },
  { name: 'Lake Monticello', state: 'Virginia', country: 'US' },
  { name: 'Lake Morton-Berrydale', state: 'Washington', country: 'US' },
  { name: 'Lake Oswego', state: 'Oregon', country: 'US' },
  { name: 'Lake Park', state: 'Florida', country: 'US' },
  { name: 'Lake Ridge', state: 'Virginia', country: 'US' },
  { name: 'Lake Ronkonkoma', state: 'New York', country: 'US' },
  { name: 'Lake Shore', state: 'Maryland', country: 'US' },
  { name: 'Lake Shore', state: 'Washington', country: 'US' },
  { name: 'Lake St. Louis', state: 'Missouri', country: 'US' },
  { name: 'Lake Station', state: 'Indiana', country: 'US' },
  { name: 'Lake Stevens', state: 'Washington', country: 'US' },
  { name: 'Lake Wales', state: 'Florida', country: 'US' },
  { name: 'Lake Worth', state: 'Florida', country: 'US' },
  { name: 'Lake Worth Corridor', state: 'Florida', country: 'US' },
  { name: 'Lake Zurich', state: 'Illinois', country: 'US' },
  { name: 'Lakeland', state: 'Florida', country: 'US' },
  { name: 'Lakeland', state: 'Tennessee', country: 'US' },
  { name: 'Lakeland Highlands', state: 'Florida', country: 'US' },
  { name: 'Lakeland North', state: 'Washington', country: 'US' },
  { name: 'Lakeland South', state: 'Washington', country: 'US' },
  { name: 'Lakes', state: 'Alaska', country: 'US' },
  { name: 'Lakes by the Bay', state: 'Florida', country: 'US' },
  { name: 'Lakes of the Four Seasons', state: 'Indiana', country: 'US' },
  { name: 'Lakeside', state: 'Florida', country: 'US' },
  { name: 'Lakeside', state: 'California', country: 'US' },
  { name: 'Lakeside', state: 'Virginia', country: 'US' },
  { name: 'Lakeville', state: 'Minnesota', country: 'US' },
  { name: 'Lakeville', state: 'Massachusetts', country: 'US' },
  { name: 'Lakeway', state: 'Texas', country: 'US' },
  { name: 'Lakewood', state: 'Ohio', country: 'US' },
  { name: 'Lakewood', state: 'New Jersey', country: 'US' },
  { name: 'Lakewood', state: 'California', country: 'US' },
  { name: 'Lakewood', state: 'Colorado', country: 'US' },
  { name: 'Lakewood', state: 'Washington', country: 'US' },
  { name: 'Lakewood Park', state: 'Florida', country: 'US' },
  { name: 'Lamar', state: 'Colorado', country: 'US' },
  { name: 'Lambertville', state: 'Michigan', country: 'US' },
  { name: 'Lamesa', state: 'Texas', country: 'US' },
  { name: 'Lamont', state: 'California', country: 'US' },
  { name: 'Lampasas', state: 'Texas', country: 'US' },
  { name: 'Lancaster', state: 'Texas', country: 'US' },
  { name: 'Lancaster', state: 'South Carolina', country: 'US' },
  { name: 'Lancaster', state: 'Pennsylvania', country: 'US' },
  { name: 'Lancaster', state: 'Ohio', country: 'US' },
  { name: 'Lancaster', state: 'New York', country: 'US' },
  { name: 'Lancaster', state: 'Massachusetts', country: 'US' },
  { name: 'Lancaster', state: 'New York', country: 'US' },
  { name: 'Lancaster', state: 'California', country: 'US' },
  { name: 'Land O’ Lakes', state: 'Florida', country: 'US' },
  { name: 'Landen', state: 'Ohio', country: 'US' },
  { name: 'Lander', state: 'Wyoming', country: 'US' },
  { name: 'Lanett', state: 'Alabama', country: 'US' },
  { name: 'Langley Park', state: 'Maryland', country: 'US' },
  { name: 'Lanham-Seabrook', state: 'Maryland', country: 'US' },
  { name: 'Lansdale', state: 'Pennsylvania', country: 'US' },
  { name: 'Lansdowne', state: 'Pennsylvania', country: 'US' },
  { name: 'Lansdowne-Baltimore Highlands', state: 'Maryland', country: 'US' },
  { name: 'Lansing', state: 'Kansas', country: 'US' },
  { name: 'Lansing', state: 'Illinois', country: 'US' },
  { name: 'Lansing', state: 'New York', country: 'US' },
  { name: 'Lansing', state: 'Michigan', country: 'US' },
  { name: 'Lantana', state: 'Florida', country: 'US' },
  { name: 'Lapeer', state: 'Michigan', country: 'US' },
  { name: 'Laplace', state: 'Louisiana', country: 'US' },
  { name: 'Laramie', state: 'Wyoming', country: 'US' },
  { name: 'Larchmont', state: 'New York', country: 'US' },
  { name: 'Laredo', state: 'Texas', country: 'US' },
  { name: 'Largo', state: 'Maryland', country: 'US' },
  { name: 'Largo', state: 'Florida', country: 'US' },
  { name: 'Larkfield-Wikiup', state: 'California', country: 'US' },
  { name: 'Larkspur', state: 'California', country: 'US' },
  { name: 'Larose', state: 'Louisiana', country: 'US' },
  { name: 'Las Cruces', state: 'New Mexico', country: 'US' },
  { name: 'Las Vegas', state: 'New Mexico', country: 'US' },
  { name: 'Las Vegas', state: 'Nevada', country: 'US' },
  { name: 'Lathrop', state: 'California', country: 'US' },
  { name: 'Latrobe', state: 'Pennsylvania', country: 'US' },
  { name: 'Lauderdale Lakes', state: 'Florida', country: 'US' },
  { name: 'Lauderhill', state: 'Florida', country: 'US' },
  { name: 'Laughlin', state: 'Nevada', country: 'US' },
  { name: 'Laurel', state: 'Mississippi', country: 'US' },
  { name: 'Laurel', state: 'Montana', country: 'US' },
  { name: 'Laurel', state: 'Florida', country: 'US' },
  { name: 'Laurel', state: 'Maryland', country: 'US' },
  { name: 'Laurel', state: 'Virginia', country: 'US' },
  { name: 'Laurel Bay', state: 'South Carolina', country: 'US' },
  { name: 'Laurence Harbor', state: 'New Jersey', country: 'US' },
  { name: 'Laurens', state: 'South Carolina', country: 'US' },
  { name: 'Laurinburg', state: 'North Carolina', country: 'US' },
  { name: 'Lawndale', state: 'California', country: 'US' },
  { name: 'Lawrence', state: 'Kansas', country: 'US' },
  { name: 'Lawrence', state: 'Indiana', country: 'US' },
  { name: 'Lawrence', state: 'New York', country: 'US' },
  { name: 'Lawrence', state: 'Massachusetts', country: 'US' },
  { name: 'Lawrenceburg', state: 'Tennessee', country: 'US' },
  { name: 'Lawrenceburg', state: 'Kentucky', country: 'US' },
  { name: 'Lawrenceville', state: 'Georgia', country: 'US' },
  { name: 'Lawton', state: 'Oklahoma', country: 'US' },
  { name: 'Layton', state: 'Utah', country: 'US' },
  { name: 'Le Mars', state: 'Iowa', country: 'US' },
  { name: 'Le Ray', state: 'New York', country: 'US' },
  { name: 'Le Roy', state: 'New York', country: 'US' },
  { name: 'Lea Hill', state: 'Washington', country: 'US' },
  { name: 'Leacock-Leola-Bareville', state: 'Pennsylvania', country: 'US' },
  { name: 'League City', state: 'Texas', country: 'US' },
  { name: 'Leander', state: 'Texas', country: 'US' },
  { name: 'Leavenworth', state: 'Kansas', country: 'US' },
  { name: 'Leawood', state: 'Kansas', country: 'US' },
  { name: 'Lebanon', state: 'Indiana', country: 'US' },
  { name: 'Lebanon', state: 'Connecticut', country: 'US' },
  { name: 'Lebanon', state: 'Pennsylvania', country: 'US' },
  { name: 'Lebanon', state: 'Tennessee', country: 'US' },
  { name: 'Lebanon', state: 'Oregon', country: 'US' },
  { name: 'Lebanon', state: 'Ohio', country: 'US' },
  { name: 'Lebanon', state: 'New Hampshire', country: 'US' },
  { name: 'Lebanon', state: 'Missouri', country: 'US' },
  { name: 'Ledyard', state: 'Connecticut', country: 'US' },
  { name: 'Lee', state: 'New York', country: 'US' },
  { name: 'Lee’s Summit', state: 'Missouri', country: 'US' },
  { name: 'Leeds', state: 'Alabama', country: 'US' },
  { name: 'Leesburg', state: 'Florida', country: 'US' },
  { name: 'Leesburg', state: 'Virginia', country: 'US' },
  { name: 'Leesville', state: 'Louisiana', country: 'US' },
  { name: 'Lehi', state: 'Utah', country: 'US' },
  { name: 'Lehigh Acres', state: 'Florida', country: 'US' },
  { name: 'Leicester', state: 'Massachusetts', country: 'US' },
  { name: 'Leisure City', state: 'Florida', country: 'US' },
  { name: 'Leisure Village West-Pine Lake Park', state: 'New Jersey', country: 'US' },
  { name: 'Leitchfield', state: 'Kentucky', country: 'US' },
  { name: 'Lemay', state: 'Missouri', country: 'US' },
  { name: 'Lemmon Valley-Golden Valley', state: 'Nevada', country: 'US' },
  { name: 'Lemon Grove', state: 'California', country: 'US' },
  { name: 'Lemont', state: 'Illinois', country: 'US' },
  { name: 'Lemoore', state: 'California', country: 'US' },
  { name: 'Lenexa', state: 'Kansas', country: 'US' },
  { name: 'Lennox', state: 'California', country: 'US' },
  { name: 'Lenoir', state: 'North Carolina', country: 'US' },
  { name: 'Lenoir City', state: 'Tennessee', country: 'US' },
  { name: 'Lenox', state: 'New York', country: 'US' },
  { name: 'Leominster', state: 'Massachusetts', country: 'US' },
  { name: 'Leon Valley', state: 'Texas', country: 'US' },
  { name: 'Leonia', state: 'New Jersey', country: 'US' },
  { name: 'Levelland', state: 'Texas', country: 'US' },
  { name: 'Levittown', state: 'Pennsylvania', country: 'US' },
  { name: 'Levittown', state: 'New York', country: 'US' },
  { name: 'Lewisboro', state: 'New York', country: 'US' },
  { name: 'Lewisburg', state: 'Tennessee', country: 'US' },
  { name: 'Lewiston', state: 'Maine', country: 'US' },
  { name: 'Lewiston', state: 'New York', country: 'US' },
  { name: 'Lewiston', state: 'Idaho', country: 'US' },
  { name: 'Lewistown', state: 'Pennsylvania', country: 'US' },
  { name: 'Lewisville', state: 'North Carolina', country: 'US' },
  { name: 'Lewisville', state: 'Texas', country: 'US' },
  { name: 'Lexington', state: 'Virginia', country: 'US' },
  { name: 'Lexington', state: 'North Carolina', country: 'US' },
  { name: 'Lexington', state: 'South Carolina', country: 'US' },
  { name: 'Lexington', state: 'Tennessee', country: 'US' },
  { name: 'Lexington', state: 'Massachusetts', country: 'US' },
  { name: 'Lexington', state: 'Nebraska', country: 'US' },
  { name: 'Lexington', state: 'Massachusetts', country: 'US' },
  { name: 'Lexington Park', state: 'Maryland', country: 'US' },
  { name: 'Lexington-Fayette', state: 'Kentucky', country: 'US' },
  { name: 'Liberal', state: 'Kansas', country: 'US' },
  { name: 'Liberty', state: 'Missouri', country: 'US' },
  { name: 'Liberty', state: 'New York', country: 'US' },
  { name: 'Liberty', state: 'Texas', country: 'US' },
  { name: 'Libertyville', state: 'Illinois', country: 'US' },
  { name: 'Lighthouse Point', state: 'Florida', country: 'US' },
  { name: 'Lilburn', state: 'Georgia', country: 'US' },
  { name: 'Lima', state: 'Ohio', country: 'US' },
  { name: 'Lincoln', state: 'Rhode Island', country: 'US' },
  { name: 'Lincoln', state: 'Nebraska', country: 'US' },
  { name: 'Lincoln', state: 'Massachusetts', country: 'US' },
  { name: 'Lincoln', state: 'Illinois', country: 'US' },
  { name: 'Lincoln', state: 'California', country: 'US' },
  { name: 'Lincoln City', state: 'Oregon', country: 'US' },
  { name: 'Lincoln Park', state: 'Michigan', country: 'US' },
  { name: 'Lincoln Park', state: 'New Jersey', country: 'US' },
  { name: 'Lincoln Village', state: 'Ohio', country: 'US' },
  { name: 'Lincolnia', state: 'Virginia', country: 'US' },
  { name: 'Lincolnshire', state: 'Illinois', country: 'US' },
  { name: 'Lincolnton', state: 'North Carolina', country: 'US' },
  { name: 'Lincolnwood', state: 'Illinois', country: 'US' },
  { name: 'Lincroft', state: 'New Jersey', country: 'US' },
  { name: 'Linda', state: 'California', country: 'US' },
  { name: 'Linden', state: 'New Jersey', country: 'US' },
  { name: 'Lindenhurst', state: 'New York', country: 'US' },
  { name: 'Lindenhurst', state: 'Illinois', country: 'US' },
  { name: 'Lindenwold', state: 'New Jersey', country: 'US' },
  { name: 'Lindon', state: 'Utah', country: 'US' },
  { name: 'Lindsay', state: 'California', country: 'US' },
  { name: 'Linganore-Bartonsville', state: 'Maryland', country: 'US' },
  { name: 'Linglestown', state: 'Pennsylvania', country: 'US' },
  { name: 'Lino Lakes', state: 'Minnesota', country: 'US' },
  { name: 'Linthicum', state: 'Maryland', country: 'US' },
  { name: 'Linton Hall', state: 'Virginia', country: 'US' },
  { name: 'Linwood', state: 'New Jersey', country: 'US' },
  { name: 'Lionville-Marchwood', state: 'Pennsylvania', country: 'US' },
  { name: 'Lisbon', state: 'Maine', country: 'US' },
  { name: 'Lisbon', state: 'Wisconsin', country: 'US' },
  { name: 'Lisle', state: 'Illinois', country: 'US' },
  { name: 'Litchfield', state: 'Illinois', country: 'US' },
  { name: 'Litchfield', state: 'Connecticut', country: 'US' },
  { name: 'Litchfield', state: 'Minnesota', country: 'US' },
  { name: 'Litchfield', state: 'New Hampshire', country: 'US' },
  { name: 'Lititz', state: 'Pennsylvania', country: 'US' },
  { name: 'Little Canada', state: 'Minnesota', country: 'US' },
  { name: 'Little Chute', state: 'Wisconsin', country: 'US' },
  { name: 'Little Cottonwood Creek Valley', state: 'Utah', country: 'US' },
  { name: 'Little Falls', state: 'Minnesota', country: 'US' },
  { name: 'Little Falls', state: 'New Jersey', country: 'US' },
  { name: 'Little Ferry', state: 'New Jersey', country: 'US' },
  { name: 'Little River', state: 'South Carolina', country: 'US' },
  { name: 'Little Rock', state: 'Arkansas', country: 'US' },
  { name: 'Little Silver', state: 'New Jersey', country: 'US' },
  { name: 'Littlefield', state: 'Texas', country: 'US' },
  { name: 'Littleton', state: 'Massachusetts', country: 'US' },
  { name: 'Littleton', state: 'Colorado', country: 'US' },
  { name: 'Live Oak', state: 'Florida', country: 'US' },
  { name: 'Live Oak', state: 'California', country: 'US' },
  { name: 'Live Oak', state: 'California', country: 'US' },
  { name: 'Live Oak', state: 'Texas', country: 'US' },
  { name: 'Livermore', state: 'California', country: 'US' },
  { name: 'Livingston', state: 'California', country: 'US' },
  { name: 'Livingston', state: 'Montana', country: 'US' },
  { name: 'Livingston', state: 'New Jersey', country: 'US' },
  { name: 'Livonia', state: 'New York', country: 'US' },
  { name: 'Livonia', state: 'Michigan', country: 'US' },
  { name: 'Lloyd', state: 'New York', country: 'US' },
  { name: 'Lochearn', state: 'Maryland', country: 'US' },
  { name: 'Lock Haven', state: 'Pennsylvania', country: 'US' },
  { name: 'Lockhart', state: 'Florida', country: 'US' },
  { name: 'Lockhart', state: 'Texas', country: 'US' },
  { name: 'Lockport', state: 'Illinois', country: 'US' },
  { name: 'Lockport', state: 'New York', country: 'US' },
  { name: 'Lockport', state: 'New York', country: 'US' },
  { name: 'Lodi', state: 'New Jersey', country: 'US' },
  { name: 'Lodi', state: 'California', country: 'US' },
  { name: 'Logan', state: 'Ohio', country: 'US' },
  { name: 'Logan', state: 'Utah', country: 'US' },
  { name: 'Logansport', state: 'Indiana', country: 'US' },
  { name: 'Loma Linda', state: 'California', country: 'US' },
  { name: 'Lombard', state: 'Illinois', country: 'US' },
  { name: 'Lomita', state: 'California', country: 'US' },
  { name: 'Lompoc', state: 'California', country: 'US' },
  { name: 'London', state: 'Ohio', country: 'US' },
  { name: 'Londonderry', state: 'New Hampshire', country: 'US' },
  { name: 'Londonderry', state: 'New Hampshire', country: 'US' },
  { name: 'Londontowne', state: 'Maryland', country: 'US' },
  { name: 'Long Beach', state: 'California', country: 'US' },
  { name: 'Long Beach', state: 'Mississippi', country: 'US' },
  { name: 'Long Beach', state: 'New York', country: 'US' },
  { name: 'Long Branch', state: 'New Jersey', country: 'US' },
  { name: 'Long Grove', state: 'Illinois', country: 'US' },
  { name: 'Longboat Key', state: 'Florida', country: 'US' },
  { name: 'Longmeadow', state: 'Massachusetts', country: 'US' },
  { name: 'Longmeadow', state: 'Massachusetts', country: 'US' },
  { name: 'Longmont', state: 'Colorado', country: 'US' },
  { name: 'Longview', state: 'Washington', country: 'US' },
  { name: 'Longview', state: 'Texas', country: 'US' },
  { name: 'Longwood', state: 'Florida', country: 'US' },
  { name: 'Loomis', state: 'California', country: 'US' },
  { name: 'Lorain', state: 'Ohio', country: 'US' },
  { name: 'Lorton', state: 'Virginia', country: 'US' },
  { name: 'Los Alamitos', state: 'California', country: 'US' },
  { name: 'Los Alamos', state: 'New Mexico', country: 'US' },
  { name: 'Los Altos', state: 'California', country: 'US' },
  { name: 'Los Altos Hills', state: 'California', country: 'US' },
  { name: 'Los Angeles', state: 'California', country: 'US' },
  { name: 'Los Banos', state: 'California', country: 'US' },
  { name: 'Los Gatos', state: 'California', country: 'US' },
  { name: 'Los Lunas', state: 'New Mexico', country: 'US' },
  { name: 'Louisville', state: 'Mississippi', country: 'US' },
  { name: 'Louisville', state: 'Ohio', country: 'US' },
  { name: 'Louisville', state: 'Colorado', country: 'US' },
  { name: 'Louisville', state: 'Kentucky', country: 'US' },
  { name: 'Loveland', state: 'Colorado', country: 'US' },
  { name: 'Loveland', state: 'Ohio', country: 'US' },
  { name: 'Loves Park', state: 'Illinois', country: 'US' },
  { name: 'Lovington', state: 'New Mexico', country: 'US' },
  { name: 'Lowell', state: 'Massachusetts', country: 'US' },
  { name: 'Lowell', state: 'Indiana', country: 'US' },
  { name: 'Lower Allen', state: 'Pennsylvania', country: 'US' },
  { name: 'Lower Burrell', state: 'Pennsylvania', country: 'US' },
  { name: 'Lubbock', state: 'Texas', country: 'US' },
  { name: 'Lucas Valley-Marinwood', state: 'California', country: 'US' },
  { name: 'Ludington', state: 'Michigan', country: 'US' },
  { name: 'Ludlow', state: 'Massachusetts', country: 'US' },
  { name: 'Lufkin', state: 'Texas', country: 'US' },
  { name: 'Lugoff', state: 'South Carolina', country: 'US' },
  { name: 'Luling', state: 'Louisiana', country: 'US' },
  { name: 'Lumberton', state: 'North Carolina', country: 'US' },
  { name: 'Lumberton', state: 'Texas', country: 'US' },
  { name: 'Lunenburg', state: 'Massachusetts', country: 'US' },
  { name: 'Lutherville-Timonium', state: 'Maryland', country: 'US' },
  { name: 'Lutz', state: 'Florida', country: 'US' },
  { name: 'Lynbrook', state: 'New York', country: 'US' },
  { name: 'Lynchburg', state: 'Virginia', country: 'US' },
  { name: 'Lynden', state: 'Washington', country: 'US' },
  { name: 'Lyndhurst', state: 'Ohio', country: 'US' },
  { name: 'Lyndhurst', state: 'New Jersey', country: 'US' },
  { name: 'Lyndon', state: 'Kentucky', country: 'US' },
  { name: 'Lynn', state: 'Massachusetts', country: 'US' },
  { name: 'Lynn Haven', state: 'Florida', country: 'US' },
  { name: 'Lynnfield', state: 'Massachusetts', country: 'US' },
  { name: 'Lynnfield', state: 'Massachusetts', country: 'US' },
  { name: 'Lynnwood', state: 'Washington', country: 'US' },
  { name: 'Lynwood', state: 'Illinois', country: 'US' },
  { name: 'Lynwood', state: 'California', country: 'US' },
  { name: 'Lyons', state: 'Illinois', country: 'US' },
  { name: 'Lysander', state: 'New York', country: 'US' },
  { name: 'Mableton', state: 'Georgia', country: 'US' },
  { name: 'Macedon', state: 'New York', country: 'US' },
  { name: 'Macedonia', state: 'Ohio', country: 'US' },
  { name: 'Machesney Park', state: 'Illinois', country: 'US' },
  { name: 'Macomb', state: 'Illinois', country: 'US' },
  { name: 'Macon', state: 'Georgia', country: 'US' },
  { name: 'Madeira', state: 'Ohio', country: 'US' },
  { name: 'Madera', state: 'California', country: 'US' },
  { name: 'Madera Acres', state: 'California', country: 'US' },
  { name: 'Madison', state: 'Alabama', country: 'US' },
  { name: 'Madison', state: 'Indiana', country: 'US' },
  { name: 'Madison', state: 'Connecticut', country: 'US' },
  { name: 'Madison', state: 'New Jersey', country: 'US' },
  { name: 'Madison', state: 'Mississippi', country: 'US' },
  { name: 'Madison', state: 'South Dakota', country: 'US' },
  { name: 'Madison', state: 'Wisconsin', country: 'US' },
  { name: 'Madison', state: 'Wisconsin', country: 'US' },
  { name: 'Madison Heights', state: 'Virginia', country: 'US' },
  { name: 'Madison Heights', state: 'Michigan', country: 'US' },
  { name: 'Madison Park', state: 'New Jersey', country: 'US' },
  { name: 'Madisonville', state: 'Kentucky', country: 'US' },
  { name: 'Magalia', state: 'California', country: 'US' },
  { name: 'Magna', state: 'Utah', country: 'US' },
  { name: 'Magnolia', state: 'Arkansas', country: 'US' },
  { name: 'Mahopac', state: 'New York', country: 'US' },
  { name: 'Mahtomedi', state: 'Minnesota', country: 'US' },
  { name: 'Maitland', state: 'Florida', country: 'US' },
  { name: 'Makaha', state: 'Hawaii', country: 'US' },
  { name: 'Makakilo City', state: 'Hawaii', country: 'US' },
  { name: 'Makawao', state: 'Hawaii', country: 'US' },
  { name: 'Malden', state: 'Massachusetts', country: 'US' },
  { name: 'Malibu', state: 'California', country: 'US' },
  { name: 'Malone', state: 'New York', country: 'US' },
  { name: 'Malone', state: 'New York', country: 'US' },
  { name: 'Malta', state: 'New York', country: 'US' },
  { name: 'Maltby', state: 'Washington', country: 'US' },
  { name: 'Malvern', state: 'Arkansas', country: 'US' },
  { name: 'Malverne', state: 'New York', country: 'US' },
  { name: 'Mamakating', state: 'New York', country: 'US' },
  { name: 'Mamaroneck', state: 'New York', country: 'US' },
  { name: 'Mamaroneck', state: 'New York', country: 'US' },
  { name: 'Mammoth Lakes', state: 'California', country: 'US' },
  { name: 'Manasquan', state: 'New Jersey', country: 'US' },
  { name: 'Manassas', state: 'Virginia', country: 'US' },
  { name: 'Manassas Park', state: 'Virginia', country: 'US' },
  { name: 'Manchester', state: 'Tennessee', country: 'US' },
  { name: 'Manchester', state: 'New Hampshire', country: 'US' },
  { name: 'Manchester', state: 'New York', country: 'US' },
  { name: 'Manchester', state: 'Missouri', country: 'US' },
  { name: 'Manchester', state: 'Connecticut', country: 'US' },
  { name: 'Mandan', state: 'North Dakota', country: 'US' },
  { name: 'Mandeville', state: 'Louisiana', country: 'US' },
  { name: 'Mango', state: 'Florida', country: 'US' },
  { name: 'Manhasset', state: 'New York', country: 'US' },
  { name: 'Manhattan', state: 'Kansas', country: 'US' },
  { name: 'Manhattan Beach', state: 'California', country: 'US' },
  { name: 'Manistee', state: 'Michigan', country: 'US' },
  { name: 'Manitowoc', state: 'Wisconsin', country: 'US' },
  { name: 'Mankato', state: 'Minnesota', country: 'US' },
  { name: 'Manlius', state: 'New York', country: 'US' },
  { name: 'Manorhaven', state: 'New York', country: 'US' },
  { name: 'Manorville', state: 'New York', country: 'US' },
  { name: 'Mansfield', state: 'Massachusetts', country: 'US' },
  { name: 'Mansfield', state: 'Connecticut', country: 'US' },
  { name: 'Mansfield', state: 'Texas', country: 'US' },
  { name: 'Mansfield', state: 'Ohio', country: 'US' },
  { name: 'Mansfield Center', state: 'Massachusetts', country: 'US' },
  { name: 'Manteca', state: 'California', country: 'US' },
  { name: 'Manteno', state: 'Illinois', country: 'US' },
  { name: 'Mantua', state: 'Virginia', country: 'US' },
  { name: 'Manville', state: 'New Jersey', country: 'US' },
  { name: 'Maple Glen', state: 'Pennsylvania', country: 'US' },
  { name: 'Maple Grove', state: 'Minnesota', country: 'US' },
  { name: 'Maple Heights', state: 'Ohio', country: 'US' },
  { name: 'Maple Valley', state: 'Washington', country: 'US' },
  { name: 'Maplewood', state: 'Minnesota', country: 'US' },
  { name: 'Maplewood', state: 'Missouri', country: 'US' },
  { name: 'Maplewood', state: 'New Jersey', country: 'US' },
  { name: 'Maquoketa', state: 'Iowa', country: 'US' },
  { name: 'Marana', state: 'Arizona', country: 'US' },
  { name: 'Marathon', state: 'Florida', country: 'US' },
  { name: 'Marblehead', state: 'Massachusetts', country: 'US' },
  { name: 'Marblehead', state: 'Massachusetts', country: 'US' },
  { name: 'Marcellus', state: 'New York', country: 'US' },
  { name: 'Marco Island', state: 'Florida', country: 'US' },
  { name: 'Marcy', state: 'New York', country: 'US' },
  { name: 'Marengo', state: 'Illinois', country: 'US' },
  { name: 'Margate', state: 'Florida', country: 'US' },
  { name: 'Margate City', state: 'New Jersey', country: 'US' },
  { name: 'Marianna', state: 'Florida', country: 'US' },
  { name: 'Marietta', state: 'Georgia', country: 'US' },
  { name: 'Marietta', state: 'Ohio', country: 'US' },
  { name: 'Marina', state: 'California', country: 'US' },
  { name: 'Marina del Rey', state: 'California', country: 'US' },
  { name: 'Marinette', state: 'Wisconsin', country: 'US' },
  { name: 'Marion', state: 'Virginia', country: 'US' },
  { name: 'Marion', state: 'Ohio', country: 'US' },
  { name: 'Marion', state: 'South Carolina', country: 'US' },
  { name: 'Marion', state: 'Arkansas', country: 'US' },
  { name: 'Marion', state: 'Indiana', country: 'US' },
  { name: 'Marion', state: 'Iowa', country: 'US' },
  { name: 'Marion', state: 'Illinois', country: 'US' },
  { name: 'Markham', state: 'Illinois', country: 'US' },
  { name: 'Marlborough', state: 'New York', country: 'US' },
  { name: 'Marlborough', state: 'Massachusetts', country: 'US' },
  { name: 'Marlin', state: 'Texas', country: 'US' },
  { name: 'Marlow Heights', state: 'Maryland', country: 'US' },
  { name: 'Marlton', state: 'Maryland', country: 'US' },
  { name: 'Marlton', state: 'New Jersey', country: 'US' },
  { name: 'Marquette', state: 'Michigan', country: 'US' },
  { name: 'Marrero', state: 'Louisiana', country: 'US' },
  { name: 'Marshall', state: 'Michigan', country: 'US' },
  { name: 'Marshall', state: 'Minnesota', country: 'US' },
  { name: 'Marshall', state: 'Missouri', country: 'US' },
  { name: 'Marshall', state: 'Texas', country: 'US' },
  { name: 'Marshalltown', state: 'Iowa', country: 'US' },
  { name: 'Marshfield', state: 'Massachusetts', country: 'US' },
  { name: 'Marshfield', state: 'Wisconsin', country: 'US' },
  { name: 'Martha Lake', state: 'Washington', country: 'US' },
  { name: 'Martin', state: 'Tennessee', country: 'US' },
  { name: 'Martinez', state: 'Georgia', country: 'US' },
  { name: 'Martinez', state: 'California', country: 'US' },
  { name: 'Martins Ferry', state: 'Ohio', country: 'US' },
  { name: 'Martinsburg', state: 'West Virginia', country: 'US' },
  { name: 'Martinsville', state: 'Virginia', country: 'US' },
  { name: 'Martinsville', state: 'Indiana', country: 'US' },
  { name: 'Maryland City', state: 'Maryland', country: 'US' },
  { name: 'Maryland Heights', state: 'Missouri', country: 'US' },
  { name: 'Marysville', state: 'Michigan', country: 'US' },
  { name: 'Marysville', state: 'California', country: 'US' },
  { name: 'Marysville', state: 'Washington', country: 'US' },
  { name: 'Marysville', state: 'Ohio', country: 'US' },
  { name: 'Maryville', state: 'Tennessee', country: 'US' },
  { name: 'Maryville', state: 'Missouri', country: 'US' },
  { name: 'Mashpee', state: 'Massachusetts', country: 'US' },
  { name: 'Mason', state: 'Michigan', country: 'US' },
  { name: 'Mason', state: 'Ohio', country: 'US' },
  { name: 'Mason City', state: 'Iowa', country: 'US' },
  { name: 'Masonboro', state: 'North Carolina', country: 'US' },
  { name: 'Massapequa', state: 'New York', country: 'US' },
  { name: 'Massapequa Park', state: 'New York', country: 'US' },
  { name: 'Massena', state: 'New York', country: 'US' },
  { name: 'Massena', state: 'New York', country: 'US' },
  { name: 'Massillon', state: 'Ohio', country: 'US' },
  { name: 'Mastic', state: 'New York', country: 'US' },
  { name: 'Mastic Beach', state: 'New York', country: 'US' },
  { name: 'Matawan', state: 'New Jersey', country: 'US' },
  { name: 'Mattapoisett', state: 'Massachusetts', country: 'US' },
  { name: 'Matteson', state: 'Illinois', country: 'US' },
  { name: 'Matthews', state: 'North Carolina', country: 'US' },
  { name: 'Mattoon', state: 'Illinois', country: 'US' },
  { name: 'Mattydale', state: 'New York', country: 'US' },
  { name: 'Mauldin', state: 'South Carolina', country: 'US' },
  { name: 'Maumee', state: 'Ohio', country: 'US' },
  { name: 'Maumelle', state: 'Arkansas', country: 'US' },
  { name: 'Mayfield', state: 'Kentucky', country: 'US' },
  { name: 'Mayfield', state: 'New York', country: 'US' },
  { name: 'Mayfield Heights', state: 'Ohio', country: 'US' },
  { name: 'Maynard', state: 'Massachusetts', country: 'US' },
  { name: 'Maynard', state: 'Massachusetts', country: 'US' },
  { name: 'Mays Chapel', state: 'Maryland', country: 'US' },
  { name: 'Maysville', state: 'Kentucky', country: 'US' },
  { name: 'Maywood', state: 'Illinois', country: 'US' },
  { name: 'Maywood', state: 'California', country: 'US' },
  { name: 'Maywood', state: 'New Jersey', country: 'US' },
  { name: 'McAlester', state: 'Oklahoma', country: 'US' },
  { name: 'McAllen', state: 'Texas', country: 'US' },
  { name: 'McCandless Township', state: 'Pennsylvania', country: 'US' },
  { name: 'McComb', state: 'Mississippi', country: 'US' },
  { name: 'McCook', state: 'Nebraska', country: 'US' },
  { name: 'McDonough', state: 'Georgia', country: 'US' },
  { name: 'McFarland', state: 'California', country: 'US' },
  { name: 'McFarland', state: 'Wisconsin', country: 'US' },
  { name: 'McGregor', state: 'Florida', country: 'US' },
  { name: 'McGuire AFB', state: 'New Jersey', country: 'US' },
  { name: 'McHenry', state: 'Illinois', country: 'US' },
  { name: 'McKees Rocks', state: 'Pennsylvania', country: 'US' },
  { name: 'McKeesport', state: 'Pennsylvania', country: 'US' },
  { name: 'McKinleyville', state: 'California', country: 'US' },
  { name: 'McKinney', state: 'Texas', country: 'US' },
  { name: 'McLean', state: 'Virginia', country: 'US' },
  { name: 'McMinnville', state: 'Tennessee', country: 'US' },
  { name: 'McMinnville', state: 'Oregon', country: 'US' },
  { name: 'McPherson', state: 'Kansas', country: 'US' },
  { name: 'Meadow Woods', state: 'Florida', country: 'US' },
  { name: 'Meadville', state: 'Pennsylvania', country: 'US' },
  { name: 'Mebane', state: 'North Carolina', country: 'US' },
  { name: 'Mechanicsburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Mechanicstown', state: 'New York', country: 'US' },
  { name: 'Mechanicsville', state: 'Virginia', country: 'US' },
  { name: 'Medfield', state: 'Massachusetts', country: 'US' },
  { name: 'Medfield', state: 'Massachusetts', country: 'US' },
  { name: 'Medford', state: 'Massachusetts', country: 'US' },
  { name: 'Medford', state: 'New York', country: 'US' },
  { name: 'Medford', state: 'Oregon', country: 'US' },
  { name: 'Medina', state: 'Ohio', country: 'US' },
  { name: 'Medina', state: 'New York', country: 'US' },
  { name: 'Medulla', state: 'Florida', country: 'US' },
  { name: 'Medway', state: 'Massachusetts', country: 'US' },
  { name: 'Mehlville', state: 'Missouri', country: 'US' },
  { name: 'Melbourne', state: 'Florida', country: 'US' },
  { name: 'Melrose', state: 'Massachusetts', country: 'US' },
  { name: 'Melrose Park', state: 'Florida', country: 'US' },
  { name: 'Melrose Park', state: 'Illinois', country: 'US' },
  { name: 'Melville', state: 'New York', country: 'US' },
  { name: 'Melvindale', state: 'Michigan', country: 'US' },
  { name: 'Memphis', state: 'Florida', country: 'US' },
  { name: 'Memphis', state: 'Tennessee', country: 'US' },
  { name: 'Menasha', state: 'Wisconsin', country: 'US' },
  { name: 'Menasha', state: 'Wisconsin', country: 'US' },
  { name: 'Mendon', state: 'New York', country: 'US' },
  { name: 'Mendota', state: 'California', country: 'US' },
  { name: 'Mendota', state: 'Illinois', country: 'US' },
  { name: 'Mendota Heights', state: 'Minnesota', country: 'US' },
  { name: 'Menlo Park', state: 'California', country: 'US' },
  { name: 'Menominee', state: 'Michigan', country: 'US' },
  { name: 'Menomonee Falls', state: 'Wisconsin', country: 'US' },
  { name: 'Menomonie', state: 'Wisconsin', country: 'US' },
  { name: 'Mentone', state: 'California', country: 'US' },
  { name: 'Mentor', state: 'Ohio', country: 'US' },
  { name: 'Mentor-on-the-Lake', state: 'Ohio', country: 'US' },
  { name: 'Mequon', state: 'Wisconsin', country: 'US' },
  { name: 'Meraux', state: 'Louisiana', country: 'US' },
  { name: 'Merced', state: 'California', country: 'US' },
  { name: 'Mercedes', state: 'Texas', country: 'US' },
  { name: 'Mercer Island', state: 'Washington', country: 'US' },
  { name: 'Mercerville-Hamilton Square', state: 'New Jersey', country: 'US' },
  { name: 'Meriden', state: 'Connecticut', country: 'US' },
  { name: 'Meriden', state: 'Connecticut', country: 'US' },
  { name: 'Meridian', state: 'Idaho', country: 'US' },
  { name: 'Meridian', state: 'Mississippi', country: 'US' },
  { name: 'Merriam', state: 'Kansas', country: 'US' },
  { name: 'Merrick', state: 'New York', country: 'US' },
  { name: 'Merrifield', state: 'Virginia', country: 'US' },
  { name: 'Merrill', state: 'Wisconsin', country: 'US' },
  { name: 'Merrillville', state: 'Indiana', country: 'US' },
  { name: 'Merrimac', state: 'Massachusetts', country: 'US' },
  { name: 'Merrimack', state: 'New Hampshire', country: 'US' },
  { name: 'Merritt Island', state: 'Florida', country: 'US' },
  { name: 'Merrydale', state: 'Louisiana', country: 'US' },
  { name: 'Merton', state: 'Wisconsin', country: 'US' },
  { name: 'Mesa', state: 'Arizona', country: 'US' },
  { name: 'Mesquite', state: 'Nevada', country: 'US' },
  { name: 'Mesquite', state: 'Texas', country: 'US' },
  { name: 'Metairie', state: 'Louisiana', country: 'US' },
  { name: 'Methuen', state: 'Massachusetts', country: 'US' },
  { name: 'Metropolis', state: 'Illinois', country: 'US' },
  { name: 'Metuchen', state: 'New Jersey', country: 'US' },
  { name: 'Mexia', state: 'Texas', country: 'US' },
  { name: 'Mexico', state: 'Missouri', country: 'US' },
  { name: 'Miami', state: 'Florida', country: 'US' },
  { name: 'Miami', state: 'Oklahoma', country: 'US' },
  { name: 'Miami Beach', state: 'Florida', country: 'US' },
  { name: 'Miami Lakes', state: 'Florida', country: 'US' },
  { name: 'Miami Shores', state: 'Florida', country: 'US' },
  { name: 'Miami Springs', state: 'Florida', country: 'US' },
  { name: 'Miamisburg', state: 'Ohio', country: 'US' },
  { name: 'Micco', state: 'Florida', country: 'US' },
  { name: 'Michigan City', state: 'Indiana', country: 'US' },
  { name: 'Middle Island', state: 'New York', country: 'US' },
  { name: 'Middle River', state: 'Maryland', country: 'US' },
  { name: 'Middle Valley', state: 'Tennessee', country: 'US' },
  { name: 'Middleborough', state: 'Massachusetts', country: 'US' },
  { name: 'Middleborough Center', state: 'Massachusetts', country: 'US' },
  { name: 'Middleburg', state: 'Florida', country: 'US' },
  { name: 'Middleburg Heights', state: 'Ohio', country: 'US' },
  { name: 'Middlebury', state: 'Vermont', country: 'US' },
  { name: 'Middlebury', state: 'Vermont', country: 'US' },
  { name: 'Middlebury', state: 'Connecticut', country: 'US' },
  { name: 'Middlesborough', state: 'Kentucky', country: 'US' },
  { name: 'Middlesex', state: 'New Jersey', country: 'US' },
  { name: 'Middleton', state: 'Massachusetts', country: 'US' },
  { name: 'Middleton', state: 'Wisconsin', country: 'US' },
  { name: 'Middletown', state: 'Ohio', country: 'US' },
  { name: 'Middletown', state: 'Pennsylvania', country: 'US' },
  { name: 'Middletown', state: 'Pennsylvania', country: 'US' },
  { name: 'Middletown', state: 'Rhode Island', country: 'US' },
  { name: 'Middletown', state: 'New York', country: 'US' },
  { name: 'Middletown', state: 'Connecticut', country: 'US' },
  { name: 'Middletown', state: 'Connecticut', country: 'US' },
  { name: 'Middletown', state: 'Delaware', country: 'US' },
  { name: 'Midland', state: 'Michigan', country: 'US' },
  { name: 'Midland', state: 'Texas', country: 'US' },
  { name: 'Midland', state: 'Washington', country: 'US' },
  { name: 'Midland Park', state: 'New Jersey', country: 'US' },
  { name: 'Midlothian', state: 'Illinois', country: 'US' },
  { name: 'Midlothian', state: 'Texas', country: 'US' },
  { name: 'Midvale', state: 'Utah', country: 'US' },
  { name: 'Midwest City', state: 'Oklahoma', country: 'US' },
  { name: 'Milan', state: 'Tennessee', country: 'US' },
  { name: 'Miles City', state: 'Montana', country: 'US' },
  { name: 'Milford', state: 'New Hampshire', country: 'US' },
  { name: 'Milford', state: 'New Hampshire', country: 'US' },
  { name: 'Milford', state: 'Michigan', country: 'US' },
  { name: 'Milford', state: 'Massachusetts', country: 'US' },
  { name: 'Milford', state: 'Massachusetts', country: 'US' },
  { name: 'Milford', state: 'Delaware', country: 'US' },
  { name: 'Milford', state: 'Connecticut', country: 'US' },
  { name: 'Milford', state: 'Connecticut', country: 'US' },
  { name: 'Milford', state: 'Ohio', country: 'US' },
  { name: 'Milford Mill', state: 'Maryland', country: 'US' },
  { name: 'Mililani Town', state: 'Hawaii', country: 'US' },
  { name: 'Mill Creek', state: 'Washington', country: 'US' },
  { name: 'Mill Plain', state: 'Washington', country: 'US' },
  { name: 'Mill Valley', state: 'California', country: 'US' },
  { name: 'Millbrae', state: 'California', country: 'US' },
  { name: 'Millbrook', state: 'Alabama', country: 'US' },
  { name: 'Millburn', state: 'New Jersey', country: 'US' },
  { name: 'Millbury', state: 'Massachusetts', country: 'US' },
  { name: 'Millcreek', state: 'Utah', country: 'US' },
  { name: 'Milledgeville', state: 'Georgia', country: 'US' },
  { name: 'Miller Place', state: 'New York', country: 'US' },
  { name: 'Millersville', state: 'Pennsylvania', country: 'US' },
  { name: 'Millington', state: 'Tennessee', country: 'US' },
  { name: 'Millis', state: 'Massachusetts', country: 'US' },
  { name: 'Milltown', state: 'New Jersey', country: 'US' },
  { name: 'Millville', state: 'New Jersey', country: 'US' },
  { name: 'Milo', state: 'New York', country: 'US' },
  { name: 'Milpitas', state: 'California', country: 'US' },
  { name: 'Milton', state: 'Florida', country: 'US' },
  { name: 'Milton', state: 'New York', country: 'US' },
  { name: 'Milton', state: 'Massachusetts', country: 'US' },
  { name: 'Milton', state: 'Massachusetts', country: 'US' },
  { name: 'Milton', state: 'Pennsylvania', country: 'US' },
  { name: 'Milton', state: 'Vermont', country: 'US' },
  { name: 'Milton-Freewater', state: 'Oregon', country: 'US' },
  { name: 'Milwaukee', state: 'Wisconsin', country: 'US' },
  { name: 'Milwaukie', state: 'Oregon', country: 'US' },
  { name: 'Mims', state: 'Florida', country: 'US' },
  { name: 'Minden', state: 'Louisiana', country: 'US' },
  { name: 'Mineola', state: 'New York', country: 'US' },
  { name: 'Mineral Wells', state: 'Texas', country: 'US' },
  { name: 'Minneapolis', state: 'Minnesota', country: 'US' },
  { name: 'Minnehaha', state: 'Washington', country: 'US' },
  { name: 'Minnetonka', state: 'Minnesota', country: 'US' },
  { name: 'Minot', state: 'North Dakota', country: 'US' },
  { name: 'Minot AFB', state: 'North Dakota', country: 'US' },
  { name: 'Mint Hill', state: 'North Carolina', country: 'US' },
  { name: 'Mira Loma', state: 'California', country: 'US' },
  { name: 'Mira Monte', state: 'California', country: 'US' },
  { name: 'Miramar', state: 'Florida', country: 'US' },
  { name: 'Mishawaka', state: 'Indiana', country: 'US' },
  { name: 'Mission', state: 'Kansas', country: 'US' },
  { name: 'Mission', state: 'Texas', country: 'US' },
  { name: 'Mission Bend', state: 'Texas', country: 'US' },
  { name: 'Mission Viejo', state: 'California', country: 'US' },
  { name: 'Missoula', state: 'Montana', country: 'US' },
  { name: 'Missouri City', state: 'Texas', country: 'US' },
  { name: 'Mitchell', state: 'South Dakota', country: 'US' },
  { name: 'Mitchellville', state: 'Maryland', country: 'US' },
  { name: 'Moberly', state: 'Missouri', country: 'US' },
  { name: 'Mobile', state: 'Alabama', country: 'US' },
  { name: 'Modesto', state: 'California', country: 'US' },
  { name: 'Mohave Valley', state: 'Arizona', country: 'US' },
  { name: 'Mokena', state: 'Illinois', country: 'US' },
  { name: 'Moline', state: 'Illinois', country: 'US' },
  { name: 'Monaca', state: 'Pennsylvania', country: 'US' },
  { name: 'Monahans', state: 'Texas', country: 'US' },
  { name: 'Monessen', state: 'Pennsylvania', country: 'US' },
  { name: 'Monett', state: 'Missouri', country: 'US' },
  { name: 'Monmouth', state: 'Illinois', country: 'US' },
  { name: 'Monmouth', state: 'Oregon', country: 'US' },
  { name: 'Monona', state: 'Wisconsin', country: 'US' },
  { name: 'Monroe', state: 'Wisconsin', country: 'US' },
  { name: 'Monroe', state: 'Washington', country: 'US' },
  { name: 'Monroe', state: 'Ohio', country: 'US' },
  { name: 'Monroe', state: 'Connecticut', country: 'US' },
  { name: 'Monroe', state: 'Georgia', country: 'US' },
  { name: 'Monroe', state: 'Michigan', country: 'US' },
  { name: 'Monroe', state: 'Louisiana', country: 'US' },
  { name: 'Monroe', state: 'North Carolina', country: 'US' },
  { name: 'Monroe', state: 'New York', country: 'US' },
  { name: 'Monroe', state: 'New York', country: 'US' },
  { name: 'Monroeville', state: 'Alabama', country: 'US' },
  { name: 'Monrovia', state: 'California', country: 'US' },
  { name: 'Monsey', state: 'New York', country: 'US' },
  { name: 'Monson', state: 'Massachusetts', country: 'US' },
  { name: 'Montague', state: 'Massachusetts', country: 'US' },
  { name: 'Montclair', state: 'New Jersey', country: 'US' },
  { name: 'Montclair', state: 'California', country: 'US' },
  { name: 'Montclair', state: 'Virginia', country: 'US' },
  { name: 'Montebello', state: 'California', country: 'US' },
  { name: 'Montecito', state: 'California', country: 'US' },
  { name: 'Monterey', state: 'California', country: 'US' },
  { name: 'Monterey Park', state: 'California', country: 'US' },
  { name: 'Montgomery', state: 'Alabama', country: 'US' },
  { name: 'Montgomery', state: 'New York', country: 'US' },
  { name: 'Montgomery', state: 'Ohio', country: 'US' },
  { name: 'Montgomery Village', state: 'Maryland', country: 'US' },
  { name: 'Montgomeryville', state: 'Pennsylvania', country: 'US' },
  { name: 'Monticello', state: 'Minnesota', country: 'US' },
  { name: 'Monticello', state: 'New York', country: 'US' },
  { name: 'Monticello', state: 'Arkansas', country: 'US' },
  { name: 'Montpelier', state: 'Vermont', country: 'US' },
  { name: 'Montrose', state: 'Virginia', country: 'US' },
  { name: 'Montrose', state: 'Colorado', country: 'US' },
  { name: 'Montvale', state: 'New Jersey', country: 'US' },
  { name: 'Montville', state: 'Connecticut', country: 'US' },
  { name: 'Moody', state: 'Alabama', country: 'US' },
  { name: 'Moore', state: 'Oklahoma', country: 'US' },
  { name: 'Moorestown-Lenola', state: 'New Jersey', country: 'US' },
  { name: 'Mooresville', state: 'North Carolina', country: 'US' },
  { name: 'Mooresville', state: 'Indiana', country: 'US' },
  { name: 'Moorhead', state: 'Minnesota', country: 'US' },
  { name: 'Moorpark', state: 'California', country: 'US' },
  { name: 'Moraga', state: 'California', country: 'US' },
  { name: 'Moraine', state: 'Ohio', country: 'US' },
  { name: 'Moreau', state: 'New York', country: 'US' },
  { name: 'Morehead City', state: 'North Carolina', country: 'US' },
  { name: 'Moreno Valley', state: 'California', country: 'US' },
  { name: 'Morgan City', state: 'Louisiana', country: 'US' },
  { name: 'Morgan Hill', state: 'California', country: 'US' },
  { name: 'Morganton', state: 'North Carolina', country: 'US' },
  { name: 'Morgantown', state: 'West Virginia', country: 'US' },
  { name: 'Morganville', state: 'New Jersey', country: 'US' },
  { name: 'Morrilton', state: 'Arkansas', country: 'US' },
  { name: 'Morris', state: 'Illinois', country: 'US' },
  { name: 'Morristown', state: 'New Jersey', country: 'US' },
  { name: 'Morristown', state: 'Tennessee', country: 'US' },
  { name: 'Morrisville', state: 'Pennsylvania', country: 'US' },
  { name: 'Morro Bay', state: 'California', country: 'US' },
  { name: 'Morton', state: 'Illinois', country: 'US' },
  { name: 'Morton Grove', state: 'Illinois', country: 'US' },
  { name: 'Moscow', state: 'Idaho', country: 'US' },
  { name: 'Moses Lake', state: 'Washington', country: 'US' },
  { name: 'Moss Bluff', state: 'Louisiana', country: 'US' },
  { name: 'Moss Point', state: 'Mississippi', country: 'US' },
  { name: 'Moultrie', state: 'Georgia', country: 'US' },
  { name: 'Mound', state: 'Minnesota', country: 'US' },
  { name: 'Mounds View', state: 'Minnesota', country: 'US' },
  { name: 'Moundsville', state: 'West Virginia', country: 'US' },
  { name: 'Mount Airy', state: 'Maryland', country: 'US' },
  { name: 'Mount Airy', state: 'North Carolina', country: 'US' },
  { name: 'Mount Carmel', state: 'Illinois', country: 'US' },
  { name: 'Mount Carmel', state: 'Pennsylvania', country: 'US' },
  { name: 'Mount Clemens', state: 'Michigan', country: 'US' },
  { name: 'Mount Dora', state: 'Florida', country: 'US' },
  { name: 'Mount Healthy', state: 'Ohio', country: 'US' },
  { name: 'Mount Holly', state: 'North Carolina', country: 'US' },
  { name: 'Mount Hope', state: 'New York', country: 'US' },
  { name: 'Mount Ivy', state: 'New York', country: 'US' },
  { name: 'Mount Joy', state: 'Pennsylvania', country: 'US' },
  { name: 'Mount Juliet', state: 'Tennessee', country: 'US' },
  { name: 'Mount Kisco', state: 'New York', country: 'US' },
  { name: 'Mount Kisco', state: 'New York', country: 'US' },
  { name: 'Mount Lebanon', state: 'Pennsylvania', country: 'US' },
  { name: 'Mount Olympus', state: 'Utah', country: 'US' },
  { name: 'Mount Pleasant', state: 'Texas', country: 'US' },
  { name: 'Mount Pleasant', state: 'Wisconsin', country: 'US' },
  { name: 'Mount Pleasant', state: 'South Carolina', country: 'US' },
  { name: 'Mount Pleasant', state: 'New York', country: 'US' },
  { name: 'Mount Pleasant', state: 'Michigan', country: 'US' },
  { name: 'Mount Pleasant', state: 'Iowa', country: 'US' },
  { name: 'Mount Prospect', state: 'Illinois', country: 'US' },
  { name: 'Mount Rainier', state: 'Maryland', country: 'US' },
  { name: 'Mount Sinai', state: 'New York', country: 'US' },
  { name: 'Mount Vernon', state: 'New York', country: 'US' },
  { name: 'Mount Vernon', state: 'Illinois', country: 'US' },
  { name: 'Mount Vernon', state: 'Indiana', country: 'US' },
  { name: 'Mount Vernon', state: 'Ohio', country: 'US' },
  { name: 'Mount Vernon', state: 'Washington', country: 'US' },
  { name: 'Mount Vernon', state: 'Virginia', country: 'US' },
  { name: 'Mount Washington', state: 'Kentucky', country: 'US' },
  { name: 'Mountain Brook', state: 'Alabama', country: 'US' },
  { name: 'Mountain Home', state: 'Arkansas', country: 'US' },
  { name: 'Mountain Home', state: 'Idaho', country: 'US' },
  { name: 'Mountain Home AFB', state: 'Idaho', country: 'US' },
  { name: 'Mountain Park', state: 'Georgia', country: 'US' },
  { name: 'Mountain Top', state: 'Pennsylvania', country: 'US' },
  { name: 'Mountain View', state: 'California', country: 'US' },
  { name: 'Mountainside', state: 'New Jersey', country: 'US' },
  { name: 'Mountlake Terrace', state: 'Washington', country: 'US' },
  { name: 'Mukilteo', state: 'Washington', country: 'US' },
  { name: 'Mukwonago', state: 'Wisconsin', country: 'US' },
  { name: 'Mukwonago', state: 'Wisconsin', country: 'US' },
  { name: 'Muncie', state: 'Indiana', country: 'US' },
  { name: 'Mundelein', state: 'Illinois', country: 'US' },
  { name: 'Munhall', state: 'Pennsylvania', country: 'US' },
  { name: 'Municipality of Monroeville', state: 'Pennsylvania', country: 'US' },
  { name: 'Municipality of Murrysville', state: 'Pennsylvania', country: 'US' },
  { name: 'Munster', state: 'Indiana', country: 'US' },
  { name: 'Murfreesboro', state: 'Tennessee', country: 'US' },
  { name: 'Murphy', state: 'Missouri', country: 'US' },
  { name: 'Murphysboro', state: 'Illinois', country: 'US' },
  { name: 'Murray', state: 'Kentucky', country: 'US' },
  { name: 'Murray', state: 'New York', country: 'US' },
  { name: 'Murray', state: 'Utah', country: 'US' },
  { name: 'Murraysville', state: 'North Carolina', country: 'US' },
  { name: 'Murrieta', state: 'California', country: 'US' },
  { name: 'Muscatine', state: 'Iowa', country: 'US' },
  { name: 'Muscle Shoals', state: 'Alabama', country: 'US' },
  { name: 'Muscoy', state: 'California', country: 'US' },
  { name: 'Muskego', state: 'Wisconsin', country: 'US' },
  { name: 'Muskegon', state: 'Michigan', country: 'US' },
  { name: 'Muskegon Heights', state: 'Michigan', country: 'US' },
  { name: 'Muskogee', state: 'Oklahoma', country: 'US' },
  { name: 'Mustang', state: 'Oklahoma', country: 'US' },
  { name: 'Myrtle Beach', state: 'South Carolina', country: 'US' },
  { name: 'Myrtle Grove', state: 'North Carolina', country: 'US' },
  { name: 'Myrtle Grove', state: 'Florida', country: 'US' },
  { name: 'Mystic Island', state: 'New Jersey', country: 'US' },
  { name: 'Nacogdoches', state: 'Texas', country: 'US' },
  { name: 'Nampa', state: 'Idaho', country: 'US' },
  { name: 'Nanakuli', state: 'Hawaii', country: 'US' },
  { name: 'Nanticoke', state: 'Pennsylvania', country: 'US' },
  { name: 'Nantucket', state: 'Massachusetts', country: 'US' },
  { name: 'Nanuet', state: 'New York', country: 'US' },
  { name: 'Napa', state: 'California', country: 'US' },
  { name: 'Naperville', state: 'Illinois', country: 'US' },
  { name: 'Napili-Honokowai', state: 'Hawaii', country: 'US' },
  { name: 'Naples', state: 'Florida', country: 'US' },
  { name: 'Naples Park', state: 'Florida', country: 'US' },
  { name: 'Napoleon', state: 'Ohio', country: 'US' },
  { name: 'Nappanee', state: 'Indiana', country: 'US' },
  { name: 'Narragansett', state: 'Rhode Island', country: 'US' },
  { name: 'Nashua', state: 'New Hampshire', country: 'US' },
  { name: 'Nashville-Davidson', state: 'Tennessee', country: 'US' },
  { name: 'Natchez', state: 'Mississippi', country: 'US' },
  { name: 'Natchitoches', state: 'Louisiana', country: 'US' },
  { name: 'Natick', state: 'Massachusetts', country: 'US' },
  { name: 'National City', state: 'California', country: 'US' },
  { name: 'Naugatuck', state: 'Connecticut', country: 'US' },
  { name: 'Naugatuck', state: 'Connecticut', country: 'US' },
  { name: 'Navasota', state: 'Texas', country: 'US' },
  { name: 'Nazareth', state: 'Pennsylvania', country: 'US' },
  { name: 'Nebraska City', state: 'Nebraska', country: 'US' },
  { name: 'Nederland', state: 'Texas', country: 'US' },
  { name: 'Needham', state: 'Massachusetts', country: 'US' },
  { name: 'Needham', state: 'Massachusetts', country: 'US' },
  { name: 'Neenah', state: 'Wisconsin', country: 'US' },
  { name: 'Nellis AFB', state: 'Nevada', country: 'US' },
  { name: 'Neosho', state: 'Missouri', country: 'US' },
  { name: 'Neptune Beach', state: 'Florida', country: 'US' },
  { name: 'Nesconset', state: 'New York', country: 'US' },
  { name: 'Nether Providence Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Nevada', state: 'Missouri', country: 'US' },
  { name: 'Nevada', state: 'Iowa', country: 'US' },
  { name: 'New Albany', state: 'Indiana', country: 'US' },
  { name: 'New Albany', state: 'Mississippi', country: 'US' },
  { name: 'New Baltimore', state: 'Michigan', country: 'US' },
  { name: 'New Bedford', state: 'Massachusetts', country: 'US' },
  { name: 'New Berlin', state: 'Wisconsin', country: 'US' },
  { name: 'New Bern', state: 'North Carolina', country: 'US' },
  { name: 'New Braunfels', state: 'Texas', country: 'US' },
  { name: 'New Brighton', state: 'Pennsylvania', country: 'US' },
  { name: 'New Brighton', state: 'Minnesota', country: 'US' },
  { name: 'New Britain', state: 'Connecticut', country: 'US' },
  { name: 'New Britain', state: 'Connecticut', country: 'US' },
  { name: 'New Brunswick', state: 'New Jersey', country: 'US' },
  { name: 'New Canaan', state: 'Connecticut', country: 'US' },
  { name: 'New Carrollton', state: 'Maryland', country: 'US' },
  { name: 'New Cassel', state: 'New York', country: 'US' },
  { name: 'New Castle', state: 'New York', country: 'US' },
  { name: 'New Castle', state: 'Pennsylvania', country: 'US' },
  { name: 'New Castle', state: 'Indiana', country: 'US' },
  { name: 'New City', state: 'New York', country: 'US' },
  { name: 'New Cumberland', state: 'Pennsylvania', country: 'US' },
  { name: 'New Fairfield', state: 'Connecticut', country: 'US' },
  { name: 'New Hartford', state: 'Connecticut', country: 'US' },
  { name: 'New Hartford', state: 'New York', country: 'US' },
  { name: 'New Haven', state: 'Connecticut', country: 'US' },
  { name: 'New Haven', state: 'Connecticut', country: 'US' },
  { name: 'New Haven', state: 'Indiana', country: 'US' },
  { name: 'New Hope', state: 'Minnesota', country: 'US' },
  { name: 'New Hyde Park', state: 'New York', country: 'US' },
  { name: 'New Iberia', state: 'Louisiana', country: 'US' },
  { name: 'New Kensington', state: 'Pennsylvania', country: 'US' },
  { name: 'New Kingman-Butler', state: 'Arizona', country: 'US' },
  { name: 'New Lenox', state: 'Illinois', country: 'US' },
  { name: 'New London', state: 'Connecticut', country: 'US' },
  { name: 'New London', state: 'Connecticut', country: 'US' },
  { name: 'New London', state: 'Wisconsin', country: 'US' },
  { name: 'New Milford', state: 'Connecticut', country: 'US' },
  { name: 'New Milford', state: 'Connecticut', country: 'US' },
  { name: 'New Milford', state: 'New Jersey', country: 'US' },
  { name: 'New Orleans', state: 'Louisiana', country: 'US' },
  { name: 'New Paltz', state: 'New York', country: 'US' },
  { name: 'New Paltz', state: 'New York', country: 'US' },
  { name: 'New Philadelphia', state: 'Ohio', country: 'US' },
  { name: 'New Port Richey', state: 'Florida', country: 'US' },
  { name: 'New Port Richey East', state: 'Florida', country: 'US' },
  { name: 'New Providence', state: 'New Jersey', country: 'US' },
  { name: 'New Richmond', state: 'Wisconsin', country: 'US' },
  { name: 'New River', state: 'Arizona', country: 'US' },
  { name: 'New Rochelle', state: 'New York', country: 'US' },
  { name: 'New Scotland', state: 'New York', country: 'US' },
  { name: 'New Smyrna Beach', state: 'Florida', country: 'US' },
  { name: 'New Territory', state: 'Texas', country: 'US' },
  { name: 'New Ulm', state: 'Minnesota', country: 'US' },
  { name: 'New Windsor', state: 'New York', country: 'US' },
  { name: 'New Windsor', state: 'New York', country: 'US' },
  { name: 'New York', state: 'New York', country: 'US' },
  { name: 'Newark', state: 'New Jersey', country: 'US' },
  { name: 'Newark', state: 'New York', country: 'US' },
  { name: 'Newark', state: 'Ohio', country: 'US' },
  { name: 'Newark', state: 'Delaware', country: 'US' },
  { name: 'Newark', state: 'California', country: 'US' },
  { name: 'Newberg', state: 'Oregon', country: 'US' },
  { name: 'Newberry', state: 'South Carolina', country: 'US' },
  { name: 'Newburg', state: 'Kentucky', country: 'US' },
  { name: 'Newburgh', state: 'New York', country: 'US' },
  { name: 'Newburgh', state: 'New York', country: 'US' },
  { name: 'Newbury', state: 'Massachusetts', country: 'US' },
  { name: 'Newburyport', state: 'Massachusetts', country: 'US' },
  { name: 'Newcastle', state: 'Washington', country: 'US' },
  { name: 'Newfane', state: 'New York', country: 'US' },
  { name: 'Newington', state: 'Virginia', country: 'US' },
  { name: 'Newington', state: 'Connecticut', country: 'US' },
  { name: 'Newington', state: 'Connecticut', country: 'US' },
  { name: 'Newman', state: 'California', country: 'US' },
  { name: 'Newmarket', state: 'New Hampshire', country: 'US' },
  { name: 'Newnan', state: 'Georgia', country: 'US' },
  { name: 'Newport', state: 'Arkansas', country: 'US' },
  { name: 'Newport', state: 'Kentucky', country: 'US' },
  { name: 'Newport', state: 'New Hampshire', country: 'US' },
  { name: 'Newport', state: 'Tennessee', country: 'US' },
  { name: 'Newport', state: 'Rhode Island', country: 'US' },
  { name: 'Newport', state: 'Oregon', country: 'US' },
  { name: 'Newport Beach', state: 'California', country: 'US' },
  { name: 'Newport East', state: 'Rhode Island', country: 'US' },
  { name: 'Newport News', state: 'Virginia', country: 'US' },
  { name: 'Newstead', state: 'New York', country: 'US' },
  { name: 'Newton', state: 'North Carolina', country: 'US' },
  { name: 'Newton', state: 'Kansas', country: 'US' },
  { name: 'Newton', state: 'Iowa', country: 'US' },
  { name: 'Newton', state: 'Massachusetts', country: 'US' },
  { name: 'Newton', state: 'New Jersey', country: 'US' },
  { name: 'Newtown', state: 'Connecticut', country: 'US' },
  { name: 'Niagara', state: 'New York', country: 'US' },
  { name: 'Niagara Falls', state: 'New York', country: 'US' },
  { name: 'Niceville', state: 'Florida', country: 'US' },
  { name: 'Nicholasville', state: 'Kentucky', country: 'US' },
  { name: 'Niles', state: 'Michigan', country: 'US' },
  { name: 'Niles', state: 'Illinois', country: 'US' },
  { name: 'Niles', state: 'Ohio', country: 'US' },
  { name: 'Nipomo', state: 'California', country: 'US' },
  { name: 'Niskayuna', state: 'New York', country: 'US' },
  { name: 'Nitro', state: 'West Virginia', country: 'US' },
  { name: 'Nixa', state: 'Missouri', country: 'US' },
  { name: 'Noblesville', state: 'Indiana', country: 'US' },
  { name: 'Nogales', state: 'Arizona', country: 'US' },
  { name: 'Norco', state: 'California', country: 'US' },
  { name: 'Norcross', state: 'Georgia', country: 'US' },
  { name: 'Norfolk', state: 'Massachusetts', country: 'US' },
  { name: 'Norfolk', state: 'Nebraska', country: 'US' },
  { name: 'Norfolk', state: 'Virginia', country: 'US' },
  { name: 'Norland', state: 'Florida', country: 'US' },
  { name: 'Normal', state: 'Illinois', country: 'US' },
  { name: 'Norman', state: 'Oklahoma', country: 'US' },
  { name: 'Normandy Park', state: 'Washington', country: 'US' },
  { name: 'Norridge', state: 'Illinois', country: 'US' },
  { name: 'Norristown', state: 'Pennsylvania', country: 'US' },
  { name: 'North Adams', state: 'Massachusetts', country: 'US' },
  { name: 'North Amherst', state: 'Massachusetts', country: 'US' },
  { name: 'North Amityville', state: 'New York', country: 'US' },
  { name: 'North Andover', state: 'Massachusetts', country: 'US' },
  { name: 'North Andrews Gardens', state: 'Florida', country: 'US' },
  { name: 'North Arlington', state: 'New Jersey', country: 'US' },
  { name: 'North Atlanta', state: 'Georgia', country: 'US' },
  { name: 'North Attleborough', state: 'Massachusetts', country: 'US' },
  { name: 'North Attleborough Center', state: 'Massachusetts', country: 'US' },
  { name: 'North Auburn', state: 'California', country: 'US' },
  { name: 'North Augusta', state: 'South Carolina', country: 'US' },
  { name: 'North Aurora', state: 'Illinois', country: 'US' },
  { name: 'North Babylon', state: 'New York', country: 'US' },
  { name: 'North Bay Shore', state: 'New York', country: 'US' },
  { name: 'North Bay Village', state: 'Florida', country: 'US' },
  { name: 'North Bellmore', state: 'New York', country: 'US' },
  { name: 'North Bellport', state: 'New York', country: 'US' },
  { name: 'North Bend', state: 'Oregon', country: 'US' },
  { name: 'North Bethesda', state: 'Maryland', country: 'US' },
  { name: 'North Braddock', state: 'Pennsylvania', country: 'US' },
  { name: 'North Branch', state: 'Minnesota', country: 'US' },
  { name: 'North Branford', state: 'Connecticut', country: 'US' },
  { name: 'North Brunswick Township', state: 'New Jersey', country: 'US' },
  { name: 'North Caldwell', state: 'New Jersey', country: 'US' },
  { name: 'North Canton', state: 'Ohio', country: 'US' },
  { name: 'North Castle', state: 'New York', country: 'US' },
  { name: 'North Charleston', state: 'South Carolina', country: 'US' },
  { name: 'North Chicago', state: 'Illinois', country: 'US' },
  { name: 'North College Hill', state: 'Ohio', country: 'US' },
  { name: 'North Creek', state: 'Washington', country: 'US' },
  { name: 'North Decatur', state: 'Georgia', country: 'US' },
  { name: 'North Druid Hills', state: 'Georgia', country: 'US' },
  { name: 'North Elba', state: 'New York', country: 'US' },
  { name: 'North Fair Oaks', state: 'California', country: 'US' },
  { name: 'North Fort Myers', state: 'Florida', country: 'US' },
  { name: 'North Greenbush', state: 'New York', country: 'US' },
  { name: 'North Haledon', state: 'New Jersey', country: 'US' },
  { name: 'North Haven', state: 'Connecticut', country: 'US' },
  { name: 'North Haven', state: 'Connecticut', country: 'US' },
  { name: 'North Hempstead', state: 'New York', country: 'US' },
  { name: 'North Highlands', state: 'California', country: 'US' },
  { name: 'North Kensington', state: 'Maryland', country: 'US' },
  { name: 'North Kingstown', state: 'Rhode Island', country: 'US' },
  { name: 'North Las Vegas', state: 'Nevada', country: 'US' },
  { name: 'North Lauderdale', state: 'Florida', country: 'US' },
  { name: 'North Laurel', state: 'Maryland', country: 'US' },
  { name: 'North Lindenhurst', state: 'New York', country: 'US' },
  { name: 'North Little Rock', state: 'Arkansas', country: 'US' },
  { name: 'North Logan', state: 'Utah', country: 'US' },
  { name: 'North Madison', state: 'Ohio', country: 'US' },
  { name: 'North Manchester', state: 'Indiana', country: 'US' },
  { name: 'North Mankato', state: 'Minnesota', country: 'US' },
  { name: 'North Marysville', state: 'Washington', country: 'US' },
  { name: 'North Massapequa', state: 'New York', country: 'US' },
  { name: 'North Merrick', state: 'New York', country: 'US' },
  { name: 'North Miami', state: 'Florida', country: 'US' },
  { name: 'North Miami Beach', state: 'Florida', country: 'US' },
  { name: 'North Myrtle Beach', state: 'South Carolina', country: 'US' },
  { name: 'North New Hyde Park', state: 'New York', country: 'US' },
  { name: 'North Ogden', state: 'Utah', country: 'US' },
  { name: 'North Olmsted', state: 'Ohio', country: 'US' },
  { name: 'North Palm Beach', state: 'Florida', country: 'US' },
  { name: 'North Patchogue', state: 'New York', country: 'US' },
  { name: 'North Plainfield', state: 'New Jersey', country: 'US' },
  { name: 'North Platte', state: 'Nebraska', country: 'US' },
  { name: 'North Port', state: 'Florida', country: 'US' },
  { name: 'North Potomac', state: 'Maryland', country: 'US' },
  { name: 'North Providence', state: 'Rhode Island', country: 'US' },
  { name: 'North Providence', state: 'Rhode Island', country: 'US' },
  { name: 'North Reading', state: 'Massachusetts', country: 'US' },
  { name: 'North Richland Hills', state: 'Texas', country: 'US' },
  { name: 'North Ridgeville', state: 'Ohio', country: 'US' },
  { name: 'North Riverside', state: 'Illinois', country: 'US' },
  { name: 'North Royalton', state: 'Ohio', country: 'US' },
  { name: 'North Salt Lake', state: 'Utah', country: 'US' },
  { name: 'North Sarasota', state: 'Florida', country: 'US' },
  { name: 'North Smithfield', state: 'Rhode Island', country: 'US' },
  { name: 'North Springfield', state: 'Virginia', country: 'US' },
  { name: 'North St. Paul', state: 'Minnesota', country: 'US' },
  { name: 'North Star', state: 'Delaware', country: 'US' },
  { name: 'North Syracuse', state: 'New York', country: 'US' },
  { name: 'North Tonawanda', state: 'New York', country: 'US' },
  { name: 'North Valley', state: 'New Mexico', country: 'US' },
  { name: 'North Valley Stream', state: 'New York', country: 'US' },
  { name: 'North Vernon', state: 'Indiana', country: 'US' },
  { name: 'North Versailles', state: 'Pennsylvania', country: 'US' },
  { name: 'North Wantagh', state: 'New York', country: 'US' },
  { name: 'Northampton', state: 'Pennsylvania', country: 'US' },
  { name: 'Northampton', state: 'Massachusetts', country: 'US' },
  { name: 'Northborough', state: 'Massachusetts', country: 'US' },
  { name: 'Northborough', state: 'Massachusetts', country: 'US' },
  { name: 'Northbridge', state: 'Massachusetts', country: 'US' },
  { name: 'Northbrook', state: 'Illinois', country: 'US' },
  { name: 'Northbrook', state: 'Ohio', country: 'US' },
  { name: 'Northfield', state: 'Minnesota', country: 'US' },
  { name: 'Northfield', state: 'New Jersey', country: 'US' },
  { name: 'Northgate', state: 'Ohio', country: 'US' },
  { name: 'Northglenn', state: 'Colorado', country: 'US' },
  { name: 'Northlake', state: 'Illinois', country: 'US' },
  { name: 'Northport', state: 'Alabama', country: 'US' },
  { name: 'Northport', state: 'New York', country: 'US' },
  { name: 'Northridge', state: 'Ohio', country: 'US' },
  { name: 'Northridge', state: 'Ohio', country: 'US' },
  { name: 'Northview', state: 'Michigan', country: 'US' },
  { name: 'Northville', state: 'Michigan', country: 'US' },
  { name: 'Northwest Harborcreek', state: 'Pennsylvania', country: 'US' },
  { name: 'Norton', state: 'Ohio', country: 'US' },
  { name: 'Norton', state: 'Massachusetts', country: 'US' },
  { name: 'Norton Shores', state: 'Michigan', country: 'US' },
  { name: 'Norwalk', state: 'Iowa', country: 'US' },
  { name: 'Norwalk', state: 'Connecticut', country: 'US' },
  { name: 'Norwalk', state: 'California', country: 'US' },
  { name: 'Norwalk', state: 'Connecticut', country: 'US' },
  { name: 'Norwalk', state: 'Ohio', country: 'US' },
  { name: 'Norway', state: 'Wisconsin', country: 'US' },
  { name: 'Norwell', state: 'Massachusetts', country: 'US' },
  { name: 'Norwich', state: 'New York', country: 'US' },
  { name: 'Norwich', state: 'Connecticut', country: 'US' },
  { name: 'Norwich', state: 'Connecticut', country: 'US' },
  { name: 'Norwood', state: 'Massachusetts', country: 'US' },
  { name: 'Norwood', state: 'Massachusetts', country: 'US' },
  { name: 'Norwood', state: 'Ohio', country: 'US' },
  { name: 'Novato', state: 'California', country: 'US' },
  { name: 'Novi', state: 'Michigan', country: 'US' },
  { name: 'Nutley', state: 'New Jersey', country: 'US' },
  { name: 'Nyack', state: 'New York', country: 'US' },
  { name: 'O’Fallon', state: 'Illinois', country: 'US' },
  { name: 'O’Fallon', state: 'Missouri', country: 'US' },
  { name: 'O’Hara Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Oak Brook', state: 'Illinois', country: 'US' },
  { name: 'Oak Creek', state: 'Wisconsin', country: 'US' },
  { name: 'Oak Forest', state: 'Illinois', country: 'US' },
  { name: 'Oak Grove', state: 'Kentucky', country: 'US' },
  { name: 'Oak Grove', state: 'South Carolina', country: 'US' },
  { name: 'Oak Grove', state: 'Oregon', country: 'US' },
  { name: 'Oak Grove', state: 'Minnesota', country: 'US' },
  { name: 'Oak Harbor', state: 'Washington', country: 'US' },
  { name: 'Oak Hill', state: 'West Virginia', country: 'US' },
  { name: 'Oak Hills', state: 'Oregon', country: 'US' },
  { name: 'Oak Hills Place', state: 'Louisiana', country: 'US' },
  { name: 'Oak Island', state: 'North Carolina', country: 'US' },
  { name: 'Oak Lawn', state: 'Illinois', country: 'US' },
  { name: 'Oak Park', state: 'Illinois', country: 'US' },
  { name: 'Oak Park', state: 'Michigan', country: 'US' },
  { name: 'Oak Ridge', state: 'Tennessee', country: 'US' },
  { name: 'Oak Ridge', state: 'Florida', country: 'US' },
  { name: 'Oakbrook', state: 'Kentucky', country: 'US' },
  { name: 'Oakdale', state: 'Louisiana', country: 'US' },
  { name: 'Oakdale', state: 'California', country: 'US' },
  { name: 'Oakdale', state: 'Minnesota', country: 'US' },
  { name: 'Oakdale', state: 'New York', country: 'US' },
  { name: 'Oakland', state: 'New Jersey', country: 'US' },
  { name: 'Oakland', state: 'California', country: 'US' },
  { name: 'Oakland Park', state: 'Florida', country: 'US' },
  { name: 'Oakley', state: 'California', country: 'US' },
  { name: 'Oakmont', state: 'Pennsylvania', country: 'US' },
  { name: 'Oakton', state: 'Virginia', country: 'US' },
  { name: 'Oakville', state: 'Missouri', country: 'US' },
  { name: 'Oakville', state: 'Connecticut', country: 'US' },
  { name: 'Oakwood', state: 'Ohio', country: 'US' },
  { name: 'Oatfield', state: 'Oregon', country: 'US' },
  { name: 'Oberlin', state: 'Ohio', country: 'US' },
  { name: 'Ocala', state: 'Florida', country: 'US' },
  { name: 'Ocean Acres', state: 'New Jersey', country: 'US' },
  { name: 'Ocean City', state: 'New Jersey', country: 'US' },
  { name: 'Ocean City', state: 'Maryland', country: 'US' },
  { name: 'Ocean Pines', state: 'Maryland', country: 'US' },
  { name: 'Ocean Springs', state: 'Mississippi', country: 'US' },
  { name: 'Oceano', state: 'California', country: 'US' },
  { name: 'Oceanside', state: 'California', country: 'US' },
  { name: 'Oceanside', state: 'New York', country: 'US' },
  { name: 'Ocoee', state: 'Florida', country: 'US' },
  { name: 'Oconomowoc', state: 'Wisconsin', country: 'US' },
  { name: 'Oconomowoc', state: 'Wisconsin', country: 'US' },
  { name: 'Odenton', state: 'Maryland', country: 'US' },
  { name: 'Odessa', state: 'Texas', country: 'US' },
  { name: 'Oelwein', state: 'Iowa', country: 'US' },
  { name: 'Offutt AFB', state: 'Nebraska', country: 'US' },
  { name: 'Ogden', state: 'New York', country: 'US' },
  { name: 'Ogden', state: 'Utah', country: 'US' },
  { name: 'Ogdensburg', state: 'New York', country: 'US' },
  { name: 'Oil City', state: 'Pennsylvania', country: 'US' },
  { name: 'Oildale', state: 'California', country: 'US' },
  { name: 'Ojai', state: 'California', country: 'US' },
  { name: 'Ojus', state: 'Florida', country: 'US' },
  { name: 'Okemos', state: 'Michigan', country: 'US' },
  { name: 'Oklahoma City', state: 'Oklahoma', country: 'US' },
  { name: 'Okmulgee', state: 'Oklahoma', country: 'US' },
  { name: 'Okolona', state: 'Kentucky', country: 'US' },
  { name: 'Olathe', state: 'Kansas', country: 'US' },
  { name: 'Old Bridge', state: 'New Jersey', country: 'US' },
  { name: 'Old Forge', state: 'Pennsylvania', country: 'US' },
  { name: 'Old Lyme', state: 'Connecticut', country: 'US' },
  { name: 'Old Orchard Beach', state: 'Maine', country: 'US' },
  { name: 'Old Orchard Beach', state: 'Maine', country: 'US' },
  { name: 'Old Saybrook', state: 'Connecticut', country: 'US' },
  { name: 'Old Town', state: 'Maine', country: 'US' },
  { name: 'Oldsmar', state: 'Florida', country: 'US' },
  { name: 'Olean', state: 'New York', country: 'US' },
  { name: 'Olive Branch', state: 'Mississippi', country: 'US' },
  { name: 'Olivehurst', state: 'California', country: 'US' },
  { name: 'Olivette', state: 'Missouri', country: 'US' },
  { name: 'Olmsted Falls', state: 'Ohio', country: 'US' },
  { name: 'Olney', state: 'Maryland', country: 'US' },
  { name: 'Olney', state: 'Illinois', country: 'US' },
  { name: 'Olympia', state: 'Washington', country: 'US' },
  { name: 'Olympia Heights', state: 'Florida', country: 'US' },
  { name: 'Omaha', state: 'Nebraska', country: 'US' },
  { name: 'Onalaska', state: 'Wisconsin', country: 'US' },
  { name: 'Oneida', state: 'New York', country: 'US' },
  { name: 'Oneonta', state: 'New York', country: 'US' },
  { name: 'Onondaga', state: 'New York', country: 'US' },
  { name: 'Ontario', state: 'New York', country: 'US' },
  { name: 'Ontario', state: 'Oregon', country: 'US' },
  { name: 'Ontario', state: 'California', country: 'US' },
  { name: 'Opa-locka', state: 'Florida', country: 'US' },
  { name: 'Opa-locka North', state: 'Florida', country: 'US' },
  { name: 'Opal Cliffs', state: 'California', country: 'US' },
  { name: 'Opelika', state: 'Alabama', country: 'US' },
  { name: 'Opelousas', state: 'Louisiana', country: 'US' },
  { name: 'Opp', state: 'Alabama', country: 'US' },
  { name: 'Opportunity', state: 'Washington', country: 'US' },
  { name: 'Oquirrh', state: 'Utah', country: 'US' },
  { name: 'Oradell', state: 'New Jersey', country: 'US' },
  { name: 'Orange', state: 'New Jersey', country: 'US' },
  { name: 'Orange', state: 'Massachusetts', country: 'US' },
  { name: 'Orange', state: 'Texas', country: 'US' },
  { name: 'Orange', state: 'Connecticut', country: 'US' },
  { name: 'Orange', state: 'California', country: 'US' },
  { name: 'Orange', state: 'Connecticut', country: 'US' },
  { name: 'Orange City', state: 'Florida', country: 'US' },
  { name: 'Orange Cove', state: 'California', country: 'US' },
  { name: 'Orange Lake', state: 'New York', country: 'US' },
  { name: 'Orange Park', state: 'Florida', country: 'US' },
  { name: 'Orangeburg', state: 'South Carolina', country: 'US' },
  { name: 'Orangetown', state: 'New York', country: 'US' },
  { name: 'Orangevale', state: 'California', country: 'US' },
  { name: 'Orchard Mesa', state: 'Colorado', country: 'US' },
  { name: 'Orchard Park', state: 'New York', country: 'US' },
  { name: 'Orchards', state: 'Washington', country: 'US' },
  { name: 'Orcutt', state: 'California', country: 'US' },
  { name: 'Oregon', state: 'Ohio', country: 'US' },
  { name: 'Oregon', state: 'Wisconsin', country: 'US' },
  { name: 'Oregon City', state: 'Oregon', country: 'US' },
  { name: 'Orem', state: 'Utah', country: 'US' },
  { name: 'Orinda', state: 'California', country: 'US' },
  { name: 'Orland', state: 'California', country: 'US' },
  { name: 'Orland Hills', state: 'Illinois', country: 'US' },
  { name: 'Orland Park', state: 'Illinois', country: 'US' },
  { name: 'Orlando', state: 'Florida', country: 'US' },
  { name: 'Orleans', state: 'Massachusetts', country: 'US' },
  { name: 'Orlovista', state: 'Florida', country: 'US' },
  { name: 'Ormond Beach', state: 'Florida', country: 'US' },
  { name: 'Ormond-By-The-Sea', state: 'Florida', country: 'US' },
  { name: 'Oro Valley', state: 'Arizona', country: 'US' },
  { name: 'Orono', state: 'Maine', country: 'US' },
  { name: 'Orono', state: 'Maine', country: 'US' },
  { name: 'Orono', state: 'Minnesota', country: 'US' },
  { name: 'Orosi', state: 'California', country: 'US' },
  { name: 'Oroville', state: 'California', country: 'US' },
  { name: 'Oroville East', state: 'California', country: 'US' },
  { name: 'Orrville', state: 'Ohio', country: 'US' },
  { name: 'Osceola', state: 'Arkansas', country: 'US' },
  { name: 'Oshkosh', state: 'Wisconsin', country: 'US' },
  { name: 'Oskaloosa', state: 'Iowa', country: 'US' },
  { name: 'Ossining', state: 'New York', country: 'US' },
  { name: 'Ossining', state: 'New York', country: 'US' },
  { name: 'Oswego', state: 'New York', country: 'US' },
  { name: 'Oswego', state: 'New York', country: 'US' },
  { name: 'Oswego', state: 'Illinois', country: 'US' },
  { name: 'Otis Orchards-East Farms', state: 'Washington', country: 'US' },
  { name: 'Otsego', state: 'Minnesota', country: 'US' },
  { name: 'Ottawa', state: 'Illinois', country: 'US' },
  { name: 'Ottawa', state: 'Kansas', country: 'US' },
  { name: 'Ottumwa', state: 'Iowa', country: 'US' },
  { name: 'Overland', state: 'Missouri', country: 'US' },
  { name: 'Overland Park', state: 'Kansas', country: 'US' },
  { name: 'Overlea', state: 'Maryland', country: 'US' },
  { name: 'Oviedo', state: 'Florida', country: 'US' },
  { name: 'Owasso', state: 'Oklahoma', country: 'US' },
  { name: 'Owatonna', state: 'Minnesota', country: 'US' },
  { name: 'Owego', state: 'New York', country: 'US' },
  { name: 'Owensboro', state: 'Kentucky', country: 'US' },
  { name: 'Owings Mills', state: 'Maryland', country: 'US' },
  { name: 'Owosso', state: 'Michigan', country: 'US' },
  { name: 'Oxford', state: 'Massachusetts', country: 'US' },
  { name: 'Oxford', state: 'Mississippi', country: 'US' },
  { name: 'Oxford', state: 'North Carolina', country: 'US' },
  { name: 'Oxford', state: 'Ohio', country: 'US' },
  { name: 'Oxford', state: 'Connecticut', country: 'US' },
  { name: 'Oxford', state: 'Alabama', country: 'US' },
  { name: 'Oxnard', state: 'California', country: 'US' },
  { name: 'Oxon Hill-Glassmanor', state: 'Maryland', country: 'US' },
  { name: 'Oyster Bay', state: 'New York', country: 'US' },
  { name: 'Oyster Bay', state: 'New York', country: 'US' },
  { name: 'Ozark', state: 'Missouri', country: 'US' },
  { name: 'Ozark', state: 'Alabama', country: 'US' },
  { name: 'Pace', state: 'Florida', country: 'US' },
  { name: 'Pacific Grove', state: 'California', country: 'US' },
  { name: 'Pacifica', state: 'California', country: 'US' },
  { name: 'Paducah', state: 'Kentucky', country: 'US' },
  { name: 'Page', state: 'Arizona', country: 'US' },
  { name: 'Pahrump', state: 'Nevada', country: 'US' },
  { name: 'Paine Field-Lake Stickney', state: 'Washington', country: 'US' },
  { name: 'Painesville', state: 'Ohio', country: 'US' },
  { name: 'Palatine', state: 'Illinois', country: 'US' },
  { name: 'Palatka', state: 'Florida', country: 'US' },
  { name: 'Palestine', state: 'Texas', country: 'US' },
  { name: 'Palisades Park', state: 'New Jersey', country: 'US' },
  { name: 'Palm Bay', state: 'Florida', country: 'US' },
  { name: 'Palm Beach', state: 'Florida', country: 'US' },
  { name: 'Palm Beach Gardens', state: 'Florida', country: 'US' },
  { name: 'Palm City', state: 'Florida', country: 'US' },
  { name: 'Palm Coast', state: 'Florida', country: 'US' },
  { name: 'Palm Desert', state: 'California', country: 'US' },
  { name: 'Palm Harbor', state: 'Florida', country: 'US' },
  { name: 'Palm River-Clair Mel', state: 'Florida', country: 'US' },
  { name: 'Palm Springs', state: 'Florida', country: 'US' },
  { name: 'Palm Springs', state: 'California', country: 'US' },
  { name: 'Palm Valley', state: 'Florida', country: 'US' },
  { name: 'Palmdale', state: 'California', country: 'US' },
  { name: 'Palmer', state: 'Massachusetts', country: 'US' },
  { name: 'Palmetto', state: 'Florida', country: 'US' },
  { name: 'Palmetto Estates', state: 'Florida', country: 'US' },
  { name: 'Palmview South', state: 'Texas', country: 'US' },
  { name: 'Palmyra', state: 'Pennsylvania', country: 'US' },
  { name: 'Palmyra', state: 'New Jersey', country: 'US' },
  { name: 'Palmyra', state: 'New York', country: 'US' },
  { name: 'Palo Alto', state: 'California', country: 'US' },
  { name: 'Palos Heights', state: 'Illinois', country: 'US' },
  { name: 'Palos Hills', state: 'Illinois', country: 'US' },
  { name: 'Palos Verdes Estates', state: 'California', country: 'US' },
  { name: 'Pampa', state: 'Texas', country: 'US' },
  { name: 'Panama City', state: 'Florida', country: 'US' },
  { name: 'Panama City Beach', state: 'Florida', country: 'US' },
  { name: 'Panthersville', state: 'Georgia', country: 'US' },
  { name: 'Papillion', state: 'Nebraska', country: 'US' },
  { name: 'Paradise', state: 'Nevada', country: 'US' },
  { name: 'Paradise', state: 'California', country: 'US' },
  { name: 'Paradise Valley', state: 'Arizona', country: 'US' },
  { name: 'Paragould', state: 'Arkansas', country: 'US' },
  { name: 'Paramount', state: 'California', country: 'US' },
  { name: 'Paramus', state: 'New Jersey', country: 'US' },
  { name: 'Paris', state: 'Texas', country: 'US' },
  { name: 'Paris', state: 'Tennessee', country: 'US' },
  { name: 'Paris', state: 'Illinois', country: 'US' },
  { name: 'Paris', state: 'Kentucky', country: 'US' },
  { name: 'Park City', state: 'Illinois', country: 'US' },
  { name: 'Park City', state: 'Utah', country: 'US' },
  { name: 'Park Forest', state: 'Illinois', country: 'US' },
  { name: 'Park Forest Village', state: 'Pennsylvania', country: 'US' },
  { name: 'Park Hills', state: 'Missouri', country: 'US' },
  { name: 'Park Ridge', state: 'New Jersey', country: 'US' },
  { name: 'Park Ridge', state: 'Illinois', country: 'US' },
  { name: 'Parker', state: 'Colorado', country: 'US' },
  { name: 'Parker', state: 'South Carolina', country: 'US' },
  { name: 'Parkersburg', state: 'West Virginia', country: 'US' },
  { name: 'Parkland', state: 'Washington', country: 'US' },
  { name: 'Parkland', state: 'Florida', country: 'US' },
  { name: 'Parkville', state: 'Maryland', country: 'US' },
  { name: 'Parkville', state: 'Pennsylvania', country: 'US' },
  { name: 'Parkway-South Sacramento', state: 'California', country: 'US' },
  { name: 'Parkwood', state: 'Washington', country: 'US' },
  { name: 'Parlier', state: 'California', country: 'US' },
  { name: 'Parma', state: 'New York', country: 'US' },
  { name: 'Parma', state: 'Ohio', country: 'US' },
  { name: 'Parma Heights', state: 'Ohio', country: 'US' },
  { name: 'Parole', state: 'Maryland', country: 'US' },
  { name: 'Parsons', state: 'Kansas', country: 'US' },
  { name: 'Pasadena', state: 'Maryland', country: 'US' },
  { name: 'Pasadena', state: 'California', country: 'US' },
  { name: 'Pasadena', state: 'Texas', country: 'US' },
  { name: 'Pascagoula', state: 'Mississippi', country: 'US' },
  { name: 'Pasco', state: 'Washington', country: 'US' },
  { name: 'Pass Christian', state: 'Mississippi', country: 'US' },
  { name: 'Passaic', state: 'New Jersey', country: 'US' },
  { name: 'Pataskala', state: 'Ohio', country: 'US' },
  { name: 'Patchogue', state: 'New York', country: 'US' },
  { name: 'Paterson', state: 'New Jersey', country: 'US' },
  { name: 'Patterson', state: 'New York', country: 'US' },
  { name: 'Patterson', state: 'California', country: 'US' },
  { name: 'Pauls Valley', state: 'Oklahoma', country: 'US' },
  { name: 'Paulsboro', state: 'New Jersey', country: 'US' },
  { name: 'Pawling', state: 'New York', country: 'US' },
  { name: 'Pawtucket', state: 'Rhode Island', country: 'US' },
  { name: 'Payette', state: 'Idaho', country: 'US' },
  { name: 'Payson', state: 'Arizona', country: 'US' },
  { name: 'Payson', state: 'Utah', country: 'US' },
  { name: 'Pea Ridge', state: 'West Virginia', country: 'US' },
  { name: 'Peabody', state: 'Massachusetts', country: 'US' },
  { name: 'Peachtree City', state: 'Georgia', country: 'US' },
  { name: 'Pearl', state: 'Mississippi', country: 'US' },
  { name: 'Pearl City', state: 'Hawaii', country: 'US' },
  { name: 'Pearl River', state: 'New York', country: 'US' },
  { name: 'Pearland', state: 'Texas', country: 'US' },
  { name: 'Pearsall', state: 'Texas', country: 'US' },
  { name: 'Pecan Grove', state: 'Texas', country: 'US' },
  { name: 'Pecos', state: 'Texas', country: 'US' },
  { name: 'Pedley', state: 'California', country: 'US' },
  { name: 'Peekskill', state: 'New York', country: 'US' },
  { name: 'Pekin', state: 'Illinois', country: 'US' },
  { name: 'Pelham', state: 'Alabama', country: 'US' },
  { name: 'Pelham', state: 'New York', country: 'US' },
  { name: 'Pelham', state: 'New York', country: 'US' },
  { name: 'Pelham', state: 'New Hampshire', country: 'US' },
  { name: 'Pell City', state: 'Alabama', country: 'US' },
  { name: 'Pella', state: 'Iowa', country: 'US' },
  { name: 'Pembroke', state: 'Massachusetts', country: 'US' },
  { name: 'Pembroke', state: 'New Hampshire', country: 'US' },
  { name: 'Pembroke Park', state: 'Florida', country: 'US' },
  { name: 'Pembroke Pines', state: 'Florida', country: 'US' },
  { name: 'Pendleton', state: 'New York', country: 'US' },
  { name: 'Pendleton', state: 'Oregon', country: 'US' },
  { name: 'Penfield', state: 'New York', country: 'US' },
  { name: 'Penn Hills', state: 'Pennsylvania', country: 'US' },
  { name: 'Pennsauken', state: 'New Jersey', country: 'US' },
  { name: 'Pennsville', state: 'New Jersey', country: 'US' },
  { name: 'Pensacola', state: 'Florida', country: 'US' },
  { name: 'Peoria', state: 'Arizona', country: 'US' },
  { name: 'Peoria', state: 'Illinois', country: 'US' },
  { name: 'Peoria Heights', state: 'Illinois', country: 'US' },
  { name: 'Pepper Pike', state: 'Ohio', country: 'US' },
  { name: 'Pepperell', state: 'Massachusetts', country: 'US' },
  { name: 'Perinton', state: 'New York', country: 'US' },
  { name: 'Perkasie', state: 'Pennsylvania', country: 'US' },
  { name: 'Perris', state: 'California', country: 'US' },
  { name: 'Perry', state: 'Florida', country: 'US' },
  { name: 'Perry', state: 'Iowa', country: 'US' },
  { name: 'Perry', state: 'Georgia', country: 'US' },
  { name: 'Perry', state: 'New York', country: 'US' },
  { name: 'Perry Hall', state: 'Maryland', country: 'US' },
  { name: 'Perry Heights', state: 'Ohio', country: 'US' },
  { name: 'Perrysburg', state: 'Ohio', country: 'US' },
  { name: 'Perryton', state: 'Texas', country: 'US' },
  { name: 'Perryville', state: 'Missouri', country: 'US' },
  { name: 'Perth Amboy', state: 'New Jersey', country: 'US' },
  { name: 'Peru', state: 'New York', country: 'US' },
  { name: 'Peru', state: 'Illinois', country: 'US' },
  { name: 'Peru', state: 'Indiana', country: 'US' },
  { name: 'Petal', state: 'Mississippi', country: 'US' },
  { name: 'Petaluma', state: 'California', country: 'US' },
  { name: 'Petersburg', state: 'Virginia', country: 'US' },
  { name: 'Petoskey', state: 'Michigan', country: 'US' },
  { name: 'Pewaukee', state: 'Wisconsin', country: 'US' },
  { name: 'Pewaukee', state: 'Wisconsin', country: 'US' },
  { name: 'Pflugerville', state: 'Texas', country: 'US' },
  { name: 'Pharr', state: 'Texas', country: 'US' },
  { name: 'Phelps', state: 'New York', country: 'US' },
  { name: 'Phenix City', state: 'Alabama', country: 'US' },
  { name: 'Philadelphia', state: 'Mississippi', country: 'US' },
  { name: 'Philadelphia', state: 'Pennsylvania', country: 'US' },
  { name: 'Philipstown', state: 'New York', country: 'US' },
  { name: 'Phillipsburg', state: 'New Jersey', country: 'US' },
  { name: 'Phoenix', state: 'Arizona', country: 'US' },
  { name: 'Phoenixville', state: 'Pennsylvania', country: 'US' },
  { name: 'Picayune', state: 'Mississippi', country: 'US' },
  { name: 'Pickerington', state: 'Ohio', country: 'US' },
  { name: 'Picnic Point-North Lynnwood', state: 'Washington', country: 'US' },
  { name: 'Pico Rivera', state: 'California', country: 'US' },
  { name: 'Picture Rocks', state: 'Arizona', country: 'US' },
  { name: 'Piedmont', state: 'California', country: 'US' },
  { name: 'Pierre', state: 'South Dakota', country: 'US' },
  { name: 'Pike Creek', state: 'Delaware', country: 'US' },
  { name: 'Pikesville', state: 'Maryland', country: 'US' },
  { name: 'Pikeville', state: 'Kentucky', country: 'US' },
  { name: 'Pimmit Hills', state: 'Virginia', country: 'US' },
  { name: 'Pine Bluff', state: 'Arkansas', country: 'US' },
  { name: 'Pine Castle', state: 'Florida', country: 'US' },
  { name: 'Pine Hill', state: 'New Jersey', country: 'US' },
  { name: 'Pine Hills', state: 'Florida', country: 'US' },
  { name: 'Pinecrest', state: 'Florida', country: 'US' },
  { name: 'Pinehurst', state: 'Massachusetts', country: 'US' },
  { name: 'Pinehurst', state: 'North Carolina', country: 'US' },
  { name: 'Pinellas Park', state: 'Florida', country: 'US' },
  { name: 'Pineville', state: 'Louisiana', country: 'US' },
  { name: 'Pinewood', state: 'Florida', country: 'US' },
  { name: 'Piney Green', state: 'North Carolina', country: 'US' },
  { name: 'Pinole', state: 'California', country: 'US' },
  { name: 'Piqua', state: 'Ohio', country: 'US' },
  { name: 'Pismo Beach', state: 'California', country: 'US' },
  { name: 'Pitman', state: 'New Jersey', country: 'US' },
  { name: 'Pittsburg', state: 'California', country: 'US' },
  { name: 'Pittsburg', state: 'Kansas', country: 'US' },
  { name: 'Pittsburgh', state: 'Pennsylvania', country: 'US' },
  { name: 'Pittsfield', state: 'Massachusetts', country: 'US' },
  { name: 'Pittsford', state: 'New York', country: 'US' },
  { name: 'Pittston', state: 'Pennsylvania', country: 'US' },
  { name: 'Placentia', state: 'California', country: 'US' },
  { name: 'Placerville', state: 'California', country: 'US' },
  { name: 'Plainedge', state: 'New York', country: 'US' },
  { name: 'Plainfield', state: 'New Jersey', country: 'US' },
  { name: 'Plainfield', state: 'Connecticut', country: 'US' },
  { name: 'Plainfield', state: 'Indiana', country: 'US' },
  { name: 'Plainfield', state: 'Illinois', country: 'US' },
  { name: 'Plainview', state: 'New York', country: 'US' },
  { name: 'Plainview', state: 'Texas', country: 'US' },
  { name: 'Plainville', state: 'Massachusetts', country: 'US' },
  { name: 'Plainville', state: 'Connecticut', country: 'US' },
  { name: 'Plaistow', state: 'New Hampshire', country: 'US' },
  { name: 'Plano', state: 'Texas', country: 'US' },
  { name: 'Plant City', state: 'Florida', country: 'US' },
  { name: 'Plantation', state: 'Florida', country: 'US' },
  { name: 'Plaquemine', state: 'Louisiana', country: 'US' },
  { name: 'Plattekill', state: 'New York', country: 'US' },
  { name: 'Platteville', state: 'Wisconsin', country: 'US' },
  { name: 'Plattsburgh', state: 'New York', country: 'US' },
  { name: 'Plattsburgh', state: 'New York', country: 'US' },
  { name: 'Plattsmouth', state: 'Nebraska', country: 'US' },
  { name: 'Pleasant Grove', state: 'Utah', country: 'US' },
  { name: 'Pleasant Grove', state: 'Alabama', country: 'US' },
  { name: 'Pleasant Hill', state: 'California', country: 'US' },
  { name: 'Pleasant Hills', state: 'Pennsylvania', country: 'US' },
  { name: 'Pleasant Prairie', state: 'Wisconsin', country: 'US' },
  { name: 'Pleasant Valley', state: 'New York', country: 'US' },
  { name: 'Pleasanton', state: 'Texas', country: 'US' },
  { name: 'Pleasanton', state: 'California', country: 'US' },
  { name: 'Pleasantville', state: 'New York', country: 'US' },
  { name: 'Pleasantville', state: 'New Jersey', country: 'US' },
  { name: 'Pleasure Ridge Park', state: 'Kentucky', country: 'US' },
  { name: 'Plover', state: 'Wisconsin', country: 'US' },
  { name: 'Plum', state: 'Pennsylvania', country: 'US' },
  { name: 'Plymouth', state: 'Pennsylvania', country: 'US' },
  { name: 'Plymouth', state: 'Massachusetts', country: 'US' },
  { name: 'Plymouth', state: 'Michigan', country: 'US' },
  { name: 'Plymouth', state: 'Massachusetts', country: 'US' },
  { name: 'Plymouth', state: 'Minnesota', country: 'US' },
  { name: 'Plymouth', state: 'Indiana', country: 'US' },
  { name: 'Plymouth', state: 'Connecticut', country: 'US' },
  { name: 'Plymouth', state: 'Wisconsin', country: 'US' },
  { name: 'Plymouth Township', state: 'Michigan', country: 'US' },
  { name: 'Pocahontas', state: 'Arkansas', country: 'US' },
  { name: 'Pocatello', state: 'Idaho', country: 'US' },
  { name: 'Poinciana', state: 'Florida', country: 'US' },
  { name: 'Point Pleasant', state: 'New Jersey', country: 'US' },
  { name: 'Pomfret', state: 'New York', country: 'US' },
  { name: 'Pomona', state: 'California', country: 'US' },
  { name: 'Pompano Beach', state: 'Florida', country: 'US' },
  { name: 'Pompano Beach Highlands', state: 'Florida', country: 'US' },
  { name: 'Pompey', state: 'New York', country: 'US' },
  { name: 'Pompton Lakes', state: 'New Jersey', country: 'US' },
  { name: 'Ponca City', state: 'Oklahoma', country: 'US' },
  { name: 'Pontiac', state: 'Illinois', country: 'US' },
  { name: 'Pontiac', state: 'Michigan', country: 'US' },
  { name: 'Pooler', state: 'Georgia', country: 'US' },
  { name: 'Poplar Bluff', state: 'Missouri', country: 'US' },
  { name: 'Poquoson', state: 'Virginia', country: 'US' },
  { name: 'Port Angeles', state: 'Washington', country: 'US' },
  { name: 'Port Arthur', state: 'Texas', country: 'US' },
  { name: 'Port Charlotte', state: 'Florida', country: 'US' },
  { name: 'Port Chester', state: 'New York', country: 'US' },
  { name: 'Port Clinton', state: 'Ohio', country: 'US' },
  { name: 'Port Hueneme', state: 'California', country: 'US' },
  { name: 'Port Huron', state: 'Michigan', country: 'US' },
  { name: 'Port Jefferson', state: 'New York', country: 'US' },
  { name: 'Port Jefferson Station', state: 'New York', country: 'US' },
  { name: 'Port Jervis', state: 'New York', country: 'US' },
  { name: 'Port Lavaca', state: 'Texas', country: 'US' },
  { name: 'Port Neches', state: 'Texas', country: 'US' },
  { name: 'Port Orange', state: 'Florida', country: 'US' },
  { name: 'Port Orchard', state: 'Washington', country: 'US' },
  { name: 'Port Salerno', state: 'Florida', country: 'US' },
  { name: 'Port St. John', state: 'Florida', country: 'US' },
  { name: 'Port St. Lucie', state: 'Florida', country: 'US' },
  { name: 'Port Townsend', state: 'Washington', country: 'US' },
  { name: 'Port Washington', state: 'New York', country: 'US' },
  { name: 'Port Washington', state: 'Wisconsin', country: 'US' },
  { name: 'Portage', state: 'Wisconsin', country: 'US' },
  { name: 'Portage', state: 'Indiana', country: 'US' },
  { name: 'Portage', state: 'Michigan', country: 'US' },
  { name: 'Portage Lakes', state: 'Ohio', country: 'US' },
  { name: 'Portales', state: 'New Mexico', country: 'US' },
  { name: 'Porter', state: 'New York', country: 'US' },
  { name: 'Porterville', state: 'California', country: 'US' },
  { name: 'Portland', state: 'Connecticut', country: 'US' },
  { name: 'Portland', state: 'Maine', country: 'US' },
  { name: 'Portland', state: 'Indiana', country: 'US' },
  { name: 'Portland', state: 'Tennessee', country: 'US' },
  { name: 'Portland', state: 'Texas', country: 'US' },
  { name: 'Portland', state: 'Oregon', country: 'US' },
  { name: 'Portola Hills', state: 'California', country: 'US' },
  { name: 'Portsmouth', state: 'Rhode Island', country: 'US' },
  { name: 'Portsmouth', state: 'Ohio', country: 'US' },
  { name: 'Portsmouth', state: 'New Hampshire', country: 'US' },
  { name: 'Portsmouth', state: 'Virginia', country: 'US' },
  { name: 'Post Falls', state: 'Idaho', country: 'US' },
  { name: 'Poteau', state: 'Oklahoma', country: 'US' },
  { name: 'Potomac', state: 'Maryland', country: 'US' },
  { name: 'Potsdam', state: 'New York', country: 'US' },
  { name: 'Potsdam', state: 'New York', country: 'US' },
  { name: 'Pottstown', state: 'Pennsylvania', country: 'US' },
  { name: 'Pottsville', state: 'Pennsylvania', country: 'US' },
  { name: 'Poughkeepsie', state: 'New York', country: 'US' },
  { name: 'Poughkeepsie', state: 'New York', country: 'US' },
  { name: 'Poulsbo', state: 'Washington', country: 'US' },
  { name: 'Poway', state: 'California', country: 'US' },
  { name: 'Powder Springs', state: 'Georgia', country: 'US' },
  { name: 'Powell', state: 'Ohio', country: 'US' },
  { name: 'Prairie du Chien', state: 'Wisconsin', country: 'US' },
  { name: 'Prairie Ridge', state: 'Washington', country: 'US' },
  { name: 'Prairie Village', state: 'Kansas', country: 'US' },
  { name: 'Pratt', state: 'Kansas', country: 'US' },
  { name: 'Prattville', state: 'Alabama', country: 'US' },
  { name: 'Prescott', state: 'Arizona', country: 'US' },
  { name: 'Prescott Valley', state: 'Arizona', country: 'US' },
  { name: 'Presque Isle', state: 'Maine', country: 'US' },
  { name: 'Price', state: 'Utah', country: 'US' },
  { name: 'Prichard', state: 'Alabama', country: 'US' },
  { name: 'Prien', state: 'Louisiana', country: 'US' },
  { name: 'Princeton', state: 'Kentucky', country: 'US' },
  { name: 'Princeton', state: 'Florida', country: 'US' },
  { name: 'Princeton', state: 'Indiana', country: 'US' },
  { name: 'Princeton', state: 'Illinois', country: 'US' },
  { name: 'Princeton', state: 'New Jersey', country: 'US' },
  { name: 'Princeton', state: 'West Virginia', country: 'US' },
  { name: 'Princeton Meadows', state: 'New Jersey', country: 'US' },
  { name: 'Prineville', state: 'Oregon', country: 'US' },
  { name: 'Prior Lake', state: 'Minnesota', country: 'US' },
  { name: 'Progress', state: 'Pennsylvania', country: 'US' },
  { name: 'Prospect', state: 'Connecticut', country: 'US' },
  { name: 'Prospect Heights', state: 'Illinois', country: 'US' },
  { name: 'Prospect Park', state: 'Pennsylvania', country: 'US' },
  { name: 'Providence', state: 'Rhode Island', country: 'US' },
  { name: 'Provo', state: 'Utah', country: 'US' },
  { name: 'Prunedale', state: 'California', country: 'US' },
  { name: 'Pryor Creek', state: 'Oklahoma', country: 'US' },
  { name: 'Pueblo', state: 'Colorado', country: 'US' },
  { name: 'Pueblo West', state: 'Colorado', country: 'US' },
  { name: 'Pukalani', state: 'Hawaii', country: 'US' },
  { name: 'Pulaski', state: 'Tennessee', country: 'US' },
  { name: 'Pulaski', state: 'Virginia', country: 'US' },
  { name: 'Pullman', state: 'Washington', country: 'US' },
  { name: 'Punta Gorda', state: 'Florida', country: 'US' },
  { name: 'Punxsutawney', state: 'Pennsylvania', country: 'US' },
  { name: 'Putnam', state: 'Connecticut', country: 'US' },
  { name: 'Putnam District', state: 'Connecticut', country: 'US' },
  { name: 'Putnam Valley', state: 'New York', country: 'US' },
  { name: 'Puyallup', state: 'Washington', country: 'US' },
  { name: 'Quakertown', state: 'Pennsylvania', country: 'US' },
  { name: 'Quantico Station', state: 'Virginia', country: 'US' },
  { name: 'Quartz Hill', state: 'California', country: 'US' },
  { name: 'Queensbury', state: 'New York', country: 'US' },
  { name: 'Quincy', state: 'Florida', country: 'US' },
  { name: 'Quincy', state: 'Illinois', country: 'US' },
  { name: 'Quincy', state: 'Massachusetts', country: 'US' },
  { name: 'Raceland', state: 'Louisiana', country: 'US' },
  { name: 'Racine', state: 'Wisconsin', country: 'US' },
  { name: 'Radcliff', state: 'Kentucky', country: 'US' },
  { name: 'Radford', state: 'Virginia', country: 'US' },
  { name: 'Radnor Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Rahway', state: 'New Jersey', country: 'US' },
  { name: 'Rainbow City', state: 'Alabama', country: 'US' },
  { name: 'Raleigh', state: 'North Carolina', country: 'US' },
  { name: 'Ralston', state: 'Nebraska', country: 'US' },
  { name: 'Ramapo', state: 'New York', country: 'US' },
  { name: 'Ramblewood', state: 'New Jersey', country: 'US' },
  { name: 'Ramona', state: 'California', country: 'US' },
  { name: 'Ramsey', state: 'New Jersey', country: 'US' },
  { name: 'Ramsey', state: 'Minnesota', country: 'US' },
  { name: 'Rancho Cordova', state: 'California', country: 'US' },
  { name: 'Rancho Cucamonga', state: 'California', country: 'US' },
  { name: 'Rancho Mirage', state: 'California', country: 'US' },
  { name: 'Rancho Palos Verdes', state: 'California', country: 'US' },
  { name: 'Rancho San Diego', state: 'California', country: 'US' },
  { name: 'Rancho Santa Margarita', state: 'California', country: 'US' },
  { name: 'Randallstown', state: 'Maryland', country: 'US' },
  { name: 'Randolph', state: 'Massachusetts', country: 'US' },
  { name: 'Randolph', state: 'Massachusetts', country: 'US' },
  { name: 'Rantoul', state: 'Illinois', country: 'US' },
  { name: 'Rapid City', state: 'South Dakota', country: 'US' },
  { name: 'Rapid Valley', state: 'South Dakota', country: 'US' },
  { name: 'Raritan', state: 'New Jersey', country: 'US' },
  { name: 'Raton', state: 'New Mexico', country: 'US' },
  { name: 'Ravenna', state: 'Ohio', country: 'US' },
  { name: 'Rawlins', state: 'Wyoming', country: 'US' },
  { name: 'Raymond', state: 'New Hampshire', country: 'US' },
  { name: 'Raymondville', state: 'Texas', country: 'US' },
  { name: 'Raymore', state: 'Missouri', country: 'US' },
  { name: 'Rayne', state: 'Louisiana', country: 'US' },
  { name: 'Raynham', state: 'Massachusetts', country: 'US' },
  { name: 'Raytown', state: 'Missouri', country: 'US' },
  { name: 'Reading', state: 'Massachusetts', country: 'US' },
  { name: 'Reading', state: 'Massachusetts', country: 'US' },
  { name: 'Reading', state: 'Pennsylvania', country: 'US' },
  { name: 'Reading', state: 'Ohio', country: 'US' },
  { name: 'Red Bank', state: 'South Carolina', country: 'US' },
  { name: 'Red Bank', state: 'Tennessee', country: 'US' },
  { name: 'Red Bank', state: 'New Jersey', country: 'US' },
  { name: 'Red Bluff', state: 'California', country: 'US' },
  { name: 'Red Hill', state: 'South Carolina', country: 'US' },
  { name: 'Red Hook', state: 'New York', country: 'US' },
  { name: 'Red Lion', state: 'Pennsylvania', country: 'US' },
  { name: 'Red Oak', state: 'Iowa', country: 'US' },
  { name: 'Red Wing', state: 'Minnesota', country: 'US' },
  { name: 'Redan', state: 'Georgia', country: 'US' },
  { name: 'Redding', state: 'Connecticut', country: 'US' },
  { name: 'Redding', state: 'California', country: 'US' },
  { name: 'Redford', state: 'Michigan', country: 'US' },
  { name: 'Redland', state: 'Maryland', country: 'US' },
  { name: 'Redlands', state: 'California', country: 'US' },
  { name: 'Redlands', state: 'Colorado', country: 'US' },
  { name: 'Redmond', state: 'Oregon', country: 'US' },
  { name: 'Redmond', state: 'Washington', country: 'US' },
  { name: 'Redondo Beach', state: 'California', country: 'US' },
  { name: 'Redwood City', state: 'California', country: 'US' },
  { name: 'Reedley', state: 'California', country: 'US' },
  { name: 'Reedsburg', state: 'Wisconsin', country: 'US' },
  { name: 'Rehoboth', state: 'Massachusetts', country: 'US' },
  { name: 'Reidsville', state: 'North Carolina', country: 'US' },
  { name: 'Reisterstown', state: 'Maryland', country: 'US' },
  { name: 'Rendon', state: 'Texas', country: 'US' },
  { name: 'Reno', state: 'Nevada', country: 'US' },
  { name: 'Rensselaer', state: 'New York', country: 'US' },
  { name: 'Renton', state: 'Washington', country: 'US' },
  { name: 'Republic', state: 'Missouri', country: 'US' },
  { name: 'Reserve', state: 'Louisiana', country: 'US' },
  { name: 'Reston', state: 'Virginia', country: 'US' },
  { name: 'Revere', state: 'Massachusetts', country: 'US' },
  { name: 'Rexburg', state: 'Idaho', country: 'US' },
  { name: 'Reynoldsburg', state: 'Ohio', country: 'US' },
  { name: 'Rhinebeck', state: 'New York', country: 'US' },
  { name: 'Rhinelander', state: 'Wisconsin', country: 'US' },
  { name: 'Rialto', state: 'California', country: 'US' },
  { name: 'Rib Mountain', state: 'Wisconsin', country: 'US' },
  { name: 'Rib Mountain', state: 'Wisconsin', country: 'US' },
  { name: 'Rice Lake', state: 'Wisconsin', country: 'US' },
  { name: 'Richardson', state: 'Texas', country: 'US' },
  { name: 'Richboro', state: 'Pennsylvania', country: 'US' },
  { name: 'Richfield', state: 'Utah', country: 'US' },
  { name: 'Richfield', state: 'Wisconsin', country: 'US' },
  { name: 'Richfield', state: 'Minnesota', country: 'US' },
  { name: 'Richland', state: 'Washington', country: 'US' },
  { name: 'Richland', state: 'Mississippi', country: 'US' },
  { name: 'Richland Hills', state: 'Texas', country: 'US' },
  { name: 'Richmond', state: 'Texas', country: 'US' },
  { name: 'Richmond', state: 'Rhode Island', country: 'US' },
  { name: 'Richmond', state: 'Virginia', country: 'US' },
  { name: 'Richmond', state: 'Missouri', country: 'US' },
  { name: 'Richmond', state: 'Kentucky', country: 'US' },
  { name: 'Richmond', state: 'Indiana', country: 'US' },
  { name: 'Richmond', state: 'California', country: 'US' },
  { name: 'Richmond Heights', state: 'Florida', country: 'US' },
  { name: 'Richmond Heights', state: 'Missouri', country: 'US' },
  { name: 'Richmond Heights', state: 'Ohio', country: 'US' },
  { name: 'Richmond Hill', state: 'Georgia', country: 'US' },
  { name: 'Richmond West', state: 'Florida', country: 'US' },
  { name: 'Richton Park', state: 'Illinois', country: 'US' },
  { name: 'Ridge', state: 'New York', country: 'US' },
  { name: 'Ridgecrest', state: 'California', country: 'US' },
  { name: 'Ridgefield', state: 'Connecticut', country: 'US' },
  { name: 'Ridgefield', state: 'Connecticut', country: 'US' },
  { name: 'Ridgefield', state: 'New Jersey', country: 'US' },
  { name: 'Ridgefield Park', state: 'New Jersey', country: 'US' },
  { name: 'Ridgeland', state: 'Mississippi', country: 'US' },
  { name: 'Ridgeway', state: 'New York', country: 'US' },
  { name: 'Ridgewood', state: 'New Jersey', country: 'US' },
  { name: 'Ridley Park', state: 'Pennsylvania', country: 'US' },
  { name: 'Rifle', state: 'Colorado', country: 'US' },
  { name: 'Ringwood', state: 'New Jersey', country: 'US' },
  { name: 'Rio del Mar', state: 'California', country: 'US' },
  { name: 'Rio Grande City', state: 'Texas', country: 'US' },
  { name: 'Rio Linda', state: 'California', country: 'US' },
  { name: 'Rio Rancho', state: 'New Mexico', country: 'US' },
  { name: 'Ripley', state: 'Tennessee', country: 'US' },
  { name: 'Ripon', state: 'Wisconsin', country: 'US' },
  { name: 'Ripon', state: 'California', country: 'US' },
  { name: 'Rittman', state: 'Ohio', country: 'US' },
  { name: 'River Edge', state: 'New Jersey', country: 'US' },
  { name: 'River Falls', state: 'Wisconsin', country: 'US' },
  { name: 'River Forest', state: 'Illinois', country: 'US' },
  { name: 'River Grove', state: 'Illinois', country: 'US' },
  { name: 'River Oaks', state: 'Texas', country: 'US' },
  { name: 'River Ridge', state: 'Louisiana', country: 'US' },
  { name: 'River Rouge', state: 'Michigan', country: 'US' },
  { name: 'River Vale', state: 'New Jersey', country: 'US' },
  { name: 'Riverbank', state: 'California', country: 'US' },
  { name: 'Riverdale', state: 'Illinois', country: 'US' },
  { name: 'Riverdale', state: 'Georgia', country: 'US' },
  { name: 'Riverdale', state: 'Utah', country: 'US' },
  { name: 'Riverdale Park', state: 'Maryland', country: 'US' },
  { name: 'Riverhead', state: 'New York', country: 'US' },
  { name: 'Riverhead', state: 'New York', country: 'US' },
  { name: 'Riverside', state: 'Ohio', country: 'US' },
  { name: 'Riverside', state: 'Maryland', country: 'US' },
  { name: 'Riverside', state: 'Illinois', country: 'US' },
  { name: 'Riverside', state: 'California', country: 'US' },
  { name: 'Riverton', state: 'Utah', country: 'US' },
  { name: 'Riverton', state: 'Wyoming', country: 'US' },
  { name: 'Riverton-Boulevard Park', state: 'Washington', country: 'US' },
  { name: 'Riverview', state: 'Florida', country: 'US' },
  { name: 'Riverview', state: 'Michigan', country: 'US' },
  { name: 'Riviera Beach', state: 'Maryland', country: 'US' },
  { name: 'Riviera Beach', state: 'Florida', country: 'US' },
  { name: 'Roanoke', state: 'Alabama', country: 'US' },
  { name: 'Roanoke', state: 'Virginia', country: 'US' },
  { name: 'Roanoke Rapids', state: 'North Carolina', country: 'US' },
  { name: 'Robbins', state: 'Illinois', country: 'US' },
  { name: 'Robbinsdale', state: 'Minnesota', country: 'US' },
  { name: 'Robinson', state: 'Illinois', country: 'US' },
  { name: 'Robinson', state: 'Texas', country: 'US' },
  { name: 'Robinson Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Robstown', state: 'Texas', country: 'US' },
  { name: 'Rochelle', state: 'Illinois', country: 'US' },
  { name: 'Rochester', state: 'Minnesota', country: 'US' },
  { name: 'Rochester', state: 'Michigan', country: 'US' },
  { name: 'Rochester', state: 'Indiana', country: 'US' },
  { name: 'Rochester', state: 'New York', country: 'US' },
  { name: 'Rochester', state: 'New York', country: 'US' },
  { name: 'Rochester', state: 'New Hampshire', country: 'US' },
  { name: 'Rochester Hills', state: 'Michigan', country: 'US' },
  { name: 'Rock Falls', state: 'Illinois', country: 'US' },
  { name: 'Rock Hill', state: 'South Carolina', country: 'US' },
  { name: 'Rock Island', state: 'Illinois', country: 'US' },
  { name: 'Rock Springs', state: 'Wyoming', country: 'US' },
  { name: 'Rockaway', state: 'New Jersey', country: 'US' },
  { name: 'Rockcreek', state: 'Oregon', country: 'US' },
  { name: 'Rockford', state: 'Illinois', country: 'US' },
  { name: 'Rockingham', state: 'North Carolina', country: 'US' },
  { name: 'Rockland', state: 'Massachusetts', country: 'US' },
  { name: 'Rockland', state: 'Maine', country: 'US' },
  { name: 'Rockledge', state: 'Florida', country: 'US' },
  { name: 'Rocklin', state: 'California', country: 'US' },
  { name: 'Rockport', state: 'Massachusetts', country: 'US' },
  { name: 'Rockport', state: 'Texas', country: 'US' },
  { name: 'Rockville', state: 'Maryland', country: 'US' },
  { name: 'Rockville', state: 'Connecticut', country: 'US' },
  { name: 'Rockville Centre', state: 'New York', country: 'US' },
  { name: 'Rockwall', state: 'Texas', country: 'US' },
  { name: 'Rocky Hill', state: 'Connecticut', country: 'US' },
  { name: 'Rocky Mount', state: 'North Carolina', country: 'US' },
  { name: 'Rocky Point', state: 'New York', country: 'US' },
  { name: 'Rocky River', state: 'Ohio', country: 'US' },
  { name: 'Rodeo', state: 'California', country: 'US' },
  { name: 'Roeland Park', state: 'Kansas', country: 'US' },
  { name: 'Rogers', state: 'Arkansas', country: 'US' },
  { name: 'Rohnert Park', state: 'California', country: 'US' },
  { name: 'Rolla', state: 'Missouri', country: 'US' },
  { name: 'Rolling Hills Estates', state: 'California', country: 'US' },
  { name: 'Rolling Meadows', state: 'Illinois', country: 'US' },
  { name: 'Roma', state: 'Texas', country: 'US' },
  { name: 'Rome', state: 'New York', country: 'US' },
  { name: 'Rome', state: 'Georgia', country: 'US' },
  { name: 'Romeoville', state: 'Illinois', country: 'US' },
  { name: 'Romulus', state: 'Michigan', country: 'US' },
  { name: 'Ronkonkoma', state: 'New York', country: 'US' },
  { name: 'Roosevelt', state: 'New York', country: 'US' },
  { name: 'Rosamond', state: 'California', country: 'US' },
  { name: 'Rosaryville', state: 'Maryland', country: 'US' },
  { name: 'Roscoe', state: 'Illinois', country: 'US' },
  { name: 'Rose Hill', state: 'Virginia', country: 'US' },
  { name: 'Roseburg', state: 'Oregon', country: 'US' },
  { name: 'Rosedale', state: 'Maryland', country: 'US' },
  { name: 'Rosedale', state: 'California', country: 'US' },
  { name: 'Roseland', state: 'California', country: 'US' },
  { name: 'Roselle', state: 'Illinois', country: 'US' },
  { name: 'Roselle', state: 'New Jersey', country: 'US' },
  { name: 'Roselle Park', state: 'New Jersey', country: 'US' },
  { name: 'Rosemead', state: 'California', country: 'US' },
  { name: 'Rosemont', state: 'California', country: 'US' },
  { name: 'Rosemount', state: 'Minnesota', country: 'US' },
  { name: 'Rosenberg', state: 'Texas', country: 'US' },
  { name: 'Rosendale', state: 'New York', country: 'US' },
  { name: 'Roseville', state: 'Minnesota', country: 'US' },
  { name: 'Roseville', state: 'Michigan', country: 'US' },
  { name: 'Roseville', state: 'California', country: 'US' },
  { name: 'Roslyn Heights', state: 'New York', country: 'US' },
  { name: 'Ross Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Rossford', state: 'Ohio', country: 'US' },
  { name: 'Rossmoor', state: 'California', country: 'US' },
  { name: 'Rossmoor', state: 'Maryland', country: 'US' },
  { name: 'Rossville', state: 'Maryland', country: 'US' },
  { name: 'Roswell', state: 'Georgia', country: 'US' },
  { name: 'Roswell', state: 'New Mexico', country: 'US' },
  { name: 'Rotonda', state: 'Florida', country: 'US' },
  { name: 'Rotterdam', state: 'New York', country: 'US' },
  { name: 'Rotterdam', state: 'New York', country: 'US' },
  { name: 'Round Lake Beach', state: 'Illinois', country: 'US' },
  { name: 'Round Lake Park', state: 'Illinois', country: 'US' },
  { name: 'Round Rock', state: 'Texas', country: 'US' },
  { name: 'Rowland Heights', state: 'California', country: 'US' },
  { name: 'Rowlett', state: 'Texas', country: 'US' },
  { name: 'Roxboro', state: 'North Carolina', country: 'US' },
  { name: 'Roy', state: 'Utah', country: 'US' },
  { name: 'Royal Oak', state: 'Michigan', country: 'US' },
  { name: 'Royal Palm Beach', state: 'Florida', country: 'US' },
  { name: 'Royalton', state: 'New York', country: 'US' },
  { name: 'Rubidoux', state: 'California', country: 'US' },
  { name: 'Ruidoso', state: 'New Mexico', country: 'US' },
  { name: 'Rumford', state: 'Maine', country: 'US' },
  { name: 'Rumson', state: 'New Jersey', country: 'US' },
  { name: 'Runnemede', state: 'New Jersey', country: 'US' },
  { name: 'Ruskin', state: 'Florida', country: 'US' },
  { name: 'Russellville', state: 'Arkansas', country: 'US' },
  { name: 'Russellville', state: 'Alabama', country: 'US' },
  { name: 'Russellville', state: 'Kentucky', country: 'US' },
  { name: 'Ruston', state: 'Louisiana', country: 'US' },
  { name: 'Rutherford', state: 'New Jersey', country: 'US' },
  { name: 'Rutland', state: 'Massachusetts', country: 'US' },
  { name: 'Rutland', state: 'Vermont', country: 'US' },
  { name: 'Rye', state: 'New York', country: 'US' },
  { name: 'Rye', state: 'New York', country: 'US' },
  { name: 'Rye Brook', state: 'New York', country: 'US' },
  { name: 'Sachse', state: 'Texas', country: 'US' },
  { name: 'Saco', state: 'Maine', country: 'US' },
  { name: 'Sacramento', state: 'California', country: 'US' },
  { name: 'Saddle Brook', state: 'New Jersey', country: 'US' },
  { name: 'Safety Harbor', state: 'Florida', country: 'US' },
  { name: 'Safford', state: 'Arizona', country: 'US' },
  { name: 'Saginaw', state: 'Michigan', country: 'US' },
  { name: 'Saginaw', state: 'Texas', country: 'US' },
  { name: 'Saginaw Township North', state: 'Michigan', country: 'US' },
  { name: 'Saginaw Township South', state: 'Michigan', country: 'US' },
  { name: 'Saks', state: 'Alabama', country: 'US' },
  { name: 'Salamanca', state: 'New York', country: 'US' },
  { name: 'Salem', state: 'New Hampshire', country: 'US' },
  { name: 'Salem', state: 'Ohio', country: 'US' },
  { name: 'Salem', state: 'Oregon', country: 'US' },
  { name: 'Salem', state: 'Virginia', country: 'US' },
  { name: 'Salem', state: 'Wisconsin', country: 'US' },
  { name: 'Salem', state: 'Massachusetts', country: 'US' },
  { name: 'Salem', state: 'Illinois', country: 'US' },
  { name: 'Salem', state: 'Indiana', country: 'US' },
  { name: 'Salida', state: 'California', country: 'US' },
  { name: 'Salina', state: 'Kansas', country: 'US' },
  { name: 'Salina', state: 'New York', country: 'US' },
  { name: 'Salinas', state: 'California', country: 'US' },
  { name: 'Saline', state: 'Michigan', country: 'US' },
  { name: 'Salisbury', state: 'Massachusetts', country: 'US' },
  { name: 'Salisbury', state: 'Maryland', country: 'US' },
  { name: 'Salisbury', state: 'New York', country: 'US' },
  { name: 'Salisbury', state: 'North Carolina', country: 'US' },
  { name: 'Sallisaw', state: 'Oklahoma', country: 'US' },
  { name: 'Salmon Creek', state: 'Washington', country: 'US' },
  { name: 'Salt Lake City', state: 'Utah', country: 'US' },
  { name: 'Sammamish', state: 'Washington', country: 'US' },
  { name: 'San Angelo', state: 'Texas', country: 'US' },
  { name: 'San Anselmo', state: 'California', country: 'US' },
  { name: 'San Antonio', state: 'Texas', country: 'US' },
  { name: 'San Benito', state: 'Texas', country: 'US' },
  { name: 'San Bernardino', state: 'California', country: 'US' },
  { name: 'San Bruno', state: 'California', country: 'US' },
  { name: 'San Buenaventura', state: 'California', country: 'US' },
  { name: 'San Carlos', state: 'California', country: 'US' },
  { name: 'San Carlos Park', state: 'Florida', country: 'US' },
  { name: 'San Clemente', state: 'California', country: 'US' },
  { name: 'San Diego', state: 'California', country: 'US' },
  { name: 'San Diego Country Estates', state: 'California', country: 'US' },
  { name: 'San Dimas', state: 'California', country: 'US' },
  { name: 'San Elizario', state: 'Texas', country: 'US' },
  { name: 'San Fernando', state: 'California', country: 'US' },
  { name: 'San Francisco', state: 'California', country: 'US' },
  { name: 'San Gabriel', state: 'California', country: 'US' },
  { name: 'San Jacinto', state: 'California', country: 'US' },
  { name: 'San Jose', state: 'California', country: 'US' },
  { name: 'San Juan', state: 'Texas', country: 'US' },
  { name: 'San Juan Capistrano', state: 'California', country: 'US' },
  { name: 'San Leandro', state: 'California', country: 'US' },
  { name: 'San Lorenzo', state: 'California', country: 'US' },
  { name: 'San Luis', state: 'Arizona', country: 'US' },
  { name: 'San Luis Obispo', state: 'California', country: 'US' },
  { name: 'San Marcos', state: 'California', country: 'US' },
  { name: 'San Marcos', state: 'Texas', country: 'US' },
  { name: 'San Marino', state: 'California', country: 'US' },
  { name: 'San Mateo', state: 'California', country: 'US' },
  { name: 'San Pablo', state: 'California', country: 'US' },
  { name: 'San Rafael', state: 'California', country: 'US' },
  { name: 'San Ramon', state: 'California', country: 'US' },
  { name: 'Sanatoga', state: 'Pennsylvania', country: 'US' },
  { name: 'Sand Lake', state: 'New York', country: 'US' },
  { name: 'Sand Springs', state: 'Oklahoma', country: 'US' },
  { name: 'Sandalfoot Cove', state: 'Florida', country: 'US' },
  { name: 'Sandersville', state: 'Georgia', country: 'US' },
  { name: 'Sandpoint', state: 'Idaho', country: 'US' },
  { name: 'Sandusky', state: 'Ohio', country: 'US' },
  { name: 'Sandusky South', state: 'Ohio', country: 'US' },
  { name: 'Sandwich', state: 'Illinois', country: 'US' },
  { name: 'Sandwich', state: 'Massachusetts', country: 'US' },
  { name: 'Sandy', state: 'Utah', country: 'US' },
  { name: 'Sandy Springs', state: 'Georgia', country: 'US' },
  { name: 'Sanford', state: 'Florida', country: 'US' },
  { name: 'Sanford', state: 'Maine', country: 'US' },
  { name: 'Sanford', state: 'Maine', country: 'US' },
  { name: 'Sanford', state: 'North Carolina', country: 'US' },
  { name: 'Sanger', state: 'California', country: 'US' },
  { name: 'Sanibel', state: 'Florida', country: 'US' },
  { name: 'Sans Souci', state: 'South Carolina', country: 'US' },
  { name: 'Santa Ana', state: 'California', country: 'US' },
  { name: 'Santa Barbara', state: 'California', country: 'US' },
  { name: 'Santa Clara', state: 'California', country: 'US' },
  { name: 'Santa Clarita', state: 'California', country: 'US' },
  { name: 'Santa Cruz', state: 'California', country: 'US' },
  { name: 'Santa Fe', state: 'New Mexico', country: 'US' },
  { name: 'Santa Fe', state: 'Texas', country: 'US' },
  { name: 'Santa Fe Springs', state: 'California', country: 'US' },
  { name: 'Santa Maria', state: 'California', country: 'US' },
  { name: 'Santa Monica', state: 'California', country: 'US' },
  { name: 'Santa Paula', state: 'California', country: 'US' },
  { name: 'Santa Rosa', state: 'California', country: 'US' },
  { name: 'Santee', state: 'California', country: 'US' },
  { name: 'Sappington', state: 'Missouri', country: 'US' },
  { name: 'Sapulpa', state: 'Oklahoma', country: 'US' },
  { name: 'Saraland', state: 'Alabama', country: 'US' },
  { name: 'Sarasota', state: 'Florida', country: 'US' },
  { name: 'Sarasota Springs', state: 'Florida', country: 'US' },
  { name: 'Saratoga', state: 'California', country: 'US' },
  { name: 'Saratoga Springs', state: 'New York', country: 'US' },
  { name: 'Sartell', state: 'Minnesota', country: 'US' },
  { name: 'Satellite Beach', state: 'Florida', country: 'US' },
  { name: 'Saugerties', state: 'New York', country: 'US' },
  { name: 'Saugus', state: 'Massachusetts', country: 'US' },
  { name: 'Saugus', state: 'Massachusetts', country: 'US' },
  { name: 'Sauk Rapids', state: 'Minnesota', country: 'US' },
  { name: 'Sauk Village', state: 'Illinois', country: 'US' },
  { name: 'Sault Ste. Marie', state: 'Michigan', country: 'US' },
  { name: 'Sausalito', state: 'California', country: 'US' },
  { name: 'Savage', state: 'Minnesota', country: 'US' },
  { name: 'Savage-Guilford', state: 'Maryland', country: 'US' },
  { name: 'Savannah', state: 'Georgia', country: 'US' },
  { name: 'Savannah', state: 'Tennessee', country: 'US' },
  { name: 'Sayreville', state: 'New Jersey', country: 'US' },
  { name: 'Sayville', state: 'New York', country: 'US' },
  { name: 'Scarborough', state: 'Maine', country: 'US' },
  { name: 'Scarsdale', state: 'New York', country: 'US' },
  { name: 'Scarsdale', state: 'New York', country: 'US' },
  { name: 'Schaghticoke', state: 'New York', country: 'US' },
  { name: 'Schaumburg', state: 'Illinois', country: 'US' },
  { name: 'Schenectady', state: 'New York', country: 'US' },
  { name: 'Schererville', state: 'Indiana', country: 'US' },
  { name: 'Schertz', state: 'Texas', country: 'US' },
  { name: 'Schiller Park', state: 'Illinois', country: 'US' },
  { name: 'Schodack', state: 'New York', country: 'US' },
  { name: 'Schofield Barracks', state: 'Hawaii', country: 'US' },
  { name: 'Schroeppel', state: 'New York', country: 'US' },
  { name: 'Scituate', state: 'Rhode Island', country: 'US' },
  { name: 'Scituate', state: 'Massachusetts', country: 'US' },
  { name: 'Scotch Plains', state: 'New Jersey', country: 'US' },
  { name: 'Scotchtown', state: 'New York', country: 'US' },
  { name: 'Scotia', state: 'New York', country: 'US' },
  { name: 'Scott', state: 'Louisiana', country: 'US' },
  { name: 'Scott Lake', state: 'Florida', country: 'US' },
  { name: 'Scott Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Scottdale', state: 'Georgia', country: 'US' },
  { name: 'Scotts Valley', state: 'California', country: 'US' },
  { name: 'Scottsbluff', state: 'Nebraska', country: 'US' },
  { name: 'Scottsboro', state: 'Alabama', country: 'US' },
  { name: 'Scottsburg', state: 'Indiana', country: 'US' },
  { name: 'Scottsdale', state: 'Arizona', country: 'US' },
  { name: 'Scranton', state: 'Pennsylvania', country: 'US' },
  { name: 'Scriba', state: 'New York', country: 'US' },
  { name: 'Seabrook', state: 'New Hampshire', country: 'US' },
  { name: 'Seabrook', state: 'Texas', country: 'US' },
  { name: 'Seaford', state: 'New York', country: 'US' },
  { name: 'Seaford', state: 'Delaware', country: 'US' },
  { name: 'Seagoville', state: 'Texas', country: 'US' },
  { name: 'Seal Beach', state: 'California', country: 'US' },
  { name: 'Searcy', state: 'Arkansas', country: 'US' },
  { name: 'Seaside', state: 'California', country: 'US' },
  { name: 'SeaTac', state: 'Washington', country: 'US' },
  { name: 'Seattle', state: 'Washington', country: 'US' },
  { name: 'Seattle Hill-Silver Firs', state: 'Washington', country: 'US' },
  { name: 'Sebastian', state: 'Florida', country: 'US' },
  { name: 'Sebastopol', state: 'California', country: 'US' },
  { name: 'Sebring', state: 'Florida', country: 'US' },
  { name: 'Secaucus', state: 'New Jersey', country: 'US' },
  { name: 'Security-Widefield', state: 'Colorado', country: 'US' },
  { name: 'Sedalia', state: 'Missouri', country: 'US' },
  { name: 'Sedona', state: 'Arizona', country: 'US' },
  { name: 'Sedro-Woolley', state: 'Washington', country: 'US' },
  { name: 'Seekonk', state: 'Massachusetts', country: 'US' },
  { name: 'Seguin', state: 'Texas', country: 'US' },
  { name: 'Selah', state: 'Washington', country: 'US' },
  { name: 'Selden', state: 'New York', country: 'US' },
  { name: 'Sellersburg', state: 'Indiana', country: 'US' },
  { name: 'Selma', state: 'Alabama', country: 'US' },
  { name: 'Selma', state: 'California', country: 'US' },
  { name: 'Seminole', state: 'Florida', country: 'US' },
  { name: 'Seminole', state: 'Oklahoma', country: 'US' },
  { name: 'Senatobia', state: 'Mississippi', country: 'US' },
  { name: 'Seneca', state: 'South Carolina', country: 'US' },
  { name: 'Seneca Falls', state: 'New York', country: 'US' },
  { name: 'Seneca Falls', state: 'New York', country: 'US' },
  { name: 'Setauket-East Setauket', state: 'New York', country: 'US' },
  { name: 'Seven Corners', state: 'Virginia', country: 'US' },
  { name: 'Seven Hills', state: 'Ohio', country: 'US' },
  { name: 'Seven Oaks', state: 'South Carolina', country: 'US' },
  { name: 'Severn', state: 'Maryland', country: 'US' },
  { name: 'Severna Park', state: 'Maryland', country: 'US' },
  { name: 'Sevierville', state: 'Tennessee', country: 'US' },
  { name: 'Seward', state: 'Nebraska', country: 'US' },
  { name: 'Seymour', state: 'Tennessee', country: 'US' },
  { name: 'Seymour', state: 'Indiana', country: 'US' },
  { name: 'Seymour', state: 'Connecticut', country: 'US' },
  { name: 'Shady Hills', state: 'Florida', country: 'US' },
  { name: 'Shafter', state: 'California', country: 'US' },
  { name: 'Shaker Heights', state: 'Ohio', country: 'US' },
  { name: 'Shakopee', state: 'Minnesota', country: 'US' },
  { name: 'Shaler Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Shamokin', state: 'Pennsylvania', country: 'US' },
  { name: 'Sharon', state: 'Pennsylvania', country: 'US' },
  { name: 'Sharon', state: 'Massachusetts', country: 'US' },
  { name: 'Sharonville', state: 'Ohio', country: 'US' },
  { name: 'Shasta Lake', state: 'California', country: 'US' },
  { name: 'Shawangunk', state: 'New York', country: 'US' },
  { name: 'Shawano', state: 'Wisconsin', country: 'US' },
  { name: 'Shawnee', state: 'Oklahoma', country: 'US' },
  { name: 'Shawnee', state: 'Kansas', country: 'US' },
  { name: 'Sheboygan', state: 'Wisconsin', country: 'US' },
  { name: 'Sheboygan Falls', state: 'Wisconsin', country: 'US' },
  { name: 'Sheffield', state: 'Alabama', country: 'US' },
  { name: 'Sheffield Lake', state: 'Ohio', country: 'US' },
  { name: 'Shelburne', state: 'Vermont', country: 'US' },
  { name: 'Shelby', state: 'Ohio', country: 'US' },
  { name: 'Shelby', state: 'North Carolina', country: 'US' },
  { name: 'Shelby', state: 'Michigan', country: 'US' },
  { name: 'Shelbyville', state: 'Kentucky', country: 'US' },
  { name: 'Shelbyville', state: 'Indiana', country: 'US' },
  { name: 'Shelbyville', state: 'Tennessee', country: 'US' },
  { name: 'Shelton', state: 'Washington', country: 'US' },
  { name: 'Shelton', state: 'Connecticut', country: 'US' },
  { name: 'Shelton', state: 'Connecticut', country: 'US' },
  { name: 'Shenandoah', state: 'Louisiana', country: 'US' },
  { name: 'Shepherdsville', state: 'Kentucky', country: 'US' },
  { name: 'Sheridan', state: 'Wyoming', country: 'US' },
  { name: 'Sherman', state: 'Texas', country: 'US' },
  { name: 'Sherrelwood', state: 'Colorado', country: 'US' },
  { name: 'Sherwood', state: 'Arkansas', country: 'US' },
  { name: 'Sherwood', state: 'Oregon', country: 'US' },
  { name: 'Shields', state: 'Michigan', country: 'US' },
  { name: 'Shiloh', state: 'Illinois', country: 'US' },
  { name: 'Shiloh', state: 'Pennsylvania', country: 'US' },
  { name: 'Shiloh', state: 'Ohio', country: 'US' },
  { name: 'Shiprock', state: 'New Mexico', country: 'US' },
  { name: 'Shirley', state: 'New York', country: 'US' },
  { name: 'Shirley', state: 'Massachusetts', country: 'US' },
  { name: 'Shively', state: 'Kentucky', country: 'US' },
  { name: 'Shoreline', state: 'Washington', country: 'US' },
  { name: 'Shoreview', state: 'Minnesota', country: 'US' },
  { name: 'Shorewood', state: 'Minnesota', country: 'US' },
  { name: 'Shorewood', state: 'Illinois', country: 'US' },
  { name: 'Shorewood', state: 'Wisconsin', country: 'US' },
  { name: 'Show Low', state: 'Arizona', country: 'US' },
  { name: 'Shreveport', state: 'Louisiana', country: 'US' },
  { name: 'Shrewsbury', state: 'Missouri', country: 'US' },
  { name: 'Shrewsbury', state: 'Massachusetts', country: 'US' },
  { name: 'Sidney', state: 'New York', country: 'US' },
  { name: 'Sidney', state: 'Nebraska', country: 'US' },
  { name: 'Sidney', state: 'Ohio', country: 'US' },
  { name: 'Sierra Madre', state: 'California', country: 'US' },
  { name: 'Sierra Vista', state: 'Arizona', country: 'US' },
  { name: 'Sierra Vista Southeast', state: 'Arizona', country: 'US' },
  { name: 'Siesta Key', state: 'Florida', country: 'US' },
  { name: 'Signal Hill', state: 'California', country: 'US' },
  { name: 'Signal Mountain', state: 'Tennessee', country: 'US' },
  { name: 'Sikeston', state: 'Missouri', country: 'US' },
  { name: 'Siler City', state: 'North Carolina', country: 'US' },
  { name: 'Siloam Springs', state: 'Arkansas', country: 'US' },
  { name: 'Silsbee', state: 'Texas', country: 'US' },
  { name: 'Silver City', state: 'New Mexico', country: 'US' },
  { name: 'Silver Spring', state: 'Maryland', country: 'US' },
  { name: 'Silver Springs Shores', state: 'Florida', country: 'US' },
  { name: 'Silverdale', state: 'Washington', country: 'US' },
  { name: 'Silverton', state: 'Oregon', country: 'US' },
  { name: 'Silvis', state: 'Illinois', country: 'US' },
  { name: 'Simi Valley', state: 'California', country: 'US' },
  { name: 'Simpsonville', state: 'South Carolina', country: 'US' },
  { name: 'Simsbury', state: 'Connecticut', country: 'US' },
  { name: 'Sioux Center', state: 'Iowa', country: 'US' },
  { name: 'Sioux City', state: 'Iowa', country: 'US' },
  { name: 'Sioux Falls', state: 'South Dakota', country: 'US' },
  { name: 'Sitka and', state: 'Alaska', country: 'US' },
  { name: 'Skaneateles', state: 'New York', country: 'US' },
  { name: 'Skidaway Island', state: 'Georgia', country: 'US' },
  { name: 'Skokie', state: 'Illinois', country: 'US' },
  { name: 'Skowhegan', state: 'Maine', country: 'US' },
  { name: 'Skowhegan', state: 'Maine', country: 'US' },
  { name: 'Slaton', state: 'Texas', country: 'US' },
  { name: 'Sleepy Hollow', state: 'New York', country: 'US' },
  { name: 'Slidell', state: 'Louisiana', country: 'US' },
  { name: 'Smithfield', state: 'North Carolina', country: 'US' },
  { name: 'Smithfield', state: 'Rhode Island', country: 'US' },
  { name: 'Smithfield', state: 'Utah', country: 'US' },
  { name: 'Smithfield', state: 'Virginia', country: 'US' },
  { name: 'Smiths', state: 'Alabama', country: 'US' },
  { name: 'Smithtown', state: 'New York', country: 'US' },
  { name: 'Smithtown', state: 'New York', country: 'US' },
  { name: 'Smyrna', state: 'Tennessee', country: 'US' },
  { name: 'Smyrna', state: 'Georgia', country: 'US' },
  { name: 'Snellville', state: 'Georgia', country: 'US' },
  { name: 'Snohomish', state: 'Washington', country: 'US' },
  { name: 'Snyder', state: 'Texas', country: 'US' },
  { name: 'Socastee', state: 'South Carolina', country: 'US' },
  { name: 'Socorro', state: 'New Mexico', country: 'US' },
  { name: 'Socorro', state: 'Texas', country: 'US' },
  { name: 'Soddy-Daisy', state: 'Tennessee', country: 'US' },
  { name: 'Sodus', state: 'New York', country: 'US' },
  { name: 'Solana Beach', state: 'California', country: 'US' },
  { name: 'Soledad', state: 'California', country: 'US' },
  { name: 'Solon', state: 'Ohio', country: 'US' },
  { name: 'Solvay', state: 'New York', country: 'US' },
  { name: 'Somers', state: 'New York', country: 'US' },
  { name: 'Somers', state: 'Wisconsin', country: 'US' },
  { name: 'Somers', state: 'Connecticut', country: 'US' },
  { name: 'Somers Point', state: 'New Jersey', country: 'US' },
  { name: 'Somerset', state: 'New Jersey', country: 'US' },
  { name: 'Somerset', state: 'Pennsylvania', country: 'US' },
  { name: 'Somerset', state: 'Kentucky', country: 'US' },
  { name: 'Somerset', state: 'Massachusetts', country: 'US' },
  { name: 'Somerset', state: 'Massachusetts', country: 'US' },
  { name: 'Somersworth', state: 'New Hampshire', country: 'US' },
  { name: 'Somerton', state: 'Arizona', country: 'US' },
  { name: 'Somerville', state: 'Massachusetts', country: 'US' },
  { name: 'Somerville', state: 'New Jersey', country: 'US' },
  { name: 'Sonoma', state: 'California', country: 'US' },
  { name: 'Souderton', state: 'Pennsylvania', country: 'US' },
  { name: 'Sound Beach', state: 'New York', country: 'US' },
  { name: 'South Amboy', state: 'New Jersey', country: 'US' },
  { name: 'South Bend', state: 'Indiana', country: 'US' },
  { name: 'South Berwick', state: 'Maine', country: 'US' },
  { name: 'South Boston', state: 'Virginia', country: 'US' },
  { name: 'South Bradenton', state: 'Florida', country: 'US' },
  { name: 'South Burlington', state: 'Vermont', country: 'US' },
  { name: 'South Charleston', state: 'West Virginia', country: 'US' },
  { name: 'South Cleveland', state: 'Tennessee', country: 'US' },
  { name: 'South Daytona', state: 'Florida', country: 'US' },
  { name: 'South El Monte', state: 'California', country: 'US' },
  { name: 'South Elgin', state: 'Illinois', country: 'US' },
  { name: 'South Euclid', state: 'Ohio', country: 'US' },
  { name: 'South Farmingdale', state: 'New York', country: 'US' },
  { name: 'South Gate', state: 'Maryland', country: 'US' },
  { name: 'South Gate', state: 'California', country: 'US' },
  { name: 'South Hadley', state: 'Massachusetts', country: 'US' },
  { name: 'South Highpoint', state: 'Florida', country: 'US' },
  { name: 'South Hill', state: 'New York', country: 'US' },
  { name: 'South Hill', state: 'Washington', country: 'US' },
  { name: 'South Holland', state: 'Illinois', country: 'US' },
  { name: 'South Houston', state: 'Texas', country: 'US' },
  { name: 'South Huntington', state: 'New York', country: 'US' },
  { name: 'South Jordan', state: 'Utah', country: 'US' },
  { name: 'South Kensington', state: 'Maryland', country: 'US' },
  { name: 'South Kingstown', state: 'Rhode Island', country: 'US' },
  { name: 'South Lake Tahoe', state: 'California', country: 'US' },
  { name: 'South Laurel', state: 'Maryland', country: 'US' },
  { name: 'South Lockport', state: 'New York', country: 'US' },
  { name: 'South Lyon', state: 'Michigan', country: 'US' },
  { name: 'South Miami', state: 'Florida', country: 'US' },
  { name: 'South Miami Heights', state: 'Florida', country: 'US' },
  { name: 'South Milwaukee', state: 'Wisconsin', country: 'US' },
  { name: 'South Monroe', state: 'Michigan', country: 'US' },
  { name: 'South Ogden', state: 'Utah', country: 'US' },
  { name: 'South Orange', state: 'New Jersey', country: 'US' },
  { name: 'South Oroville', state: 'California', country: 'US' },
  { name: 'South Park Township', state: 'Pennsylvania', country: 'US' },
  { name: 'South Pasadena', state: 'California', country: 'US' },
  { name: 'South Patrick Shores', state: 'Florida', country: 'US' },
  { name: 'South Plainfield', state: 'New Jersey', country: 'US' },
  { name: 'South Portland', state: 'Maine', country: 'US' },
  { name: 'South River', state: 'New Jersey', country: 'US' },
  { name: 'South Salt Lake', state: 'Utah', country: 'US' },
  { name: 'South San Francisco', state: 'California', country: 'US' },
  { name: 'South San Gabriel', state: 'California', country: 'US' },
  { name: 'South San Jose Hills', state: 'California', country: 'US' },
  { name: 'South Sioux City', state: 'Nebraska', country: 'US' },
  { name: 'South St. Paul', state: 'Minnesota', country: 'US' },
  { name: 'South Valley', state: 'New Mexico', country: 'US' },
  { name: 'South Venice', state: 'Florida', country: 'US' },
  { name: 'South Whittier', state: 'California', country: 'US' },
  { name: 'South Williamsport', state: 'Pennsylvania', country: 'US' },
  { name: 'South Windsor', state: 'Connecticut', country: 'US' },
  { name: 'South Yarmouth', state: 'Massachusetts', country: 'US' },
  { name: 'South Yuba City', state: 'California', country: 'US' },
  { name: 'Southampton', state: 'New York', country: 'US' },
  { name: 'Southaven', state: 'Mississippi', country: 'US' },
  { name: 'Southborough', state: 'Massachusetts', country: 'US' },
  { name: 'Southbridge', state: 'Massachusetts', country: 'US' },
  { name: 'Southbridge', state: 'Massachusetts', country: 'US' },
  { name: 'Southbury', state: 'Connecticut', country: 'US' },
  { name: 'Southeast', state: 'New York', country: 'US' },
  { name: 'Southeast Arcadia', state: 'Florida', country: 'US' },
  { name: 'Southern Pines', state: 'North Carolina', country: 'US' },
  { name: 'Southfield', state: 'Michigan', country: 'US' },
  { name: 'Southgate', state: 'Michigan', country: 'US' },
  { name: 'Southgate', state: 'Florida', country: 'US' },
  { name: 'Southglenn', state: 'Colorado', country: 'US' },
  { name: 'Southington', state: 'Connecticut', country: 'US' },
  { name: 'Southlake', state: 'Texas', country: 'US' },
  { name: 'Southold', state: 'New York', country: 'US' },
  { name: 'Southport', state: 'New York', country: 'US' },
  { name: 'Southport', state: 'New York', country: 'US' },
  { name: 'Southside', state: 'Alabama', country: 'US' },
  { name: 'Southwick', state: 'Massachusetts', country: 'US' },
  { name: 'Southwood Acres', state: 'Connecticut', country: 'US' },
  { name: 'Spanaway', state: 'Washington', country: 'US' },
  { name: 'Spanish Fork', state: 'Utah', country: 'US' },
  { name: 'Spanish Lake', state: 'Missouri', country: 'US' },
  { name: 'Spanish Springs', state: 'Nevada', country: 'US' },
  { name: 'Sparks', state: 'Nevada', country: 'US' },
  { name: 'Sparta', state: 'Wisconsin', country: 'US' },
  { name: 'Spartanburg', state: 'South Carolina', country: 'US' },
  { name: 'Spearfish', state: 'South Dakota', country: 'US' },
  { name: 'Speedway', state: 'Indiana', country: 'US' },
  { name: 'Spencer', state: 'Iowa', country: 'US' },
  { name: 'Spencer', state: 'Massachusetts', country: 'US' },
  { name: 'Spencer', state: 'Massachusetts', country: 'US' },
  { name: 'Spokane', state: 'Washington', country: 'US' },
  { name: 'Spotswood', state: 'New Jersey', country: 'US' },
  { name: 'Spring', state: 'Texas', country: 'US' },
  { name: 'Spring Creek', state: 'Nevada', country: 'US' },
  { name: 'Spring Hill', state: 'Tennessee', country: 'US' },
  { name: 'Spring Hill', state: 'Florida', country: 'US' },
  { name: 'Spring Lake', state: 'North Carolina', country: 'US' },
  { name: 'Spring Lake Park', state: 'Minnesota', country: 'US' },
  { name: 'Spring Valley', state: 'California', country: 'US' },
  { name: 'Spring Valley', state: 'Nevada', country: 'US' },
  { name: 'Spring Valley', state: 'New York', country: 'US' },
  { name: 'Springboro', state: 'Ohio', country: 'US' },
  { name: 'Springdale', state: 'Ohio', country: 'US' },
  { name: 'Springdale', state: 'New Jersey', country: 'US' },
  { name: 'Springdale', state: 'Arkansas', country: 'US' },
  { name: 'Springfield', state: 'Florida', country: 'US' },
  { name: 'Springfield', state: 'Missouri', country: 'US' },
  { name: 'Springfield', state: 'Massachusetts', country: 'US' },
  { name: 'Springfield', state: 'Illinois', country: 'US' },
  { name: 'Springfield', state: 'New Jersey', country: 'US' },
  { name: 'Springfield', state: 'Ohio', country: 'US' },
  { name: 'Springfield', state: 'Oregon', country: 'US' },
  { name: 'Springfield', state: 'Pennsylvania', country: 'US' },
  { name: 'Springfield', state: 'Vermont', country: 'US' },
  { name: 'Springfield', state: 'Tennessee', country: 'US' },
  { name: 'Springfield', state: 'Virginia', country: 'US' },
  { name: 'Springville', state: 'Utah', country: 'US' },
  { name: 'St. Albans', state: 'Vermont', country: 'US' },
  { name: 'St. Albans', state: 'West Virginia', country: 'US' },
  { name: 'St. Andrews', state: 'South Carolina', country: 'US' },
  { name: 'St. Ann', state: 'Missouri', country: 'US' },
  { name: 'St. Anthony', state: 'Minnesota', country: 'US' },
  { name: 'St. Augustine', state: 'Florida', country: 'US' },
  { name: 'St. Charles', state: 'Missouri', country: 'US' },
  { name: 'St. Charles', state: 'Illinois', country: 'US' },
  { name: 'St. Charles', state: 'Maryland', country: 'US' },
  { name: 'St. Clair Shores', state: 'Michigan', country: 'US' },
  { name: 'St. Cloud', state: 'Minnesota', country: 'US' },
  { name: 'St. Cloud', state: 'Florida', country: 'US' },
  { name: 'St. Dennis', state: 'Kentucky', country: 'US' },
  { name: 'St. Francis', state: 'Wisconsin', country: 'US' },
  { name: 'St. George', state: 'Utah', country: 'US' },
  { name: 'St. Helens', state: 'Oregon', country: 'US' },
  { name: 'St. James', state: 'New York', country: 'US' },
  { name: 'St. John', state: 'Indiana', country: 'US' },
  { name: 'St. John', state: 'Missouri', country: 'US' },
  { name: 'St. Johns', state: 'Michigan', country: 'US' },
  { name: 'St. Johnsbury', state: 'Vermont', country: 'US' },
  { name: 'St. Johnsbury', state: 'Vermont', country: 'US' },
  { name: 'St. Joseph', state: 'Michigan', country: 'US' },
  { name: 'St. Joseph', state: 'Missouri', country: 'US' },
  { name: 'St. Louis', state: 'Missouri', country: 'US' },
  { name: 'St. Louis Park', state: 'Minnesota', country: 'US' },
  { name: 'St. Martin', state: 'Mississippi', country: 'US' },
  { name: 'St. Martinville', state: 'Louisiana', country: 'US' },
  { name: 'St. Marys', state: 'Georgia', country: 'US' },
  { name: 'St. Marys', state: 'Pennsylvania', country: 'US' },
  { name: 'St. Marys', state: 'Ohio', country: 'US' },
  { name: 'St. Matthews', state: 'Kentucky', country: 'US' },
  { name: 'St. Michael', state: 'Minnesota', country: 'US' },
  { name: 'St. Paul', state: 'Minnesota', country: 'US' },
  { name: 'St. Pete Beach', state: 'Florida', country: 'US' },
  { name: 'St. Peter', state: 'Minnesota', country: 'US' },
  { name: 'St. Peters', state: 'Missouri', country: 'US' },
  { name: 'St. Petersburg', state: 'Florida', country: 'US' },
  { name: 'St. Rose', state: 'Louisiana', country: 'US' },
  { name: 'St. Simons', state: 'Georgia', country: 'US' },
  { name: 'St. Stephens', state: 'North Carolina', country: 'US' },
  { name: 'Stafford', state: 'Texas', country: 'US' },
  { name: 'Stafford', state: 'Connecticut', country: 'US' },
  { name: 'Stamford', state: 'Connecticut', country: 'US' },
  { name: 'Stamford', state: 'Connecticut', country: 'US' },
  { name: 'Standish', state: 'Maine', country: 'US' },
  { name: 'Stanford', state: 'California', country: 'US' },
  { name: 'Stanton', state: 'California', country: 'US' },
  { name: 'Starkville', state: 'Mississippi', country: 'US' },
  { name: 'State College', state: 'Pennsylvania', country: 'US' },
  { name: 'Statesboro', state: 'Georgia', country: 'US' },
  { name: 'Statesville', state: 'North Carolina', country: 'US' },
  { name: 'Staunton', state: 'Virginia', country: 'US' },
  { name: 'Stayton', state: 'Oregon', country: 'US' },
  { name: 'Steamboat Springs', state: 'Colorado', country: 'US' },
  { name: 'Steger', state: 'Illinois', country: 'US' },
  { name: 'Steilacoom', state: 'Washington', country: 'US' },
  { name: 'Stephenville', state: 'Texas', country: 'US' },
  { name: 'Sterling', state: 'Illinois', country: 'US' },
  { name: 'Sterling', state: 'Massachusetts', country: 'US' },
  { name: 'Sterling', state: 'Colorado', country: 'US' },
  { name: 'Sterling Heights', state: 'Michigan', country: 'US' },
  { name: 'Steubenville', state: 'Ohio', country: 'US' },
  { name: 'Stevens Point', state: 'Wisconsin', country: 'US' },
  { name: 'Stickney', state: 'Illinois', country: 'US' },
  { name: 'Stillwater', state: 'Minnesota', country: 'US' },
  { name: 'Stillwater', state: 'New York', country: 'US' },
  { name: 'Stillwater', state: 'Oklahoma', country: 'US' },
  { name: 'Stockbridge', state: 'Georgia', country: 'US' },
  { name: 'Stockton', state: 'California', country: 'US' },
  { name: 'Stone Mountain', state: 'Georgia', country: 'US' },
  { name: 'Stonegate', state: 'Colorado', country: 'US' },
  { name: 'Stoneham', state: 'Massachusetts', country: 'US' },
  { name: 'Stoneham', state: 'Massachusetts', country: 'US' },
  { name: 'Stonington', state: 'Connecticut', country: 'US' },
  { name: 'Stony Brook', state: 'New York', country: 'US' },
  { name: 'Stony Point', state: 'New York', country: 'US' },
  { name: 'Stony Point', state: 'New York', country: 'US' },
  { name: 'Storm Lake', state: 'Iowa', country: 'US' },
  { name: 'Storrs', state: 'Connecticut', country: 'US' },
  { name: 'Stoughton', state: 'Massachusetts', country: 'US' },
  { name: 'Stoughton', state: 'Wisconsin', country: 'US' },
  { name: 'Stow', state: 'Ohio', country: 'US' },
  { name: 'Stowe Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Stratford', state: 'New Jersey', country: 'US' },
  { name: 'Stratford', state: 'Connecticut', country: 'US' },
  { name: 'Stratford', state: 'Connecticut', country: 'US' },
  { name: 'Stratham', state: 'New Hampshire', country: 'US' },
  { name: 'Strathmore', state: 'New Jersey', country: 'US' },
  { name: 'Stratmoor', state: 'Colorado', country: 'US' },
  { name: 'Streamwood', state: 'Illinois', country: 'US' },
  { name: 'Streator', state: 'Illinois', country: 'US' },
  { name: 'Streetsboro', state: 'Ohio', country: 'US' },
  { name: 'Strongsville', state: 'Ohio', country: 'US' },
  { name: 'Struthers', state: 'Ohio', country: 'US' },
  { name: 'Stuart', state: 'Florida', country: 'US' },
  { name: 'Stuarts Draft', state: 'Virginia', country: 'US' },
  { name: 'Sturbridge', state: 'Massachusetts', country: 'US' },
  { name: 'Sturgeon Bay', state: 'Wisconsin', country: 'US' },
  { name: 'Sturgis', state: 'South Dakota', country: 'US' },
  { name: 'Sturgis', state: 'Michigan', country: 'US' },
  { name: 'Stuttgart', state: 'Arkansas', country: 'US' },
  { name: 'Suamico', state: 'Wisconsin', country: 'US' },
  { name: 'Succasunna-Kenvil', state: 'New Jersey', country: 'US' },
  { name: 'Sudbury', state: 'Massachusetts', country: 'US' },
  { name: 'Sudley', state: 'Virginia', country: 'US' },
  { name: 'Suffern', state: 'New York', country: 'US' },
  { name: 'Suffield', state: 'Connecticut', country: 'US' },
  { name: 'Suffolk', state: 'Virginia', country: 'US' },
  { name: 'Sugar Hill', state: 'Georgia', country: 'US' },
  { name: 'Sugar Land', state: 'Texas', country: 'US' },
  { name: 'Sugarmill Woods', state: 'Florida', country: 'US' },
  { name: 'Suisun City', state: 'California', country: 'US' },
  { name: 'Suitland-Silver Hill', state: 'Maryland', country: 'US' },
  { name: 'Sullivan', state: 'Missouri', country: 'US' },
  { name: 'Sullivan', state: 'New York', country: 'US' },
  { name: 'Sulphur', state: 'Louisiana', country: 'US' },
  { name: 'Sulphur Springs', state: 'Texas', country: 'US' },
  { name: 'Summerfield', state: 'North Carolina', country: 'US' },
  { name: 'Summerville', state: 'South Carolina', country: 'US' },
  { name: 'Summit', state: 'New Jersey', country: 'US' },
  { name: 'Summit', state: 'Washington', country: 'US' },
  { name: 'Summit', state: 'Illinois', country: 'US' },
  { name: 'Summit Park', state: 'Utah', country: 'US' },
  { name: 'Sumner', state: 'Washington', country: 'US' },
  { name: 'Sumter', state: 'South Carolina', country: 'US' },
  { name: 'Sun City', state: 'California', country: 'US' },
  { name: 'Sun City', state: 'Arizona', country: 'US' },
  { name: 'Sun City West', state: 'Arizona', country: 'US' },
  { name: 'Sun Lakes', state: 'Arizona', country: 'US' },
  { name: 'Sun Prairie', state: 'Wisconsin', country: 'US' },
  { name: 'Sun Valley', state: 'Nevada', country: 'US' },
  { name: 'Sunbury', state: 'Pennsylvania', country: 'US' },
  { name: 'Sunland Park', state: 'New Mexico', country: 'US' },
  { name: 'Sunny Isles Beach', state: 'Florida', country: 'US' },
  { name: 'Sunnyside', state: 'Oregon', country: 'US' },
  { name: 'Sunnyside', state: 'Washington', country: 'US' },
  { name: 'Sunnyvale', state: 'California', country: 'US' },
  { name: 'Sunrise', state: 'Florida', country: 'US' },
  { name: 'Sunrise Manor', state: 'Nevada', country: 'US' },
  { name: 'Sunset', state: 'Florida', country: 'US' },
  { name: 'Sunset Hills', state: 'Missouri', country: 'US' },
  { name: 'Superior', state: 'Colorado', country: 'US' },
  { name: 'Superior', state: 'Wisconsin', country: 'US' },
  { name: 'Surprise', state: 'Arizona', country: 'US' },
  { name: 'Susanville', state: 'California', country: 'US' },
  { name: 'Sussex', state: 'Wisconsin', country: 'US' },
  { name: 'Sutherlin', state: 'Oregon', country: 'US' },
  { name: 'Sutton', state: 'Massachusetts', country: 'US' },
  { name: 'Suwanee', state: 'Georgia', country: 'US' },
  { name: 'Swainsboro', state: 'Georgia', country: 'US' },
  { name: 'Swampscott', state: 'Massachusetts', country: 'US' },
  { name: 'Swampscott', state: 'Massachusetts', country: 'US' },
  { name: 'Swansea', state: 'Illinois', country: 'US' },
  { name: 'Swansea', state: 'Massachusetts', country: 'US' },
  { name: 'Swanton', state: 'Vermont', country: 'US' },
  { name: 'Swanzey', state: 'New Hampshire', country: 'US' },
  { name: 'Swarthmore', state: 'Pennsylvania', country: 'US' },
  { name: 'Sweden', state: 'New York', country: 'US' },
  { name: 'Sweet Home', state: 'Oregon', country: 'US' },
  { name: 'Sweetwater', state: 'Texas', country: 'US' },
  { name: 'Sweetwater', state: 'Florida', country: 'US' },
  { name: 'Swissvale', state: 'Pennsylvania', country: 'US' },
  { name: 'Sycamore', state: 'Illinois', country: 'US' },
  { name: 'Sylacauga', state: 'Alabama', country: 'US' },
  { name: 'Sylvania', state: 'Ohio', country: 'US' },
  { name: 'Syosset', state: 'New York', country: 'US' },
  { name: 'Syracuse', state: 'New York', country: 'US' },
  { name: 'Syracuse', state: 'Utah', country: 'US' },
  { name: 'Tacoma', state: 'Washington', country: 'US' },
  { name: 'Taft', state: 'California', country: 'US' },
  { name: 'Tahlequah', state: 'Oklahoma', country: 'US' },
  { name: 'Takoma Park', state: 'Maryland', country: 'US' },
  { name: 'Talladega', state: 'Alabama', country: 'US' },
  { name: 'Tallahassee', state: 'Florida', country: 'US' },
  { name: 'Tallmadge', state: 'Ohio', country: 'US' },
  { name: 'Tallulah', state: 'Louisiana', country: 'US' },
  { name: 'Tamalpais-Homestead Valley', state: 'California', country: 'US' },
  { name: 'Tamaqua', state: 'Pennsylvania', country: 'US' },
  { name: 'Tamarac', state: 'Florida', country: 'US' },
  { name: 'Tamiami', state: 'Florida', country: 'US' },
  { name: 'Tampa', state: 'Florida', country: 'US' },
  { name: 'Tanque Verde', state: 'Arizona', country: 'US' },
  { name: 'Tappan', state: 'New York', country: 'US' },
  { name: 'Tarboro', state: 'North Carolina', country: 'US' },
  { name: 'Tarpon Springs', state: 'Florida', country: 'US' },
  { name: 'Tarrant', state: 'Alabama', country: 'US' },
  { name: 'Tarrytown', state: 'New York', country: 'US' },
  { name: 'Taunton', state: 'Massachusetts', country: 'US' },
  { name: 'Tavares', state: 'Florida', country: 'US' },
  { name: 'Taylor', state: 'Michigan', country: 'US' },
  { name: 'Taylor', state: 'Pennsylvania', country: 'US' },
  { name: 'Taylor', state: 'Texas', country: 'US' },
  { name: 'Taylor Mill', state: 'Kentucky', country: 'US' },
  { name: 'Taylors', state: 'South Carolina', country: 'US' },
  { name: 'Taylorsville', state: 'Utah', country: 'US' },
  { name: 'Taylorville', state: 'Illinois', country: 'US' },
  { name: 'Teaneck', state: 'New Jersey', country: 'US' },
  { name: 'Teays Valley', state: 'West Virginia', country: 'US' },
  { name: 'Tecumseh', state: 'Michigan', country: 'US' },
  { name: 'Tecumseh', state: 'Oklahoma', country: 'US' },
  { name: 'Tehachapi', state: 'California', country: 'US' },
  { name: 'Tell City', state: 'Indiana', country: 'US' },
  { name: 'Temecula', state: 'California', country: 'US' },
  { name: 'Tempe', state: 'Arizona', country: 'US' },
  { name: 'Temperance', state: 'Michigan', country: 'US' },
  { name: 'Temple', state: 'Texas', country: 'US' },
  { name: 'Temple City', state: 'California', country: 'US' },
  { name: 'Temple Hills', state: 'Maryland', country: 'US' },
  { name: 'Temple Terrace', state: 'Florida', country: 'US' },
  { name: 'Templeton', state: 'Massachusetts', country: 'US' },
  { name: 'Tenafly', state: 'New Jersey', country: 'US' },
  { name: 'Terrace Heights', state: 'Washington', country: 'US' },
  { name: 'Terre Haute', state: 'Indiana', country: 'US' },
  { name: 'Terrell', state: 'Texas', country: 'US' },
  { name: 'Terrytown', state: 'Louisiana', country: 'US' },
  { name: 'Terryville', state: 'New York', country: 'US' },
  { name: 'Tewksbury', state: 'Massachusetts', country: 'US' },
  { name: 'Texarkana', state: 'Texas', country: 'US' },
  { name: 'Texarkana', state: 'Arkansas', country: 'US' },
  { name: 'Texas City', state: 'Texas', country: 'US' },
  { name: 'The Colony', state: 'Texas', country: 'US' },
  { name: 'The Crossings', state: 'Florida', country: 'US' },
  { name: 'The Hammocks', state: 'Florida', country: 'US' },
  { name: 'The Pinery', state: 'Colorado', country: 'US' },
  { name: 'The Village', state: 'Oklahoma', country: 'US' },
  { name: 'The Villages', state: 'Florida', country: 'US' },
  { name: 'The Woodlands', state: 'Texas', country: 'US' },
  { name: 'Theodore', state: 'Alabama', country: 'US' },
  { name: 'Thermalito', state: 'California', country: 'US' },
  { name: 'Thibodaux', state: 'Louisiana', country: 'US' },
  { name: 'Thief River Falls', state: 'Minnesota', country: 'US' },
  { name: 'Thomaston', state: 'Georgia', country: 'US' },
  { name: 'Thomaston', state: 'Connecticut', country: 'US' },
  { name: 'Thomasville', state: 'Georgia', country: 'US' },
  { name: 'Thomasville', state: 'North Carolina', country: 'US' },
  { name: 'Thompson', state: 'New York', country: 'US' },
  { name: 'Thompson', state: 'Connecticut', country: 'US' },
  { name: 'Thompsonville', state: 'Connecticut', country: 'US' },
  { name: 'Thomson', state: 'Georgia', country: 'US' },
  { name: 'Thonotosassa', state: 'Florida', country: 'US' },
  { name: 'Thornton', state: 'Colorado', country: 'US' },
  { name: 'Thousand Oaks', state: 'California', country: 'US' },
  { name: 'Three Lakes', state: 'Florida', country: 'US' },
  { name: 'Three Rivers', state: 'Michigan', country: 'US' },
  { name: 'Tiburon', state: 'California', country: 'US' },
  { name: 'Tiffin', state: 'Ohio', country: 'US' },
  { name: 'Tifton', state: 'Georgia', country: 'US' },
  { name: 'Tigard', state: 'Oregon', country: 'US' },
  { name: 'Tillmans Corner', state: 'Alabama', country: 'US' },
  { name: 'Timberlake', state: 'Virginia', country: 'US' },
  { name: 'Timberlane', state: 'Louisiana', country: 'US' },
  { name: 'Tinley Park', state: 'Illinois', country: 'US' },
  { name: 'Tinton Falls', state: 'New Jersey', country: 'US' },
  { name: 'Tipp City', state: 'Ohio', country: 'US' },
  { name: 'Titusville', state: 'Pennsylvania', country: 'US' },
  { name: 'Titusville', state: 'Florida', country: 'US' },
  { name: 'Tiverton', state: 'Rhode Island', country: 'US' },
  { name: 'Tiverton', state: 'Rhode Island', country: 'US' },
  { name: 'Toccoa', state: 'Georgia', country: 'US' },
  { name: 'Toledo', state: 'Ohio', country: 'US' },
  { name: 'Tolland', state: 'Connecticut', country: 'US' },
  { name: 'Tomah', state: 'Wisconsin', country: 'US' },
  { name: 'Tomball', state: 'Texas', country: 'US' },
  { name: 'Toms River', state: 'New Jersey', country: 'US' },
  { name: 'Tonawanda', state: 'New York', country: 'US' },
  { name: 'Tonawanda', state: 'New York', country: 'US' },
  { name: 'Tonawanda', state: 'New York', country: 'US' },
  { name: 'Tooele', state: 'Utah', country: 'US' },
  { name: 'Topeka', state: 'Kansas', country: 'US' },
  { name: 'Toppenish', state: 'Washington', country: 'US' },
  { name: 'Topsfield', state: 'Massachusetts', country: 'US' },
  { name: 'Topsham', state: 'Maine', country: 'US' },
  { name: 'Topsham', state: 'Maine', country: 'US' },
  { name: 'Torrance', state: 'California', country: 'US' },
  { name: 'Torrington', state: 'Connecticut', country: 'US' },
  { name: 'Torrington', state: 'Connecticut', country: 'US' },
  { name: 'Totowa', state: 'New Jersey', country: 'US' },
  { name: "Town 'n' Country", state: 'Florida', country: 'US' },
  { name: 'Town and Country', state: 'Missouri', country: 'US' },
  { name: 'Townsend', state: 'Massachusetts', country: 'US' },
  { name: 'Towson', state: 'Maryland', country: 'US' },
  { name: 'Tracy', state: 'California', country: 'US' },
  { name: 'Traverse City', state: 'Michigan', country: 'US' },
  { name: 'Travilah', state: 'Maryland', country: 'US' },
  { name: 'Treasure Island', state: 'Florida', country: 'US' },
  { name: 'Trenton', state: 'Michigan', country: 'US' },
  { name: 'Trenton', state: 'Missouri', country: 'US' },
  { name: 'Trenton', state: 'New Jersey', country: 'US' },
  { name: 'Trenton', state: 'Ohio', country: 'US' },
  { name: 'Trinidad', state: 'Colorado', country: 'US' },
  { name: 'Trinity', state: 'North Carolina', country: 'US' },
  { name: 'Trooper', state: 'Pennsylvania', country: 'US' },
  { name: 'Trophy Club', state: 'Texas', country: 'US' },
  { name: 'Trotwood', state: 'Ohio', country: 'US' },
  { name: 'Troutdale', state: 'Oregon', country: 'US' },
  { name: 'Troy', state: 'Ohio', country: 'US' },
  { name: 'Troy', state: 'New York', country: 'US' },
  { name: 'Troy', state: 'Missouri', country: 'US' },
  { name: 'Troy', state: 'Michigan', country: 'US' },
  { name: 'Troy', state: 'Alabama', country: 'US' },
  { name: 'Troy', state: 'Illinois', country: 'US' },
  { name: 'Truckee', state: 'California', country: 'US' },
  { name: 'Trumann', state: 'Arkansas', country: 'US' },
  { name: 'Trumbull', state: 'Connecticut', country: 'US' },
  { name: 'Trumbull', state: 'Connecticut', country: 'US' },
  { name: 'Trussville', state: 'Alabama', country: 'US' },
  { name: 'Truth or Consequences', state: 'New Mexico', country: 'US' },
  { name: 'Tualatin', state: 'Oregon', country: 'US' },
  { name: 'Tuba City', state: 'Arizona', country: 'US' },
  { name: 'Tuckahoe', state: 'New York', country: 'US' },
  { name: 'Tuckahoe', state: 'Virginia', country: 'US' },
  { name: 'Tucker', state: 'Georgia', country: 'US' },
  { name: 'Tucson', state: 'Arizona', country: 'US' },
  { name: 'Tucson Estates', state: 'Arizona', country: 'US' },
  { name: 'Tukwila', state: 'Washington', country: 'US' },
  { name: 'Tulare', state: 'California', country: 'US' },
  { name: 'Tullahoma', state: 'Tennessee', country: 'US' },
  { name: 'Tulsa', state: 'Oklahoma', country: 'US' },
  { name: 'Tumwater', state: 'Washington', country: 'US' },
  { name: 'Tupelo', state: 'Mississippi', country: 'US' },
  { name: 'Turlock', state: 'California', country: 'US' },
  { name: 'Turtle Creek', state: 'Pennsylvania', country: 'US' },
  { name: 'Tuscaloosa', state: 'Alabama', country: 'US' },
  { name: 'Tuscumbia', state: 'Alabama', country: 'US' },
  { name: 'Tuskegee', state: 'Alabama', country: 'US' },
  { name: 'Tustin', state: 'California', country: 'US' },
  { name: 'Tustin Foothills', state: 'California', country: 'US' },
  { name: 'Twentynine Palms', state: 'California', country: 'US' },
  { name: 'Twentynine Palms Base', state: 'California', country: 'US' },
  { name: 'Twin Falls', state: 'Idaho', country: 'US' },
  { name: 'Twin Lakes', state: 'Colorado', country: 'US' },
  { name: 'Twin Rivers', state: 'New Jersey', country: 'US' },
  { name: 'Twinsburg', state: 'Ohio', country: 'US' },
  { name: 'Two Rivers', state: 'Wisconsin', country: 'US' },
  { name: 'Tyler', state: 'Texas', country: 'US' },
  { name: 'Tyngsborough', state: 'Massachusetts', country: 'US' },
  { name: 'Tysons Corner', state: 'Virginia', country: 'US' },
  { name: 'Ukiah', state: 'California', country: 'US' },
  { name: 'Ulster', state: 'New York', country: 'US' },
  { name: 'Union', state: 'South Carolina', country: 'US' },
  { name: 'Union', state: 'New York', country: 'US' },
  { name: 'Union', state: 'Missouri', country: 'US' },
  { name: 'Union', state: 'New Jersey', country: 'US' },
  { name: 'Union Beach', state: 'New Jersey', country: 'US' },
  { name: 'Union City', state: 'New Jersey', country: 'US' },
  { name: 'Union City', state: 'California', country: 'US' },
  { name: 'Union City', state: 'Georgia', country: 'US' },
  { name: 'Union City', state: 'Tennessee', country: 'US' },
  { name: 'Union Hill-Novelty Hill', state: 'Washington', country: 'US' },
  { name: 'Union Park', state: 'Florida', country: 'US' },
  { name: 'Uniondale', state: 'New York', country: 'US' },
  { name: 'Uniontown', state: 'Pennsylvania', country: 'US' },
  { name: 'Universal City', state: 'Texas', country: 'US' },
  { name: 'University', state: 'Florida', country: 'US' },
  { name: 'University City', state: 'Missouri', country: 'US' },
  { name: 'University Heights', state: 'Ohio', country: 'US' },
  { name: 'University Park', state: 'Texas', country: 'US' },
  { name: 'University Park', state: 'Florida', country: 'US' },
  { name: 'University Park', state: 'Illinois', country: 'US' },
  { name: 'University Place', state: 'Washington', country: 'US' },
  { name: 'Upland', state: 'California', country: 'US' },
  { name: 'Upper Arlington', state: 'Ohio', country: 'US' },
  { name: 'Upper Grand Lagoon', state: 'Florida', country: 'US' },
  { name: 'Upper Providence Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Upper Saddle River', state: 'New Jersey', country: 'US' },
  { name: 'Upper Sandusky', state: 'Ohio', country: 'US' },
  { name: 'Upper St. Clair', state: 'Pennsylvania', country: 'US' },
  { name: 'Urbana', state: 'Ohio', country: 'US' },
  { name: 'Urbana', state: 'Illinois', country: 'US' },
  { name: 'Urbandale', state: 'Iowa', country: 'US' },
  { name: 'Utica', state: 'New York', country: 'US' },
  { name: 'Uvalde', state: 'Texas', country: 'US' },
  { name: 'Uxbridge', state: 'Massachusetts', country: 'US' },
  { name: 'Vacaville', state: 'California', country: 'US' },
  { name: 'Vadnais Heights', state: 'Minnesota', country: 'US' },
  { name: 'Valdosta', state: 'Georgia', country: 'US' },
  { name: 'Valinda', state: 'California', country: 'US' },
  { name: 'Valle Vista', state: 'California', country: 'US' },
  { name: 'Vallejo', state: 'California', country: 'US' },
  { name: 'Valley', state: 'Alabama', country: 'US' },
  { name: 'Valley Center', state: 'California', country: 'US' },
  { name: 'Valley City', state: 'North Dakota', country: 'US' },
  { name: 'Valley Cottage', state: 'New York', country: 'US' },
  { name: 'Valley Falls', state: 'Rhode Island', country: 'US' },
  { name: 'Valley Park', state: 'Missouri', country: 'US' },
  { name: 'Valley Station', state: 'Kentucky', country: 'US' },
  { name: 'Valley Stream', state: 'New York', country: 'US' },
  { name: 'Valparaiso', state: 'Indiana', country: 'US' },
  { name: 'Valparaiso', state: 'Florida', country: 'US' },
  { name: 'Valrico', state: 'Florida', country: 'US' },
  { name: 'Van Buren', state: 'Arkansas', country: 'US' },
  { name: 'Van Buren', state: 'New York', country: 'US' },
  { name: 'Van Wert', state: 'Ohio', country: 'US' },
  { name: 'Vancouver', state: 'Washington', country: 'US' },
  { name: 'Vandalia', state: 'Ohio', country: 'US' },
  { name: 'Vandalia', state: 'Illinois', country: 'US' },
  { name: 'Vandenberg AFB', state: 'California', country: 'US' },
  { name: 'Vashon', state: 'Washington', country: 'US' },
  { name: 'Venice', state: 'Florida', country: 'US' },
  { name: 'Venice Gardens', state: 'Florida', country: 'US' },
  { name: 'Ventnor City', state: 'New Jersey', country: 'US' },
  { name: 'Veradale', state: 'Washington', country: 'US' },
  { name: 'Vermilion', state: 'Ohio', country: 'US' },
  { name: 'Vermillion', state: 'South Dakota', country: 'US' },
  { name: 'Vernal', state: 'Utah', country: 'US' },
  { name: 'Vernon', state: 'Wisconsin', country: 'US' },
  { name: 'Vernon', state: 'Texas', country: 'US' },
  { name: 'Vernon', state: 'Connecticut', country: 'US' },
  { name: 'Vernon Hills', state: 'Illinois', country: 'US' },
  { name: 'Vero Beach', state: 'Florida', country: 'US' },
  { name: 'Vero Beach South', state: 'Florida', country: 'US' },
  { name: 'Verona', state: 'New Jersey', country: 'US' },
  { name: 'Verona', state: 'New York', country: 'US' },
  { name: 'Verona', state: 'Wisconsin', country: 'US' },
  { name: 'Versailles', state: 'Kentucky', country: 'US' },
  { name: 'Vestal', state: 'New York', country: 'US' },
  { name: 'Vestavia Hills', state: 'Alabama', country: 'US' },
  { name: 'Vicksburg', state: 'Mississippi', country: 'US' },
  { name: 'Victor', state: 'New York', country: 'US' },
  { name: 'Victoria', state: 'Texas', country: 'US' },
  { name: 'Victorville', state: 'California', country: 'US' },
  { name: 'Vidalia', state: 'Georgia', country: 'US' },
  { name: 'Vidor', state: 'Texas', country: 'US' },
  { name: 'Vienna', state: 'Virginia', country: 'US' },
  { name: 'Vienna', state: 'West Virginia', country: 'US' },
  { name: 'View Park-Windsor Hills', state: 'California', country: 'US' },
  { name: 'Villa Hills', state: 'Kentucky', country: 'US' },
  { name: 'Villa Park', state: 'Illinois', country: 'US' },
  { name: 'Village Green-Green Ridge', state: 'Pennsylvania', country: 'US' },
  { name: 'Village Park', state: 'Hawaii', country: 'US' },
  { name: 'Village St. George', state: 'Louisiana', country: 'US' },
  { name: 'Villas', state: 'Florida', country: 'US' },
  { name: 'Villas', state: 'New Jersey', country: 'US' },
  { name: 'Ville Platte', state: 'Louisiana', country: 'US' },
  { name: 'Vincennes', state: 'Indiana', country: 'US' },
  { name: 'Vincent', state: 'California', country: 'US' },
  { name: 'Vineland', state: 'New Jersey', country: 'US' },
  { name: 'Vineyard', state: 'California', country: 'US' },
  { name: 'Vinings', state: 'Georgia', country: 'US' },
  { name: 'Vinita', state: 'Oklahoma', country: 'US' },
  { name: 'Vinton', state: 'Virginia', country: 'US' },
  { name: 'Violet', state: 'Louisiana', country: 'US' },
  { name: 'Virginia', state: 'Minnesota', country: 'US' },
  { name: 'Virginia Beach', state: 'Virginia', country: 'US' },
  { name: 'Visalia', state: 'California', country: 'US' },
  { name: 'Vista', state: 'California', country: 'US' },
  { name: 'Volney', state: 'New York', country: 'US' },
  { name: 'Wabash', state: 'Indiana', country: 'US' },
  { name: 'Waco', state: 'Texas', country: 'US' },
  { name: 'Waconia', state: 'Minnesota', country: 'US' },
  { name: 'Wade Hampton', state: 'South Carolina', country: 'US' },
  { name: 'Wading River', state: 'New York', country: 'US' },
  { name: 'Wadsworth', state: 'Ohio', country: 'US' },
  { name: 'Waggaman', state: 'Louisiana', country: 'US' },
  { name: 'Wagoner', state: 'Oklahoma', country: 'US' },
  { name: 'Wahiawa', state: 'Hawaii', country: 'US' },
  { name: 'Wahpeton', state: 'North Dakota', country: 'US' },
  { name: 'Waianae', state: 'Hawaii', country: 'US' },
  { name: 'Waihee-Waiehu', state: 'Hawaii', country: 'US' },
  { name: 'Wailuku', state: 'Hawaii', country: 'US' },
  { name: 'Waimalu', state: 'Hawaii', country: 'US' },
  { name: 'Waimea', state: 'Hawaii', country: 'US' },
  { name: 'Waipahu', state: 'Hawaii', country: 'US' },
  { name: 'Waipio', state: 'Hawaii', country: 'US' },
  { name: 'Waite Park', state: 'Minnesota', country: 'US' },
  { name: 'Wake Forest', state: 'North Carolina', country: 'US' },
  { name: 'Wakefield', state: 'Massachusetts', country: 'US' },
  { name: 'Wakefield', state: 'Massachusetts', country: 'US' },
  { name: 'Wakefield-Peacedale', state: 'Rhode Island', country: 'US' },
  { name: 'Walden', state: 'New York', country: 'US' },
  { name: 'Waldorf', state: 'Maryland', country: 'US' },
  { name: 'Waldwick', state: 'New Jersey', country: 'US' },
  { name: 'Walker', state: 'Michigan', country: 'US' },
  { name: 'Walker Mill', state: 'Maryland', country: 'US' },
  { name: 'Walla Walla', state: 'Washington', country: 'US' },
  { name: 'Walled Lake', state: 'Michigan', country: 'US' },
  { name: 'Waller', state: 'Washington', country: 'US' },
  { name: 'Wallingford', state: 'Connecticut', country: 'US' },
  { name: 'Wallingford Center', state: 'Connecticut', country: 'US' },
  { name: 'Wallington', state: 'New Jersey', country: 'US' },
  { name: 'Wallkill', state: 'New York', country: 'US' },
  { name: 'Walnut', state: 'California', country: 'US' },
  { name: 'Walnut Creek', state: 'California', country: 'US' },
  { name: 'Walnut Grove', state: 'Washington', country: 'US' },
  { name: 'Walnut Park', state: 'California', country: 'US' },
  { name: 'Walpole', state: 'Massachusetts', country: 'US' },
  { name: 'Waltham', state: 'Massachusetts', country: 'US' },
  { name: 'Walworth', state: 'New York', country: 'US' },
  { name: 'Wanaque', state: 'New Jersey', country: 'US' },
  { name: 'Wantagh', state: 'New York', country: 'US' },
  { name: 'Wapakoneta', state: 'Ohio', country: 'US' },
  { name: 'Wappinger', state: 'New York', country: 'US' },
  { name: 'Ware', state: 'Massachusetts', country: 'US' },
  { name: 'Ware', state: 'Massachusetts', country: 'US' },
  { name: 'Wareham', state: 'Massachusetts', country: 'US' },
  { name: 'Warner Robins', state: 'Georgia', country: 'US' },
  { name: 'Warr Acres', state: 'Oklahoma', country: 'US' },
  { name: 'Warren', state: 'Pennsylvania', country: 'US' },
  { name: 'Warren', state: 'Rhode Island', country: 'US' },
  { name: 'Warren', state: 'Arkansas', country: 'US' },
  { name: 'Warren', state: 'Michigan', country: 'US' },
  { name: 'Warren', state: 'Ohio', country: 'US' },
  { name: 'Warrensburg', state: 'Missouri', country: 'US' },
  { name: 'Warrensville Heights', state: 'Ohio', country: 'US' },
  { name: 'Warrenton', state: 'Virginia', country: 'US' },
  { name: 'Warrenville', state: 'Illinois', country: 'US' },
  { name: 'Warrington', state: 'Florida', country: 'US' },
  { name: 'Warsaw', state: 'Indiana', country: 'US' },
  { name: 'Warwick', state: 'New York', country: 'US' },
  { name: 'Warwick', state: 'New York', country: 'US' },
  { name: 'Warwick', state: 'Rhode Island', country: 'US' },
  { name: 'Wasco', state: 'California', country: 'US' },
  { name: 'Waseca', state: 'Minnesota', country: 'US' },
  { name: 'Washington', state: 'Missouri', country: 'US' },
  { name: 'Washington', state: 'New Jersey', country: 'US' },
  { name: 'Washington', state: 'Ohio', country: 'US' },
  { name: 'Washington', state: 'North Carolina', country: 'US' },
  { name: 'Washington', state: 'Indiana', country: 'US' },
  { name: 'Washington', state: 'Iowa', country: 'US' },
  { name: 'Washington', state: 'Illinois', country: 'US' },
  { name: 'Washington', state: 'Pennsylvania', country: 'US' },
  { name: 'Washington', state: 'Utah', country: 'US' },
  { name: 'Washington', state: 'Wisconsin', country: 'US' },
  { name: 'Washington', state: 'Washington', country: 'US' },
  { name: 'Washington Terrace', state: 'Utah', country: 'US' },
  { name: 'Washington Township', state: 'New Jersey', country: 'US' },
  { name: 'Washougal', state: 'Washington', country: 'US' },
  { name: 'Watauga', state: 'Texas', country: 'US' },
  { name: 'Waterboro', state: 'Maine', country: 'US' },
  { name: 'Waterbury', state: 'Connecticut', country: 'US' },
  { name: 'Waterbury', state: 'Connecticut', country: 'US' },
  { name: 'Waterford', state: 'Connecticut', country: 'US' },
  { name: 'Waterford', state: 'California', country: 'US' },
  { name: 'Waterford', state: 'Michigan', country: 'US' },
  { name: 'Waterford', state: 'New York', country: 'US' },
  { name: 'Waterloo', state: 'New York', country: 'US' },
  { name: 'Waterloo', state: 'Illinois', country: 'US' },
  { name: 'Waterloo', state: 'Iowa', country: 'US' },
  { name: 'Watertown', state: 'Connecticut', country: 'US' },
  { name: 'Watertown', state: 'New York', country: 'US' },
  { name: 'Watertown', state: 'Massachusetts', country: 'US' },
  { name: 'Watertown', state: 'South Dakota', country: 'US' },
  { name: 'Watertown', state: 'Wisconsin', country: 'US' },
  { name: 'Waterville', state: 'Maine', country: 'US' },
  { name: 'Watervliet', state: 'New York', country: 'US' },
  { name: 'Watsonville', state: 'California', country: 'US' },
  { name: 'Wauconda', state: 'Illinois', country: 'US' },
  { name: 'Waukegan', state: 'Illinois', country: 'US' },
  { name: 'Waukesha', state: 'Wisconsin', country: 'US' },
  { name: 'Waukesha', state: 'Wisconsin', country: 'US' },
  { name: 'Waunakee', state: 'Wisconsin', country: 'US' },
  { name: 'Waupun', state: 'Wisconsin', country: 'US' },
  { name: 'Wausau', state: 'Wisconsin', country: 'US' },
  { name: 'Wauseon', state: 'Ohio', country: 'US' },
  { name: 'Wauwatosa', state: 'Wisconsin', country: 'US' },
  { name: 'Waveland', state: 'Mississippi', country: 'US' },
  { name: 'Waverly', state: 'Michigan', country: 'US' },
  { name: 'Waverly', state: 'Iowa', country: 'US' },
  { name: 'Wawarsing', state: 'New York', country: 'US' },
  { name: 'Wawayanda', state: 'New York', country: 'US' },
  { name: 'Waxahachie', state: 'Texas', country: 'US' },
  { name: 'Waycross', state: 'Georgia', country: 'US' },
  { name: 'Wayland', state: 'Massachusetts', country: 'US' },
  { name: 'Wayne', state: 'Michigan', country: 'US' },
  { name: 'Wayne', state: 'New Jersey', country: 'US' },
  { name: 'Waynesboro', state: 'Virginia', country: 'US' },
  { name: 'Waynesboro', state: 'Pennsylvania', country: 'US' },
  { name: 'Waynesville', state: 'North Carolina', country: 'US' },
  { name: 'Weare', state: 'New Hampshire', country: 'US' },
  { name: 'Weatherford', state: 'Oklahoma', country: 'US' },
  { name: 'Weatherford', state: 'Texas', country: 'US' },
  { name: 'Webb City', state: 'Missouri', country: 'US' },
  { name: 'Webster', state: 'Massachusetts', country: 'US' },
  { name: 'Webster', state: 'Massachusetts', country: 'US' },
  { name: 'Webster', state: 'New York', country: 'US' },
  { name: 'Webster', state: 'Texas', country: 'US' },
  { name: 'Webster City', state: 'Iowa', country: 'US' },
  { name: 'Webster Groves', state: 'Missouri', country: 'US' },
  { name: 'Weddington', state: 'North Carolina', country: 'US' },
  { name: 'Weigelstown', state: 'Pennsylvania', country: 'US' },
  { name: 'Weirton', state: 'West Virginia', country: 'US' },
  { name: 'Wekiwa Springs', state: 'Florida', country: 'US' },
  { name: 'Welby', state: 'Colorado', country: 'US' },
  { name: 'Welcome', state: 'South Carolina', country: 'US' },
  { name: 'Wellesley', state: 'Massachusetts', country: 'US' },
  { name: 'Wellesley', state: 'Massachusetts', country: 'US' },
  { name: 'Wellington', state: 'Florida', country: 'US' },
  { name: 'Wellington', state: 'Kansas', country: 'US' },
  { name: 'Wells', state: 'Maine', country: 'US' },
  { name: 'Wells Branch', state: 'Texas', country: 'US' },
  { name: 'Wellston', state: 'Ohio', country: 'US' },
  { name: 'Wellsville', state: 'New York', country: 'US' },
  { name: 'Wenatchee', state: 'Washington', country: 'US' },
  { name: 'Wentzville', state: 'Missouri', country: 'US' },
  { name: 'Weslaco', state: 'Texas', country: 'US' },
  { name: 'West Allis', state: 'Wisconsin', country: 'US' },
  { name: 'West and East Lealman', state: 'Florida', country: 'US' },
  { name: 'West Athens', state: 'California', country: 'US' },
  { name: 'West Babylon', state: 'New York', country: 'US' },
  { name: 'West Bend', state: 'Wisconsin', country: 'US' },
  { name: 'West Bloomfield Township', state: 'Michigan', country: 'US' },
  { name: 'West Boylston', state: 'Massachusetts', country: 'US' },
  { name: 'West Bridgewater', state: 'Massachusetts', country: 'US' },
  { name: 'West Caldwell', state: 'New Jersey', country: 'US' },
  { name: 'West Carrollton City', state: 'Ohio', country: 'US' },
  { name: 'West Carson', state: 'California', country: 'US' },
  { name: 'West Chester', state: 'Pennsylvania', country: 'US' },
  { name: 'West Chicago', state: 'Illinois', country: 'US' },
  { name: 'West Columbia', state: 'South Carolina', country: 'US' },
  { name: 'West Covina', state: 'California', country: 'US' },
  { name: 'West Des Moines', state: 'Iowa', country: 'US' },
  { name: 'West Fargo', state: 'North Dakota', country: 'US' },
  { name: 'West Frankfort', state: 'Illinois', country: 'US' },
  { name: 'West Freehold', state: 'New Jersey', country: 'US' },
  { name: 'West Gate', state: 'Virginia', country: 'US' },
  { name: 'West Glens Falls', state: 'New York', country: 'US' },
  { name: 'West Goshen', state: 'Pennsylvania', country: 'US' },
  { name: 'West Hartford', state: 'Connecticut', country: 'US' },
  { name: 'West Hartford', state: 'Connecticut', country: 'US' },
  { name: 'West Hattiesburg', state: 'Mississippi', country: 'US' },
  { name: 'West Haven', state: 'Connecticut', country: 'US' },
  { name: 'West Haven', state: 'Connecticut', country: 'US' },
  { name: 'West Haven-Sylvan', state: 'Oregon', country: 'US' },
  { name: 'West Haverstraw', state: 'New York', country: 'US' },
  { name: 'West Helena', state: 'Arkansas', country: 'US' },
  { name: 'West Hempstead', state: 'New York', country: 'US' },
  { name: 'West Hollywood', state: 'California', country: 'US' },
  { name: 'West Islip', state: 'New York', country: 'US' },
  { name: 'West Jordan', state: 'Utah', country: 'US' },
  { name: 'West Lafayette', state: 'Indiana', country: 'US' },
  { name: 'West Lake Stevens', state: 'Washington', country: 'US' },
  { name: 'West Linn', state: 'Oregon', country: 'US' },
  { name: 'West Little River', state: 'Florida', country: 'US' },
  { name: 'West Livingston', state: 'Texas', country: 'US' },
  { name: 'West Long Branch', state: 'New Jersey', country: 'US' },
  { name: 'West Melbourne', state: 'Florida', country: 'US' },
  { name: 'West Memphis', state: 'Arkansas', country: 'US' },
  { name: 'West Mifflin', state: 'Pennsylvania', country: 'US' },
  { name: 'West Milford', state: 'New Jersey', country: 'US' },
  { name: 'West Modesto', state: 'California', country: 'US' },
  { name: 'West Monroe', state: 'Louisiana', country: 'US' },
  { name: 'West New York', state: 'New Jersey', country: 'US' },
  { name: 'West Norriton', state: 'Pennsylvania', country: 'US' },
  { name: 'West Odessa', state: 'Texas', country: 'US' },
  { name: 'West Orange', state: 'New Jersey', country: 'US' },
  { name: 'West Palm Beach', state: 'Florida', country: 'US' },
  { name: 'West Paterson', state: 'New Jersey', country: 'US' },
  { name: 'West Pensacola', state: 'Florida', country: 'US' },
  { name: 'West Perrine', state: 'Florida', country: 'US' },
  { name: 'West Plains', state: 'Missouri', country: 'US' },
  { name: 'West Point', state: 'New York', country: 'US' },
  { name: 'West Point', state: 'Mississippi', country: 'US' },
  { name: 'West Point', state: 'Utah', country: 'US' },
  { name: 'West Puente Valley', state: 'California', country: 'US' },
  { name: 'West Richland', state: 'Washington', country: 'US' },
  { name: 'West Sacramento', state: 'California', country: 'US' },
  { name: 'West Seneca', state: 'New York', country: 'US' },
  { name: 'West Seneca', state: 'New York', country: 'US' },
  { name: 'West Slope', state: 'Oregon', country: 'US' },
  { name: 'West Springfield', state: 'Virginia', country: 'US' },
  { name: 'West Springfield', state: 'Massachusetts', country: 'US' },
  { name: 'West Springfield', state: 'Massachusetts', country: 'US' },
  { name: 'West St. Paul', state: 'Minnesota', country: 'US' },
  { name: 'West University Place', state: 'Texas', country: 'US' },
  { name: 'West Valley', state: 'Washington', country: 'US' },
  { name: 'West Valley City', state: 'Utah', country: 'US' },
  { name: 'West Vero Corridor', state: 'Florida', country: 'US' },
  { name: 'West View', state: 'Pennsylvania', country: 'US' },
  { name: 'West Warwick', state: 'Rhode Island', country: 'US' },
  { name: 'West Warwick', state: 'Rhode Island', country: 'US' },
  { name: 'West Whittier-Los Nietos', state: 'California', country: 'US' },
  { name: 'West Yarmouth', state: 'Massachusetts', country: 'US' },
  { name: 'Westborough', state: 'Massachusetts', country: 'US' },
  { name: 'Westbrook', state: 'Connecticut', country: 'US' },
  { name: 'Westbrook', state: 'Maine', country: 'US' },
  { name: 'Westbury', state: 'New York', country: 'US' },
  { name: 'Westchase', state: 'Florida', country: 'US' },
  { name: 'Westchester', state: 'Florida', country: 'US' },
  { name: 'Westchester', state: 'Illinois', country: 'US' },
  { name: 'Westerly', state: 'Rhode Island', country: 'US' },
  { name: 'Westerly', state: 'Rhode Island', country: 'US' },
  { name: 'Western Springs', state: 'Illinois', country: 'US' },
  { name: 'Westerville', state: 'Ohio', country: 'US' },
  { name: 'Westfield', state: 'Massachusetts', country: 'US' },
  { name: 'Westfield', state: 'New Jersey', country: 'US' },
  { name: 'Westfield', state: 'Indiana', country: 'US' },
  { name: 'Westford', state: 'Massachusetts', country: 'US' },
  { name: 'Westgate-Belvedere Homes', state: 'Florida', country: 'US' },
  { name: 'Westlake', state: 'Ohio', country: 'US' },
  { name: 'Westlake Village', state: 'California', country: 'US' },
  { name: 'Westland', state: 'Michigan', country: 'US' },
  { name: 'Westmere', state: 'New York', country: 'US' },
  { name: 'Westminster', state: 'Massachusetts', country: 'US' },
  { name: 'Westminster', state: 'California', country: 'US' },
  { name: 'Westminster', state: 'Colorado', country: 'US' },
  { name: 'Westminster', state: 'Maryland', country: 'US' },
  { name: 'Westmont', state: 'Illinois', country: 'US' },
  { name: 'Westmont', state: 'California', country: 'US' },
  { name: 'Westmoreland', state: 'New York', country: 'US' },
  { name: 'Weston', state: 'Connecticut', country: 'US' },
  { name: 'Weston', state: 'Florida', country: 'US' },
  { name: 'Weston', state: 'Massachusetts', country: 'US' },
  { name: 'Weston', state: 'Wisconsin', country: 'US' },
  { name: 'Westport', state: 'Massachusetts', country: 'US' },
  { name: 'Westport', state: 'Connecticut', country: 'US' },
  { name: 'Westport', state: 'Connecticut', country: 'US' },
  { name: 'Westview', state: 'Florida', country: 'US' },
  { name: 'Westwego', state: 'Louisiana', country: 'US' },
  { name: 'Westwood', state: 'Massachusetts', country: 'US' },
  { name: 'Westwood', state: 'Michigan', country: 'US' },
  { name: 'Westwood', state: 'New Jersey', country: 'US' },
  { name: 'Westwood Lakes', state: 'Florida', country: 'US' },
  { name: 'Wethersfield', state: 'Connecticut', country: 'US' },
  { name: 'Wethersfield', state: 'Connecticut', country: 'US' },
  { name: 'Weymouth', state: 'Massachusetts', country: 'US' },
  { name: 'Weymouth', state: 'Massachusetts', country: 'US' },
  { name: 'Wharton', state: 'New Jersey', country: 'US' },
  { name: 'Wharton', state: 'Texas', country: 'US' },
  { name: 'Wheat Ridge', state: 'Colorado', country: 'US' },
  { name: 'Wheatfield', state: 'New York', country: 'US' },
  { name: 'Wheaton', state: 'Illinois', country: 'US' },
  { name: 'Wheaton-Glenmont', state: 'Maryland', country: 'US' },
  { name: 'Wheelersburg', state: 'Ohio', country: 'US' },
  { name: 'Wheeling', state: 'Illinois', country: 'US' },
  { name: 'Wheeling', state: 'West Virginia', country: 'US' },
  { name: 'White Bear Lake', state: 'Minnesota', country: 'US' },
  { name: 'White Center', state: 'Washington', country: 'US' },
  { name: 'White Horse', state: 'New Jersey', country: 'US' },
  { name: 'White House', state: 'Tennessee', country: 'US' },
  { name: 'White Marsh', state: 'Maryland', country: 'US' },
  { name: 'White Meadow Lake', state: 'New Jersey', country: 'US' },
  { name: 'White Oak', state: 'Ohio', country: 'US' },
  { name: 'White Oak', state: 'Maryland', country: 'US' },
  { name: 'White Oak', state: 'Pennsylvania', country: 'US' },
  { name: 'White Plains', state: 'New York', country: 'US' },
  { name: 'White Rock', state: 'New Mexico', country: 'US' },
  { name: 'White Settlement', state: 'Texas', country: 'US' },
  { name: 'Whitefish Bay', state: 'Wisconsin', country: 'US' },
  { name: 'Whitehall', state: 'Pennsylvania', country: 'US' },
  { name: 'Whitehall', state: 'Ohio', country: 'US' },
  { name: 'Whitestown', state: 'New York', country: 'US' },
  { name: 'Whitewater', state: 'Wisconsin', country: 'US' },
  { name: 'Whitinsville', state: 'Massachusetts', country: 'US' },
  { name: 'Whitman', state: 'Massachusetts', country: 'US' },
  { name: 'Whitmore Lake', state: 'Michigan', country: 'US' },
  { name: 'Whitney', state: 'Nevada', country: 'US' },
  { name: 'Whittier', state: 'California', country: 'US' },
  { name: 'Wichita', state: 'Kansas', country: 'US' },
  { name: 'Wichita Falls', state: 'Texas', country: 'US' },
  { name: 'Wickliffe', state: 'Ohio', country: 'US' },
  { name: 'Wilbraham', state: 'Massachusetts', country: 'US' },
  { name: 'Wildomar', state: 'California', country: 'US' },
  { name: 'Wildwood', state: 'Missouri', country: 'US' },
  { name: 'Wilkes-Barre', state: 'Pennsylvania', country: 'US' },
  { name: 'Wilkins Township', state: 'Pennsylvania', country: 'US' },
  { name: 'Wilkinsburg', state: 'Pennsylvania', country: 'US' },
  { name: 'Willard', state: 'Ohio', country: 'US' },
  { name: 'Williamsburg', state: 'Florida', country: 'US' },
  { name: 'Williamsburg', state: 'Virginia', country: 'US' },
  { name: 'Williamson', state: 'New York', country: 'US' },
  { name: 'Williamsport', state: 'Pennsylvania', country: 'US' },
  { name: 'Williamstown', state: 'New Jersey', country: 'US' },
  { name: 'Williamstown', state: 'Massachusetts', country: 'US' },
  { name: 'Willimantic', state: 'Connecticut', country: 'US' },
  { name: 'Williston', state: 'North Dakota', country: 'US' },
  { name: 'Williston', state: 'Vermont', country: 'US' },
  { name: 'Williston Park', state: 'New York', country: 'US' },
  { name: 'Willmar', state: 'Minnesota', country: 'US' },
  { name: 'Willoughby', state: 'Ohio', country: 'US' },
  { name: 'Willoughby Hills', state: 'Ohio', country: 'US' },
  { name: 'Willow Grove', state: 'Pennsylvania', country: 'US' },
  { name: 'Willow Street', state: 'Pennsylvania', country: 'US' },
  { name: 'Willowbrook', state: 'California', country: 'US' },
  { name: 'Willowbrook', state: 'Illinois', country: 'US' },
  { name: 'Willowick', state: 'Ohio', country: 'US' },
  { name: 'Willows', state: 'California', country: 'US' },
  { name: 'Wilmette', state: 'Illinois', country: 'US' },
  { name: 'Wilmington', state: 'Massachusetts', country: 'US' },
  { name: 'Wilmington', state: 'Delaware', country: 'US' },
  { name: 'Wilmington', state: 'Ohio', country: 'US' },
  { name: 'Wilmington', state: 'North Carolina', country: 'US' },
  { name: 'Wilmington', state: 'Massachusetts', country: 'US' },
  { name: 'Wilmington Island', state: 'Georgia', country: 'US' },
  { name: 'Wilmington Manor', state: 'Delaware', country: 'US' },
  { name: 'Wilna', state: 'New York', country: 'US' },
  { name: 'Wilson', state: 'North Carolina', country: 'US' },
  { name: 'Wilson', state: 'Pennsylvania', country: 'US' },
  { name: 'Wilsonville', state: 'Oregon', country: 'US' },
  { name: 'Wilton', state: 'New York', country: 'US' },
  { name: 'Wilton', state: 'Connecticut', country: 'US' },
  { name: 'Wilton Manors', state: 'Florida', country: 'US' },
  { name: 'Winchendon', state: 'Massachusetts', country: 'US' },
  { name: 'Winchester', state: 'Massachusetts', country: 'US' },
  { name: 'Winchester', state: 'Nevada', country: 'US' },
  { name: 'Winchester', state: 'Connecticut', country: 'US' },
  { name: 'Winchester', state: 'Massachusetts', country: 'US' },
  { name: 'Winchester', state: 'Kentucky', country: 'US' },
  { name: 'Winchester', state: 'Tennessee', country: 'US' },
  { name: 'Winchester', state: 'Virginia', country: 'US' },
  { name: 'Windemere', state: 'Texas', country: 'US' },
  { name: 'Winder', state: 'Georgia', country: 'US' },
  { name: 'Windham', state: 'Maine', country: 'US' },
  { name: 'Windham', state: 'Connecticut', country: 'US' },
  { name: 'Windham', state: 'New Hampshire', country: 'US' },
  { name: 'Windsor', state: 'New York', country: 'US' },
  { name: 'Windsor', state: 'Connecticut', country: 'US' },
  { name: 'Windsor', state: 'Colorado', country: 'US' },
  { name: 'Windsor', state: 'California', country: 'US' },
  { name: 'Windsor Locks', state: 'Connecticut', country: 'US' },
  { name: 'Windsor Locks', state: 'Connecticut', country: 'US' },
  { name: 'Winfield', state: 'Kansas', country: 'US' },
  { name: 'Winfield', state: 'Illinois', country: 'US' },
  { name: 'Winnemucca', state: 'Nevada', country: 'US' },
  { name: 'Winnetka', state: 'Illinois', country: 'US' },
  { name: 'Winona', state: 'Minnesota', country: 'US' },
  { name: 'Winooski', state: 'Vermont', country: 'US' },
  { name: 'Winslow', state: 'Maine', country: 'US' },
  { name: 'Winslow', state: 'Maine', country: 'US' },
  { name: 'Winslow', state: 'Arizona', country: 'US' },
  { name: 'Winsted', state: 'Connecticut', country: 'US' },
  { name: 'Winston', state: 'Florida', country: 'US' },
  { name: 'Winston-Salem', state: 'North Carolina', country: 'US' },
  { name: 'Winter Garden', state: 'Florida', country: 'US' },
  { name: 'Winter Gardens', state: 'California', country: 'US' },
  { name: 'Winter Haven', state: 'Florida', country: 'US' },
  { name: 'Winter Park', state: 'Florida', country: 'US' },
  { name: 'Winter Springs', state: 'Florida', country: 'US' },
  { name: 'Winters', state: 'California', country: 'US' },
  { name: 'Winthrop', state: 'Maine', country: 'US' },
  { name: 'Winthrop', state: 'Massachusetts', country: 'US' },
  { name: 'Winthrop', state: 'Massachusetts', country: 'US' },
  { name: 'Winthrop Harbor', state: 'Illinois', country: 'US' },
  { name: 'Winton', state: 'California', country: 'US' },
  { name: 'Wisconsin Rapids', state: 'Wisconsin', country: 'US' },
  { name: 'Wixom', state: 'Michigan', country: 'US' },
  { name: 'Woburn', state: 'Massachusetts', country: 'US' },
  { name: 'Wolcott', state: 'Connecticut', country: 'US' },
  { name: 'Wolf Trap', state: 'Virginia', country: 'US' },
  { name: 'Wolfeboro', state: 'New Hampshire', country: 'US' },
  { name: 'Wonder Lake', state: 'Illinois', country: 'US' },
  { name: 'Wood Dale', state: 'Illinois', country: 'US' },
  { name: 'Wood River', state: 'Illinois', country: 'US' },
  { name: 'Wood-Ridge', state: 'New Jersey', country: 'US' },
  { name: 'Woodbourne-Hyde Park', state: 'Ohio', country: 'US' },
  { name: 'Woodbridge', state: 'New Jersey', country: 'US' },
  { name: 'Woodbridge', state: 'Connecticut', country: 'US' },
  { name: 'Woodbridge', state: 'Virginia', country: 'US' },
  { name: 'Woodburn', state: 'Oregon', country: 'US' },
  { name: 'Woodbury', state: 'Connecticut', country: 'US' },
  { name: 'Woodbury', state: 'New Jersey', country: 'US' },
  { name: 'Woodbury', state: 'Minnesota', country: 'US' },
  { name: 'Woodbury', state: 'New York', country: 'US' },
  { name: 'Woodbury', state: 'New York', country: 'US' },
  { name: 'Woodcrest', state: 'California', country: 'US' },
  { name: 'Woodfield', state: 'South Carolina', country: 'US' },
  { name: 'Woodhaven', state: 'Michigan', country: 'US' },
  { name: 'Woodinville', state: 'Washington', country: 'US' },
  { name: 'Woodlake', state: 'California', country: 'US' },
  { name: 'Woodland', state: 'California', country: 'US' },
  { name: 'Woodland Park', state: 'Colorado', country: 'US' },
  { name: 'Woodlawn', state: 'Maryland', country: 'US' },
  { name: 'Woodlawn', state: 'Maryland', country: 'US' },
  { name: 'Woodlyn', state: 'Pennsylvania', country: 'US' },
  { name: 'Woodmere', state: 'Louisiana', country: 'US' },
  { name: 'Woodmere', state: 'New York', country: 'US' },
  { name: 'Woodmoor', state: 'Colorado', country: 'US' },
  { name: 'Woodmore', state: 'Maryland', country: 'US' },
  { name: 'Woodridge', state: 'Illinois', country: 'US' },
  { name: 'Woods Cross', state: 'Utah', country: 'US' },
  { name: 'Woodstock', state: 'Georgia', country: 'US' },
  { name: 'Woodstock', state: 'Illinois', country: 'US' },
  { name: 'Woodstock', state: 'Connecticut', country: 'US' },
  { name: 'Woodstock', state: 'New York', country: 'US' },
  { name: 'Woodward', state: 'Oklahoma', country: 'US' },
  { name: 'Woodway', state: 'Texas', country: 'US' },
  { name: 'Woonsocket', state: 'Rhode Island', country: 'US' },
  { name: 'Wooster', state: 'Ohio', country: 'US' },
  { name: 'Worcester', state: 'Massachusetts', country: 'US' },
  { name: 'Worth', state: 'Illinois', country: 'US' },
  { name: 'Worthington', state: 'Minnesota', country: 'US' },
  { name: 'Worthington', state: 'Ohio', country: 'US' },
  { name: 'Wrentham', state: 'Massachusetts', country: 'US' },
  { name: 'Wright', state: 'Florida', country: 'US' },
  { name: 'Wright-Patterson AFB', state: 'Ohio', country: 'US' },
  { name: 'Wyandanch', state: 'New York', country: 'US' },
  { name: 'Wyandotte', state: 'Michigan', country: 'US' },
  { name: 'Wyckoff', state: 'New Jersey', country: 'US' },
  { name: 'Wylie', state: 'Texas', country: 'US' },
  { name: 'Wyndham', state: 'Virginia', country: 'US' },
  { name: 'Wynne', state: 'Arkansas', country: 'US' },
  { name: 'Wyoming', state: 'Ohio', country: 'US' },
  { name: 'Wyoming', state: 'Michigan', country: 'US' },
  { name: 'Wyomissing', state: 'Pennsylvania', country: 'US' },
  { name: 'Wytheville', state: 'Virginia', country: 'US' },
  { name: 'Xenia', state: 'Ohio', country: 'US' },
  { name: 'Yakima', state: 'Washington', country: 'US' },
  { name: 'Yankton', state: 'South Dakota', country: 'US' },
  { name: 'Yardville-Groveville', state: 'New Jersey', country: 'US' },
  { name: 'Yarmouth', state: 'Maine', country: 'US' },
  { name: 'Yarmouth', state: 'Massachusetts', country: 'US' },
  { name: 'Yazoo City', state: 'Mississippi', country: 'US' },
  { name: 'Yeadon', state: 'Pennsylvania', country: 'US' },
  { name: 'Yeehaw Junction', state: 'Florida', country: 'US' },
  { name: 'Yonkers', state: 'New York', country: 'US' },
  { name: 'Yorba Linda', state: 'California', country: 'US' },
  { name: 'York', state: 'Nebraska', country: 'US' },
  { name: 'York', state: 'Maine', country: 'US' },
  { name: 'York', state: 'Pennsylvania', country: 'US' },
  { name: 'York', state: 'South Carolina', country: 'US' },
  { name: 'Yorketown', state: 'New Jersey', country: 'US' },
  { name: 'Yorkshire', state: 'Virginia', country: 'US' },
  { name: 'Yorktown', state: 'New York', country: 'US' },
  { name: 'Yorktown Heights', state: 'New York', country: 'US' },
  { name: 'Yorkville', state: 'Illinois', country: 'US' },
  { name: 'Youngstown', state: 'Ohio', country: 'US' },
  { name: 'Ypsilanti', state: 'Michigan', country: 'US' },
  { name: 'Yreka', state: 'California', country: 'US' },
  { name: 'Yuba City', state: 'California', country: 'US' },
  { name: 'Yucaipa', state: 'California', country: 'US' },
  { name: 'Yucca Valley', state: 'California', country: 'US' },
  { name: 'Yukon', state: 'Oklahoma', country: 'US' },
  { name: 'Yulee', state: 'Florida', country: 'US' },
  { name: 'Yuma', state: 'Arizona', country: 'US' },
  { name: 'Zachary', state: 'Louisiana', country: 'US' },
  { name: 'Zanesville', state: 'Ohio', country: 'US' },
  { name: 'Zephyrhills', state: 'Florida', country: 'US' },
  { name: 'Zion', state: 'Illinois', country: 'US' },
  { name: 'Zionsville', state: 'Indiana', country: 'US' },
  { name: 'Zuni Pueblo', state: 'New Mexico', country: 'US' }
]
