import { IExternalMembers, IGetExternalMembersParams } from './membersSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { LIMIT_DEFAULT } from '../../constants'
import { gql } from 'graphql-request'

type Filter = {
  key: string
  values: string[]
}[]

export const queryExternalMembersEmailsCount = (params?: IGetExternalMembersParams): string => {
  const { page = 1, filters } = params || {}
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let filterParamsString = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  if (filterString) {
    filterParamsString += `filter: ${filterString}`
  }

  //Received Emails Filter Param
  let receivedParamStr = ''
  let receivedFilterArr: Filter = [{ key: 'MAIL_FOLDER_TYPE', values: ['INBOX'] }]
  if (filters?.filter && filters.filter.length) {
    receivedFilterArr = [...receivedFilterArr, ...filters.filter]
  }
  const receivedFilterStr = parameterizeArrayofObjects(receivedFilterArr || [])

  if (receivedFilterStr) {
    receivedParamStr += `, filter:${receivedFilterStr}`
  }

  return gql`
    {
      externalMembersSentMails: members(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${filterParamsString}){
        count
      }
      externalMembersReceivedMails: members(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${receivedParamStr}){
        count
      }
    }`
}

export const mapQueryExternalMembersEmailsCount = (
  raw: any
): { sentEmailsTotal: number; receivedEmailsTotal: number } => {
  return {
    sentEmailsTotal: raw?.externalMembersSentMails?.count || 0,
    receivedEmailsTotal: raw?.externalMembersReceivedMails?.count || 0
  }
}

export const queryExternalMembers = (params?: IGetExternalMembersParams): string => {
  const { page = 1, filters, isEmail = false } = params || {}
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let filterParamsString = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  if (filterString) {
    filterParamsString += `filter: ${filterString}`
  }
  const piiCountSubQuery = isEmail
    ? `piiDataMailCount`
    : `objects(booleanFilter: {key:IS_SENSITIVE, value:true}){ count }`

  return gql`
    {
      members(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${filterParamsString}){
        edges{
          node{
            email,
            piiFilesCount: ${piiCountSubQuery}
          }
        }
      }
    }`
}

export const mapQueryExternalMembers = (raw: any): { list: IExternalMembers[] } => {
  let list = []
  if (raw && raw.members && raw.members.edges && Array.isArray(raw.members.edges)) {
    list = raw.members.edges.map(({ node: { email = '', piiFilesCount } }) => ({
      email,
      piiFilesCount: piiFilesCount?.count || 0,
      piiDataMailCount: piiFilesCount || 0
    }))
  }

  return {
    list
  }
}
