import {
  EXCESSIVE_ACCESS_TYPES,
  OPEN_ACCESS_TYPES,
  POLICY_TYPES,
  SENSITIVE_LABEL
} from '../../../constants'
import AnyoneWithTheLinkIcon from '../../../assets/inlineSvg/icon-anyone-with-link.svg'
import ExternalPeopleIcon from '../../../assets/inlineSvg/icon-external-people.svg'
import OrganizationWithTheLinkIcon from '../../../assets/inlineSvg/icon-organisation-with-link.svg'
import { capitalize } from '../../../utils'
import { gql } from 'graphql-request'

export const STEPS = [
  ' Rule Set Criteria',
  'Data Sources',
  'Alert & Notifications',
  'Automation (Beta)'
]

export const POLICY_WITH_RULESETS = [
  POLICY_TYPES.ENTITY_LOCATION_POLICY,
  POLICY_TYPES.ACCESS_POLICY
]

export const getSteps = (isPolicyDocumentLabeling: boolean) =>
  isPolicyDocumentLabeling ? [...STEPS].splice(0, 2) : STEPS

export enum PolicySteps {
  rulesetCriteria = 0,
  dataSources,
  alertAndNotifications,
  automation
}

export enum RulesetType {
  classification = 'FILE_CLASSIFICATION_SET',
  attributeSensitivity = 'ATTRIBUTE_SENSITIVITY_COUNT',
  attributeInstanceCount = 'ATTRIBUTE_INSTANCE_COUNT',
  label = 'DOCUMENT_LABELS'
}

export enum CriteriaOperatorTypes {
  or = 'OR',
  and = 'AND'
}
export enum CriteriaGroupOperatorTypes {
  or = 'ANY_OF',
  and = 'ALL_OF',
  nor = 'NOT_ANY_OF',
  nand = 'NOT_ALL_OF'
}

const OPERATOR_CONFIG = {
  [RulesetType.classification]: [CriteriaGroupOperatorTypes.or, CriteriaGroupOperatorTypes.nor]
}

export const getGroupOperatorOptions = (intl, type?: RulesetType) => {
  const options = [
    {
      key: CriteriaGroupOperatorTypes.or,
      value: CriteriaGroupOperatorTypes.or,
      label: intl.formatMessage({ id: 'labels.conditionOr' })
    },
    {
      key: CriteriaGroupOperatorTypes.and,
      value: CriteriaGroupOperatorTypes.and,
      label: intl.formatMessage({ id: 'labels.conditionAnd' })
    },
    {
      key: CriteriaGroupOperatorTypes.nor,
      value: CriteriaGroupOperatorTypes.nor,
      label: intl.formatMessage({ id: 'labels.conditionNor' })
    },
    {
      key: CriteriaGroupOperatorTypes.nand,
      value: CriteriaGroupOperatorTypes.nand,
      label: intl.formatMessage({ id: 'labels.conditionNand' })
    }
  ]
  const operatorConfig = type && OPERATOR_CONFIG[type]
  if (!operatorConfig) return options
  return options.filter((option) => operatorConfig.includes(option.key))
}

export const getCriteriaOptions = (intl) => [
  {
    key: CriteriaOperatorTypes.or,
    value: CriteriaOperatorTypes.or,
    label: intl.formatMessage({ id: 'labels.any' })
  },
  {
    key: CriteriaOperatorTypes.and,
    value: CriteriaOperatorTypes.and,
    label: intl.formatMessage({ id: 'labels.all' })
  }
]

export const getLabelOption = (intl) => ({
  key: RulesetType.label,
  value: RulesetType.label,
  label: intl.formatMessage({ id: 'ruleset.field.criteria.DOCUMENT_LABELS' })
})

export const getRulesetTypeOptions = (intl) => [
  {
    key: RulesetType.classification,
    value: RulesetType.classification,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.FILE_CLASSIFICATION_SET' })
  },
  {
    key: RulesetType.attributeInstanceCount,
    value: RulesetType.attributeInstanceCount,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.ATTRIBUTE_INSTANCE_COUNT' })
  },
  {
    key: RulesetType.attributeSensitivity,
    value: RulesetType.attributeSensitivity,
    label: intl.formatMessage({ id: 'ruleset.field.criteria.ATTRIBUTE_SENSITIVITY_COUNT' })
  },
  getLabelOption(intl)
]

export type AttributeSensitivityType = {
  type: RulesetType.attributeSensitivity
  attributeSensitivityCounts: {
    attributeSensitivityLabel: SENSITIVE_LABEL
    minCount: number
  }[]
}

export type AttributeInstanceCountType = {
  type: RulesetType.attributeInstanceCount
  attributeInstanceCounts: {
    attributeId: string
    minCount: number
    maxCount: number | null
  }[]
}

export type FileClassificationType = {
  type: RulesetType.classification
  fileClassificationSet: {
    fileClass: string
    fileSubClass: string
  }[]
}

export type LabelType = {
  type: RulesetType.label
  documentLabels: {
    labelSetId: string
    labelId: string
  }[]
}

export type Ruleset = {
  operator: CriteriaGroupOperatorTypes
  collasped?: boolean
} & (AttributeSensitivityType | AttributeInstanceCountType | FileClassificationType | LabelType)

export const defaultClassificationRuleset: Ruleset = {
  type: RulesetType.classification,
  operator: CriteriaGroupOperatorTypes.or,
  fileClassificationSet: [],
  collasped: false
}

export const defaultAttributeInstanceCountRuleset: Ruleset = {
  type: RulesetType.attributeInstanceCount,
  operator: CriteriaGroupOperatorTypes.or,
  attributeInstanceCounts: [],
  collasped: false
}
const iconMapper = {
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE]: ExternalPeopleIcon,
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE_WITH_WRITE_ACCESS]: ExternalPeopleIcon,
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK]: AnyoneWithTheLinkIcon,
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK_HAS_WRITE_ACCESS]: AnyoneWithTheLinkIcon,
  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK]: OrganizationWithTheLinkIcon,
  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK_HAS_WRITE_ACCESS]: OrganizationWithTheLinkIcon
}

export const getAccessControlOpenAccessTypeOptions = (intl) => {
  return Object.keys(OPEN_ACCESS_TYPES).map((type) => ({
    key: type,
    value: type,
    label: intl.formatMessage({ id: `ruleset.field.accessSubType.${type}` }),
    description: intl.formatMessage({
      id: `ruleset.field.accessSubType.${type}.description`
    }),
    icon: iconMapper[OPEN_ACCESS_TYPES[type]]
  }))
}

export const getExcessiveAccessOpenAccessTypeOptions = (intl) => {
  return Object.keys(EXCESSIVE_ACCESS_TYPES).map((type) => ({
    key: type,
    value: EXCESSIVE_ACCESS_TYPES[type],
    label: intl.formatMessage({ id: `ruleset.field.accessSubType.${EXCESSIVE_ACCESS_TYPES[type]}` })
  }))
}

export enum AttributeInstanceOptions {
  attributeSensitivity = 'SENSITIVITY',
  attributeSet = 'ATTRIBUTE SET'
}

export const getAttributeInstanceOptions = () => {
  return Object.keys(AttributeInstanceOptions).map((type) => ({
    key: type,
    value: AttributeInstanceOptions[type],
    label: capitalize(AttributeInstanceOptions[type] as string)
  }))
}

enum AccessMode {
  PublicLink = 'PUBLIC_LINK',
  OrganizationLink = 'ORGANIZATION_LINK',
  Link = 'LINK',
  Direct = 'DIRECT'
}

enum AccessAttribute {
  Department = 'DEPARTMENT',
  Company = 'COMPANY'
}

enum Operator {
  NotEq = 'NOT_EQ',
  Eq = 'EQ'
}

enum AccessPermission {
  Read = 'READ',
  Write = 'WRITE'
}

export const AccessPolicyQueryMapper: {
  [key in OPEN_ACCESS_TYPES | EXCESSIVE_ACCESS_TYPES]: {
    accessMode: AccessMode[]
    accessPermission?: AccessPermission[]
    attribute?: AccessAttribute
    operator?: Operator
  }
} = {
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE]: {
    accessMode: [AccessMode.Direct],
    attribute: AccessAttribute.Company,
    operator: Operator.NotEq
  },
  [OPEN_ACCESS_TYPES.EXTERNAL_PEOPLE_WITH_WRITE_ACCESS]: {
    accessMode: [AccessMode.Direct],
    accessPermission: [AccessPermission.Write],
    attribute: AccessAttribute.Company,
    operator: Operator.NotEq
  },
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK]: {
    accessMode: [AccessMode.PublicLink]
  },
  [OPEN_ACCESS_TYPES.ANYONE_WITH_THE_LINK_HAS_WRITE_ACCESS]: {
    accessMode: [AccessMode.PublicLink],
    accessPermission: [AccessPermission.Write]
  },

  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK]: {
    accessMode: [AccessMode.OrganizationLink]
  },
  [OPEN_ACCESS_TYPES.ORGANIZATION_WITH_THE_LINK_HAS_WRITE_ACCESS]: {
    accessMode: [AccessMode.OrganizationLink],
    accessPermission: [AccessPermission.Write]
  },
  [EXCESSIVE_ACCESS_TYPES.READ_WRITE]: {
    accessMode: [AccessMode.Direct, AccessMode.Link]
  },
  [EXCESSIVE_ACCESS_TYPES.WRITE_ACCESS]: {
    accessMode: [AccessMode.Direct, AccessMode.Link, AccessMode.PublicLink],
    accessPermission: [AccessPermission.Write, AccessPermission.Write, AccessPermission.Write]
  }
}

const accessValuesFragment = (values: string[], permission?: string[]) => {
  return values
    .map(
      (value, idx) => `{
  accessMode: ${value}
  ${permission?.at(idx) ? `accessPermissions: [${permission[idx]}]` : ''}
  }`
    )
    .join('')
}

export const getAccessRulesQueryAccessConditions = (
  type?: OPEN_ACCESS_TYPES | EXCESSIVE_ACCESS_TYPES,
  count?: number
) => {
  if (!type) return ``
  const isExcessiveAccess = type === EXCESSIVE_ACCESS_TYPES.READ_WRITE && count && count > 0
  return gql`{
    operator: ALL_OF,
    conditions: [
     ${
       AccessPolicyQueryMapper?.[type]?.attribute
         ? `
       { conditionType: ACCESSOR_ATTRIBUTE_OPERATOR_OBJECT_OWNER_ATTRIBUTE,
        accessorAttributeArg:
          {
            operator: ${AccessPolicyQueryMapper[type].operator},
            attributeType: ${AccessPolicyQueryMapper[type].attribute}
          }
      }`
         : ''
     },
      {
        conditionType: ACCESS_TYPE_OPERATOR_SPECIFIC_VALUES
        accessTypeArg: {
          operator: IN
          accessTypeValues: [${accessValuesFragment(
            AccessPolicyQueryMapper[type]?.accessMode,
            AccessPolicyQueryMapper[type]?.accessPermission
          )}]
        }
      },
      ${
        isExcessiveAccess
          ? `
        {
          conditionType: ACCESSOR_AGGREGATION_OPERATOR_CONDITION
          accessorAggregationArg: {
            operator: GREATER_THAN
            aggregationOp: COUNT
            conditionValues: [${count}]
          }
        }
        `
          : ''
      }
    ]
  }`
}
