import {
  mapQueryClusterColumns,
  mapQueryColumns,
  mapQueryColumnsBySensitivity,
  mapQueryColumnsSummaryByTableId,
  mapQueryColumnsWidget,
  mapQueryIdentifiers,
  queryClusterColumns,
  queryColumns,
  queryColumnsSummaryByTableId,
  queryColumnsWidget,
  queryCreateTablePrimaryKey,
  queryDeleteTablePrimaryKey,
  queryIdentifiers,
  queryReviewTable,
  queryColumnsBySensitivity,
  mapQueryColumnsCount,
  queryColumnsCount,
  queryReviewColumns,
  queryDataSourceColumns,
  mapQueryDataSourceColumns,
  queryFullscanColumns,
  queryColumnSamples,
  mapQueryColumnSamples
} from './queries'
import { defaultSortParams, getSortDirection, sortByOrder, SortParams } from '../../utils/sortUtil'
import apiService from '../../services/api/apiService'
import { Identifier } from '../../services/api/apiTypes'
import { SORT_ORDER } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import {
  ATTRIBUTE_ID,
  CHANGE_EVENT_TYPE,
  CLUSTER_ID,
  COLUMN_ID,
  DATABASE,
  DATABASE_ID,
  DATA_SOURCE_ID,
  FILTER_PII_VALUES,
  IS_ALL,
  IS_AT_RISK,
  IS_SENSITIVE,
  PAGE,
  SEARCH_QUERY,
  TABLE_ID
} from '../../constants'
import { Label } from '../documentLabels/documentLabelsSlice'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export enum COLUMN_CHANGES {
  noChanges = 'noChanges',
  updated = 'updated',
  pending = 'pending',
  saved = 'saved'
}

export enum ColumnChangeTypes {
  create = 'CREATE',
  update = 'UPDATE',
  delete = 'DELETE',
  changedToPii = 'CHANGED_TO_PII',
  changedToNonPii = 'CHANGED_TO_NON_PII'
}

export enum ColumnReviewStatuses {
  notReviewed = 'not_reviewed',
  reviewed = 'reviewed'
}

export enum ColumnTypes {
  blob = 'BLOB'
}

export enum MaybePiiReasons {
  pattern = 'PATTERN_MATCH',
  context = 'CONTEXT_WORD_MATCH'
}
export type MaybePiiIdentifier = { attributeId: string; reason?: MaybePiiReasons }

export type Column = {
  columnId: string
  columnName?: string
  constraint?: string | null
  qualifiedName?: string
  classification?: string
  confidence?: number
  identifierId?: string
  additionalAttributeIds?: string[]
  maybePii?: MaybePiiIdentifier[]
  dsrMasked?: boolean
  dsrIndex?: boolean
  changesStatus?: COLUMN_CHANGES
  isReviewed?: boolean
  type?: ColumnTypes
  changeType?: ColumnChangeTypes
  tableId?: string
  tableName?: string
  databaseId?: string
  databaseName?: string
}

export type ColumnsWidget = {
  columnsCount: number
  columnsPiiCount: number
  columnsNeedReviewCount: number
  columnsNeedReviewPiiCount: number
}

export type ColumnsSettings = {
  list?: Column[]
  total?: number
  sort: SortParams
}

const initialList: ColumnsSettings = {
  sort: defaultSortParams
}

export const ACTION_COLUMNS_IDENTIFIERS_FETCH = 'columns/identifiers'
export const fetchIdentifiers = createAsyncThunk(ACTION_COLUMNS_IDENTIFIERS_FETCH, async () => {
  const raw = await graphqlService.execute(queryIdentifiers())
  return await mapQueryIdentifiers(raw)
})

export type PiiParams = {
  attributeId: string
  additionalAttributes?: string[]
  numRowsSampled: number
  numRowsPII: number
  isReviewed: boolean
  reviewedBy: string
  createdBy: string
}

export type ColumnClassificationsParams = {
  typeName: 'column' | 'identity'
  classifications: Array<{
    pii?: PiiParams
    identity?: {
      piiColumnsToScan?: string[]
      createdBy: string
      isIdentity: boolean
      entityType?: string
      metadata?: {
        primaryIdentifierColumn?: string
        timestampColumn?: string
        labels?: Label[]
      }
    }
    entityType?: {
      type: string
      createdBy: string
    }
    primaryIdentifier?: {
      createdBy: string
      isPrimaryIdentifier: boolean
    }
    qualifiedName: string
  }>
}

export type ColumnClassificationAttribute = {
  columnId: string
  qualifiedName: string
  identifierId: string
  additionalAttributeIds?: string[]
}
export type SaveColumnClassificationParams = {
  tableId?: string
  columnAttributes: ColumnClassificationAttribute[]
}

export const ACTION_COLUMNS_CLASSIFICATION_SAVE = 'columns/saveClassification'
export const saveColumnClassification = createAsyncThunk(
  ACTION_COLUMNS_CLASSIFICATION_SAVE,
  async (params: SaveColumnClassificationParams): Promise<ColumnClassificationAttribute[]> => {
    const { tableId = '', columnAttributes = [] } = params

    if (tableId) {
      await await graphqlService.execute(queryReviewTable({ tableId }))
    }

    const classifications = columnAttributes.map((param) => {
      const pii: PiiParams = {
        attributeId: param.identifierId,
        ...(param.additionalAttributeIds?.length
          ? { additionalAttributes: [...new Set(param.additionalAttributeIds)] }
          : {}),
        numRowsSampled: 0,
        numRowsPII: 0,
        isReviewed: true,
        reviewedBy: '',
        createdBy: ''
      }

      return { pii, qualifiedName: param.qualifiedName }
    })

    const withAttributes = classifications.filter(
      (item) => item.pii.attributeId || item.pii.additionalAttributes?.length
    )

    if (withAttributes.length > 0) {
      await apiService.postColumnClassifications({
        typeName: 'column',
        classifications: withAttributes
      })
    }

    const withoutAttributes = columnAttributes.filter((item) => !item.identifierId)

    if (withoutAttributes.length > 0) {
      for (const item of withoutAttributes) {
        await apiService.deleteTableClassification(item.columnId)
      }
    }

    return columnAttributes
  }
)

export interface ColumnsCountParams {
  [DATA_SOURCE_ID]: string
  [SEARCH_QUERY]?: string
  piiDetection?: FILTER_PII_VALUES[]
  reviewStatus?: string
  filters: {
    [DATABASE]?: string
  }
}
export const ACTION_COLUMNS_COUNT = 'columns/listCount'
export const fetchColumnsCount = createAsyncThunk(
  ACTION_COLUMNS_COUNT,
  async (params: ColumnsCountParams) => {
    const resultRaw = await graphqlService.execute(queryColumnsCount(params))
    return mapQueryColumnsCount(resultRaw)
  }
)

export interface ColumnsParams {
  [TABLE_ID]?: string
  [CLUSTER_ID]?: string
  [DATABASE_ID]?: string
  [DATA_SOURCE_ID]?: string
  [PAGE]?: number
  [IS_AT_RISK]?: boolean
  [IS_SENSITIVE]?: boolean
  [SEARCH_QUERY]?: string
  reviewStatus?: string
  piiDetection?: FILTER_PII_VALUES[]
  filters?: {
    [DATABASE]?: string
    [CHANGE_EVENT_TYPE]?: string
  }
}
export const ACTION_COLUMNS_LIST_FETCH = 'columns/list'
export const fetchColumns = createAsyncThunk(
  ACTION_COLUMNS_LIST_FETCH,
  async (params: ColumnsParams) => {
    let result: { list: Column[]; total: number } = { list: [], total: 0 }
    if (params.tableId) {
      const resultRaw = await graphqlService.execute(queryColumns(params))
      result = mapQueryColumns(resultRaw)
    } else {
      const resultRaw = await graphqlService.execute(queryClusterColumns(params))
      result = mapQueryClusterColumns(resultRaw)
    }
    return { ...params, ...result }
  }
)

export const ACTION_DATA_SOURCE_COLUMNS_LIST_FETCH = 'columns/datasourceList'
export const fetchDataSourceColumns = createAsyncThunk(
  ACTION_DATA_SOURCE_COLUMNS_LIST_FETCH,
  async (params: ColumnsParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourceColumns(params))
    const result = mapQueryDataSourceColumns(resultRaw)
    return { ...params, ...result }
  }
)

export interface ColumnsWidgetParams {
  [DATABASE_ID]?: string
}

export const ACTION_COLUMNS_WIDGET_FETCH = 'columns/widget'
export const fetchColumnsWidget = createAsyncThunk(
  ACTION_COLUMNS_WIDGET_FETCH,
  async (params: ColumnsWidgetParams) => {
    const resultRaw = await graphqlService.execute(queryColumnsWidget(params))
    return mapQueryColumnsWidget(resultRaw)
  }
)

export type ColumnsByTableWidget = {
  tableId: string
  tableName: string
  columnsCount: number
  columnsPiiCount: number
  columnsNeedReviewCount: number
}

export interface ColumnsByTableWidgetParams {
  [TABLE_ID]: string
}

export const ACTION_COLUMNS_WIDGET_BY_TABLE = 'columns/columnsWidgetByTable'
export const fetchSummaryWidgetByTableId = createAsyncThunk(
  ACTION_COLUMNS_WIDGET_BY_TABLE,
  async (params: ColumnsByTableWidgetParams) => {
    const resultRaw = await graphqlService.execute(queryColumnsSummaryByTableId(params))
    return mapQueryColumnsSummaryByTableId(resultRaw)
  }
)

export interface ToggleConstraintParams {
  [COLUMN_ID]: string
  constraint: string
  isDelete?: boolean
}
export interface ToggleTablePrimaryKeyParams {
  [COLUMN_ID]: string
}

export const ACTION_TABLE_PRIMARY_KEY_CREATE = 'columns/primaryKeyCreate'
export const createPrimaryKey = createAsyncThunk(
  ACTION_TABLE_PRIMARY_KEY_CREATE,
  async (params: ToggleTablePrimaryKeyParams) => {
    return await graphqlService.execute(queryCreateTablePrimaryKey(params))
  }
)
export const ACTION_TABLE_PRIMARY_KEY_DELETE = 'columns/primaryKeyDelete'
export const deletePrimaryKey = createAsyncThunk(
  ACTION_TABLE_PRIMARY_KEY_DELETE,
  async (params: ToggleTablePrimaryKeyParams) => {
    return await graphqlService.execute(queryDeleteTablePrimaryKey(params))
  }
)

export interface ColumnIdParams {
  [COLUMN_ID]: string
}

export interface ColumnsReviewParams {
  [COLUMN_ID]: string
  [ATTRIBUTE_ID]?: string
  additionalAttributeIds?: string[]
  deleteClassification?: boolean
}
export const ACTION_COLUMNS_REVIEW = 'columns/review'
export const reviewColumns = createAsyncThunk(
  ACTION_COLUMNS_REVIEW,
  async (params: ColumnsReviewParams[]) => {
    return await graphqlService.execute(queryReviewColumns(params))
  }
)

export const ACTION_COLUMNS_FULLSCAN = 'columns/full-scan'
export const fullscanColumns = createAsyncThunk(
  ACTION_COLUMNS_FULLSCAN,
  async (colIds: string[]) => {
    return await graphqlService.execute(queryFullscanColumns(colIds))
  }
)

export type ColumnsSensitiveByDataSource = {
  datasourceId: string
  total: number
  highSensitivityCount: number
  mediumSensitivityCount: number
}
export type ColumnsSensitiveByDataSourceParams = {
  [DATA_SOURCE_ID]: string
}
export const ACTION_COLUMNS_BY_SENSITIVITY = 'columns/bySensitivity'
export const fetchColumnsBySensitivity = createAsyncThunk(
  ACTION_COLUMNS_BY_SENSITIVITY,
  async (params: ColumnsSensitiveByDataSourceParams) => {
    const resultRaw = await graphqlService.execute(queryColumnsBySensitivity(params))
    return mapQueryColumnsBySensitivity(resultRaw)
  }
)

// column samples
export type ColumnSample = {
  tableId: string
  columnId: string
  columnName?: string
  samples?: string[]
}
export type ColumnSamplesParams = {
  tableId: string
  columnId: string
  columnName: string
}
export const ACTION_COLUMNS_SAMPLES = 'columns/samples'
export const fetchColumnSamples = createAsyncThunk(
  ACTION_COLUMNS_SAMPLES,
  async (params: ColumnSamplesParams) => {
    const resultRaw = await graphqlService.execute(queryColumnSamples(params))
    const samples = mapQueryColumnSamples(resultRaw)
    return { ...params, samples: samples.slice(0, 5).flat() }
  }
)

interface ColumnsState {
  tableId?: string
  primaryKeyUpdated: boolean
  all: ColumnsSettings
  issues: ColumnsSettings
  sensitive: ColumnsSettings
  identifiers?: Identifier[]
  widget?: ColumnsWidget
  widgetByTable?: ColumnsByTableWidget
  widgetColumnsSensitiveByDataSources: ColumnsSensitiveByDataSource[]
  columnSamples: ColumnSample[]
  isColumnClassificationSaveSucces: boolean
}

export const initialState: ColumnsState = {
  primaryKeyUpdated: false,
  all: initialList,
  issues: initialList,
  sensitive: initialList,
  widgetColumnsSensitiveByDataSources: [],
  columnSamples: [],
  isColumnClassificationSaveSucces: false
}

const columnsSlice = createSlice({
  name: 'columns',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    updateColumns: (state, action) => {
      const { list, columns } = action.payload

      state[list].list = state[list].list.map((listCol) => {
        const colValues = columns.find((col) => col.columnId === listCol.columnId)
        return colValues
          ? { ...listCol, ...colValues, changesStatus: COLUMN_CHANGES.updated }
          : listCol
      })
    },
    resetLists: () => initialState,
    resetWidget: (state) => {
      state.widget = initialState.widget
    },
    resetPrimaryKeyUpdateStatus: (state) => {
      state.primaryKeyUpdated = initialState.primaryKeyUpdated
    },
    resetIsColumnClassificationSaveSucces: (state) => {
      state.isColumnClassificationSaveSucces = initialState.isColumnClassificationSaveSucces
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIdentifiers.fulfilled, (state, { payload }) => {
      state.identifiers = sortByOrder(payload, 'name', SORT_ORDER.ASC)
    })
    builder.addCase(fetchColumns.fulfilled, (state, { payload }) => {
      state.all.list = payload.list
      state.all.total = payload.total || 0

      state.issues.list = payload.list.filter((column) => !column.isReviewed)
      state.issues.total = state.issues?.list?.length || 0

      state.sensitive.list = payload.list.filter((column) => !!column.identifierId)
      state.sensitive.total = state.sensitive?.list?.length || 0
    })
    builder.addCase(fetchDataSourceColumns.fulfilled, (state, { payload }) => {
      if (payload[IS_ALL]) state.all.list = payload.list
      if (payload[IS_AT_RISK]) state.issues.list = payload.list
      if (payload[IS_SENSITIVE]) state.sensitive.list = payload.list
    })
    builder.addCase(fetchColumnsCount.fulfilled, (state, { payload }) => {
      state.all.total = payload[IS_ALL]
      state.sensitive.total = payload[IS_SENSITIVE]
      state.issues.total = payload[IS_AT_RISK]
    })
    builder.addCase(saveColumnClassification.pending, (state, action) => {
      state.isColumnClassificationSaveSucces = false
      const params = action.meta.arg.columnAttributes

      state.all.list = state.all?.list?.map((column) => {
        const foundCol = params.find((item) => item.columnId === column.columnId)
        return foundCol ? { ...column, ...foundCol, changesStatus: COLUMN_CHANGES.pending } : column
      })

      state.issues.list = state.issues?.list?.map((column) => {
        const foundCol = params.find((item) => item.columnId === column.columnId)
        return foundCol ? { ...column, ...foundCol, changesStatus: COLUMN_CHANGES.pending } : column
      })
      state.issues.total = state.issues?.list?.length

      state.sensitive.list = state.sensitive?.list?.map((column) => {
        const foundCol = params.find((item) => item.columnId === column.columnId)
        return foundCol ? { ...column, ...foundCol, changesStatus: COLUMN_CHANGES.pending } : column
      })
      state.sensitive.total = state.sensitive?.list?.length
    })
    builder.addCase(saveColumnClassification.fulfilled, (state, { payload }) => {
      const updatedList = state.all?.list?.map((column) => {
        const foundCol = payload.find((item) => item.columnId === column.columnId)
        const updatedColumn = { ...column, isReviewed: true }
        return foundCol
          ? { ...column, ...foundCol, changesStatus: COLUMN_CHANGES.saved }
          : updatedColumn
      })

      state.all.list = updatedList

      state.issues.list = updatedList?.filter((column) => !column.isReviewed)
      state.issues.total = state.issues?.list?.length

      state.sensitive.list = updatedList?.filter(
        (column) => !!column.identifierId || !!column.maybePii?.length
      )
      state.sensitive.total = state.sensitive?.list?.length
      state.isColumnClassificationSaveSucces = true
    })
    builder.addCase(fetchColumnsWidget.fulfilled, (state, { payload }) => {
      state.widget = payload
    })
    builder.addCase(fetchSummaryWidgetByTableId.fulfilled, (state, { payload }) => {
      state.widgetByTable = payload
    })
    builder.addCase(createPrimaryKey.fulfilled, (state) => {
      state.primaryKeyUpdated = true
    })
    builder.addCase(deletePrimaryKey.fulfilled, (state) => {
      state.primaryKeyUpdated = true
    })

    builder.addCase(fetchColumnsBySensitivity.fulfilled, (state, { payload }) => {
      state.widgetColumnsSensitiveByDataSources.push(payload)
    })
    builder.addCase(fetchColumnSamples.fulfilled, (state, { payload }) => {
      if (
        !state.columnSamples.find((sample) => {
          return sample.tableId === payload.tableId && sample.columnId === payload.columnId
        })
      ) {
        state.columnSamples.push(payload)
      }
    })
  }
})

export const {
  setSort,
  resetLists,
  resetWidget,
  updateColumns,
  resetPrimaryKeyUpdateStatus,
  resetIsColumnClassificationSaveSucces
} = columnsSlice.actions

export default columnsSlice.reducer
