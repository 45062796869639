import { hasFeatureAccess, hasRouteAccess, redirectByRole } from './accessFunctions'
import { RootState } from '../rootReducer'
import AccessDenied from '../components/AccessDenied'
import Navbar from '../components/Navbar'
import { accessTokenKey, DEFAULT_LANGUAGE, URL_DASHBOARD } from '../constants'
import useUrlContext from '../hooks/useUrlContext'
import { goToLoginPage } from '../utils/urlUtil'
import { ACTION_LOGOUT, fetchUserInfo, setAuth } from '../features/login/loginSlice'
import { STATES, UserDetails } from '../interfaces'
import { useCircuitBreaker } from '../hooks/useCircuitBreaker'
import { RequestState, getAction, getShowLoader } from '../reducers/requestReducer'
import LoaderView from '../components/Loader/loader'
import { setLanguage } from '../features/manageAccount/languageSlice'
import historyService from '../services/historyService'
import { useThemeContext } from '../features/dashboards/mainDashboardv2/components/ThemeContext'
import {
  ACTION_FETCH_FEATURE_FLAGS,
  FeatureFlags,
  fetchFeatureFlags
} from '../configs/featureFlagSlice'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Redirect } from 'react-router-dom'
import React, { useEffect } from 'react'

type TParams = { subPage: string; tabFilter: string }
interface IProps {
  exact?: boolean
  strict?: boolean
  featureFlags: FeatureFlags
  isAuthenticated: boolean | null
  path: string | string[]
  fetchFeatureFlagsAction?: RequestState
  component?: React.ComponentType<any>
  render?: (routeProps: RouteComponentProps<TParams>) => React.ReactElement
  setAuth: (payload: boolean) => void
  setLanguage: (value: string) => void
  fetchUserInfo: () => void
  fetchFeatureFlags: () => void
  userDetails?: UserDetails
  showLoader: boolean
  hideNavBar?: boolean
}
const LoggedInRoute = ({
  component: Component,
  isAuthenticated,
  featureFlags,
  fetchFeatureFlags,
  fetchFeatureFlagsAction,
  path,
  exact,
  strict,
  render,
  fetchUserInfo,
  userDetails,
  setAuth,
  showLoader,
  setLanguage,
  hideNavBar = false
}: IProps) => {
  const context = useUrlContext()
  const [fetUserInfo_cb] = useCircuitBreaker(2, 10000, fetchUserInfo, () => {
    throw new Error('The breaker trips!')
  })
  useEffect(() => {
    if (userDetails?.language) {
      setLanguage(userDetails?.language || DEFAULT_LANGUAGE)
    }
    if (!isAuthenticated) {
      if (window.localStorage.getItem(accessTokenKey)) {
        setAuth(true)
      } else {
        goToLoginPage()
      }
    } else {
      if (!userDetails?.roles?.length) {
        fetUserInfo_cb()
        fetchFeatureFlags()
      }
    }
  }, [userDetails, isAuthenticated, fetchFeatureFlags, fetchUserInfo])

  const redirectPath =
    window.__featureFlags &&
    redirectByRole(userDetails, Array.isArray(path) ? path : [path], context.pathname)

  useEffect(() => {
    redirectPath && historyService.replace(redirectPath)
  }, [redirectPath])
  const { theme } = useThemeContext()

  return fetchFeatureFlagsAction?.state === STATES.success ? (
    <>
      <div className={`theme-overrides ${theme}`}>
        <LoaderView showLoader={showLoader} />
        {hideNavBar || <Navbar />}
        <main>
          {hasFeatureAccess(context.pathname, featureFlags) ? (
            <>
              {hasRouteAccess(userDetails, path) ? (
                <Route
                  exact={exact}
                  strict={strict}
                  path={path}
                  render={render as any}
                  component={Component}
                />
              ) : (
                <Route
                  exact={exact}
                  strict={strict}
                  path={path}
                  render={render as any}
                  component={() => <AccessDenied />}
                />
              )}
            </>
          ) : (
            <Redirect to={URL_DASHBOARD} />
          )}
        </main>
      </div>
    </>
  ) : (
    <></>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated,
  userDetails: state.user.userInfo,
  featureFlags: state.featureFlags,
  showLoader: getShowLoader(ACTION_LOGOUT),
  fetchFeatureFlagsAction: getAction(ACTION_FETCH_FEATURE_FLAGS)
})
const mapDispatchToProps = {
  setAuth,
  fetchUserInfo,
  setLanguage,
  fetchFeatureFlags
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInRoute)
