import { mapQueryReportersList, queryReportersList } from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type Reporter = {
  name: string
  email: string
  totalTicketsWithPII: number
}

export interface FetchReportersListParams {
  datasourceId: string
  page: number
}

export const ACTION_REPORTERS_LIST_FETCH = 'reporters/list'
export const fetchReportersList = createAsyncThunk(
  ACTION_REPORTERS_LIST_FETCH,
  async (params: FetchReportersListParams) => {
    const result = await graphqlService.execute(queryReportersList(params))
    return mapQueryReportersList(result)
  }
)

export type ReportersListSettings = {
  sort: SortParams
  list?: Reporter[]
  total?: number
}

interface TicketsState {
  list: ReportersListSettings
}

const initialReportersList: ReportersListSettings = { sort: defaultSortParams }
export const initialState: TicketsState = {
  list: initialReportersList
}

const reportersSlice = createSlice({
  name: 'reporters',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state.list.sort = getSortDirection(state.list.sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportersList.fulfilled, (state, action) => {
      state.list.list = action.payload.list
      state.list.total = action.payload.totalReporters
    })
  }
})

export const { setSort } = reportersSlice.actions
export default reportersSlice.reducer
