import apiService from '../../services/api/apiService'
import service from '../../services/api/apiService'
import { DataSource, GSUITE_TYPE } from '../../services/api/apiTypes'
import { queryResetPauseResumeError } from '../dataSources/queries'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface SyncInfo {
  name?: string
  registeredOn?: number
  status?: string
  totalCount?: number
  syncedCount?: number
  gDriveType?: GSUITE_TYPE
  dataSourceType?: string
  numberOfObjects?: number
  numberOfEntities?: number
  dataSource?: DataSource
}

interface SyncInfoState {
  data?: SyncInfo
}

export const initialState: SyncInfoState = {}

export const ACTION_FETCH_SYNC_INFO = 'syncInfo/dataSource'
export const fetchSyncInfo = createAsyncThunk(
  ACTION_FETCH_SYNC_INFO,
  async (dataSourceId: string) => await service.getDataSourceById(dataSourceId)
)

export const ACTION_PAUSE_DATASOURCE_SCAN = 'dataSource/scan/pause'
export const pauseDataSourceScan = createAsyncThunk(
  ACTION_PAUSE_DATASOURCE_SCAN,
  async (dataSourceId: string) => {
    await apiService.postPauseDatasourceScan(dataSourceId)
  }
)

export const ACTION_RESUME_DATASOURCE_SCAN = 'dataSource/scan/resume'
export const resumeDataSourceScan = createAsyncThunk(
  ACTION_RESUME_DATASOURCE_SCAN,
  async (dataSourceId: string) => {
    await apiService.postResumeDatasourceScan(dataSourceId)
  }
)

export const ACTION_RESET_PAUSE_RESUME_ERROR = 'dataSource/scan/resume'
export const resetPauseResumeError = createAsyncThunk(
  ACTION_RESET_PAUSE_RESUME_ERROR,
  async (dataSourceId: string) => {
    await graphqlService.execute(queryResetPauseResumeError(dataSourceId))
  }
)

const syncInfoSlice = createSlice({
  name: 'syncInfo',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSyncInfo.fulfilled, (state, { payload }) => {
      const {
        data: { registeredOn, status, numberOfObjects, numberOfEntities, configuration },
        files: { totalCount, syncedCount }
      } = payload

      state.data = {
        registeredOn,
        totalCount,
        syncedCount,
        status,
        numberOfObjects,
        numberOfEntities,
        name: configuration.name || '',
        dataSourceType: configuration.datasourceType,
        gDriveType: configuration?.gsuiteConfiguration?.accountType,
        dataSource: {
          configuration
        }
      }
    })
  }
})

export const { reset } = syncInfoSlice.actions

export default syncInfoSlice.reducer
