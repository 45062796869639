import {
  mapQueryEntitiesSummary,
  mapQueryEntitiesWidgetCompact,
  mapQueryEntitiesWidgetCompactDataSourceInfo,
  mapQueryEntityList,
  mapQueryEntityTypesSummary,
  mapQueryIdentifiers,
  mapQueryLocalEntities,
  mapQuerySampleData,
  queryDeleteEntity,
  queryEntitiesSummary,
  queryEntitiesWidgetCompact,
  queryEntitiesWidgetCompactDataSourceInfo,
  queryEntityList,
  queryEntityTypesSummary,
  queryExtendEntityLegalHold,
  queryIdentifiers,
  queryLocalEntities,
  queryPutEntityLegalHold,
  queryRemoveEntityLegalHold,
  querySampleData
} from './queries'
import service from '../../services/api/apiService'
import { getSortDirection, defaultSortParams, SortParams, sortByOrder } from '../../utils/sortUtil'
import {
  IGetEntitiesParams,
  EntitiesWidget,
  SORT_ORDER,
  DownloadListParams
} from '../../interfaces'
import { DATABASE_ID, DATA_SOURCE_ID } from '../../constants'
import graphqlService from '../../services/graphqlService'
import { Identifier, UserEntity } from '../../services/api/apiTypes'
import { ITableRow } from '../../components/SimpleGridTable'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface FetchSampleDataParams {
  entityId: string
}

export type SampleDataTableData = {
  header: string[]
  row: ITableRow[]
}
export interface SampleDataTable {
  tableName: string
  header: string[]
  rows: ITableRow[]
}

export type SampleDataResponseType = {
  sampleDataList: SampleDataTable[]
  entityName: string
  totalRecords: number
}

export type EntitiesListSettings = {
  list: Entity[]
  total: number
  sort: SortParams
  sampleDataList?: SampleDataTable[]
  entityName?: string
  totalRecords?: number
}
export type Entity = {
  id: string
  name: string
  objectsCount?: number
  riskStatus?: boolean
  datasourceCount?: number
  documentCount?: number
  sharedAttributes: number
  residencies?: string[]
  sharedDocumentCount?: number
  piiDataCount?: number
  attributesCount?: number
  ticketsCount?: number
  entityType?: string
  deletedUserEntityReferences?: string[]
  isOnLegalHold?: boolean
  hasHoldExpired?: boolean
  lastModifiedTime?: string
  tableCluster?: string
  userAccessCount?: number
  groupAccessCount?: number
}
export type EntitiesSummaryWidgetParams = {
  [DATA_SOURCE_ID]?: string
}
export type LocalEntitiesParams = {
  userEntityId: string
  [DATA_SOURCE_ID]?: string
}
export type EntityPolicy = {
  id: string
  name: string
  description: string
  owner: string
  systemDefined: boolean
  entitiesInRiskCount: number
  policyId: string
  policyName: string
}

export type EntitiesSummaryWidget = {
  total: number
  totalRisky: number
  list: EntityPolicy[]
}
export type LocalEntities = {
  attributesCount: number
  localEntitiesCount: number
  localEntities?: { id: string; attributes: { [key: string]: string } }[]
}

export type EntityTypeSummary = {
  id: string
  name: string
}

export const ACTION_ENTITIES_WIDGET_SUMMARY = 'entities/widgetSummary'
export const fetchEntitiesWidgetSummary = createAsyncThunk(
  ACTION_ENTITIES_WIDGET_SUMMARY,
  async (params: EntitiesSummaryWidgetParams) => {
    const resultRaw = await graphqlService.execute(queryEntitiesSummary(params))
    return mapQueryEntitiesSummary(resultRaw)
  }
)

const initialList = {
  list: [],
  total: 0,
  sort: defaultSortParams
}

export const ACTION_ENTITIES_LIST_FETCH = 'entities/list'
export const fetchEntities = createAsyncThunk(
  ACTION_ENTITIES_LIST_FETCH,
  async (filters: IGetEntitiesParams) => {
    return await service.getEntities(filters)
  }
)
export const ACTION_ENTITIES_LIST_FETCH_QUERY = 'entities/list_query'
export const fetchEntitiesByQuery = createAsyncThunk(
  ACTION_ENTITIES_LIST_FETCH_QUERY,
  async (params: IGetEntitiesParams) => {
    const resultRaw = await graphqlService.execute(queryEntityList(params))
    return mapQueryEntityList(resultRaw)
  }
)
export const fetchEntitiesWidgetData = createAsyncThunk(
  'entities/widget',
  async (filters: IGetEntitiesParams): Promise<EntitiesWidget> => {
    const results = await Promise.all([
      service.getEntities({ ...filters }),
      service.getEntities({ isAtRisk: true, ...filters }),
      service.getEntities({ isSensitive: true, ...filters })
    ]).catch((error) => {
      throw error
    })

    return {
      totalCount: results[0].total || 0,
      riskCount: results[1].total || 0,
      sensitiveCount: results[2].total || 0
    }
  }
)

export const getFlattedEntitiesList = (entities: UserEntity[]): Entity[] => {
  if (!Array.isArray(entities)) return []

  return entities.map((entity) => ({
    id: entity.id,
    //Todo: Name should be available from backend.
    name: entity && entity?.name ? entity.name[0] : '',
    documentCount: entity.documentCount || 0,
    sharedAttributes: entity.sharedAttributes?.length || 0,
    residencies: entity.residencies,
    sharedDocumentCount: entity.sharedDocumentCount || 0,
    piiDataCount: entity.piiDataCount || 0,
    riskStatus: entity.risky || false,
    entityType: entity.type || '',
    deletedUserEntityReferences: entity.deletedUserEntityReferences || [],
    isOnLegalHold: entity?.legalHoldStatus?.isOnLegalHold,
    lastModifiedTime: entity?.lastModifiedTime,
    tableCluster: entity?.tableCluster,
    hasHoldExpired: entity?.legalHoldStatus?.hasHoldExpired
  }))
}

export type EntitiesWidgetCompact = {
  entitiesCount: number
}

export interface EntitiesWidgetCompactParams {
  [DATABASE_ID]?: string
}

export const ACTION_ENTITIES_WIDGET_COMPACT_FETCH = 'entities/widgetCompact'
export const fetchEntitiesWidgetCompact = createAsyncThunk(
  ACTION_ENTITIES_WIDGET_COMPACT_FETCH,
  async (params: EntitiesWidgetCompactParams) => {
    const preparationResult = await graphqlService.execute(
      queryEntitiesWidgetCompactDataSourceInfo(params)
    )
    const dsInfo = mapQueryEntitiesWidgetCompactDataSourceInfo(preparationResult)

    const resultRaw = await graphqlService.execute(
      queryEntitiesWidgetCompact({
        datasourceId: dsInfo.dataSourceId,
        databaseName: dsInfo.databaseName
      })
    )
    return mapQueryEntitiesWidgetCompact(resultRaw)
  }
)
export const ACTION_LOCAL_ENTITIES_FETCH = 'entities/localEntities'
export const fetchLocalEntities = createAsyncThunk(
  ACTION_LOCAL_ENTITIES_FETCH,
  async (params: LocalEntitiesParams) => {
    const result = await graphqlService.execute(queryLocalEntities(params))
    return mapQueryLocalEntities(result)
  }
)

export const ACTION_ALL_ENTITIY_TYPES_SUMMARY_FETCH = 'entities/types/summary'
export const fetchEntityTypesSummary = createAsyncThunk(
  ACTION_ALL_ENTITIY_TYPES_SUMMARY_FETCH,
  async () => {
    const result = await graphqlService.execute(queryEntityTypesSummary())
    return mapQueryEntityTypesSummary(result)
  }
)

export interface DeleteEntityParam {
  entityId: string
}

export interface PutExtendLegalHoldEntityParam {
  entityId: string
  duration: number
}

export const ACTION_DELETE_ENTITY = 'entity/delete'
export const deleteEntity = createAsyncThunk(
  ACTION_DELETE_ENTITY,
  async ({ entityId }: DeleteEntityParam) => {
    await graphqlService.execute(queryDeleteEntity({ entityId }))
  }
)

export const ACTION_PUT_LEGAL_HOLD = 'entity/putLegalHold'
export const putEntityLegalHold = createAsyncThunk(
  ACTION_PUT_LEGAL_HOLD,
  async ({ entityId, duration }: PutExtendLegalHoldEntityParam) => {
    await graphqlService.execute(queryPutEntityLegalHold({ entityId, duration }))
  }
)

export const ACTION_EXTEND_LEGAL_HOLD = 'entity/extendLegalHold'
export const extendEntityLegalHold = createAsyncThunk(
  ACTION_EXTEND_LEGAL_HOLD,
  async ({ entityId, duration }: PutExtendLegalHoldEntityParam) => {
    await graphqlService.execute(queryExtendEntityLegalHold({ entityId, duration }))
  }
)

export const ACTION_REMOVE_LEGAL_HOLD = 'entity/removeLegalHold'
export const removeEntityLegalHold = createAsyncThunk(
  ACTION_REMOVE_LEGAL_HOLD,
  async ({ entityId }: DeleteEntityParam) => {
    await graphqlService.execute(queryRemoveEntityLegalHold({ entityId }))
  }
)

export type ErasedEntityAttribute = {
  attributeId: string
  values: string[]
  internalName?: string
}

export type ErasedEntity = {
  id: string
  entityID?: string
  attributes: ErasedEntityAttribute[]
  attributesFoundCount: number
  isNewAttributesFound: boolean
  createdAt: string
}
export const ACTION_FETCH_DELETED_ENTITIES = 'entities/deleted'
export const fetchDeletedEntities = createAsyncThunk(ACTION_FETCH_DELETED_ENTITIES, async () => {
  return service.getErasedEntities()
})

export interface CrateErasedEntityParams {
  attributes: Array<{ attributeId: string; values: string[] }>
}
export const ACTION_ADD_ENTITY_TO_ERASED_LIST = 'entities/addToErased'
export const createErasedEntity = createAsyncThunk(
  ACTION_ADD_ENTITY_TO_ERASED_LIST,
  async (params: CrateErasedEntityParams) => {
    return service.postErasedEntity(params)
  }
)
export interface UpdateErasedEntityParams extends CrateErasedEntityParams {
  id: string
}
export const ACTION_UPDATE_ERASED_ENTITY = 'entities/updateErased'
export const updateErasedEntity = createAsyncThunk(
  ACTION_UPDATE_ERASED_ENTITY,
  async (params: UpdateErasedEntityParams) => {
    return service.putErasedEntity(params)
  }
)

export interface DownloadJsonParams {
  datasourceId?: string
  entityId: string
}
export const ACTION_ENTITY_JSON_FETCH = 'entity/json'
export const fetchEntityAsJSON = createAsyncThunk(
  ACTION_ENTITY_JSON_FETCH,
  async (params: DownloadJsonParams) => {
    const { data } = await service.downloadEntityJson(params)
    return data
  }
)

export const ACTION_ENTITY_CSV_FETCH = 'entity/csv'
export const fetchEntityAsCSV = createAsyncThunk(
  ACTION_ENTITY_CSV_FETCH,
  async (params: DownloadListParams): Promise<{ data: string; fileName: string }> => {
    const { data, headers } = await service.downloadFile(params)
    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)

export const ACTION_SEND_EXPORT_ENTITY_CSV_REQUEST = 'entity/sendEntityCsvExportRequest'
export const sendEntityCsvExportRequest = createAsyncThunk(
  ACTION_SEND_EXPORT_ENTITY_CSV_REQUEST,
  async (params: DownloadListParams): Promise<{ data: string }> => {
    const { data } = await service.downloadFile(params)

    return { data }
  }
)

export const ACTION_FETCH_ANNOTATOR_IDENTIFIERS = 'entity/identifiers'
export const fetchIdentifiers = createAsyncThunk(ACTION_FETCH_ANNOTATOR_IDENTIFIERS, async () => {
  const raw = await graphqlService.execute(queryIdentifiers())
  return await mapQueryIdentifiers(raw)
})

export const ACTION_FETCH_SAMPLE_DATA = 'entities/sampleData'
export const fetchSampleData = createAsyncThunk(
  ACTION_FETCH_SAMPLE_DATA,
  async (params: FetchSampleDataParams) => {
    const raw = await graphqlService.execute(querySampleData(params))
    return mapQuerySampleData(raw)
  }
)

type EntitiesState = {
  widget?: EntitiesWidget
  widgetCompact?: EntitiesWidgetCompact
  widgetSummary?: EntitiesSummaryWidget
  all: EntitiesListSettings
  localEntities?: LocalEntities
  entityJson?: string
  entityCSV?: { data: string; fileName: string }
  entityTypes?: EntityTypeSummary[]
  identifiers?: Identifier[]
  deletedEntities: {
    sort: SortParams
    list?: ErasedEntity[]
    total?: number
  }
}

export const initialState: EntitiesState = {
  all: { ...initialList },
  deletedEntities: { ...initialList }
}

const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    resetLists: (state) => {
      state.all = { ...initialList }
    },
    resetWidget: (state) => {
      state.widget = initialState.widget
    },
    resetWidgetCompact: (state) => {
      state.widgetCompact = initialState.widgetCompact
    },
    resetWidgetSummary: (state) => {
      state.widgetSummary = initialState.widgetSummary
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetEntityTypesSummary: (state) => {
      state.entityTypes = initialState.entityTypes
    },
    resetEntityJSON: (state) => {
      state.entityJson = initialState.entityJson
    },
    resetEntityCSV: (state) => {
      state.entityCSV = initialState.entityCSV
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntities.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.all.list = [...getFlattedEntitiesList(list)]
      state.all.total = total
    })
    builder.addCase(fetchEntitiesByQuery.fulfilled, (state, { payload }) => {
      const { list, total } = payload
      state.all.list = list
      state.all.total = total
    })
    builder.addCase(fetchEntitiesWidgetData.fulfilled, (state, { payload }) => {
      state.widget = payload
    })
    builder.addCase(fetchEntitiesWidgetCompact.fulfilled, (state, { payload }) => {
      state.widgetCompact = payload
    })
    builder.addCase(fetchEntitiesWidgetSummary.fulfilled, (state, { payload }) => {
      state.widgetSummary = payload
    })
    builder.addCase(fetchLocalEntities.fulfilled, (state, { payload }) => {
      state.localEntities = payload
    })
    builder.addCase(fetchEntityTypesSummary.fulfilled, (state, { payload }) => {
      state.entityTypes = payload
    })
    builder.addCase(fetchEntityAsJSON.fulfilled, (state, action) => {
      state.entityJson = action.payload
    })
    builder.addCase(fetchEntityAsCSV.fulfilled, (state, { payload }) => {
      state.entityCSV = payload
    })
    builder.addCase(fetchDeletedEntities.fulfilled, (state, { payload }) => {
      state.deletedEntities.list = payload.list
      state.deletedEntities.total = payload.total
    })
    builder.addCase(fetchIdentifiers.fulfilled, (state, { payload }) => {
      state.identifiers = sortByOrder(payload, 'name', SORT_ORDER.ASC)
    })
    builder.addCase(fetchSampleData.fulfilled, (state, { payload }) => {
      state.all.sampleDataList = payload.sampleDataList
      state.all.entityName = payload.entityName
      state.all.totalRecords = payload.totalRecords
    })
  }
})

export const {
  setSort,
  resetLists,
  resetWidget,
  resetWidgetCompact,
  resetWidgetSummary,
  resetEntityTypesSummary,
  resetEntityJSON,
  resetEntityCSV
} = entitiesSlice.actions

export default entitiesSlice.reducer
