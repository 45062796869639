import { IGetPermitTableListParams, IPermitListItem } from './permitListSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { LIMIT_DEFAULT } from '../../constants'
import { FilterParams } from '../../interfaces'
import { gql } from 'graphql-request'

export const queryPermitListCards = (params: FilterParams): string => {
  const { ...listFilters } = params
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  const paramString = filterString ? `(filter: ${filterString})` : ''

  return gql`
      {
        permitListGroupedbyName${paramString}{
            objectCount
            ruleSet{
              edges{
                node{
                  name
                }
              }
            }
          }
      }
    `
}

export const mapQueryPermitListCards = (
  raw: any
): { list: IPermitListItem[]; total: number; objects: number } => {
  let totalObjects = 0
  const list: IPermitListItem[] = []
  raw.permitListGroupedbyName.forEach(({ ruleSet, objectCount = 0 }) => {
    totalObjects += objectCount
    const ruleSetInfo = ruleSet.edges[0]?.node
    ruleSetInfo && list.push({ ...ruleSetInfo, objectCount })
  })

  return { list, total: list.length, objects: totalObjects }
}

export const queryPermitListTable = (params: IGetPermitTableListParams): string => {
  const { page, ...listFilters } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  const filterString = listFilters.filter ? parameterizeArrayofObjects(listFilters.filter) : ''
  const paramString = filterString ? `filter: ${filterString}` : ''

  return gql`
      {
        permitList(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${paramString}){
          count,
          edges {
            node {
              updateAt
              updateBy
              ruleSet{
                edges {
                  node {
                    name
                  }
                }
              }
              datasource{
                edges {
                  node {
                    id
                    name
                    type
                  }
                }
              }
              permitListObjects{
                ... on Channel{
                  channelName
                  channelId
                }
                ... on Object{
                  id
                  name
                  type
                }
                ... on Mail{
                  messageId
                  recipients{
                    email
                  }
                }
              }
            }
          }
        }
      }
    `
}

export const mapQueryPermitListTable = (raw: any): { list: IPermitListItem[]; total: number } => {
  try {
    const list =
      raw.permitList.edges?.map(
        ({ node: { ruleSet, datasource, permitListObjects, updateBy, updateAt } }) => ({
          ruleSetName: ruleSet.edges[0]?.node?.name || '',
          datasourceName: datasource.edges[0]?.node?.name || '',
          datasourceType: datasource.edges[0]?.node?.type || '',
          datasourceId: datasource.edges[0]?.node?.id || '',
          channelName: permitListObjects.channelName || '',
          channelId: permitListObjects?.channelId || '',
          objectName: permitListObjects?.name || '',
          objectType: permitListObjects?.type || '',
          objectId: permitListObjects?.id || '',
          recipient: permitListObjects?.recipients ? permitListObjects?.recipients[0]?.email : '',
          emailMessageId: permitListObjects?.messageId || '',
          addedBy: updateBy || '',
          addedOn: updateAt || ''
        })
      ) || []

    return { list, total: raw.permitList?.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}
