import {
  mapQueryDsrDataSourcesList,
  mapQueryDsrDefaultDataSources,
  queryDefaultDataSources,
  queryDsrDataSourcesList
} from './queries'
import { FilterParams } from '../../../interfaces'
import graphqlService from '../../../services/graphqlService'
import { DataSourceSettings } from '../../dataSources/dataSourcesSlice'
import { defaultSortParams } from '../../../utils/sortUtil'
import { DataSource } from '../../../services/api/apiTypes'
import { PAGE } from '../../../constants'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export type CountOutOfTotal = {
  count: number
  total: number
}

export type ReportData = {
  status?: string
  statusDate?: string
  dataCollected: CountOutOfTotal
  dataSources: CountOutOfTotal
  generatedOn?: string
  sharingStatus?: string
  sharedOn?: string
  scheduledOn?: string
  reportTemplate?: string
  showIdentifiers?: boolean
}

export type ClosureData = {
  createdOn?: string
  status?: string
}

export type User = {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  residency?: string
  address?: string
  phoneNumber?: string
  uniqueID?: string
  dob?: string
}

export interface DSRRequestDetails {
  dataSources: DataSourceSettings
  report: ReportData
  closure: ClosureData
  defaultDatasources: (DataSource & { instancesDetectedCount?: number })[] | null
  defaultDatasourcesCount: number
}
const initialList: DataSourceSettings = {
  sort: defaultSortParams
}
export const initialState: DSRRequestDetails = {
  dataSources: initialList,
  report: {
    dataCollected: {
      count: 0,
      total: 0
    },
    dataSources: {
      count: 0,
      total: 0
    }
  },
  closure: {},
  defaultDatasources: null,
  defaultDatasourcesCount: 0
}

export interface DsrDataSourceFilters {
  attributeValidated?: boolean
  attributeDetected?: boolean
  assignee?: string[]
  dataValidationStatus?: string[]
}

export interface FetchDsrDataSourcesParams {
  entityId: string
  filters?: FilterParams
  dsrRequestId?: string
  dsrDataSourceFilters?: DsrDataSourceFilters
  [PAGE]?: number
  searchQuery?: string
}
export const ACTION_DSR_DATA_SOURCES = 'dataSources/dsr'
export const fetchDsrDataSourcesList = createAsyncThunk(
  ACTION_DSR_DATA_SOURCES,
  async (params: FetchDsrDataSourcesParams) => {
    const resultRaw = await graphqlService.execute(queryDsrDataSourcesList(params))
    return mapQueryDsrDataSourcesList(resultRaw)
  }
)

export const FETCH_DEFAULT_DATA_SOURCES = 'fetch/dsr/data-discovery/default-data-sources'
export const fetchDefaultDataSources = createAsyncThunk(
  FETCH_DEFAULT_DATA_SOURCES,
  async (params: FetchDsrDataSourcesParams) => {
    const resultRaw = await graphqlService.execute(queryDefaultDataSources(params))
    return mapQueryDsrDefaultDataSources(resultRaw)
  }
)

const requestListSlice = createSlice({
  name: 'dsrRequestDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDsrDataSourcesList.fulfilled, (state, { payload }) => {
      state.dataSources.list = payload.list
      state.dataSources.total = payload.total
    })
    builder.addCase(fetchDefaultDataSources.fulfilled, (state, { payload }) => {
      state.defaultDatasources = payload.defaultDatasources
      state.defaultDatasourcesCount = payload.defaultDatasourcesCount
    })
  }
})

export default requestListSlice.reducer
