import {
  queryBucketsList,
  queryBucketsCount,
  mapQueryBucketsList,
  mapQueryBucketsCount,
  queryImpactedObjectsCountByBucket,
  mapImpactedObjectsCountByBucket,
  queryEntitiesCountByBucket,
  mapQueryEntitiesCountByBucket
} from './queries'
import { getSortDirection, defaultSortParams, SortParams } from '../../utils/sortUtil'
import { FilterParams } from '../../interfaces'
import graphqlService from '../../services/graphqlService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export enum ContainerAccess {
  private = 'private',
  public = 'public',
  anonymousContainerRead = 'ANONYMOUS_CONTAINER_READ',
  anonymousBlobRead = 'ANONYMOUS_BLOB_READ'
}

export enum MappedContainerAccess {
  BLOB = 'blob',
  CONTAINER = 'container'
}
export interface Bucket {
  id: string
  bucketName: string
  bucketAccess: ContainerAccess
  objectsTotal: number
  impactedObjects: number
  entities: number
  bucketSize: string
  bucketOwner: string
  displayName?: string
  storageAccountName?: string
}

export interface IFetchParams {
  datasourceId?: string
  filters?: FilterParams
  page?: number
}

export interface BucketState {
  list: Bucket[]
  total: number
  objectsWithPIICount: number
  entitiesCount: number
  attributesCount: number
  sort: SortParams
}

const initialList = {
  sort: defaultSortParams
}

const initialState: BucketState = {
  list: [],
  total: 0,
  sort: defaultSortParams,
  objectsWithPIICount: 0,
  entitiesCount: 0,
  attributesCount: 0
}

export const ACTION_FETCH_BUCKETS_LIST = '/buckets/list'
export const fetchBucketsList = createAsyncThunk(
  ACTION_FETCH_BUCKETS_LIST,
  async (params: IFetchParams) => {
    const resultRaw = await graphqlService.execute(queryBucketsList(params))
    return mapQueryBucketsList(resultRaw)
  }
)

export const ACTION_FETCH_BUCKETS_COUNT = '/buckets/count'
export const fetchBucketsCount = createAsyncThunk(
  ACTION_FETCH_BUCKETS_COUNT,
  async (params: string) => {
    const resultRaw = await graphqlService.execute(queryBucketsCount(params))

    return mapQueryBucketsCount(resultRaw)
  }
)

export const ACTION_FETCH_IMPACTED_OBJECTS_COUNT_BY_BUCKET = '/buckets/impactedobject'
export const fetchImpactedObjectsCountByBucket = createAsyncThunk(
  ACTION_FETCH_IMPACTED_OBJECTS_COUNT_BY_BUCKET,
  async (params: IFetchParams) => {
    const resultRaw = await graphqlService.execute(queryImpactedObjectsCountByBucket(params))

    return mapImpactedObjectsCountByBucket(resultRaw)
  }
)

export const ACTION_FETCH_ENTITIES_COUNT = '/buckets/entitiesCount'
export const fetchEntitesCount = createAsyncThunk(
  ACTION_FETCH_ENTITIES_COUNT,
  async (params: IFetchParams) => {
    const resultRaw = await graphqlService.execute(queryEntitiesCountByBucket(params))

    return mapQueryEntitiesCountByBucket(resultRaw)
  }
)

const bucketsSlice = createSlice({
  name: 'buckets',
  initialState,
  reducers: {
    resetLists: (state) => {
      state = { ...state, ...initialList }
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBucketsList.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.total = payload.total
    })
    builder.addCase(fetchBucketsCount.fulfilled, (state, { payload }) => {
      state.total = payload
    })
    builder.addCase(fetchImpactedObjectsCountByBucket.fulfilled, (state, { payload }) => {
      state.objectsWithPIICount = payload
    })
    builder.addCase(fetchEntitesCount.fulfilled, (state, { payload }) => {
      state.entitiesCount = payload
    })
  }
})

export const { setSort, resetLists } = bucketsSlice.actions

export default bucketsSlice.reducer
