import { IGetAttributesTableListParams, Attribute } from './attributeListSlice'
import { LIMIT_DEFAULT } from '../../../../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../../../../utils/graphqlUtil'
import { gql } from 'graphql-request'

export const queryDSRDataDiscoveryAttributesTable = (
  params: IGetAttributesTableListParams
): string => {
  const { page, entityId, filters } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let commonParamString = ''
  if (entityId) {
    commonParamString += `,entityId:"${entityId}"`
  }
  const filterString = filters?.filter ? parameterizeArrayofObjects(filters.filter) : ''
  let paramString = ''
  if (filterString) {
    paramString += `, filter: ${filterString}`
  }
  const dataSourceIDString = filters?.datasourceIds ? JSON.stringify(filters.datasourceIds) : ''
  if (dataSourceIDString) {
    paramString += `, datasourceIds: ${dataSourceIDString}`
  }

  return gql`
    {
      attributeInstance(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${commonParamString}${paramString}) {
        count
        edges{
          node{
            id
            name
            values
            datasource{
              count
              edges{
                node{
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

export const queryDSRAttributesList = (params: IGetAttributesTableListParams): string => {
  const { page, entityId } = params
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let commonParamString = ''
  if (entityId) {
    commonParamString += `, entityId: "${entityId}"`
  }

  return gql`
    {
      attributeInstance(first: ${LIMIT_DEFAULT}, after: "${cursor}", ${commonParamString}) {
        edges {
          node {
            name
            attribute {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryDSRAttributesList = (
  raw: any
): { attributesList: Attribute[]; totalAttributes: number } => {
  const attributesList = raw.attributeInstance.edges.map(({ node }) => ({
    name: node.name,
    attribute: node.attribute.edges.map(({ node }) => node.id)
  }))

  return { attributesList, totalAttributes: raw.attributeInstance.count }
}

export const mapQueryDSRDataDiscoveryAttributesListing = (
  raw: any
): { list: Attribute[]; total: number } => {
  const list = raw.attributeInstance.edges.map(({ node }) => ({
    id: node.id,
    name: node.name,
    identifier: node.values,
    dataSourcesCount: node.datasource?.count || 0,
    datasources: node.datasource.edges.map(({ node }) => node.name)
  }))

  return { list, total: raw.attributeInstance.count }
}
